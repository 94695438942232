import parse from 'html-react-parser';
import React from 'react';

import style from './FaqAnswer.module.scss';
import { FaqT } from "../ClaimMessage";

type MainPropsT = {
    typeAnswer: FaqT['answerType'],
    answer: string|undefined,
    t: (text: string, data?: any) => string
}

const FaqAnswer: React.FC<MainPropsT> = ({ typeAnswer, answer, t }) => {

    if (typeAnswer === 'text'){
        return <div className={style.textContent}>{parse(t(answer))}</div>
    }

    if (typeAnswer === 'referenceNumber'){
        return (
            <div className={style.contentComponent}>
                {t('createClaim.refTopContent')}
            </div>
        )
    }
    if (typeAnswer === 'order'){
        return (
            <div className={style.contentComponent}>
                {t('chatContact.faqOrderAnswer')}
            </div>
        )
    }

    return (
        <div className={style.contentComponent}>
            Outside component <strong>{typeAnswer}</strong>
        </div>
    );
}
export default FaqAnswer;