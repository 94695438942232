import RepairMessageGroup, {
  RESOLUTION_TYPE as REPAIR_RESOLUTION_TYPE,
} from './RepairMessageGroup/RepairMessageGroup';

const messageGroups = {
  [REPAIR_RESOLUTION_TYPE]: RepairMessageGroup,
  "orderNewLuggage": undefined,
};

export default messageGroups;
