import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import FilterHeader from '../FilterElemementHeader/FilterHeader';
import FilterBody from '../FilterElementBody/FilterBody';
import { ReactComponent as OkIcon } from 'Assets/okey.svg';
import styles from './FilterBox.module.scss';

interface Elems {
  title: string;
  value: string;
  name: string;
  materialId?: number;
}
interface IProps {
  elems: Elems[];
  title: string;
  state: string[];
  rounded?: string;
  actionHandleClick?: (param: string) => void
}

export default function FilterBox(props: IProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { title, elems, rounded, state, actionHandleClick } = props;
  const onClickHeaderHandler = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleCheckboxClick = (value: string, materialId?: number) => {
    const param = materialId ? String(materialId) : value;
    actionHandleClick?.(param);
  };

  return (
    <div
      role="group"
      aria-labelledby="checkbox-group"
      className={cx(styles.root, {
        [styles.top]: rounded === 'top',
        [styles.bottom]: rounded === 'bottom',
      })}
    >
      <FilterHeader title={title} onClickHandler={onClickHeaderHandler} isOpen={isOpen} />
      <FilterBody isOpen={isOpen}>
        <ul className={styles.list}>
          {elems.map((value: Elems, index: number) => (
            <li key={index} className={styles.elem} onClick={() => handleCheckboxClick(value.value, value.materialId)}>
              <p className={styles.label}>{value.value}</p>
              <div
                className={cx(styles.input, {
                  [styles.isChecked]: state.includes(value.materialId ? String(value.materialId) : value.value),
                })}
              >
                <input
                  className={styles.hidden}
                  type="checkbox"
                  value={value.value}
                  name={value.name}
                  id={String(value.materialId)}
                  onChange={() => {}}
                />
                <div className={styles.icon}>
                  <OkIcon />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </FilterBody>
    </div>
  );
}