import Modal from "Components/Modal/Modal";
import Lottie from 'react-lottie';
import cx from 'classnames';
import React from 'react';

import { useDeviceType } from "Helpers/responsiveContainers";
import animationSuccess from 'Assets/lotties/loading.json';
import style from './ModalWaitRedirectShop.module.scss';
import useTranslate from "Hooks/useTranslate";

const lottieOptions = {
    loop: true,
};

const ModalWaitRedirectShop: React.FC = () => {

    const { isMobile } = useDeviceType();
    const t = useTranslate();

    return (
        <Modal open onClose={() => null} hideTitle hideCloseButton animatedContentClassName={cx(style.root, { [style.mobile]: isMobile })}>
            <div className={style.body}>
                <div className={style.content}>
                    <Lottie
                        options={{
                            ...lottieOptions, 
                            animationData: animationSuccess
                        }} 
                        height={120} 
                        width={120}
                    />
                    <div className={style.contentText}>
                        {t('shop.modalWaitingRedirectShop')}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default React.memo(ModalWaitRedirectShop);