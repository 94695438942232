import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import WireTransfer from 'PageComponents/LuggageStore/ShoppingCartMainScreen/Payments/WireTransfer/WireTransfer';
import { changeShopCounter, PaymentChooseT, setDelivery, ShopStateT } from 'Store/modules/luggageStore/shop';
import CreditCard from 'PageComponents/LuggageStore/ShoppingCartMainScreen/Payments/CreditCard/CreditCard';
// import Paypal from 'PageComponents/LuggageStore/ShoppingCartMainScreen/Payments/Paypal/Paypal';
import Payments from 'PageComponents/LuggageStore/ShoppingCartMainScreen/Payments/Payments';
import styles from './PaymentsMethods.module.scss';
import { validField } from 'Helpers/validForm';
import { Modal, Button } from 'Components';
import { AppStateT } from 'Store/modules';

type MainProps = {
    actionClose: () => void,
    show: boolean,
    amountTotal: number
}

const PaymentsMethods: React.FC<MainProps> = ({ actionClose, show, amountTotal }) => {

    const { delivery, configShop } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const [paymentMethod, setPaymentMethod] = useState<PaymentChooseT>(null);
    const [validActual, setValidActual] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (show){
            setPaymentMethod(null);
        }
    }, [show]);

    const checkValidation = useCallback(() => {
        if (paymentMethod === 'WIRE_TRANSFER' || paymentMethod === 'PAYPAL'){
            setValidActual(true);
        } else {
            setValidActual(false);
        }
    }, [paymentMethod]);

    useEffect(() => {
        checkValidation();
    }, [paymentMethod, checkValidation]);

    const checkValidCreditCard = useCallback(() => {
        const dataChecks = ['cardNumber', 'exp', 'cvv'];
        for(let i=0;i<dataChecks.length;i++){
            if (!validField(`creditCard-${dataChecks[i]}`, delivery.payment.creditCard[dataChecks[i]])){
                return setValidActual(false);
            }
        }
        setValidActual(true);
    }, [delivery.payment.creditCard]);

    useEffect(() => {
        if (show){
            if (paymentMethod === 'CREDIT_CARD'){
                checkValidCreditCard();
            } else if (paymentMethod === 'PAYPAL'){
                // checkValidPaypal();
                setValidActual(true);
            }
        }
    }, [show, delivery.payment, paymentMethod, checkValidCreditCard]);

    const actionChangeMethod = useCallback((paymentMethod: PaymentChooseT) => {
        setPaymentMethod(paymentMethod);
    }, []);

    const actionBack = useCallback(() => {
        if (!paymentMethod){
            actionClose();
        } else {
            setPaymentMethod(null);
        }
    }, [actionClose, paymentMethod]);

    const actionConfirm = useCallback(() => {
        if (validActual){
            dispatch(setDelivery({
                ...delivery,
                paymentChoose: paymentMethod
            }));
            dispatch(changeShopCounter());
            actionClose();
        }
    }, [dispatch, validActual, paymentMethod, actionClose, delivery]);

    const actionChangeDataWireTransfer = (type: string, data: string) => {
        dispatch(setDelivery({
            ...delivery,
            payment: {
                ...delivery.payment,
                creditCard: {
                    ...delivery.payment.creditCard,
                    [type]: data
                }
            }
        }));
    }

    return (
        <Modal open={show} onClose={() => actionClose()} title="Payment method" back={actionBack} contentClassName={styles.root}>
            {!paymentMethod ?
                <Payments
                    validField={validField}
                    mobile
                    actionChangeMethod={actionChangeMethod}
                    cartAmount={amountTotal}
                    actionClose={actionClose}
                />:
                <>
                    <div className={styles.methods}>
                        {(paymentMethod === 'WIRE_TRANSFER' && configShop.payment.wireTransferData) && 
                            <WireTransfer currency={configShop.currency.symbol} t={t} configWireTransfer={configShop.payment.wireTransferData} />
                        }
                        {paymentMethod === 'CREDIT_CARD' && 
                            <CreditCard actionChange={actionChangeDataWireTransfer} payments={delivery.payment} validField={validField} mobile t={t} currency={configShop.currency.symbol} />
                        }
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button type="submit" className={styles.btn} disabled={!validActual} onClick={actionConfirm}>
                            {t('common.confirm')}
                        </Button>
                    </div>
                </>
            }
        </Modal>
    );
}
export default PaymentsMethods;