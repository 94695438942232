import { ClaimT, ReviewT } from 'api/modules/claim';
import { error, loaded } from 'Helpers/store-async';
import {
  WEBSOCKET_CLAIM_VERDICT_RECEIVED,
  WEBSOCKET_NEW_STATUS_RECEIVED,
  WebsocketClaimVerdictReceivedT,
  WebsocketNewClaimStatusReceivedT,
} from 'Store/modules/websocket';
import { CLAIM_STATUSES } from 'Config/statuses';

export const CLAIM_LIST_SUCCESS = 'CLAIM_LIST_SUCCESS';
export const CLAIM_LIST_FAILURE = 'CLAIM_LIST_FAILURE';
export const SUBMIT_REVIEW = 'SUBMIT_REVIEW';
export const INCREASE_NOTIFICATION_COUNT = 'INCREASE_NOTIFICATION_COUNT';
export const CLEAR_NOTIFICATION_COUNT = 'CLEAR_NOTIFICATION_COUNT';
export const SET_DOWNLOAD_FILE = 'SET_DOWNLOAD_FILE';
export const SET_LIST_MISSING_DOCS = 'SET_LIST_MISSING_DOCS';
export const LIST_MISSING_DOCS = 'LIST_MISSING_DOCS';
export const LIST_UPDATE_CLAIM = 'LIST_UPDATE_CLAIM';
export const SET_LOADING_CLAIMS = 'SET_LOADING_CLAIMS';
export const SET_MISING_DOCUMENTS_EXAMPLES = 'SET_MISING_DOCUMENTS_EXAMPLES';
export const REMOVE_MISING_DOCUMENTS_EXAMPLES = 'REMOVE_MISING_DOCUMENTS_EXAMPLES';

export type ClaimListSuccessT = {
  type: typeof CLAIM_LIST_SUCCESS;
  data: ClaimT[];
};

export type ClaimListFailureT = {
  type: typeof CLAIM_LIST_FAILURE;
  error: string;
};

export type SubmitReviewT = {
  type: typeof SUBMIT_REVIEW;
  payload: ReviewT;
};

export type IncreaseNotificationCountT = {
  type: typeof INCREASE_NOTIFICATION_COUNT;
  data: string;
};

export type ClearNotificationCountT = {
  type: typeof CLEAR_NOTIFICATION_COUNT;
  data: string;
};

export type SetDownloadPath = {
  type: typeof SET_DOWNLOAD_FILE;
  filePath: string,
};

export type SetUpdateClaimT = {
  type: typeof LIST_UPDATE_CLAIM;
  claim: ClaimT,
};

export type SetMissingDocumentExamplesT = {
  type: typeof SET_MISING_DOCUMENTS_EXAMPLES;
  examples: MissingDocExampleT[],
  claimId: string
};

export type RemoveMissingDocumentExamplesT = {
  type: typeof REMOVE_MISING_DOCUMENTS_EXAMPLES;
  claimId: string
};

export type MissingDocT = {
  SFTag: string,
  name: string,
  file: string,
  url: string
}

export type MissingDocExampleT = {
  name?: string,
  id?: string,
  SFTag?: string,
  examples: {
    isDefault: boolean,
    type: 'TEXT'|'URL',
    text?: string,
    url?: string
  }[],
  externalUrl?: string
}

export type MissingDocsT = {
  type: typeof SET_LIST_MISSING_DOCS;
  claimId?: string|undefined
}

export type SetMissingDocs = {
  type: typeof LIST_MISSING_DOCS;
  data: MissingDocT[];
};

export type SetLoadingClaimsT = {
  type: typeof SET_LOADING_CLAIMS;
  data: boolean
};

export type ActionTypeT =
  | ClaimListSuccessT
  | ClaimListFailureT
  | WebsocketClaimVerdictReceivedT
  | WebsocketNewClaimStatusReceivedT
  | ClearNotificationCountT
  | IncreaseNotificationCountT
  | SetDownloadPath
  | SetMissingDocs
  | SetUpdateClaimT
  | SetLoadingClaimsT
  | SetMissingDocumentExamplesT
  | RemoveMissingDocumentExamplesT;

export type StateT = {
  loading: boolean;
  loaded: boolean;
  data: ClaimT[];
  error: string | null;
  missingDocs: MissingDocT[],
  downloadPath: string|null,
  archive: boolean
  missingDocumentExamples: {
    claimId: string,
    missingDocExamples: MissingDocExampleT[]
  }[]
};

const initialState: StateT = {
  loading: false,
  loaded: false,
  data: [],
  error: null,
  missingDocs: [],
  downloadPath: null,
  archive: false,
  missingDocumentExamples: []
};

export default function claimReducer(state: StateT = initialState, action: ActionTypeT): StateT {
  switch (action.type) {
    case CLAIM_LIST_SUCCESS: {
      return loaded<StateT, ClaimT[]>(state, action.data);
    }
    case CLAIM_LIST_FAILURE: {
      return error<StateT>(state, action.error);
    }
    case WEBSOCKET_CLAIM_VERDICT_RECEIVED: {
      return websocketClaimVerdictReceived(state, action);
    }
    case WEBSOCKET_NEW_STATUS_RECEIVED: {
      return handleWebsocketNewClaimStatusReceived(state, action);
    }
    case REMOVE_MISING_DOCUMENTS_EXAMPLES: {
      return {
        ...state,
        missingDocumentExamples: state.missingDocumentExamples.filter(ex => ex.claimId !== action.claimId)
      }
    }
    case SET_MISING_DOCUMENTS_EXAMPLES: {
      return {
        ...state,
        missingDocumentExamples: state.missingDocumentExamples.find(ex => ex.claimId === action.claimId) ?
          state.missingDocumentExamples.map(ex => {
            if (ex.claimId === action.claimId) ex.missingDocExamples = action.examples;
            return ex;
          }) : [
            ...state.missingDocumentExamples,
            ...[{ claimId: action.claimId, missingDocExamples: action.examples }]
          ]
      }
    }
    case CLEAR_NOTIFICATION_COUNT: {
      return {
        ...state,
        data: state.data.map((claim) => {
          if (claim.id !== action.data) {
            return claim;
          }

          return {
            ...claim,
            unreadCount: 0,
          };
        }),
      };
    }

    case SET_DOWNLOAD_FILE:

      return {
        ...state,
        downloadPath: action.filePath
      }

    case INCREASE_NOTIFICATION_COUNT: {
      return {
        ...state,
        data: state.data.map((claim) => {
          if (claim.id !== action.data) {
            return claim;
          }

          return {
            ...claim,
            unreadCount: claim.unreadCount + 1,
          };
        }),
      };
    }

    case LIST_MISSING_DOCS: {
      return {
        ...state,
        missingDocs: action.data
      }
    }

    case LIST_UPDATE_CLAIM: return {
      ...state,
      data: state.data.map(data => {
        if (data.id === action.claim.id){
          data = action.claim;
        }
        return data;
      })
    }

    case SET_LOADING_CLAIMS: return {
      ...state,
      loading: action.data
    }

    default: {
      return state;
    }
  }
}

export function fetchClaimListSuccess(data: ClaimT[]): ClaimListSuccessT {
  return {
    type: CLAIM_LIST_SUCCESS,
    data,
  };
}

export function fetchClaimListFailure(error: string): ClaimListFailureT {
  return {
    type: CLAIM_LIST_FAILURE,
    error,
  };
}

export function setReview(review: ReviewT): SubmitReviewT {
  return {
    type: SUBMIT_REVIEW,
    payload: review,
  };
}

export function fetchMissongDocs(claimId?: string|undefined): MissingDocsT {
  return {
    type: SET_LIST_MISSING_DOCS,
    claimId
  };
}

export function actionUpdateClaimFromList(claim: ClaimT): SetUpdateClaimT {
  return {
    type: LIST_UPDATE_CLAIM,
    claim
  };
}

export function setLoadingClaims(data: boolean): SetLoadingClaimsT {
  return {
    type: SET_LOADING_CLAIMS,
    data
  };
}

function websocketClaimVerdictReceived(
  state: StateT,
  action: WebsocketClaimVerdictReceivedT,
): StateT {
  return {
    ...state,
    data: state.data.map((claim) => {
      if (claim.id !== action.data.claimId) {
        return claim;
      }

      return {
        ...claim,
        verdict: action.data,
      };
    }),
  };
}

function handleWebsocketNewClaimStatusReceived(
  state: StateT,
  action: WebsocketNewClaimStatusReceivedT,
): StateT {
  return {
    ...state,
    data: state.data.map((claim) => {
      if (claim.id !== action.data.claimId) {
        return claim;
      }

      return {
        ...claim,
        // claimStatus: [...claim.claimStatus, action.data],
        closed: [CLAIM_STATUSES.CANCELLED, CLAIM_STATUSES.CLOSED].includes(action.data.status),
      };
    }),
  };
}

export function increaseNotificationCount(claimId: string): IncreaseNotificationCountT {
  return {
    type: INCREASE_NOTIFICATION_COUNT,
    data: claimId,
  };
}

export function clearNotificationCount(claimId: string): ClearNotificationCountT {
  return {
    type: CLEAR_NOTIFICATION_COUNT,
    data: claimId,
  };
}

export function setDownloadFile(filePath: string): SetDownloadPath {
  return {
    type: SET_DOWNLOAD_FILE,
    filePath
  };
}

export function setMissingDocs(data: MissingDocT[]): SetMissingDocs {
  return {
    type: LIST_MISSING_DOCS,
    data
  };
}

export function fetchMissingDocs(claimId: string|undefined) {
  return {
    type: SET_LIST_MISSING_DOCS,
    claimId: claimId
  };
}

export function setMissingDocumentExamples(claimId: string, examples: MissingDocExampleT[]): SetMissingDocumentExamplesT {
  return {
    type: SET_MISING_DOCUMENTS_EXAMPLES,
    claimId: claimId,
    examples
  };
}

export function removeMissingDocumentExamples(claimId: string): RemoveMissingDocumentExamplesT {
  return {
    type: REMOVE_MISING_DOCUMENTS_EXAMPLES,
    claimId: claimId
  };
}