import React from 'react';

import { ReactComponent as BackIcon } from 'Assets/back.svg';
import { mobileHeaderHeight } from "../ClaimListMobile";
import style from './ChatHeader.module.scss';
import useKeyImage from "Hooks/useKeyImage";
import { ClaimT } from "api/modules/claim";

type MainPropsT = {
    claim: ClaimT,
    t: (data: string) => string,
    actionBack: () => void
}

const ChatHeader: React.FC<MainPropsT> = ({ claim, t, actionBack }) => {

    const urlImage = useKeyImage(claim);

    return (
        <div className={style.root} style={{ height: mobileHeaderHeight }}>
            <BackIcon className={style.icon} style={{ height: mobileHeaderHeight }} onClick={actionBack} />
            {urlImage &&
                <div className={style.photo} style={{ backgroundImage: `url(${urlImage})` }} />
            }
            {claim &&
                <div className={style.dataId}>
                    <div className={style.label}>{t('caseDetails.caseNumberLabel')}</div>
                    <div className={style.caseNumber}>{claim.caseNumber}</div>
                </div>
            }
        </div>
    );
}
export default React.memo(ChatHeader);