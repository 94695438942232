import React, { useCallback } from 'react';
// import { useSelector } from 'react-redux';
import messageGroups from './messageGroups';
import { RESOLUTION_TYPE } from 'PageComponents/Chat/Chat/Chat.constants';
// import type { AppStateT } from 'Store/modules';
// import type { ClaimChatStateT } from 'Store/modules/claimChat';
import { ClaimHistoryT } from 'api/modules/case-resolution';

interface IUserResolutionBlock {
  as: any;
  isNotMobile: boolean;
  onChooseResolution: (resolution: RESOLUTION_TYPE) => void;
  setCaseResolutionModal: (resolution: RESOLUTION_TYPE) => void;
  statusInfo: ClaimHistoryT;
}

export default function UserResolutionBlock(props: IUserResolutionBlock) {
  const { onChooseResolution, setCaseResolutionModal, isNotMobile, statusInfo } = props;

  // const claimData = useSelector<
  //   AppStateT,
  //   Pick<ClaimChatStateT['data'], 'claim' | 'verdict' | 'resolution'>
  // >(({ claimChat }) => ({
  //   claim: claimChat.data.claim,
  //   verdict: claimChat.data.verdict,
  //   resolution: claimChat.data.resolution,
  // }));

  const claimData = {
    claimId: statusInfo.status.claimId,
    verdict: statusInfo.verdict,
    resolution: statusInfo.resolution,
  };

  const setResolution = useCallback(
    (resolution: RESOLUTION_TYPE) => {
      if (isNotMobile) {
        onChooseResolution(resolution);
      } else {
        setCaseResolutionModal(resolution);
      }
    },
    [onChooseResolution, setCaseResolutionModal, isNotMobile],
  );

  if (!claimData.claimId) {
    return null;
  }

  const resolutionType = claimData?.resolution?.type;
  const MessageGroup = resolutionType && messageGroups[resolutionType];

  if (!MessageGroup) {
    return null;
  }

  return <MessageGroup statusInfo={statusInfo} setResolution={setResolution} />;
}
