import React from 'react';
import cx from 'classnames';
import { MessageModal } from 'Components';
import styles from './ActionModal.module.scss';

interface IActionModalItem {
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  icon: React.ReactElement;
  text: string;
  description?: string;
  className?: string;
}

interface IActionModal {
  open: boolean;
  children: React.ReactNode | string;
  onClose: (evt: React.MouseEvent<HTMLElement>) => void;
  closeOnClickOutside?: boolean;
  className?: string;
}

export function ActionModalItem(props: IActionModalItem) {
  return (
    <li className={cx(styles.listItem, props.className)}>
      <button onClick={props.onClick}>
        <span className={styles.itemIcon}>{props.icon}</span>

        <span className={styles.main}>{props.text}</span>
        {props.description && <span className={styles.description}>{props.description}</span>}
      </button>
    </li>
  );
}

export default function ActionModal(props: IActionModal) {
  const { open, closeOnClickOutside, className, onClose } = props;

  return (
    <MessageModal open={open} onClose={onClose} closeOnClickOutside={closeOnClickOutside}>
      <ul className={cx(styles.list, className)}>{props.children}</ul>
    </MessageModal>
  );
}
