import { blobToBase64 } from 'base64-blob';

export async function fileToBlob(file: File) {
  const image = await blobToBase64(file);

  return {
    img: image,
    name: file.name,
  };
}

export default async function filesToBlob(files: File[]) {
  const arrayOfPromises = files.map(fileToBlob);

  return Promise.all(arrayOfPromises);
}
