import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import type { AccountSettingsValueT } from 'Store/modules/accountSettings';
import { HelpCenter, PersonalInformation } from 'PageComponents/Settings';
import runApi, { ApiSendMethods, ResultApiTypeT } from 'api/base/runApi';
import { setAccountSettings } from 'Store/modules/accountSettings';
import type { StateT as UserStateT } from 'Store/modules/user';
import { clearCart } from 'Store/modules/luggageStore/shop';
import { useMobile } from 'Helpers/responsiveContainers';
import { Option } from 'Store/modules/accountSettings';
import {  updateUserReset } from 'Store/modules/user';
import { logout } from 'Store/modules/authorization';
import styles from './AccountSettings.module.scss';
import { runNotify } from 'Helpers/notifications';
import { setUserData } from 'Store/modules/user';
import { OptionItem } from 'PageComponents/Chat';
import type { AppStateT } from 'Store/modules';
import { actionShowCookies } from "Helpers/browser";

let timer: any = null;

function AccountSettings() {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useMobile();

    const { data: user, accountInfo } = useSelector<AppStateT, UserStateT>((state) => state.user);
    const setting = useSelector<AppStateT, AccountSettingsValueT>((state) => state.accountSettings.setting);
    const [saving, setSaving] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);

    useEffect(() => {
        return clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (errorEmail){
            timer = setTimeout(() => {
                setErrorEmail(false);
            }, 6000);
        }
    }, [errorEmail]);

    const t = useCallback((frase: string, data?: any) => {
        const resultText = translate(frase, data);
        if (!resultText) return frase;
        return resultText;
    }, [translate])

    function chooseSetting(value: AccountSettingsValueT) {
        dispatch(setAccountSettings(value));
    }

    const changePersonalInfo = useCallback((personalInfo) => {
        setSaving(true);
        runApi('user', personalInfo, (r: ResultApiTypeT) => {
            if (r.result){
                dispatch(setUserData(r.data));
                runNotify({ message: t('common.updateChanged'), type: 'success' });
            } else {
                runNotify({ message: r.error?.message || t('registration.errorRegister'), type: 'error' });
                if (r.error.code === 'USER_EMAIL_EXIST'){
                    setErrorEmail(true);
                }
            }
            setSaving(false);
        }, ApiSendMethods.patch);
    }, [dispatch, t]);

    const resetPersonalInfoFormState = useCallback(() => {
        dispatch(updateUserReset());
    }, [dispatch]);

    const logoutHandler = useCallback(() => {
        dispatch(logout());
        dispatch(clearCart(true));
        window.location.href = '/';
    }, [dispatch]);

    const settingsOptions = [
        {
            title: t('common.cookiesInfo'),
            chevron: true,
            onClick: actionShowCookies,
        },
        {
            title: t('common.personalInformation'),
            chevron: true,
            onClick: () => {
                chooseSetting(Option.PERSONAL_INFORMATION);
            },
        },
        {
            title: t('common.helpCenter'),
            chevron: true,
            onClick: () => {
                chooseSetting(Option.HELP_CENTER);
            },
        },
    ];

    function optionContent() {
        switch (setting) {
            case Option.PERSONAL_INFORMATION:
                return (
                    <PersonalInformation
                        userData={user}
                        onSubmit={changePersonalInfo}
                        submitted={accountInfo.loaded}
                        error={accountInfo.error}
                        reset={resetPersonalInfoFormState}
                        actionLogout={logoutHandler}
                        isSubmitting={saving}
                        errEmail={errorEmail}
                        setErrEmail={setErrorEmail}
                    />
                );
            case Option.HELP_CENTER:
            case Option.HELP_CENTER_DESCRIPTIONS:
                return <HelpCenter setting={setting} chooseSetting={chooseSetting} />;

            default:
                return (
                    <div className={styles.optionsContainer}>
                        {settingsOptions.map(({ onClick, ...option }, idx) => (
                            <OptionItem
                                key={idx}
                                data={option}
                                onClick={onClick}
                                titleClassName={styles.optionItemTitle}
                                bodyClassName={styles.optionItemBody}
                                fixedBorderRadius
                            />
                        ))}
                    </div>
                );
        }
    }

    return (
        <div
            className={cx(styles.root, {
                [styles.mobile]: isMobile,
                [styles.optionContent]: setting !== Option.HELP_CENTER,
                [styles.noPadding]: setting === Option.PERSONAL_INFORMATION
            })}
        >
            {optionContent()}
            {/* {(setting === Option.ACCOUNT_SETTINGS ||
                (setting === Option.PERSONAL_INFORMATION && !isMobile) ||
                !setting) && (
                <Button
                    color="secondary"
                    onClick={logoutHandler}
                    className={styles.buttonContainer}
                    type="button"
                    animation={!isMobile}
                >
                    {t('common.logOut')}
                </Button>
            )} */}
        </div>
    );
}

export default AccountSettings;
