import React, { useCallback, useLayoutEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'Assets/cross-icon.svg';

import SideBarDesktop from 'Components/SideBarDesktop/SideBarDesktop';
import AnimatedModal from 'Components/AnimatedModal/AnimatedModal';
import { changeShowModalLanguages } from 'Store/modules/page';
import { useDeviceType } from 'Helpers/responsiveContainers';
import ListLanguages from './ListLanguages/ListLanguages';
import styles from './SelectLanguages.module.scss';
import { AppStateT } from 'Store/modules';

/*
 *  Show modal of chooose language
 */
const SelectLanguage: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const showModalLanguages = useSelector<AppStateT, boolean>((state) => state.page.showModalLanguages);
    const { isMobile } = useDeviceType();

    const onCloseAction = useCallback(() => {
        dispatch(changeShowModalLanguages(false));
    }, [dispatch]);

    useLayoutEffect(() => {

        if (!isMobile && showModalLanguages){

            const listenerOutside = (event: any) => {

                // Check if this click is not on language menu link
                const elementMenuLanguages = document.querySelector('.changeLanguageLink');
                if (!(elementMenuLanguages && elementMenuLanguages.contains(event.target))){

                    const elementModal = document.querySelector('[class^="SelectLanguages_sideBarTitle"],[class^="SideBarDesktop_root"]');
                    if (elementModal){
                        if (!elementModal.contains(event.target)) {
                            onCloseAction();
                        }
                    }

                }

            }
        
            const listenerKeyEscape = (event: KeyboardEvent) => {
                if (event.key === 'Escape'){
                    onCloseAction();
                }
            }

            document.addEventListener("mousedown", listenerOutside);
            document.addEventListener("keydown", listenerKeyEscape);

            return () => {
                document.removeEventListener("mousedown", listenerOutside);
                document.removeEventListener("keydown", listenerKeyEscape);
            }
        }


    }, [isMobile, onCloseAction, showModalLanguages]);

    if (isMobile){
        return <AnimatedModal
            children={<ListLanguages closeAction={onCloseAction} />}
            onClose={onCloseAction}
            open={showModalLanguages}
            closeOnClickOutside
            containerClassName={styles.messageModalContainer}
        />
    }

    return (
        <>
            <SideBarDesktop
                children={<ListLanguages closeAction={onCloseAction} showHeader={false} />}
                show={showModalLanguages}
                backdrop
                animation="right"
                closeOnClickOutside
                onClose={onCloseAction}
                backdropClassName={styles.backgropDesktop}
                headerTitle={() => (
                    <>
                        <div className={styles.sideBarTitle}>
                            {t('labels.selectLanguage')}

                        </div>
                        <div className={styles.closeIconHeader} onClick={onCloseAction}>
                            <CloseIcon />
                        </div>
                    </>
                )}
                containerClassName={styles.reportProblemSidebarMobile}
            />
        </>)
}

export default SelectLanguage;