import React, { useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
import parse from 'html-react-parser';
import cx from 'classnames';

import ChatGoogleReview from "PageComponents/Chat/Chat/ChatGoogleReview/ChatGoogleReview";
import ChatMessageGroup from "Components/ChatMessageGroup/ChatMessageGroup";
import StatusRow from "Components/CaseStatus/StatusRow/StatusRow";
import { useDeviceType } from "Helpers/responsiveContainers";
import style from './ClaimBlockedOnChat.module.scss';
import { setShowContact } from "Store/modules/page";
import type { ClaimT } from 'api/modules/claim';
import Order from "Components/Order/Order";
import { useDispatch } from "react-redux";
import useHistory from "Hooks/useHistory";

type MainProps = {
    claim: ClaimT,
    reason?: string
}

const ClaimBlockedOnChat: React.FC<MainProps> = ({ claim, reason }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const claimHistory = useHistory(claim);
    const { isMobile } = useDeviceType();
    const navigate = useNavigate();

    const textReason = useMemo(() => (): string => {
        if (claim?.blockReason && claim.blockReason === 'TK_GERMANY_POSTAL_CODE'){
            return 'common.noServeAirline';
        }
        return 'caseDetails.blockedClaim';
    }, [claim?.blockReason]);

    useLayoutEffect(() => {
        const infoContact = document.querySelector("#infoContact strong");

        const clickEvent = (event: any) => {
            if (isMobile){
                navigate(`/${claim.id}/contact`);
            } else {
                dispatch(setShowContact(claim.id));
            }
        }

        if (infoContact){
            infoContact.addEventListener('click', clickEvent);
            return () => {
                infoContact.removeEventListener('click', clickEvent);
            }
        }
    }, [dispatch, claim.id, isMobile, navigate]);


    const introMessages = [
        {
            render: () => reason ? <>{reason}</> : parse(t(textReason(), { caseNumber: `<strong>${claim.caseNumber}</strong>`, airlineName: `<strong>${claim.airlineName}</strong>` }))
        },
        {
          render: () => (
            <div className={style.infoContact} id="infoContact">{parse(t('caseDetails.blockedClaimContact'))}</div>
          )
        },
        {
            render: () => (
                <>
                    {t('caseDetails.actualCaseStatus')}: <strong>{t(`caseStatuses.${claim.status}`)}</strong>
                    <StatusRow
                        claim={claim}
                        date={new Date()}
                        status={claim.status}
                        index={0}
                        t={t}
                        id={claim.id}
                        showOnlyContent
                        className={style.statusContent}
                    />
                </>
            ),
        },
    ];

    return (
        <div className={cx(style.root)}>
            <ChatMessageGroup
                as="MessageGroup"
                direction="incoming"
                avatar="bot"
                data={introMessages}
            />
            {(!!claim.case?.review?.googleMapsUrl || !!claim.case?.review?.trustPilotUrl) &&
                <ChatGoogleReview claimId={claim.id} review={claim.case?.review} />
            }
            {(claim.orders && Array.isArray(claim.orders) && claim.orders.length > 0) &&
                <Order claim={claim} statusInfo={claimHistory[claimHistory.length -1]} />
            }
        </div>
    );
}
export default ClaimBlockedOnChat;