import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import { PaymentsT } from 'Store/modules/luggageStore/shop';
import { Input } from 'Components';

import { chooseImageCart } from 'Helpers/validForm';
import styled from './CreditCard.module.scss';

type MainProps = {
    actionChange: (type: string, data: string) => void,
    payments: PaymentsT,
    validField: (type: string, data: any) => boolean,
    mobile?: boolean,
    blurAction?: () => void,
    t: (data: string, obj?: any) => string,
    currency: string
}

const CreditCard: React.FC<MainProps> = ({ actionChange, payments, validField, mobile = false, blurAction, t, currency }) => {

    const [err, setErr] = useState({ cardNumber: false, exp: false, cvv: false });
    const [cardSrc, setCardSrc] = useState(null);

    useEffect(() => {
        setCardSrc(chooseImageCart(payments.creditCard.cardNumber));
    }, [payments.creditCard.cardNumber]);

    const actionValidate = (type: string, data: string) => {
        const _data = data.trim();

        blurAction?.();
        if (_data.length === 0){
            return setSt(type, false);
        }

        if (type === 'cardNumber' && !validField('creditCard-cardNumber', _data)) return setSt(type, true);
        if (type === 'exp' && !validField('creditCard-exp', _data)) return setSt(type, true);
        if (type === 'cvv' && !validField('creditCard-cvv', _data)) return setSt(type, true);

    }

    const setSt = useCallback((type: string, data: boolean = false) => {
        setErr(errOld => {
            return {
                ...errOld,
                [type]: data
            }
        });
    }, []);

    const actionValidateCardNumber = useCallback((cardNumber: string) => {
        const validCard = !!chooseImageCart(cardNumber);
        setErr(old => {
            return {
                ...old,
                cardNumber: !!!validCard
            }
        })
    }, [])

    return (
        <div className={styled.root}>
            {/* <div className={cx(styled.rowNames, { [styled.rowNamesMobile]: mobile })}>
                <Input
                    label={t('shop.formFirstName')}
                    value={payments.creditCard.firstName}
                    onChange={(data: React.ChangeEvent<HTMLInputElement>) => actionChange('firstName', data.target.value)}
                    className={cx(styled.input, { [styled.error]: err.firstName })}
                    onFocus={() => setSt('firstName')}
                    onBlur={(data: React.ChangeEvent<HTMLInputElement>) => actionValidate('firstName', data.target.value)}
                />
                <Input
                    label={t('shop.formLastName')}
                    value={payments.creditCard.lastName}
                    onChange={(data: React.ChangeEvent<HTMLInputElement>) => actionChange('lastName', data.target.value)}
                    className={cx(styled.input, { [styled.error]: err.lastName })}
                    onFocus={() => setSt('lastName')}
                    onBlur={(data: React.ChangeEvent<HTMLInputElement>) => actionValidate('lastName', data.target.value)}
                />
            </div> */}
            <div className={cx(styled.restInputs, { [styled.restInputsMobile]: mobile } )}>
                <Input
                    label={t('shop.creditCardNumber')}
                    value={payments.creditCard.cardNumber}
                    onChange={(data: React.ChangeEvent<HTMLInputElement>) => actionChange('cardNumber', data.target.value)}
                    className={cx(styled.input, styled.inputCardNumber, { [styled.error]: err.cardNumber })}
                    onFocus={() => setSt('cardNumber')}
                    // onBlur={(data: React.ChangeEvent<HTMLInputElement>) => actionValidate('cardNumber', data.target.value)}
                    onBlur={(data: React.ChangeEvent<HTMLInputElement>) => actionValidateCardNumber(data.target.value)}
                    mask={'9999-9999-9999-9999'}
                />
                <div className={styled.cardLogo}>
                    {cardSrc &&
                        <img src={cardSrc} alt="" />
                    }
                </div>
                
            </div>
            <div className={cx(styled.restInputs, styled.cardDetail)}>
                <Input
                    label={t('shop.creditCardExp')}
                    value={payments.creditCard.exp}
                    onChange={(data: React.ChangeEvent<HTMLInputElement>) => actionChange('exp', data.target.value)}
                    className={cx(styled.input, styled.inputExp, { [styled.error]: err.exp })}
                    onFocus={() => setSt('exp')}
                    onBlur={(data: React.ChangeEvent<HTMLInputElement>) => actionValidate('exp', data.target.value)}
                    mask={'99/99'}
                />
                <Input
                    label={!mobile ? t('shop.creditCardCvv') : 'CVC'}
                    value={payments.creditCard.cvv}
                    onChange={(data: React.ChangeEvent<HTMLInputElement>) => actionChange('cvv', data.target.value)}
                    className={cx(styled.input, styled.inputCvv, { [styled.error]: err.cvv })}
                    onFocus={() => setSt('cvv')}
                    onBlur={(data: React.ChangeEvent<HTMLInputElement>) => actionValidate('cvv', data.target.value)}
                    mask={'999'}
                />
            </div>
            {/* <div className={styled.infoBottom}>
                <InfoIcon />
                {t('shop.WireTransferExtraFee', { currency, amount: 4.99 })}
             </div> */}
        </div>
    );
}
export default CreditCard;