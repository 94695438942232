import React, { useCallback, useEffect, useState } from 'react';
import { Portal } from "react-portal";

import style from './ImageGallery.module.scss';

type ImageGalleryProps = {
    images: string[],
    actionClose: () => void,
    startIndex?: number
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images, actionClose, startIndex }) => {
    const [currentIndex, setCurrentIndex] = useState(startIndex || 0);

    const nextImage = useCallback((event?: React.MouseEvent) => {
        if (event){
            event.stopPropagation();
        }
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, [images.length]);

    const prevImage = useCallback((event?: React.MouseEvent) => {
        if (event){
            event.stopPropagation();
        }
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }, [images.length]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                actionClose();
            } else if (event.key === 'ArrowRight' || event.key === ' ') {
                nextImage();
            } else if (event.key === 'ArrowLeft') {
                prevImage();
            }
        };
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [actionClose, nextImage, prevImage]);

    return (
        <Portal>
            <div className={style.modal} onClick={actionClose}>
                <span className={style.close} onClick={actionClose}>&times;</span>
                {images.length > 1 &&
                    <>
                        <span className={style.prev} onClick={prevImage}>&#10094;</span>
                        <span className={style.next} onClick={nextImage}>&#10095;</span>
                    </>
                }
                <img src={images[currentIndex]} alt={``} className={style.modalContent} onClick={(e) => e.stopPropagation()} />
            </div>
        </Portal>
    );
};

export default ImageGallery;