import useTranslate from "Hooks/useTranslate";
import React from 'react';

import type { StateT as ChatDataStateT } from 'Store/modules/claimChat/data';
import ContactBranch from "Components/ContactBranch/ContactBranch";
import Modal from "Components/Modal/Modal";
import style from './Contact.module.scss';
import { AppStateT } from "Store/modules";
import { useSelector } from "react-redux";

type MainPropsT = {
    modal?: boolean,
    open?: boolean,
    actionClose?: () => void
}

const Contact: React.FC<MainPropsT> = ({ modal, open = true, actionClose }) => {

    const { claim } = useSelector<AppStateT, Pick<ChatDataStateT, 'claim'>>(({ claimChat }) => ({ claim: claimChat.data.claim }));
    const t = useTranslate();

    if (!claim){
        actionClose?.();
        return <></>;
    }

    if (modal){
        return (
            <Modal open={open} onClose={actionClose} title={t('chatContact.contactWithUs')} closeOnClickOutside>
                <div className={style.root}>
                    <ContactBranch
                        claim={claim}
                        transparent
                    />
                </div>
            </Modal>
        )
    }

    return (
        <div className={style.root}>
            <div className={style.header}>{t('chatContact.contactWithUs')}</div>
            <ContactBranch
                claim={claim}
                transparent
            />
        </div>
    );
}
export default Contact;