import React from 'react';
import Lottie from 'react-lottie';
import animationData from './RefundIcon.json';

const iconOptions = {
  loop: false,
  animationData: animationData,
};

export default function AnimatedVerdict() {
  return <Lottie options={iconOptions} height={50} width={50} />;
}
