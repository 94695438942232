import { addMinutesToCurrentTime } from "Helpers/date"

export type LocalStateConfirmLoginT = {
    code: string,
    agreeTerms: boolean,
    sending: boolean,
    error: boolean,
    errorName: boolean,
    errorCheck: boolean,
    errorInvalidCode: boolean,
    resendButtonDisabled: boolean,
    verificationCodeExpired: boolean,
    initialVerificationCodeCounter: number,
    dateTry: Date|null
}

export const initialStateConfirmLogin: LocalStateConfirmLoginT = {
    code: '',
    agreeTerms: false,
    sending: false,
    error: false,
    errorName: false,
    errorCheck: false,
    errorInvalidCode: false,
    resendButtonDisabled: false,
    verificationCodeExpired: false,
    initialVerificationCodeCounter: 30,
    dateTry: null
}

const Store = (state: LocalStateConfirmLoginT, action: any): LocalStateConfirmLoginT => {

    switch(action.type){

        case 'SET_CODE': return {
            ...state,
            code: action.data
        }

        case 'TOGGLE_AGREE': return {
            ...state,
            agreeTerms: !state.agreeTerms
        }

        case 'SET_SENDING': return {
            ...state,
            sending: action.data
        }

        case 'SET_ERROR': return {
            ...state,
            error: action.data
        }

        case 'SET_ERROR_NAME': return {
            ...state,
            errorName: action.data
        }

        case 'SET_ERROR_CHECK': return {
            ...state,
            errorCheck: action.data
        }

        case 'SET_ERROR_INVALID_CODE': return {
            ...state,
            errorInvalidCode: action.data
        }

        case 'SET_RESEND_BUTTON': return {
            ...state,
            resendButtonDisabled: action.data
        }

        case 'SET_VERIFICATION_EXPIRED': return {
            ...state,
            verificationCodeExpired: action.data
        }

        case 'SET_INITIAL_VERIFICATION_CODE': return {
            ...state,
            initialVerificationCodeCounter: action.data
        }

        case 'INPUT_FOCUS': return {
            ...state,
            error: false,
            errorName: false,
            errorInvalidCode: false,
            verificationCodeExpired: false
        }

        case 'CLEAR_TRY': 
            localStorage.removeItem('dateTryCodeLogin');
            return {
                ...state,
                dateTry: null
            }

        case 'END_TRY': 
            const endDate = addMinutesToCurrentTime(5);
            localStorage.setItem('dateTryCodeLogin', endDate.toUTCString());
            return {
                ...state,
                dateTry: endDate
            }

        case 'START_COUNTER_FROM_LS': 
            return {
                ...state,
                dateTry: action.data
            }

    }

    return state;

}

export default Store;