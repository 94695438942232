import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import Lottie from 'react-lottie';
import React, { useEffect, useState } from 'react';

import animationDataError from 'Assets/lotties/error-server.json';
import style from './BlockServerError.module.scss';
import { BlockServerT } from 'Store/modules/page';

type MainPropsT = {
    type: BlockServerT
}

const BlockServerError: React.FC<MainPropsT> = ({ type }) => {

    const { t } = useTranslation();
    const [lang, setLang] = useState('en');

    useEffect(() => {
        const dataStorage = localStorage.getItem('APP_LANGUAGE');
        if (dataStorage){
            setLang(dataStorage);
        }
    }, []);

    const _t = (data: string, defaultEn: string, defaultPl: string) => {
        if (type === 'live'){
            return t(data);
        }
        if (lang.trim().indexOf('pl') >=0){
            return defaultPl;
        }
        return defaultEn;
    }

    return (
        <Portal>
            <div className={style.root}>
                <div className={style.body}>
                    <div className={style.lottie}>
                        <Lottie
                            options={{ loop: true, animationData: animationDataError }}
                            height={250}
                            width={250}
                            isClickToPauseDisabled={true}
                        />
                    </div>
                    <div className={style.header}>
                        {_t('common.attention', 'Attention', 'Uwaga')}!
                    </div>
                    <div className={style.content}>
                        {_t('common.errorServerContent', 'We are working on upgrading our website. If it doesn\'t start working soon, please try to visit us a bit later.', 'Trwają prace nad modernizacją naszej strony. Jeśli nie zacznie ona działać w najbliższym czasie spróbuj nas odwiedzić nieco później.')}
                    </div>
                    <div className={style.apologies}>
                        {_t('common.errorServerDesc', 'We apologize for this inconvenience', 'Przepraszamy za te niedogodności')}
                    </div>
                </div>
            </div>
        </Portal>
    ) 
}

export default BlockServerError;