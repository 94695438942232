import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Img1 from 'Assets/materials/material_1.png';
import Img2 from 'Assets/materials/material_2.png';
import Img3 from 'Assets/materials/material_3.png';
import Img4 from 'Assets/materials/material_4.png';
import Img5 from 'Assets/materials/material_5.png';

import { makeSlug, setMaterialTitleLang } from 'Helpers/strings';
import style from './Material.module.scss';

type MaterialT = {
    slug: string,
    title: string,
    orginTitle: string
}

type MainProps = {
    luggageMaterial: string,
    materials: string[],
    updateClaim: (dataType: string, data: any) => void,
}

// const allowMaterials = ['Soft material', 'Hardshell', 'Dual soft/hard'];

const Material: React.FC<MainProps> = ({ updateClaim, luggageMaterial, materials }) => {

    const { t } = useTranslation();

    const [luggageMaterials, setLuggageMaterials] = useState<MaterialT[]>([]);

    useEffect(() => {
        setLuggageMaterials(materials.map(mat => {

            const slug = makeSlug(mat);

            return {
                slug,
                orginTitle: mat,
                title: t(setMaterialTitleLang(slug)),
            }
        }));
    }, [materials, t]);

    const actionChangeLuggageType = useCallback((orginTitle: string) => {
        if (orginTitle === luggageMaterial) return updateClaim('luggageMaterial', '');
        updateClaim('luggageMaterial', orginTitle);
    }, [updateClaim, luggageMaterial])

    const chooseImage = useMemo(() => (slug: string) => {
        switch(slug){

            case 'aluminum': return Img2;
            case 'leather': return Img3;
            case 'hardshell': return Img4;
            case 'soft-material': return Img5;

            default: return Img1;
        }
    }, []);

    return (
        <div className={style.root}>
            <div className={style.layerElements}>
                {luggageMaterials.map(item => {

                    return (
                        <div
                            className={cx(style.item, { [style.selected]: item.orginTitle === luggageMaterial })}
                            onClick={() => actionChangeLuggageType(item.orginTitle)}
                            key={`lugg_material_${item.slug}`}
                            id={'select_new_material_type_' + item.slug.toLowerCase()}
                        >
                            <div className={style.imageBgLayer}>
                                <div className={style.imageBg} style={{ backgroundImage: `url(${chooseImage(item.slug)})` }} />
                            </div>
                            
                            <div className={style.label}>{item.title}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
export default Material;