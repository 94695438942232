import { createBrowserHistory } from "history";
import { combineReducers } from 'redux';

import authorization, { LOGOUT_SUCCESS } from './authorization';
import accountSettings from './accountSettings';
import claimDetails from './claimDetails';
import markedClaims from './markedClaims';
import savedRating from './savedRating';
import createClaim from './createClaim';
import reportChat from './reportChat';
import claimChat from './claimChat';
import store from './luggageStore';
import claim from './claim';
import i18n from './i18n';
import page from './page';
import user from './user';

export const browserHistory = createBrowserHistory();

const createAppReducer = () =>
  combineReducers({
    authorization,
    user,
    claim,
    createClaim,
    claimDetails,
    claimChat,
    markedClaims,
    accountSettings,
    store,
    reportChat,
    i18n,
    page,
    savedRating,
});

let appReducer = combineReducers({
    authorization,
    user,
    claim,
    createClaim,
    claimDetails,
    claimChat,
    markedClaims,
    accountSettings,
    store,
    reportChat,
    i18n,
    page,
    savedRating,
});

type AppReducerT = ReturnType<typeof createAppReducer>;
export type AppStateT = ReturnType<AppReducerT>;

export const rootReducer = (
  state: AppStateT | undefined,
  action: { type: string }
) => {
	if (!appReducer) {
		appReducer = createAppReducer();
	}

	if (action.type === LOGOUT_SUCCESS) {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};
