import React, { ReactNode } from 'react';
import styles from './MainScreenDesktop.module.scss';

type Props = {
  children?: ReactNode;
};

function MainScreenDesktop({ children }: Props) {
  return <div className={styles.root}>{children}</div>;
}

export default MainScreenDesktop;
