import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

import { ResolutionVerdictT, ShipmentT } from "api/modules/claim";
import useFormattedPrice from './useFormattedPrice';
import { CLAIM_STATUSES } from "Config/statuses";
import { PageConfigT } from 'Store/modules/page';
import { AppStateT } from 'Store/modules';

export type DataCheckChatStatusT = {
    voucher: number,
    residualValue: number,
    templateValue: number,
    baggageAge: string,
    currencyIsoCode: string,
    airlineName: string,
    urlForm: string,
    urlTrustPilot: string,
    urlGoogleReview: string,
    pickupData: string,
    resolution?: ResolutionVerdictT
}

const excludeStatuses = [ CLAIM_STATUSES.SOLUTION_PICKUP_PROCESS ];

const useStatusHasContent = (status: string, shipments: ShipmentT[]|undefined|null, data: DataCheckChatStatusT) => {

    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const formatPrice = useFormattedPrice(data.currencyIsoCode, pageConfig.noFractionCurrencies)
    const { t } = useTranslation();

    const getCountShipments = useMemo(() => (): ShipmentT[] => {
        const _shipments = !!shipments && Array.isArray(shipments) && shipments.length > 0 ? [...shipments.filter(sh => !!sh?.trackingNumber && !!sh.trackingUrl)] : [];
        if (_shipments.length === 0) return [];
        const found: ShipmentT[] = [];
        _shipments.forEach(s => {
            if (s?.trackingUrl && !found.some(sh => sh.trackingNumber === s.trackingNumber)){
                found.push(s);
            }
        });
        return _shipments;
    }, [shipments]);

    const [countShipments, setCountShipments] = useState(getCountShipments());

    const getTrackingContent = useMemo(() => (countShipments: ShipmentT[]) => {
        const _shipments = countShipments.filter(sh => sh.trackingNumber && sh.trackingUrl);
        
        if (_shipments.length === 1) {
            let singleShipmentContent = `<p style="margin-top: 10px;">${t('chatMessages.shipmentNumberLabel')} <a href="${_shipments[0].trackingUrl}" target="_blank">${_shipments[0].trackingNumber}</a></p>`;
            if (data.pickupData) {
                singleShipmentContent += `<p style="margin-top: 4px;">${t('chatMessages.shipmentDateLabel')}${data.pickupData}</p>`;
            }
            return singleShipmentContent;
        } else if (_shipments.length > 1) {
            let shipmentList = `<p style="margin-top: 10px;">${t('chatMessages.shipmentNumberLabel')}</p><ul class="trackingList">`;
            _shipments.forEach(sh => {
                shipmentList += `<li><a href="${sh.trackingUrl}" target="_blank">${sh.trackingNumber}</a></li>`;
            });
            shipmentList += '</ul>';
            
            if (data.pickupData) {
                shipmentList += `<p>${t('chatMessages.shipmentDateLabel')}${data.pickupData}</p>`;
            }
            return shipmentList;
        }
        return '';
    }, [data.pickupData, t]);

    const statusContent = useMemo(() => () => {
        let keyTranslate = 'caseStatusesBasic.' + status + '_EXTEND';

        let dateParam: any = {};

        if (excludeStatuses.includes(status as any)) return '';

        if (status === CLAIM_STATUSES.ASSESSMENT_SELECTION) {
            dateParam.voucher = `${formatPrice(data.voucher)} ${data.currencyIsoCode}`;
        } else if (status === CLAIM_STATUSES.SOLUTION_REFUND_PROCESS){
            dateParam.airlineName = data.airlineName;
        } else if (status === CLAIM_STATUSES.SOLUTION_REFUND_PENDING) {
            dateParam.airlineName = data.airlineName;
            dateParam.templateValue = formatPrice(data.templateValue);
            dateParam.currency = data.currencyIsoCode;
            dateParam.baggageAge = data.baggageAge;
            dateParam.voucherValue = formatPrice(data.voucher);
            dateParam.urlForm = data.urlForm;
        } else if (status === CLAIM_STATUSES.SOLUTION_PICKUP_PENDING) {
            dateParam.date = data.pickupData;
        } else if (status === CLAIM_STATUSES.SHIPMENT_OUT_FOR_DELIVERY || status === CLAIM_STATUSES.SHIPMENT_IN_PROCESS || status === CLAIM_STATUSES.SHIPMENT_PARTLY_SHIPPED){
            dateParam.date = data.pickupData;
            if (status === CLAIM_STATUSES.SHIPMENT_PARTLY_SHIPPED){
                keyTranslate = 'caseStatusesBasic.' + CLAIM_STATUSES.SHIPMENT_OUT_FOR_DELIVERY + '_EXTEND';
            }
            const _shipments = countShipments;
            if (_shipments.length === 1){
                dateParam.shipmentTrackingNumber = `<strong>${_shipments[0]?.trackingNumber || ''}</strong>`;
                dateParam.shipmentCourier = _shipments[0]?.trackingUrl || '';
            } else if (_shipments.length > 1){
                dateParam.shipmentList = '<ul class="trackingList">';
                _shipments.forEach(sh => {
                    dateParam.shipmentList += `<li><a href="${sh.trackingUrl}" target="_blank">${sh.trackingNumber}</a></li>`
                });
                dateParam.shipmentList += '</ul>';
                const translate = t(`${keyTranslate}_2`, dateParam);
                if (translate !== keyTranslate){
                    return translate;
                }
            } else {
                return '';
            }
        } else if (status === CLAIM_STATUSES.SHIPMENT_COMPLETED || status === CLAIM_STATUSES.CLOSED){
            dateParam.urlTrustPilot = data.urlTrustPilot;
            dateParam.urlGoogleReview = data.urlGoogleReview;
        }

        let additional: string = '';
        if (status === CLAIM_STATUSES.SOLUTION_DROP_OFF && countShipments.length > 0){
            additional = getTrackingContent(countShipments);
        }

        const translate = t(keyTranslate, dateParam);
        if (translate !== keyTranslate){
            return `${translate}${additional}`;
        }
        return additional;
    }, [t, status, data, countShipments, formatPrice, getTrackingContent]);

    const statusHeader = useMemo(() => () => {
        const keyTranslate = 'caseStatusesBasic.' + status + '_MAIN'
        let dateParam: any = {};

        if (excludeStatuses.includes(status as any)) return '';

        if (status === CLAIM_STATUSES.SOLUTION_REFUND_PENDING) {
            dateParam.residualValue = formatPrice(data.residualValue);
            dateParam.currency = data.currencyIsoCode;
        }

        const translate = t(keyTranslate, dateParam);
        if (translate !== keyTranslate){
            return translate;
        }
        return '';
    }, [t, status, data.currencyIsoCode, data.residualValue, formatPrice]);

    const checkHasHeader = useMemo(() => (): boolean => {
        const keyTranslate = 'caseStatusesBasic.' + status + '_MAIN'
        const translate = t(keyTranslate);

        if (excludeStatuses.includes(status as any)) return false;

        if (translate !== keyTranslate) {
            switch (status) {
                case CLAIM_STATUSES.SOLUTION_REFUND_PENDING:
                    return !!data.residualValue && !!data.currencyIsoCode;
                default:
                    return true;
            }
        }
        return false;
    }, [t, status, data.residualValue, data.currencyIsoCode]);

    const checkHasContent = useMemo(() => (): boolean => {
        const keyTranslate = 'caseStatusesBasic.' + status + '_EXTEND'
        const translate = t(keyTranslate);
        if (translate !== keyTranslate){
            switch (status){
                case CLAIM_STATUSES.SHIPMENT_COMPLETED: 
                case CLAIM_STATUSES.CLOSED: 
                case CLAIM_STATUSES.INVOICING:
                    return !!data.urlTrustPilot && !!data.urlGoogleReview;
                case CLAIM_STATUSES.ASSESSMENT_SELECTION:
                    return !!data.voucher && !!data.currencyIsoCode;
                case CLAIM_STATUSES.SOLUTION_REFUND_PROCESS:
                    return !!data.airlineName;
                case CLAIM_STATUSES.SOLUTION_PICKUP_PENDING:
                    return !!data.pickupData || data.pickupData === '';
                case CLAIM_STATUSES.SHIPMENT_OUT_FOR_DELIVERY:
                case CLAIM_STATUSES.SHIPMENT_IN_PROCESS:
                case CLAIM_STATUSES.SHIPMENT_PARTLY_SHIPPED:
                    const _shipments = countShipments;
                    if (_shipments.length === 1){
                        return true;
                    } else if (_shipments.length > 1 && keyTranslate !== t(`${keyTranslate}_2`)){
                        return true;
                    }
                    return false;
            }

            return true;
        }
        return false;
    }, [t, data, countShipments, status]);

    const [hasContent, setHasContent] = useState(checkHasContent());
    const [content, setContent] = useState(statusContent());
    const [hasHeader, setHasHeader] = useState(checkHasHeader());
    const [header, setHeader] = useState(statusHeader())

    useEffect(() => {
        setHasContent(checkHasContent());
    }, [status, data, shipments, checkHasContent]);

    useEffect(() => {
        setContent(statusContent());
    }, [t, status, data, countShipments, statusContent]);

    useEffect(() => {
        setHasHeader(checkHasHeader());
    }, [status, checkHasHeader]);

    useEffect(() => {
        setHeader(statusHeader());
    }, [t, status, data, statusHeader]);

    useEffect(() => {
        setCountShipments(getCountShipments());
    }, [shipments, getCountShipments]);

    return { hasContent, content, hasHeader, header };

}
export default useStatusHasContent;