import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import cx from 'classnames';

import type { LuggageProductsSimpleColorT, LuggageProductsSimpleT, LuggageT, LuggageCustomOptionT } from 'api/modules/store';
import { setShopProductSimple, ShopStateT } from 'Store/modules/luggageStore/shop';
import type { StateT as CatalogStateT } from 'Store/modules/luggageStore/catalog';
import { LuggageDetailsForm, VoucherBlock } from 'PageComponents/LuggageStore';
import { cPriceVoucher, productMaterial, productSize } from 'Helpers/store';
import useFormattedPrice from 'Hooks/useFormattedPrice';
import styles from './LuggageDetailsMenu.module.scss';
import { materialsData } from 'Helpers/useMaterials';
import { PageConfigT } from 'Store/modules/page';
import { useTranslation } from 'react-i18next';
import { AppStateT } from 'Store/modules';
import { translate } from 'Helpers/i18';
import { Button } from 'Components';

type Props = {
  luggage: LuggageT;
  addToCart: (productSimple: LuggageProductsSimpleT | null, data: LuggageT) => void;
  claimId?: string,
  productSimpleId?: string,
  mobile?: boolean,
  changeSize?: (luggageId: number) => void
};

export type OptionSizeT = {
  label: React.ReactNode;
  value: string;
}

type MaterialsDataT = {
    nameId: number,
    label: string,
}

type ProductDataT = {
  sizes: OptionSizeT[],
  sizeDefault: OptionSizeT | null,
  colors: LuggageProductsSimpleColorT[],
} | null;

function LuggageDetailsMenu({ luggage, addToCart, claimId, productSimpleId, mobile, changeSize }: Props) {
    const { voucherActual, actualProductSimple, configShop } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const { data: catalog } = useSelector<AppStateT, CatalogStateT>(({ store }) => store.catalog);
    const [productData, setProductData] = useState<ProductDataT>(null);
    const producerName = luggage?.extension_attributes?.simple_products[0]?.manufacturer || '';
    const navigate = useNavigate();
    // const description = productDescription(luggage);
    const dispatch = useDispatch();
    const materialName = productMaterial(luggage);
    const material = luggage.extension_attributes.custom_options.find((m: LuggageCustomOptionT) => m.attribute_code === 'material');
    const materialLabel = materialsData.find((m: MaterialsDataT) => m.nameId === material?.id)?.label
    const { t } = useTranslation();

    const formatPrice = useFormattedPrice(configShop.currency.code, pageConfig.noFractionCurrencies);
    const isKRStoreView = configShop.storeView.includes('kr_');
    const actualProductSimplePrice = isKRStoreView ? actualProductSimple?.base_price : actualProductSimple?.price;

    useEffect(() => {

        let resultSizes: OptionSizeT[] = [];
        let resultColors: LuggageProductsSimpleColorT[] = [];
        let sizeDefault: OptionSizeT = { label: productSize(luggage), value: String(luggage.id) };
        let simpleProduct: LuggageProductsSimpleT | null = null;
        if (catalog.length>0){
            if (luggage?.product_links && luggage.product_links.length>0){
                for (let lugg of luggage.product_links) {
                    const productFound = catalog.find((pr: LuggageT) => pr.id === lugg.extension_attributes.linked_product.id);
                    if (productFound){
                        resultSizes.push({ label: productSize(productFound), value: String(productFound.id) });
                    }
                    // dodajemy rozmiar aktualnego bagażu, chyba że jest to wtedy go okrrślamy jako domyślny
                    const sizeFind = resultSizes.find((pr: OptionSizeT) => pr.value === String(luggage.id));
                    if (sizeFind){
                        sizeDefault = sizeFind;
                    } else {
                        resultSizes = [sizeDefault, ...resultSizes];
                    }
                }
            }
            if (luggage?.extension_attributes?.simple_products && luggage?.extension_attributes?.simple_products.length > 0){
                if (productSimpleId){
                    const productAsParam = luggage.extension_attributes.simple_products.find((simple: LuggageProductsSimpleT) => simple.id === +productSimpleId);
                    if (productAsParam){
                        simpleProduct = productAsParam;
                        dispatch(setShopProductSimple(productAsParam))
                    }
                }
                if (!simpleProduct){
                    // simpleProduct = luggage?.extension_attributes?.simple_products[0];
                     const simpleProductFirst = luggage?.extension_attributes?.simple_products.find(si => si.color?.type === 1 && si.color.swatch_value);
                     if (simpleProductFirst){
                        simpleProduct = simpleProductFirst;
                        dispatch(setShopProductSimple(simpleProduct))
                     }
                }
                resultColors = luggage.extension_attributes.simple_products.filter((simple: LuggageProductsSimpleT) => simple.color?.type === 1).map((simple: LuggageProductsSimpleT) => {
                    return {
                        value: simple.color.swatch_value,
                        swatch_value: simple.color.value,
                        id: simple.id,
                        type: simple.color?.type,
                        group_swatch_value: '',
                        group_value: ''
                    }
                });
            }
        }
        setProductData({ sizes: resultSizes, colors: resultColors, sizeDefault });

    }, [catalog, luggage, productSimpleId, dispatch]);

    const handleHangeSize = useCallback((size: OptionSizeT) => {
        if (mobile){
            return changeSize?.(+size.value);
        }
        navigate(`/store/${claimId}/luggage-details/${size.value}`);
    }, [navigate, claimId, mobile, changeSize]);

    const handleChangeColor = useCallback((color: LuggageProductsSimpleColorT) => {
        if (luggage?.extension_attributes?.simple_products && luggage.extension_attributes.simple_products.length > 0){
            // const simpleProduct: LuggageProductsSimpleT | null = luggage?.extension_attributes?.simple_products.find((simple: LuggageProductsSimpleT) => simple.id === color?.id)||null;
            const simpleProduct: LuggageProductsSimpleT | null = luggage?.extension_attributes?.simple_products.find((simple: LuggageProductsSimpleT) => simple.id === color?.id)||null;
            if (simpleProduct){
                if (mobile){
                    return dispatch(setShopProductSimple(simpleProduct));
                }
                navigate(`/store/${claimId}/luggage-details/${luggage.id}/${simpleProduct.id}`);
            }
        }
    }, [luggage, claimId, navigate, mobile, dispatch]);

    return (
        <div className={styles.root}>
            <div className={cx(styles.mainContainter, { [styles.mobile]: mobile })}>
                <div className={styles.header}>
                    {producerName && <div className={styles.model}>{producerName}</div>}
                    <div className={styles.productName}>{luggage?.name}</div>
                    <VoucherBlock />
                    {voucherActual>0 ?
                        <div className={styles.saleContainer}>
                            <div className={styles.oldPrice}> {formatPrice(actualProductSimplePrice)} {configShop.currency.symbol}
                            </div>
                            <div className={styles.saleValue}>
                                {formatPrice(cPriceVoucher(actualProductSimplePrice, voucherActual))} {configShop.currency.symbol}
                            </div>
                        </div> :
                        <div className={styles.saleContainer}>
                            <div className={cx(styles.saleValue, styles.priceRegular)}>
                                {formatPrice(cPriceVoucher(actualProductSimplePrice, voucherActual))} {configShop.currency.symbol}
                            </div>
                        </div>
                    }
                </div>
                <div className={styles.body}>
                    <LuggageDetailsForm
                        size={productData?.sizes}
                        onSubmit={() => {}}
                        defaultSize={productData?.sizeDefault}
                        defaultColor={actualProductSimple ? actualProductSimple.color : null}
                        onSizeChange={handleHangeSize}
                        colors={productData?.colors}
                        actionChangeColor={handleChangeColor}
                        mobile={mobile}
                    />
                    <div className={styles.title}>{t('shop.summary')}</div>

                    {materialName &&
                        <div className={styles.text}>{t('shop.material')}: {t(materialLabel) || '—'}</div>
                    }
                    <div className={styles.text}>{t('shop.weight')}: {luggage?.weight}{translate(t, 'units.kg')}</div>
                    {/* <div className={styles.text}>Capacity : {luggage?.capacity}</div> */}
                    <div className={styles.text}>{t('shop.size')}: {productSize(luggage)}</div>
                    {/* {description &&
                        <>
                            <div className={styles.title}>{t('shop.description')}</div>
                            <div className={styles.description}>{mParse(description)}</div>
                        </>
                    } */}
                </div>

                <div className={styles.buttonWrapper}>
                    <Button color="primary" className={styles.button} onClick={() => addToCart(actualProductSimple, luggage)}>
                        {t('shop.addToCart')}
                    </Button>
                </div>
            </div>

        </div>
    );
}

export default LuggageDetailsMenu;
