import { useTranslation } from 'react-i18next';
import React from 'react';

import type { StateT as UserStateT } from 'Store/modules/user';
import { userNameJoin } from 'Helpers/strings';
import style from './StepSplashScreen.module.scss';
import useSalutation from "Hooks/useSalutation";

type MainProps = {
    user: UserStateT['data']
}

const StepSplashScreen: React.FC<MainProps> = ({ user }) => {

    const { t } = useTranslation();
    const userTitle = useSalutation(user);

    return (
        <div className={style.root}>
            <div className={style.content}>
                <div className={style.header}>{t('createClaim.titleHi', { name: userNameJoin(user, userTitle.salutation, userTitle.title) })}</div>
                {t('createClaim.titleContent')}
            </div>
        </div>
    )
}

export default StepSplashScreen;