import { IProps as SelectPropsT } from 'react-responsive-select';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as ArrowDown } from 'Assets/arrow-down.svg';
import { isIOS } from 'Helpers/responsiveContainers';
import styles from './Dropdown.module.scss';

export interface IDropdown {
	name: string;
	label: string;
	value: string;
	options: SelectPropsT['options'];
	onChange: (value: any) => void;
	className?: string;
	keyLang?:number;
	disabled?: boolean;
	noSelectionLabel?: string;
	isMobile?: boolean;
	hasEmptyValue?: boolean;
	id?: string;
}

const Dropdown: React.FC<IDropdown> = ({ name, label, options, value, onChange, className, disabled = false, noSelectionLabel = '', isMobile = false, keyLang, hasEmptyValue = false, id }) => {
    const refreshKey = Date.now();
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(e.target);
	};
    return (
		<div className={cx(styles.root, className, { [styles.ios]: isIOS()})}>
			{!disabled && isIOS() && (
				<ArrowDown className={styles.arrow}/>
			)}
			<span className={styles.label}>{label}</span>
			<select
				key={refreshKey || keyLang} 
				name={name}
				value={value || ''}
				onChange={handleChange}
				disabled={disabled}
				className={styles.select}
				id={id}
			>
				{hasEmptyValue && <option value={''}></option>}
				{options.filter((option) => option.value).map((option) => (
					<option
						key={option.value}
						value={option.value || ''}
					>
						{option.text}
					</option>
				))}
			</select>
		</div>
    );
}

export default React.memo(Dropdown);