import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import parse from 'html-react-parser';
import Lottie from 'react-lottie';
import cx from 'classnames';

import { changeModalTooltip, dataModalArticleT } from "Store/modules/page";
import { ReactComponent as RedirectIco } from 'Assets/Arrow-Forward.svg';
import animationData from 'Assets/lotties/reading-documents.json';
import ImageGallery from "Components/ImageGallery/ImageGallery";
import { ReactComponent as InfoIco } from 'Assets/info2.svg';
import { MissingDocExampleT } from "Store/modules/claim";
import style from './ExternalnfoData.module.scss';
import { fixDocumentName } from "Helpers/strings";
import { AppStateT } from "Store/modules";
import { Button } from "Components";

type MainPropsT = {
    missingDocumentsAdditionalInfo: MissingDocExampleT
    t: (data: string, params?: any) => string,
    missingType: string
}

const iconOptions = {
    loop: true,
    animationData: animationData,
};

const docsToSteps = [ 'dpr copy' ];

const ExternalnfoData: React.FC<MainPropsT> = ({ t, missingDocumentsAdditionalInfo, missingType }) => {
    const [openGalleryIndex, setOpenGalleryIndex] = useState(-1);
    const showModalTooltip = useSelector<AppStateT, dataModalArticleT|null>((state) => state.page.showModalTolltip);
    const dispatch = useDispatch();

    const galleryExamples = useMemo(() => missingDocumentsAdditionalInfo?.examples && Array.isArray(missingDocumentsAdditionalInfo.examples) && missingDocumentsAdditionalInfo.examples.filter(ex => ex.type === 'URL' || !ex?.type).length > 0 ? missingDocumentsAdditionalInfo.examples.filter(ex => ex.type === 'URL' || !ex.type).map(ex => ex?.url ? ex.url : String(ex)) : [], [missingDocumentsAdditionalInfo]);
    const textExamples = useMemo(() => missingDocumentsAdditionalInfo?.examples && Array.isArray(missingDocumentsAdditionalInfo.examples) && missingDocumentsAdditionalInfo.examples.filter(ex => ex.type === 'TEXT').length > 0 ? missingDocumentsAdditionalInfo.examples.filter(ex => ex.type === 'TEXT').map(ex => ex.text) : [], [missingDocumentsAdditionalInfo]);

    const actionAfterClickUrl = useCallback(() => {
        dispatch(changeModalTooltip(null));
        setTimeout(() => {
            dispatch(changeModalTooltip({
                object: (
                    <div onClick={() => dispatch(changeModalTooltip(null))} className={style.docWaiting}>
                        <div className={style.header}>
                            {t('createClaim.missingDocWaiting')}
                            <div className={style.animation}>
                                <Lottie options={iconOptions} height={150} width={150} />
                            </div>
                        </div>
                        <Button block onClick={() => dispatch(changeModalTooltip(null))} type={'button'}>{t('common.close')}</Button>
                    </div>
                )
            }));
        }, 500);
    }, [dispatch, t]);

    const actionGoUrl = useCallback((ev: any) => {

        ev.preventDefault();

        window.open(missingDocumentsAdditionalInfo.externalUrl);
        actionAfterClickUrl();
    }, [missingDocumentsAdditionalInfo.externalUrl, actionAfterClickUrl]);

    const GetExamples = useMemo(() => () => {
        return (
            <>
                <div className={style.examplesLayer}>
                    <div className={style.examplesHeader}><span>{t('createClaim.missingDocExamples')}</span></div>
                        {galleryExamples.length > 0 &&
                            <div className={style.examples}>
                                {galleryExamples.map((image, idx) => {
                                    return (
                                        <div key={`img-${image}`} className={style.item} style={{ backgroundImage: `url(${image})` }} onClick={() => setOpenGalleryIndex(idx)} />
                                    )
                                })}
                            </div>
                        }
                        {textExamples.length > 0 &&
                            <div className={cx(style.examples, style.textExamples)}>
                                {textExamples.map(text => (
                                    <div className={style.item} key={`text-${text}`}><span>"<strong>{text}</strong>"</span></div>
                                ))}
                            </div>
                        }
                </div>
            </>
        )
    }, [galleryExamples, textExamples, t]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const element = document.querySelector("#rightInUrl a");
            if (element) {
                element.addEventListener('click', actionGoUrl);
            }
        }, 100);

        return () => {
            clearTimeout(timer);
            const element = document.querySelector("#rightInUrl a");
            if (element) {
                element.removeEventListener('click', actionGoUrl);
            }
        };
    }, [dispatch, t, missingType, missingDocumentsAdditionalInfo.externalUrl, actionGoUrl, actionAfterClickUrl, GetExamples, showModalTooltip]);

    const toolTipBrand = useCallback((event: React.MouseEvent) => {
        event.preventDefault();

        const isFirstOption = docsToSteps.some(docType => docType.indexOf(missingType.toLowerCase()) >= 0);

        dispatch(changeModalTooltip({ 
            object: (
                <div className={style.layerTooltip}>
                    <div className={style.icoTopTooltip}><InfoIco /></div>

                    <div className={style.header}>
                        {parse(t('createClaim.missingDoc3Header', { missingType: fixDocumentName(missingType, t) }))}
                    </div>

                    <div className={style.body}>
                        <div className={style.row}>
                            <div className={style.left}>1</div>
                            <div className={style.right} id="rightInUrl">
                                {parse(t('createClaim.missingDocStep1', { url: missingDocumentsAdditionalInfo.externalUrl, actionAfterClickUrl }))}
                                <button className={cx(style.btn, style.btnSmall)} onClick={actionGoUrl}>{t('createClaim.missingDocForm')} <RedirectIco /></button>
                            </div>
                        </div>
                        <div className={style.row}>
                            <div className={style.left}>2</div>
                            <div className={style.right}>
                                {isFirstOption ? t('createClaim.missingDocStep2') : t('createClaim.missingDocStep2a')}
                            </div>
                        </div>
                        <div className={style.row}>
                            <div className={style.left}>3</div>
                            <div className={style.right}>
                                {isFirstOption ? t('createClaim.missingDocStep3') : t('createClaim.missingDocStep3a')}
                            </div>
                        </div>
                    </div>
                    <GetExamples />

                </div>
            ),
        }));
    }, [dispatch, t, missingType, missingDocumentsAdditionalInfo.externalUrl, actionGoUrl, actionAfterClickUrl, GetExamples]);

    return (
        <>
            {!!(missingDocumentsAdditionalInfo?.externalUrl || (galleryExamples.length > 0 || textExamples.length > 0)) &&
                <div className={style.root}>
                    <div className={style.header}>
                        <GetExamples />
                        {missingDocumentsAdditionalInfo?.externalUrl &&
                            <button className={style.btn} onClick={toolTipBrand}>{t('createClaim.missingDocUrlHeader')}</button>
                        }
                    </div>
                </div>
            }
            {(openGalleryIndex >=0 && galleryExamples.length > 0) &&
                <ImageGallery
                    actionClose={() => setOpenGalleryIndex(-1)}
                    images={galleryExamples}
                    startIndex={openGalleryIndex}
                />
            }
        </>
    );
}
export default React.memo(ExternalnfoData);