export const countriesCodes = [ 
    {text: 'Polish', countryCode: 'pl', localeCode: 'pl'},
    {text: 'Czech Republic', countryCode: 'cs-cz', localeCode: 'cs'},
    {text: 'Slovak', countryCode: 'cs-cz', localeCode: 'cs'},
    {text: 'English', countryCode: 'en-us', localeCode: 'en'},
    {text: 'Hungary', countryCode: 'hu', localeCode: 'hu'},
    {text: 'Portugal', countryCode: 'pt-pt', localeCode: 'pt'},
    {text: 'Japan', countryCode: 'jp', localeCode: 'ja'},
    {text: 'China', countryCode: 'cn', localeCode: 'zh'},
    {text: 'Republic of Korea', countryCode: 'kr', localeCode: 'ko'},
    {text: 'France', countryCode: 'fr', localeCode: 'fr'},
    {text: 'Italy', countryCode: 'it', localeCode: 'it'},
    {text: 'Spanish', countryCode: 'es', localeCode: 'es'},
    {text: 'German', countryCode: 'de', localeCode: 'de'}
];

// correct right language code
export const correctLanguageCode = (code: string, availableLanguages: { text: string, title: string, value: string }[], callBackCorrect?: (correctCode?: string) => void): string => {

    if (!availableLanguages.some(lang => lang.value === code.toLowerCase())){
        const splitCode = code.toLowerCase().split("-");
        if (splitCode.length > 1){
            const findFirstSplit = availableLanguages.find(lang => lang.value === splitCode[0]);
            if (findFirstSplit){
                callBackCorrect(findFirstSplit.value);
                return findFirstSplit.value;
            }
            const findSecondSplit = availableLanguages.find(lang => lang.value === splitCode[0]);
            if (findSecondSplit){
                callBackCorrect(findSecondSplit.value);
                return findSecondSplit.value;
            }
        }
        const defaultLang = availableLanguages.find(lang => lang.value === 'en-us' || lang.value === 'en');
        if (defaultLang){
            return defaultLang.value;
        }
        return availableLanguages[0].value;
    }
    return code;

}