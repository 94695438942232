import React, { useState, useRef, useLayoutEffect } from 'react';
import cx from 'classnames';
import styles from './FilterBody.module.scss';

interface IProps {
  isOpen: boolean;
  children: React.ReactNode | string;
}

export default function FilterBody(props: IProps) {
  const [height, setHeight] = useState<number | undefined>(0);
  const { isOpen } = props;
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setHeight(ref.current?.scrollHeight);
  }, []);

  return (
    <div
      className={cx(styles.wrapper, { [styles.isOpen]: isOpen })}
      style={isOpen ? { maxHeight: height + 'px' } : { maxHeight: 0 }}
      ref={ref}
    >
      <div className={styles.body}>{props.children}</div>
    </div>
  );
}
