import { LocalStateT } from "./RegistrationForm";


const Store = (state: LocalStateT, action: any): LocalStateT => {

    switch (action.type){

        case 'SET_DATA': return {
            ...state,
            [action.dataType]: action.data
        }

        case 'SET_COUNTRY': return {
            ...state,
            countryCode: action.data,
            showCountry: false
        }

        case 'SET_PHONE_NUMBER_DIGITS': return {
            ...state,
            phoneNumberDigits: action.data
        }

        case 'SET_SHOW_VALIDATION': return {
            ...state,
            showValidation: {
                ...state.showValidation,
                [action.field]: action.value
            }
        }

        case 'SET_VALIDATION_TIMER': return {
            ...state,
            validationTimer: {
                ...state.validationTimer,
                [action.field]: action.timer
            }
        }

        case 'CLEAR_ERROR': return {
            ...state,
            errors: state.errors.filter(err => err !== action.data)
        }

        default: return state;
    }
}

export default Store;