import { useNavigate, useLocation, Outlet, useParams } from 'react-router-dom';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { CartProductT, clearDataStore, clearMostPopular, ClientDataT, fetchConfigShop, fetchSaveCart, initialsFilters, initialState, setDeliveryData, setProductsLimit, setShopClaim, setShopFilters, setShopFiltersLocal, ShopStateT, showModalOrderSuccess } from 'Store/modules/luggageStore/shop';
import { CartButton } from 'PageComponents/LuggageStore';
import { changeStoreConfig, setReloadClaims } from 'Store/modules/page';
import { fetchStoreCatalog } from 'Store/modules/luggageStore/catalog';
import { openClaimDetailsRequest } from 'Store/modules/claimDetails';
import { ReactComponent as BackIcon } from 'Assets/left-arrow.svg';
import type { StateT as UserStateT } from 'Store/modules/user';
import { getPropablyCountryNameByCode } from 'Helpers/strings';
import { ReactComponent as CrossIcon } from 'Assets/cross.svg';
import { ReactComponent as Search } from 'Assets/search.svg';
import { useDeviceType } from 'Helpers/responsiveContainers';
import ModalSuccess from './ModalSuccess/ModalSuccess';
import { getDataCart } from 'Helpers/useDataCart';
import { ClaimDetailsT } from 'api/modules/claim';
import styles from './StoreDesktop.module.scss';
import type { AppStateT } from 'Store/modules';
import usePrevious from 'Helpers/usePrevious';
import { SearchPanel } from 'Components';

let timeout: any  = null;

function StoreDesktop() {
    const dispatch = useDispatch();
    const [showSearchPanel, setSearchPanel] = useState(false);
    const claimsArchived = useSelector<AppStateT, boolean>((state) => state.page.claimsArchived);
    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const [searchPanelValue, setSearchPanelValue] = useState('');
    const claim = useSelector<AppStateT, ClaimDetailsT | null>(state => state.claimDetails.data );
    const { filters, cart, counterResetSearch, maxPrice, configShop, voucher, rabatCode, delivery, termsAccept, changeShopCounter, showModalSuccess, note } = useSelector<AppStateT, ShopStateT>(state => state.store.shop);
    const _changeShopCounter = usePrevious(changeShopCounter);
    const { t } = useTranslation();
    const { isMobile } = useDeviceType();
    const navigate = useNavigate();
    const location  = useLocation();
    const params = useParams();
    
    useEffect(() => {
        if (params?.claimId){
            dispatch(openClaimDetailsRequest(params?.claimId, false));
        }
    }, [params?.claimId, dispatch]);

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
        }
    }, []);

    useEffect(() => {
        if (user && claim?.location){
            const savedDelivery: { clientAddress: ClientDataT, clientAddressDelivery: ClientDataT } = {
                clientAddress: {
                    name: user?.firstName || '',
                    surname: user?.lastName || '',
                    email: user?.email?.replace(' ', '').toLowerCase() || '',
                    countryCode: claim?.location.countryCode || 'PL',
                    country: claim.location.country || getPropablyCountryNameByCode(user?.location.countryCode || 'PL'),
                    phoneNumber: user?.phoneNumber || '',
                    street: claim.location.address,
                    city: claim.location.city,
                    zipCode: claim.location.zipCode,
                    apt: '',
                    companyName: '',
                    companyTaxNumber: ''
                  },
                clientAddressDelivery: {
                    name: user?.firstName || '',
                    surname: user?.lastName || '',
                    email: user?.email?.replace(' ', '').toLowerCase() || '',
                    countryCode: claim?.location.countryCode || 'PL',
                    country: claim.location.country || getPropablyCountryNameByCode(user?.location.countryCode || 'PL'),
                    phoneNumber: user?.phoneNumber || '',
                    street: claim.location.address,
                    city: claim.location.city,
                    zipCode: claim.location.zipCode,
                    apt: '',
                    companyName: '',
                    companyTaxNumber: ''
                  },
            };

            dispatch(setDeliveryData(savedDelivery.clientAddress, savedDelivery.clientAddressDelivery));
        }
    }, [user, dispatch, claim?.location, claim?.id]);

    // Zapis stanu koszyka do bazy
    useEffect(() => {
        if (claim?.id && voucher > 0 && delivery.deliveryMethod && _changeShopCounter !== changeShopCounter){
            dispatch(fetchSaveCart(claim?.id, getDataCart(claim, rabatCode, voucher, cart, delivery, termsAccept, configShop, note)));
        }
    }, [dispatch, cart, voucher, rabatCode, claim, delivery, termsAccept, _changeShopCounter, changeShopCounter, configShop, note]);

    const handleCloseSearchPanel = useCallback(() => {

        setSearchPanel(false);
        setSearchPanelValue('');
        dispatch(setShopFilters({
            ...filters,
            search: ''
        }));
        dispatch(setProductsLimit(initialState.productsLimit));

    }, [dispatch, filters]);

    /*
    * Inicjacja sklepu, produkty oraz ustawienia
    **/
    useEffect(() => {
        if (claim?.id){
            dispatch(fetchStoreCatalog(claim.id as string, configShop.storeView as string));
            dispatch(fetchConfigShop(claim.id));
        }
    }, [dispatch, claim?.id, configShop.storeView]);

    useEffect(() => {
        dispatch(changeStoreConfig(claim?.id && configShop.categories.length > 0 ? { ...configShop, claimId: claim.id } : null));
    }, [dispatch, claim?.id, configShop]);

    useEffect(() => {
        if (counterResetSearch > 0){
            setSearchPanel(false);
            setSearchPanelValue('');
        }
    }, [counterResetSearch, handleCloseSearchPanel, filters]);

    // Ustawienie vouchera
    useEffect(() => {
        dispatch(setShopClaim(claim));
    }, [dispatch, claim]);

    // Wczytanie danych claima z endpointu
    useEffect(() => {
        if (params?.claimId){
            dispatch(openClaimDetailsRequest(params?.claimId, claimsArchived));
        }
    }, [dispatch, params?.claimId, claimsArchived]);

    const backToStore = useCallback(() => {
        navigate(`/store/${params?.claimId}`);
    }, [navigate, params]);

    const goToCart = useCallback(() => {
        navigate(`/store/${params?.claimId}/shopping-cart`);
    }, [navigate, params?.claimId]);

    const showBackArrow = useMemo(() =>
        location.pathname.includes('luggage-details') || location.pathname.includes('shoping-cart')
    , [location.pathname]);

    const actionSearch = (value?: string) => {
        dispatch(clearMostPopular());
        dispatch(setShopFilters({
            ...initialsFilters,
            showListPriceWithVoucher: filters.showListPriceWithVoucher,
            search: value || searchPanelValue,
            price: [ 0, maxPrice ]
        }));
        dispatch(setShopFiltersLocal({
            ...initialsFilters,
            price: [ 0, maxPrice ]
        }));
        dispatch(setProductsLimit(initialState.productsLimit));
        
        navigate(`/store/${params?.claimId}`);
    }

    const luggageSearch = ({ target }) => {
        setSearchPanelValue(target.value);
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            actionSearch(target.value);
        }, 1000);
    }

    const onSearchEnter = (keyName: string) => {
        clearTimeout(timeout);
        if (keyName.toLocaleLowerCase() === 'escape'){
            setSearchPanelValue('');
            setSearchPanel(false);
            dispatch(setShopFilters({
                ...filters,
                search: ''
            }));
        } else if (keyName.toLocaleLowerCase() === 'enter'){
            actionSearch();
        }
    }

    const handleCloseStore = () => {
        dispatch(changeStoreConfig(null));
        dispatch(setShopFilters(initialsFilters));
        dispatch(clearDataStore());
        navigate('/'+(claim?.id || ''));
    }

    const actionGoClaim = () => {
        dispatch(showModalOrderSuccess(false));
        dispatch(setReloadClaims());
        dispatch(changeStoreConfig(null));
        dispatch(setShopFilters(initialsFilters));
        navigate('/'+(claim?.id || ''));
    }

    return (
        <div className={styles.storeHeaderContainer}>
            <div className={styles.storeHeader}>
                <div
                    className={cx(styles.searchPanel, {
                        [styles.searchPannelWithoutBack]: !showBackArrow,
                    })}
                >
                    {showBackArrow && <BackIcon onClick={backToStore} className={styles.backIcon} />}

                    {showSearchPanel && (
                        <SearchPanel
                            onChange={luggageSearch}
                            onClose={handleCloseSearchPanel}
                            value={searchPanelValue}
                            maxLength= {80}
                            autoFocus
                            onEnter={onSearchEnter}
                            t={t}
                            onBlur={() => actionSearch()}
                        />
                    )}

                    <div className={styles.iconsContainer}>
                        {!showSearchPanel && (
                            <Search
                                onClick={() => setSearchPanel(!showSearchPanel)}
                                className={styles.serchIcon}
                            />
                        )}

                        <CartButton
                            onClick={goToCart}
                            className={styles.cartButton}
                            inCart={cart.products.reduce((acc: number, pr: CartProductT) => acc + pr.quantity,0)}
                            t={t}
                        />
                    </div>
                </div>
                <div className={styles.storeTitle}>
                    {location.pathname.includes('shoping-cart') ? t('shop.checkOut') : t('shop.fixiStore')}
                </div>
                <CrossIcon className={styles.crossIcon} onClick={() => handleCloseStore()} />
            </div>

            <div className={cx(styles.root)}>
                <Outlet />
            </div>
            {(showModalSuccess && !isMobile) &&
                <ModalSuccess
                    actionClose={actionGoClaim}
                    open t={t}
                    actionGoClaim={actionGoClaim}
                />
            }
        </div>
    );
}

export default StoreDesktop;
