import { call, put, takeLatest, select } from 'redux-saga/effects';
import api from 'api';
import {
  ClaimRepairDropOffRequestT,
  CLAIM_REPAIR_DROP_OFF_REQUEST,
  claimRepairDropOffSuccess,
  claimRepairDropOffFailure,
} from 'Store/modules/claimChat/repair';
import type { AppStateT } from 'Store/modules';
import type { RepairLocationT } from 'api/modules/case-resolution';

function locationSelector(state: AppStateT): RepairLocationT {
  const location = state.claimChat.repair.location;

  if (!location) {
    throw new Error('Location not found');
  }

  return location;
}

function userLocationSelector(state: AppStateT): LocationT {
  const location = state.claimChat.repair.userLocation;

  if (!location) {
    throw new Error('Location not found');
  }

  return location;
}

function* claimRepairDropOffSaga({ id }: ClaimRepairDropOffRequestT): any {
  try {
    const location = yield select(locationSelector);
    const userLocation = yield select(userLocationSelector);
    const response = yield call(api().caseResolution.repairDropOff, id, {
      location,
      userLocation,
    });
    yield put(claimRepairDropOffSuccess(response.data));
  } catch (err) {
    yield put(claimRepairDropOffFailure(err));
  }
}

export default function* repair(): Generator {
  yield takeLatest(CLAIM_REPAIR_DROP_OFF_REQUEST, claimRepairDropOffSaga);
}
