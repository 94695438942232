import markedClaimsService, { MarkedClaimsDictT } from 'Helpers/marked-claims';

export const MARK_CLAIM_TO_RESOLVE = 'MARK_CLAIM_TO_RESOLVE';

export type MarkClaimToResolveT = {
  type: typeof MARK_CLAIM_TO_RESOLVE;
  claimId: string;
};

export type StateT = MarkedClaimsDictT;

const initialState: StateT = markedClaimsService.getList();

type ActionTypeT = MarkClaimToResolveT;

export default function markedClaims(state: StateT = initialState, action: ActionTypeT): StateT {
  switch (action.type) {
    case MARK_CLAIM_TO_RESOLVE: {
      markedClaimsService.mark(action.claimId);
      return markedClaimsService.getList();
    }
    default: {
      return state;
    }
  }
}

export function markClaimToResolve(claimId: string): MarkClaimToResolveT {
  return {
    type: MARK_CLAIM_TO_RESOLVE,
    claimId,
  };
}
