export const materialsData = [
    {nameId: 6807, label: 'shop.materialHardsideABS'},
    {nameId: 6808, label: 'shop.materialHardsideAluminium'},
    {nameId: 6809, label: 'shop.materialCurv'},
    {nameId: 6811, label: 'shop.materialHardsidePolycarbonate'},
    {nameId: 6812, label: 'shop.materialHardsidePolypropylene'},
    {nameId: 6813, label: 'shop.materialPolyester'},
    {nameId: 6815, label: 'shop.materialSoftsideTextile'},
    {nameId: 6816, label: 'shop.materialABSPolycarbonate'},
    {nameId: 6817, label: 'shop.materialPolyurethanePolyester'},
    {nameId: 6820, label: 'shop.materialHDPEPolyethylene'},
    {nameId: 6822, label: 'shop.materialNylon'},
    {nameId: 6823, label: 'shop.materialPolyamide'},
    {nameId: 6824, label: 'shop.materialPVC'},
    {nameId: 6962, label: 'shop.materialABSPC'},
    {nameId: 6963, label: 'shop.materialRecyclex'},
    {nameId: 6964, label: 'shop.materialNylonPolyester'},
    {nameId: 6965, label: 'shop.materialPUPolyester'},
    {nameId: 6967, label: 'shop.materialBallisticNylon'},
    {nameId: 6970, label: 'shop.materialTarpaulin'},
    {nameId: 6972, label: 'shop.materialPolyesterPolyamidePolyurethane'},
    {nameId: 6973, label: 'shop.materialSciconDTEX860'},
    {nameId: 6999, label: 'shop.materialCordura'},
    {nameId: 7035, label: 'shop.materialPhthalateFreeTPELaminate'},
    {nameId: 7038, label: 'shop.materialLeather'},
    {nameId: 7067, label: 'shop.materialPET'},
    {nameId: 7152, label: 'shop.materialRoxkin'},
    {nameId: 7209, label: 'shop.materialABSPC'},
    {nameId: 7254, label: 'shop.materialResistantMaterial'},
    {nameId: 7326, label: 'shop.materialPlastic'},
    {nameId: 7358, label: 'shop.materialStainlessSteel'}, 
    {nameId: 7452, label: 'shop.materialBPA'},
    {nameId: 7506, label: 'shop.materialPolyurethane'},
    {nameId: 7696, label: 'shop.materialPolyesterPolyethylenePolypropylene'},
    {nameId: 7729, label: 'shop.materialEcoLeather'},
    {nameId: 7737, label: 'shop.materialPCPPLeather'},
    {nameId: 7792, label: 'shop.materialNylonLeather'},
    {nameId: 7800, label: 'shop.materialNylonLeatherPolyester'},
    {nameId: 7804, label: 'shop.materialCarbonFibre'},
    {nameId: 7807, label: 'shop.materialDuraflex'},
]