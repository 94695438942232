import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { useDispatch } from 'react-redux';

import { IImage } from 'PageComponents/Chat/ReportUploadBox/ReportUploadBox';
import { ReportUploadBox } from 'PageComponents/Chat';
import { StateLocalT } from '../../CreateForm';
import style from './PhotosDamage.module.scss';

const MaxPhotos = 9;

type MainPropsT = {
    state: StateLocalT,
    setValidStep: (result: boolean) => void,
    updateClaim: (dataType: string, data: any) => void,
    mobile: boolean
}

const PhotosDamage: React.FC<MainPropsT> = ({ state, updateClaim, setValidStep, mobile }) => {

    // const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        setValidStep(state.claimData.photoDamages.length > 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.claimData.photoDamages.length]);

    const actionChangeImage = useCallback((idx: number, image: IImage | null) => {
        updateClaim('photoDamages', state.claimData.photoDamages.map((photo, index) => {
            if (idx === index) photo = image;
            return photo;
        }));
    }, [state.claimData.photoDamages, updateClaim]);

    const actionAddImage = useCallback((image: IImage) => {
        updateClaim('photoDamages', [
            ...state.claimData.photoDamages,
            ...[image]
        ]);
    }, [state.claimData.photoDamages, updateClaim]);

    const removeDamageImage = useCallback((image: IImage) => {
        updateClaim('photoDamages', state.claimData.photoDamages.filter(photo => photo.file.lastModified !== image.file.lastModified));
    }, [state.claimData.photoDamages, updateClaim]);

   return (
        <div className={style.root}>

            <div className={style.header}>
                {t('createClaim.dmgPhotoLabel')}
            </div>

            <div className={style.images}>
                {state.claimData.photoDamages.map((photo, idx) => {
                    return (
                        <div className={style.imagePhoto} key={`photoDamage_${photo.file.name}_${photo.file.size}`}>
                            <ReportUploadBox
                                value={photo}
                                removable={true}
                                onRemove={() => removeDamageImage(photo)}
                                onChange={(value) => actionChangeImage(idx, value)}
                                name={'damage'+idx}
                                hideName
                                excludeImages={state.imagesInClaim}
                                fitWidth
                                onlyImages
                                id={'file_new_claim_damage_'+idx}
                            />
                        </div>
                    )
                })}
                {state.claimData.photoDamages.length < MaxPhotos &&
                    <div className={style.imagePhoto}>
                        <ReportUploadBox
                            onChange={(value) => actionAddImage(value)}
                            name={'addNew'}
                            excludeImages={state.imagesInClaim}
                            hideName
                            addMode
                            fitWidth
                            onlyImages
                            id={'file_new_claim_damage'}
                        />
                    </div>
                }
            </div>
        </div>
    );
}
export default PhotosDamage;