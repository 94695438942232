import { call, takeLatest } from 'redux-saga/effects';
import api from 'api';
import urlBase64ToUint8Array from 'Helpers/web-push';
import { SET_USER } from 'Store/modules/user';

function* webPushSubscriptionSaga(): any {
  if (!('serviceWorker' in navigator)) {
    return;
  }

  const sw = yield navigator.serviceWorker.ready;
  const subscription = yield sw.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY as string),
  });

  try {
    yield call(api().webPush.subscribe, subscription);
  } catch {
    // Unlucky
  }
}

export default function* webPush(): Generator {
  yield takeLatest(SET_USER, webPushSubscriptionSaga);
}
