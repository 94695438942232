import cx from 'classnames';
import React from 'react';

// import { ReactComponent as InfoButton } from 'Assets/exclamation.svg';
import { ReactComponent as Arrow } from 'Assets/right-arrow.svg';
import styles from './OptionItem.module.scss';
import { makeSlug } from "Helpers/strings";

interface Elem {
    icon?: any;
    title: string;
    description?: string;
    info?: (evt: React.MouseEvent<HTMLElement>) => void;
    rounded?: string;
    chevron?: boolean;
    value?: number | string;
    tooltipKey?: string;
}

interface IOptionItem {
    data: Elem;
    onClick?: () => void;
    disabled?: boolean;
    titleClassName?: string;
    bodyClassName?: string;
    fixedBorderRadius?: boolean;
    descriptionClassName?: string;
    toolTipClickAction?: (tooltipKey: string|undefined) => void;
    valid?: boolean
}

function OptionItem(props: IOptionItem) {
    const { data, valid = true } = props;

    // const handleClickInfoTooltip = (event: React.MouseEvent) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     props.toolTipClickAction?.(data.tooltipKey);
    // }

    return (
        <div
            onClick={props.disabled ? undefined : props.onClick}
            className={cx(styles.elem, {
                [styles.disabled]: props.disabled,
                [styles.fixedBorderRadius]: props.fixedBorderRadius,
            })}
            id={'chat_option_' + makeSlug(data.title)}
        >
            <div className={cx(styles.body, props.bodyClassName)}>
                {data.icon && (
                    <div className={styles.icon}>
                        {typeof data.icon === 'function' ? data.icon() : data.icon}
                    </div>
                )}

                <div className="content">
                    <p className={cx(styles.title, props.titleClassName, { [styles.error]: !valid })}>{data.title}</p>
                    {data.description && (
                        <p className={cx(styles.description, props.descriptionClassName)}>{data.description}</p>
                    )}
                </div>

                {data.info && (
                    <span onClick={data.info} className={styles.info}>
                        i
                    </span>
                )}
                {data.value && <div className={styles.value}>{data.value}</div>}
                {data.chevron && <Arrow className={styles.chevron} />}
            </div>
            {/* {(data.tooltipKey) &&
                <div className={styles.layerTooltipIco}>
                    <InfoButton className={styles.infoButton} onClick={handleClickInfoTooltip} />
                </div>
            } */}
        </div>
    );
}

export default React.memo(OptionItem);
