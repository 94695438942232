import { useState, useCallback, useEffect } from 'react';
import type { CreateClaimFormDataT } from 'api/modules/claim';

export const CLAIM_STORE_KEY = 'claimData';
export const WIRE_TRANSFER_STORE_KEY = 'wireTransfer';
export const PAYPAL_STORE_KEY = 'paypal';

export function useDraftData(storeKey: string) {
  const [draftData, setDraftDataValues] = useState<CreateClaimFormDataT | null>(() =>
    getDraftData(storeKey),
  );
  const setDraftData = useCallback(
    (formValues: CreateClaimFormDataT) => {
      storeAsDraft(formValues, storeKey);
    },
    [storeKey],
  );

  useEffect(() => setDraftDataValues(getDraftData(storeKey)), [storeKey]);
  return {
    draftData,
    setDraftData,
  };
}

export function getDraftData(storeKey: string): CreateClaimFormDataT | null {
  let draftData = localStorage.getItem(storeKey);

  if (draftData) {
    return JSON.parse(draftData) as CreateClaimFormDataT;
  }

  return draftData as null;
}

export function storeAsDraft(formValues: CreateClaimFormDataT, storeKey: string) {
  localStorage.setItem(storeKey, JSON.stringify(formValues));
}

export function clearDraftData(storeKey: string): void {
  localStorage.removeItem(storeKey);
}
