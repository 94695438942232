import { useState, useCallback, useMemo, useEffect } from 'react';
import cx from 'classnames';

import FilterHeader from '../FilterElemementHeader/FilterHeader';
import FilterBody from '../FilterElementBody/FilterBody';

import styles from './FilterColor.module.scss';
import { LuggageProductsSimpleColorT } from 'api/modules/store';

interface IProps {
  colors: LuggageProductsSimpleColorT[];
  defaultColor?: LuggageProductsSimpleColorT;
  title: string;
  rounded?: string;
  state: string[];
  noFilterBody?: boolean;
  singleSelect?: boolean;
  actionHandleClick?: (param: string) => void;
  actionChangeColor?: (color: LuggageProductsSimpleColorT) => void
}

export default function Filter(props: IProps) {
    const { title, state, rounded, colors, actionHandleClick, defaultColor, actionChangeColor } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedColor, setSelectedColor] = useState(defaultColor ? defaultColor.value : '');
    const onClickHeaderHandler = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    useEffect(() => {
        if (defaultColor){
            setSelectedColor(defaultColor.value);
        }
    }, [defaultColor]);

    const onClickHandler = useCallback((value: LuggageProductsSimpleColorT) => {
        if (props.singleSelect) {
            setSelectedColor(value.value);
        }
        actionHandleClick?.(value.value);
        actionChangeColor?.(value);
    }, [props.singleSelect, actionHandleClick, actionChangeColor]);
    
    const filterColor = useMemo(() => {
        return (
          <div className={styles.wrapper}>
            <div className={styles.body} role="group" aria-labelledby="checkbox-group">
              {colors.map((value: LuggageProductsSimpleColorT, index: number) => (
                <div
                  key={index}
                  className={cx(styles.input, {
                    [styles.isChecked]: state.includes(value.value) && !props.singleSelect,
                    [styles.selected]: selectedColor === value.swatch_value && props.singleSelect,
                    [styles.whiteColor]: value.value === '#ffffff' || value.swatch_value === '#ffffff',
                  })}
                  onClick={() => onClickHandler(value)}
                >
                  <span className={styles.circle} style={{ backgroundColor: value.value }} />
                </div>
              ))}
            </div>
          </div>
        );
      }, [colors, state, onClickHandler, props.singleSelect, selectedColor]);

    return (
        <div
            className={cx(styles.root, {
                [styles.top]: rounded === 'top',
                [styles.bottom]: rounded === 'bottom',
                [styles.desktopRootNoFilter]: props.noFilterBody,
            })}
        >
            {props.noFilterBody ? (filterColor) : (
                <>
                    <FilterHeader title={title} onClickHandler={onClickHeaderHandler} isOpen={isOpen} />
                    <FilterBody isOpen={isOpen}>{filterColor}</FilterBody>
                </>
            )}
        </div>
    );
}
