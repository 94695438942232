import { LocalStoreT } from "./PersonalInformation";

const Store = (state: LocalStoreT, action: any): LocalStoreT => {

    switch (action.type){

        case 'SET_DATA': return {
            ...state,
            [action.dataType]: action.data
        }

        case 'SET_SHOW_TITLE': return {
            ...state,
            showTitle: action.data
        }

        default: return state;
    }

}

export default Store;