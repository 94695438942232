import React, { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import styles from './SideBarDesktop.module.scss';

type Props = {
  children: ReactNode;
  show?: boolean;
  backdrop?: boolean;
  closeOnClickOutside?: boolean;
  onClose?: (evt: React.MouseEvent<HTMLElement>) => void;
  headerTitle?: string | (() => React.ReactNode);
  headerClassName?: string;
  containerClassName?: string;
  backdropClassName?: string;
  animation?: string;
};

const backdropClassNames = {
  enter: styles['backdrop-enter'],
  enterActive: styles['backdrop-active-enter'],
  exit: styles['backdrop-exit'],
  exitActive: styles['backdrop-active-exit'],
};

function SideBarDesktop({
  children,
  backdrop = false,
  show = true,
  headerTitle,
  headerClassName,
  closeOnClickOutside,
  containerClassName,
  backdropClassName,
  animation,
  ...props
}: Props) {
  if (!show) {
    return null;
  }

  return (
    <>
      <CSSTransition in={backdrop} timeout={150} unmountOnExit classNames={backdropClassNames}>
        <div
          className={cx(styles.backdrop, backdropClassName)}
          onClick={closeOnClickOutside ? props.onClose : undefined}
        />
      </CSSTransition>

      <div className={cx(styles.root, containerClassName, animation && styles[animation])}>
        {headerTitle && (
          <header className={cx(styles.headerSideBarDesktop, headerClassName)}>
            {typeof headerTitle === 'function' ? headerTitle() : headerTitle}
          </header>
        )}
        {children}
      </div>
    </>
  );
}
export default SideBarDesktop;
