import cx from 'classnames';
import React from 'react';

import { ReactComponent as OkIcon } from 'Assets/okey.svg';

import styles from './Checkbox.module.scss';

type MainPropsT = {
    checked: boolean;
    name: string,
    onChange?: (param: boolean) => void,
    className?: string,
    id?: string
}

const Checkbox: React.FC<MainPropsT> = ({ checked, onChange, className = '', id }) => {

    return (
        <div 
            className={cx(styles.input, className, {
                [styles.isChecked]: checked,
            })}
            onClick={() => onChange?.(!checked)}
            id={id}
        >
            <div className={styles.icon}>
                <OkIcon />
            </div>
        </div>
    )
}

export default React.memo(Checkbox);