import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import cx from 'classnames';

import { ClaimHistoryT, IMyResolution } from '../../../../api/modules/case-resolution';
import { StateT as ChatDataStateT } from '../../../../Store/modules/claimChat/data';
import { RESOLUTION_TYPE } from 'PageComponents/Chat/Chat/Chat.constants';
import { IOneResolutionOption, tyShortcutToRes } from '../../Chat/Chat';
import type { ClaimChatStateT } from 'Store/modules/claimChat';
import { useMobile } from 'Helpers/responsiveContainers';
import { changeModalArticle } from 'Store/modules/page';
import { Button, ChatMessageGroup } from 'Components';
import { AppStateT } from '../../../../Store/modules';
import styles from '../../Chat/Chat.module.scss';
import { OptionItem } from 'PageComponents/Chat';
import { VerdictT } from 'api/modules/claim';
import Order from "Components/Order/Order";
import useOrder from "Hooks/useOrder";


export function getVoucherValue(value: number | false | undefined) {
    if (typeof value === 'boolean' || typeof value === 'undefined') {
        return 0;
    }

    return Number(value).toFixed(2);
}

interface ICaseResolutionBlock {
    as: any;
    claim: ClaimChatStateT['data']['claim'];
    showStartResolutionButton: boolean | null;
    markClaimToResolve: () => void;
    isNotMobile: boolean;
    onChooseResolution: (value: RESOLUTION_TYPE) => void;
    setCaseResolutionModal: (value: string) => void;
    shortcut?: tyShortcutToRes;

    optionsForHistCurrResolution: IOneResolutionOption[];
    statusInfo: ClaimHistoryT;
    lastResolution?: IMyResolution | null;
    firstVerdict?: VerdictT | null;
}

export default function CaseResolutionBlock(props: ICaseResolutionBlock) {
    const { t } = useTranslation();
    const {
        showStartResolutionButton,
        markClaimToResolve,
        isNotMobile,
        statusInfo,
        optionsForHistCurrResolution,
    } = props;
    const { claim } = useSelector<AppStateT, Pick<ChatDataStateT, 'claim'>>(({ claimChat }) => ({ claim: claimChat.data.claim }));
    const dispatch = useDispatch();
    const isMobile = useMobile();
    const order = useOrder(claim?.orders);

    const currPossibleResolutions = statusInfo.verdict.resolution;

    const selectedRes = statusInfo?.verdict?.selectedResolution;

    const allOptions = optionsForHistCurrResolution;

    let userOptions = allOptions.map((element: IOneResolutionOption) => {
        if (element.title === t('resolutionOptions.cashRefundTitle')) element.tooltipKey = 'tooltip.resolutionOptions.cashRefundTitle';
        else if (element.title === t('resolutionOptions.repairTitle')) element.tooltipKey = 'tooltip.resolutionOptions.repairTitle';
        else if (element.title === t('resolutionOptions.storeTitle')) element.tooltipKey = 'tooltip.resolutionOptions.storeTitle';
        return element;
    });

    if (currPossibleResolutions) {
        // xxxxxxxxx - remove cash refund
        userOptions = userOptions.filter((x) => x.title !== t('resolutionOptions.cashRefundTitle'));
        // xxxxxxxxx - END
        if (!currPossibleResolutions.refund && selectedRes !== "cashRefund") {
            userOptions = userOptions.filter((x) => x.title !== t('resolutionOptions.cashRefundTitle'));
        }

        if (!currPossibleResolutions.repair && selectedRes !== "pick-up") {
            userOptions = userOptions.filter((x) => x.title !== t('resolutionOptions.repairTitle'));
        }

        // usuniecie opcji sklepu gdy walizka jest naprawialna 
        if ((currPossibleResolutions.voucher !== 0 && !currPossibleResolutions.voucher && selectedRes !== "shop") || userOptions.some(item => item.tooltipKey === 'tooltip.resolutionOptions.repairTitle')) {
            userOptions = userOptions.filter((x) => x.title !== t('resolutionOptions.storeTitle'));
        }
    }

    const toolTipClickAction = useCallback((tooltipKey: string | undefined) => {
        if (tooltipKey) {
            dispatch(changeModalArticle({ pagesContentKey: tooltipKey }));
        }
    }, [dispatch])


    if (!claim || !statusInfo?.verdict) {
        return null;
    }

    if (showStartResolutionButton) {
        return (
            <div
                className={cx(styles.markClaimToResolve, {
                    [styles.markClaimToResolveDesktop]: isNotMobile,
                })}
            >
                <Button onClick={markClaimToResolve}>{t('chatMessages.resolve')}</Button>
            </div>
        );
    }

    const getLineTextBeforeResolution = () => {
        if (userOptions && userOptions.length === 1 && userOptions[0].tooltipKey === 'tooltip.resolutionOptions.repairTitle') {
            return t('chatMessages.afterVerdictPickup1');
        }
        if (userOptions && userOptions.length > 1) {
            return t('chatMessages.afterVerdictPickup2');
        }
        return t('chatMessages.letsRecoverYourClaim');
    }

    return (
        <>
            {order ?
                <>
                    <Order claim={claim} statusInfo={statusInfo} />
                </> :
                <>
                    {!claim?.closed &&
                        <>
                            <ChatMessageGroup
                                direction="outgoing"
                                data={[
                                    {
                                        message: t('chatMessages.userLetsGetItDone'),
                                        className: cx(styles.message, styles.messageOut),
                                    },
                                ]}
                                statusInfo={statusInfo}
                            />
                            <ChatMessageGroup
                                statusInfo={statusInfo}
                                avatar="bot"
                                direction="incoming"
                                data={[
                                    {
                                        message: getLineTextBeforeResolution(),
                                        className: cx(styles.message, styles.messageIn),
                                    },
                                    {
                                        render: () => (
                                            <div className={cx(styles.userOptions, isMobile ? styles.userOptionsMobile : null)}>
                                                {userOptions.map(({ onClick, disabled, ...option }, idx) => {
                                                    return (
                                                        <OptionItem
                                                            key={idx}
                                                            data={option}
                                                            onClick={onClick}
                                                            disabled={disabled}
                                                            toolTipClickAction={toolTipClickAction}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        ),
                                        className: cx(styles.message, styles.messageIn, styles.customMessage),
                                    },
                                ]}
                            />
                        </>
                    }
                </>
            }
        </>
    );
}
