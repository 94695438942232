import React, { useState } from 'react';
import { useSnapCarousel } from 'react-snap-carousel';
import cx from 'classnames';

import { ColorConfigT, MostPopAttrsT } from 'Store/modules/luggageStore/shop';
import { ReactComponent as IcoArrow } from 'Assets/arrow.svg';
import MostPopularTag from './MostPopularTag/MostPopularTag';
import style from './MostPopularTags.module.scss';

type MainProps = {
    tags: MostPopAttrsT[],
    actionClickTag: (tag: MostPopAttrsT) => void,
    mobile: boolean | undefined,
    colors: ColorConfigT[],
    t: (data: string) => string
}

const MostPopularTags: React.FC<MainProps> = ({ tags, actionClickTag, mobile, colors, t }) => {

    const { scrollRef, next, prev, pages, activePageIndex} = useSnapCarousel();
    const [startX, setStartX] = useState(0);

    const handleMouseDown = (event: React.MouseEvent) => {
        setStartX(event.clientX);
    };
    
    const handleMouseUp = (event: React.MouseEvent) => {
        const deltaX = startX - event.clientX;
        if (deltaX > 10) {
            next();
        } else if (deltaX < -10) {
            prev();
        }
    };

    return (
        <div
            className={style.root}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            <ul
                className={cx(style.slider, { [style.mobile]: !!mobile })}
                ref={scrollRef}
            >
                {tags.map((tag: MostPopAttrsT, idx: number) => (
                    <li key={`tag_${tag.id}_${idx}`}>
                        <MostPopularTag
                            key={`mostP_${tag.id}.${tag.value}`}
                            tag={tag}
                            actionClick={actionClickTag}
                            selected={tag.selected}
                            colorsPalette={colors}
                            t={t}
                        />
                    </li>
                ))}                
            </ul>
            { pages.length > 1 && (
                <>
                    {activePageIndex > 0 && (
                        <Arrow forward={false} actionClick={() => prev()} mobile={mobile} />
                    )}

                    {(activePageIndex + 1) < pages.length && (
                        <Arrow forward actionClick={() => next()} mobile={mobile} />
                    )}
                </>
            )}
        </div>
    );
}

type ArrowPropsT = {
    forward: boolean,
    actionClick: (forward: boolean) => void,
    mobile: boolean | undefined
}

const Arrow: React.FC<ArrowPropsT> = ({ forward, actionClick, mobile }) => {
    return (
        <div className={cx(style.arrow, { [style.arrowForward]: forward }, { [style.mobile]: !!mobile })} onClick={() => actionClick(!forward)}>
            <IcoArrow
                className={cx(style.icoArrow, { [style.forward]: forward }, { [style.mobile]: !!mobile })}
            />
        </div>
    )
}

export default MostPopularTags;
