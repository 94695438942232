import { combineReducers } from 'redux';
import data, { StateT as DataStateT } from './data';
import repair, { StateT as RepairStateT } from './repair';
import report, { StateT as ReportStateT } from './report';

export type ClaimChatStateT = {
  data: DataStateT;
  repair: RepairStateT;
  report: ReportStateT;
};

export default combineReducers({
  data,
  repair,
  report,
});
