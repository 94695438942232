import { loading, loaded, error } from 'Helpers/store-async';
import type { RepairDataT, RepairLocationT } from 'api/modules/case-resolution';

export const CLAIM_REPAIR_LOCATION_SUCCESS = 'CLAIM_REPAIR_LOCATION_SUCCESS';
export const CLAIM_REPAIR_LOCATION_FAILURE = 'CLAIM_REPAIR_LOCATION_FAILURE';
export const CLAIM_REPAIR_LOCATION_RESET = 'CLAIM_REPAIR_LOCATION_RESET';
export const CLAIM_REPAIR_PICK_UP_SUCCESS = 'CLAIM_REPAIR_PICK_UP_SUCCESS';
export const CLAIM_REPAIR_PICK_UP_FAILURE = 'CLAIM_REPAIR_PICK_UP_FAILURE';
export const CLAIM_REPAIR_DROP_OFF_REQUEST = 'CLAIM_REPAIR_DROP_OFF_REQUEST';
export const CLAIM_REPAIR_DROP_OFF_SUCCESS = 'CLAIM_REPAIR_DROP_OFF_SUCCESS';
export const CLAIM_REPAIR_DROP_OFF_FAILURE = 'CLAIM_REPAIR_DROP_OFF_FAILURE';
export const NO_PARCEL_SHOP_AVAILABLE = 'NO_PARCEL_SHOP_AVAILABLE';
export const RESET_REPAIR_STATE = 'RESET_REPAIR_STATE';

export type ClaimRepairLocationSuccessT = {
  type: typeof CLAIM_REPAIR_LOCATION_SUCCESS;
  data: RepairLocationT;
};

export type ClaimRepairLocationFailureT = {
  type: typeof CLAIM_REPAIR_LOCATION_FAILURE;
  error: string;
};

export type ClaimRepairLocationResetT = {
  type: typeof CLAIM_REPAIR_LOCATION_RESET;
};

export type ClaimRepairPickUpSuccessT = {
  type: typeof CLAIM_REPAIR_PICK_UP_SUCCESS;
  data: any;
};

export type ClaimRepairPickUpFailureT = {
  type: typeof CLAIM_REPAIR_PICK_UP_FAILURE;
  error: string;
};

export type ClaimRepairDropOffRequestT = {
  type: typeof CLAIM_REPAIR_DROP_OFF_REQUEST;
  id: string;
};

export type ClaimRepairDropOffSuccessT = {
  type: typeof CLAIM_REPAIR_DROP_OFF_SUCCESS;
  data: any;
};

export type ClaimRepairDropOffFailureT = {
  type: typeof CLAIM_REPAIR_DROP_OFF_FAILURE;
  error: string;
};

export type ClaimRepairNoParcelShopAvailableT = {
  type: typeof NO_PARCEL_SHOP_AVAILABLE;
};

export type ClaimRepairResetStateT = {
  type: typeof RESET_REPAIR_STATE;
};

export type ActionTypeT =
  | ClaimRepairLocationSuccessT
  | ClaimRepairLocationFailureT
  | ClaimRepairLocationResetT
  | ClaimRepairPickUpSuccessT
  | ClaimRepairPickUpFailureT
  | ClaimRepairDropOffRequestT
  | ClaimRepairDropOffSuccessT
  | ClaimRepairDropOffFailureT
  | ClaimRepairNoParcelShopAvailableT
  | ClaimRepairResetStateT;

export type StateT = {
  loading: boolean;
  loaded: boolean;
  data: null | RepairDataT;
  error: string | null;
  userLocation: null | LocationT;
  location: null | RepairLocationT;
  noParcelLocation: boolean;
};

const initialState: StateT = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
  userLocation: null,
  location: null,
  noParcelLocation: false,
};

export default function ClaimRepairReducer(
  state: StateT = initialState,
  action: ActionTypeT,
): StateT {
  switch (action.type) {
    case CLAIM_REPAIR_LOCATION_SUCCESS: {
      return {
        ...loaded<StateT>(state),
        location: action.data,
      };
    }
    case CLAIM_REPAIR_LOCATION_RESET: {
      return {
        ...initialState,
        userLocation: state.userLocation,
      };
    }
    case CLAIM_REPAIR_LOCATION_FAILURE: {
      return error<StateT>(state, action.error);
    }
    case NO_PARCEL_SHOP_AVAILABLE: {
      return {
        ...state,
        noParcelLocation: true,
      };
    }
    case CLAIM_REPAIR_DROP_OFF_REQUEST: {
      return loading<StateT>(state);
    }
    case CLAIM_REPAIR_PICK_UP_SUCCESS:
    case CLAIM_REPAIR_DROP_OFF_SUCCESS: {
      return loaded<StateT, any>(state, action.data);
    }
    case CLAIM_REPAIR_PICK_UP_FAILURE:
    case CLAIM_REPAIR_DROP_OFF_FAILURE: {
      return error<StateT>(state, action.error);
    }
    case RESET_REPAIR_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export function claimRepairLocationSuccess(data: RepairLocationT): ClaimRepairLocationSuccessT {
  return {
    type: CLAIM_REPAIR_LOCATION_SUCCESS,
    data,
  };
}

export function claimRepairLocationFailure(error: string): ClaimRepairLocationFailureT {
  return {
    type: CLAIM_REPAIR_LOCATION_FAILURE,
    error,
  };
}

export function noParcelShopAvailable() {
  return {
    type: NO_PARCEL_SHOP_AVAILABLE,
  };
}

export function claimRepairLocationReset(): ClaimRepairLocationResetT {
  return {
    type: CLAIM_REPAIR_LOCATION_RESET,
  };
}

export function claimRepairPickUpSuccess(data: any): ClaimRepairPickUpSuccessT {
  return {
    type: CLAIM_REPAIR_PICK_UP_SUCCESS,
    data,
  };
}

export function claimRepairPickUpFailure(error: string): ClaimRepairPickUpFailureT {
  return {
    type: CLAIM_REPAIR_PICK_UP_FAILURE,
    error,
  };
}

export function claimRepairDropOffRequest(id: string): ClaimRepairDropOffRequestT {
  return {
    type: CLAIM_REPAIR_DROP_OFF_REQUEST,
    id,
  };
}

export function claimRepairDropOffSuccess(data: any): ClaimRepairDropOffSuccessT {
  return {
    type: CLAIM_REPAIR_DROP_OFF_SUCCESS,
    data,
  };
}

export function claimRepairDropOffFailure(error: string): ClaimRepairDropOffFailureT {
  return {
    type: CLAIM_REPAIR_DROP_OFF_FAILURE,
    error,
  };
}

export function resetRepairState() {
  return {
    type: RESET_REPAIR_STATE,
  };
}
