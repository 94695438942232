import API from '../base/api';
import type { ReqTypeT, ResTypeT } from '../';
import { PageConfigT } from 'Store/modules/page';

export type SupportedLanguageT = {
  text: string;
  value: string;
  title: string
};

export class I18nApi extends API {
  getEnvBackend = (): ResTypeT<PageConfigT> => {
    return this.r({
      method: 'GET',
      url: `env`,
    });
  };

  getSupportedLanguages = (): ResTypeT<SupportedLanguageT[]> => {
    return this.r({
      method: 'GET',
      url: `locales`,
    });
  };

}

export default function store(request: ReqTypeT) {
  return new I18nApi(request);
}
