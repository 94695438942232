import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import cx from 'classnames';

import NoImageSvgPath from 'Assets/no-image.svg';

import type { StateT as CatalogStateT } from 'Store/modules/luggageStore/catalog';
import { CartProductT, CartT, ShopStateT } from 'Store/modules/luggageStore/shop';
import { productImageSmallUrl, productSize } from 'Helpers/store';
import { ReactComponent as TrashIcon } from 'Assets/trash.svg';
import { ReactComponent as Vaucher } from 'Assets/vaucher.svg';
import useFormattedPrice from 'Hooks/useFormattedPrice';
import styles from './ShoppingCartList.module.scss';
import { PageConfigT } from 'Store/modules/page';
import { AppStateT } from 'Store/modules';
interface IShoppingCartList {
  cart: CartT;
  className?: string;
  removeFromCart: (data: CartProductT) => void;
  claimId?: string;
  actionShowProduct: (product: CartProductT) => void
}

export default function ShoppingCartList({ cart, className, removeFromCart, claimId, actionShowProduct }: IShoppingCartList) {

    const { voucher, configShop } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const { dataIndexed: catalogIndexed } = useSelector<AppStateT, CatalogStateT>(({ store }) => store.catalog);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    let _voucher = voucher;
    const { t } = useTranslation();

    const formatPrice = useFormattedPrice(configShop.currency.code, pageConfig.noFractionCurrencies);

    useEffect(() => {
        const contentModal = document.querySelector("#contentModal");
        if (contentModal){
            contentModal.scroll({ top:0, behavior: 'auto' });
        }
    }, []);

    return (
        <div className={cx(styles.root, className)}>
            {cart.products.map((product, index) => {
                const item = catalogIndexed[product.id];
                const simpleProduct = item.extension_attributes.simple_products.find(sp => sp.id === product.productSimpleId);
                const producerName = simpleProduct?.manufacturer || '';
                const image = productImageSmallUrl(item, configShop, product.productSimpleId);

                let productPrice = product.amount;
                if (_voucher > 0){
                    if (_voucher > productPrice){
                        _voucher -= productPrice;
                        productPrice = 0;
                    } else {
                        productPrice -= _voucher;
                        _voucher = 0;
                    }
                }

                return (
                    <div className={styles.card} key={index}>
                        <div
                            className={styles.trashIcon}
                            onClick={() => {
                                removeFromCart(product);
                            }}
                        >
                            <TrashIcon className={styles.trashIconIcon} />
                        </div>

                        <div className={styles.content}>
                            <div className={styles.luggageImg}>
                                <img src={image || NoImageSvgPath} className={styles.img} alt="luggage" onClick={() => actionShowProduct(product)} />
                            </div>
                            <div className={styles.luggageTextContainer}>
                                {producerName && <div className={styles.model}>{producerName}</div>}
                                <div className={styles.typeModel} onClick={() => actionShowProduct(product)}>{item.name}</div>
                                <div className={styles.size}>
                                    {/* {item.size[0]}*blue*{item.weight} */}
                                    {productSize(item)}
                                </div>
                                <div className={styles.quantity}>
                                    {t('shop.quantity')}: {product.quantity}
                                </div>
                                <div className={styles.saleContainer}>
                                    <Vaucher height={12} width={12} />
                                    <div className={styles.sale}> {t('shop.inValueOf')} {formatPrice(+item.price)} {configShop.currency.symbol}</div>
                                </div>
                                <div className={styles.discountContainer}>
                                    {product.amount !== productPrice ?
                                        <>
                                            <div className={styles.oldPrice}>{formatPrice(Number(product.amountOrigin * product.quantity))} {configShop.currency.symbol}</div>
                                            <div className={styles.saleValue}>
                                                {formatPrice(+productPrice)} {configShop.currency.symbol}
                                            </div>
                                        </>:
                                        <div className={styles.regularPrice}>{`${formatPrice(+product.amount)} ${configShop.currency.symbol}`}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
