import { CountdownCircleTimer } from "react-countdown-circle-timer";
import React, { useCallback } from 'react';
import cx from 'classnames';

import { useDeviceType } from 'Helpers/responsiveContainers';
import style from './ModalRedirect.module.scss';
import Button from "Components/Button/Button";
import Modal from "Components/Modal/Modal";

type MainProps = {
    content: string|JSX.Element,
    buttonSend?: string
    open: boolean,
    url: string,
    seconds?: number,
    actionAfterRedirect: () => void,
    size?: 'small'|'big'
}

const ModalRedirect: React.FC<MainProps> = ({ open, content, url, seconds = 5, actionAfterRedirect, buttonSend, size = 'small' }) => {

    const { isMobile } = useDeviceType();

    const actionCompleted = useCallback(() => {
        actionAfterRedirect();
        window.location.href = url;
    }, [url, actionAfterRedirect]);

    return (
        <Modal animatedContentClassName={cx(style.root, { [style.mobile]: isMobile, [style.big]: size === 'big' })} open={open} onClose={() => null} hideTitle hideCloseButton>
            <div className={style.body}>
                <div className={style.content}>
                    {typeof content === 'string' ? content : content}
                </div>
                <div className={style.circle}>
                    <CountdownCircleTimer
                        isPlaying
                        // initialRemainingTime={initialVerificationCodeCounter}
                        duration={seconds}
                        colors={'#2fc3dd'}
                        size={100}
                        strokeWidth={10}
                        onComplete={actionCompleted}
                    >
                        {({ remainingTime }) => remainingTime }
                    </CountdownCircleTimer>
                </div>
                {buttonSend &&
                    <div className={style.buttons}>
                        <Button onClick={actionCompleted}>
                            {buttonSend}
                        </Button>
                    </div>
                }
            </div>
        </Modal>
    );
}
export default React.memo(ModalRedirect);
