import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { initialState, setCategotysId, setProductsLimit, setShopFilters, ShopStateT, StoreCategoryT } from 'Store/modules/luggageStore/shop';
import StoreFormDesktop from 'PageComponents/LuggageStore/StoreFormDesktop/StoreFormDesktop';
import { isFiltersChange } from 'Helpers/store';
import styles from './Filters.module.scss';
import { AppStateT } from 'Store/modules';
import { Modal } from 'Components';

export default function Filters(props: any) {
    const [applyFilters, setApplyFilters] = useState(false);
    const dispatch = useDispatch();
    const { filtersLocal, configShop, filters, categorysId } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);

    const changeFilters = isFiltersChange(filters, filtersLocal);

    const modalHeaderButton = (
        <div className={styles.applyContainer}>
            <button
                className={styles.apply}
                type="submit"
                disabled={!changeFilters}
                onClick={() => {
                    setApplyFilters(true);
                    setTimeout(() => {
                        setApplyFilters(false);
                    }, 500);
                }}
            >
                Apply
            </button>
        </div>
    );

    const handleSetFilters = () => {
        dispatch(setShopFilters({
            ...filtersLocal,
            search: filters.search
        }));
        dispatch(setProductsLimit(initialState.productsLimit));

        if (filtersLocal.categories.length > 0){
            const cats = configShop.categories[0].children_data.find((c: StoreCategoryT) => c.id === categorysId[0]);
            if (cats){
                dispatch(setCategotysId([...[cats.id], ...filtersLocal.categories.map((nr: string) => +nr)]));
            }
        }

    }

    return (
        <Modal
            title="Filter"
            headerRight={modalHeaderButton}
            onClose={props.onClose}
            open={props.open}
            closeOnClickOutside={true}
            back={props.onClose}
            hideCloseButton
        >
            <div className={styles.root}>

                {/* show desktop version of filters */}
                <StoreFormDesktop
                    onSubmit={handleSetFilters}
                    catalog={props.catalog}
                    mobile
                    closeModalAction={props.onClose}
                    applyFromOutside={applyFilters}
                />

            </div>
        </Modal>
    );
}
