import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MessageModal } from '../index';
import { AppStateT } from 'Store/modules';
import { clearPdfError, StateT as ClaimDetailsStateT } from 'Store/modules/claimDetails';
import styles from './ErrorModal.module.scss';

export default function ErrorModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pdfError } = useSelector<AppStateT, ClaimDetailsStateT>(
    ({ claimDetails }) => claimDetails,
  );

  const closeErrorModal = () => {
    dispatch(clearPdfError());
  };

  return (
    <MessageModal open={pdfError} onClose={closeErrorModal} centered error>
      <div className={styles.errorMessage}>{t('cashRefund.somethingWendWrong')}</div>
    </MessageModal>
  );
}
