import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import FilterHeader from '../FilterElemementHeader/FilterHeader';
import FilterBody from '../FilterElementBody/FilterBody';
import { Handle, Track, Tick } from './helpers';

import styles from './FilterRange.module.scss';

interface IProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  range: number[];
  state: number[];
  title?: string;
  rounded?: string;
  setInputValues: (a: any) => void;
  inputValues: number[];
  currency: string;
}

function FilterRange(props: IProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClickHeaderHandler = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const {
    title,
    rounded,
    setFieldValue,
    range,
    state,
    inputValues,
    setInputValues,
    currency,
  } = props;
  const values = state;
  const domain = range;

  const filterRange = useMemo(() => {
    return (
      <div className={cx(styles.wrapper)}>
        <div className={styles.header}>
          <div className={styles.valueBox}>
            <span className={styles.valueText}>{t('shop.minRange')}</span>
            <span className={styles.valueText}>{currency + inputValues[0]}</span>
          </div>
          <div className={styles.valueBox}>
            <span className={styles.valueText}>{t('shop.maxRange')}</span>
            <span className={styles.valueText}>{currency + inputValues[1]}</span>
          </div>
        </div>
        <div className={styles.sliderWrapper}>
          <Slider
            mode={1}
            step={1}
            domain={domain}
            className={styles.sliderStyle}
            onUpdate={(values: readonly number[]) => setInputValues(values)}
            onChange={(values: readonly number[]) => setFieldValue('price', values)}
            values={values}
          >
            <Rail>
              {({ getRailProps }) => <div className={styles.railStyle} {...getRailProps()} />}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                  {handles.map((handle) => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={domain}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }) => (
                    <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                  ))}
                </div>
              )}
            </Tracks>
            <Ticks count={10}>
              {({ ticks }) => (
                <div className="slider-ticks">
                  {ticks.map((tick) => (
                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                  ))}
                </div>
              )}
            </Ticks>
          </Slider>
        </div>
      </div>
    );
  }, [currency, domain, inputValues, setFieldValue, setInputValues, values, t]);

  return (
    <div
      className={cx(styles.root, {
        [styles.top]: rounded === 'top',
        [styles.bottom]: rounded === 'bottom',
        [styles.rootDesktop]: !title,
      })}
    >
      {title ? (
        <>
          <FilterHeader title={title} onClickHandler={onClickHeaderHandler} isOpen={isOpen} />
          <FilterBody isOpen={isOpen}>{filterRange}</FilterBody>
        </>
      ) : (
        filterRange
      )}
    </div>
  );
}

export default FilterRange;
