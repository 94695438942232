import { LuggageT } from 'api/modules/store';
import { loading, loaded, error } from 'Helpers/store-async';

export const FETCH_LUGGAGE_DETAILS_REQUEST = 'FETCH_LUGGAGE_DETAILS_REQUEST';
export const FETCH_LUGGAGE_DETAILS_SUCCESS = 'FETCH_LUGGAGE_DETAILS_SUCCESS';
export const FETCH_LUGGAGE_DETAILS_FAILURE = 'FETCH_LUGGAGE_DETAILS_FAILURE';

export type FetchLuggageDetailsRequestT = {
  type: typeof FETCH_LUGGAGE_DETAILS_REQUEST;
  data: number;
};

export type FetchLuggageDetailsSuccessT = {
  type: typeof FETCH_LUGGAGE_DETAILS_SUCCESS;
  data: LuggageT;
};

export type FetchLuggageDetailsFailureT = {
  type: typeof FETCH_LUGGAGE_DETAILS_FAILURE;
  error: string;
};

export type StateT = {
  data: LuggageT | null;
  loading: boolean;
  loaded: boolean;
  error: string | null;
};

const initialState: StateT = {
  data: null,
  loading: false,
  loaded: false,
  error: null,
};

type ActionTypeT =
  | FetchLuggageDetailsRequestT
  | FetchLuggageDetailsSuccessT
  | FetchLuggageDetailsFailureT;

export default function markedClaims(state: StateT = initialState, action: ActionTypeT): StateT {
  switch (action.type) {
    case FETCH_LUGGAGE_DETAILS_REQUEST: {
      return loading<StateT>(state);
    }
    case FETCH_LUGGAGE_DETAILS_SUCCESS: {
      return loaded<StateT, LuggageT>(state, action.data);
    }
    case FETCH_LUGGAGE_DETAILS_FAILURE: {
      return error<StateT>(state, action.error);
    }
    default: {
      return state;
    }
  }
}

export function fetchLuggageDetails(luggageId: number): FetchLuggageDetailsRequestT {
  return {
    type: FETCH_LUGGAGE_DETAILS_REQUEST,
    data: luggageId,
  };
}

export function fetchLuggageDetailsSuccess(luggage: LuggageT): FetchLuggageDetailsSuccessT {
  return {
    type: FETCH_LUGGAGE_DETAILS_SUCCESS,
    data: luggage,
  };
}

export function fetchLuggageDetailsFailure(error: string): FetchLuggageDetailsFailureT {
  return {
    type: FETCH_LUGGAGE_DETAILS_FAILURE,
    error,
  };
}
