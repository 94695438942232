import React from 'react';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ChatMessageGroup } from 'Components';
import styles from 'PageComponents/Chat/Chat/Chat.module.scss';
// import type { AppStateT } from 'Store/modules';
// import type { ClaimChatStateT } from 'Store/modules/claimChat';
import { ClaimHistoryT } from 'api/modules/case-resolution';

export const RESOLUTION_TYPE = 'repair';

export default React.memo(function RepairMessageGroup({ statusInfo }: { statusInfo: ClaimHistoryT }) {
  const { t } = useTranslation();
  // const resolution = useSelector<AppStateT, ClaimChatStateT['data']['resolution']>(
  //   ({ claimChat }) => claimChat.data.resolution,
  // );

  const resolution = statusInfo.resolution;

  if (!resolution) {
    return null;
  }

  let userMessages;
  if (resolution.orderData.type === 'pick-up') {
    // userMessages = t('repair.pickItUpUserMessage');
  } else if (resolution.orderData.type === 'drop-off') {
    userMessages = t('repair.dropOffUserMessage');
  }

  if (!userMessages) {
    return null;
  }

  return (
    <ChatMessageGroup
      as="MessageGroup"
      direction="outgoing"
      data={[
        {
          message: userMessages,
          className: styles.messageOut,
        },
      ]}
    />
  );
});
