import React, { useState, useMemo, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import cx from 'classnames';

import InputMask from 'react-input-mask';
import styles from './Input.module.scss';

type RightDataT = {
    value: string,
    label: string
}

export type PropsT = {
  Icon?: React.JSXElementConstructor<any>;
  RightIcon?: React.JSXElementConstructor<any>;
  name?: string;
  type?: string;
  value?: string;
  label?: React.ReactNode | string;
  className?: string;
  error?: string | string[] | any;
  notValid?: boolean;
  onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (evt: MouseEvent<HTMLInputElement>) => void;
  touched?: boolean | any;
  InputComponent?: React.JSXElementConstructor<any>;
  hasValue?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  withoutLabel?: boolean;
  readOnly?: boolean;
  success?: boolean,
  inputMode?: string,
  inputModeNoNumber?: boolean,
  rightModeData?: RightDataT[]|string|undefined,
  rightModeValue?: string|null
  rightModeAction?: (data: string) => void,
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  formatters?: Array<
    (evt: React.ChangeEvent<HTMLInputElement>) => React.ChangeEvent<HTMLInputElement>
  >;
  mask?: string;
  disabled?: boolean,
  maxLength?: number,
  prefixText?: string,
  alwaysSmallLabel?: boolean,
  id?: string,
  onPrefixClick?: () => void
};

const Input = (props: PropsT) => {
    const {
        Icon,
        RightIcon,
        label,
        className,
        error,
        notValid,
        touched,
        value,
        onFocus: propsOnFocus,
        onBlur: propsOnBlur,
        onClick,
        InputComponent,
        hasValue,
        inputRef,
        withoutLabel,
        onChange,
        readOnly,
        formatters,
        success,
        mask = null,
        disabled = false,
        inputMode = '',
        inputModeNoNumber = false,
        rightModeData = '',
        rightModeValue = null,
        maxLength = 255,
        rightModeAction,
        prefixText,
        alwaysSmallLabel,
        id,
        onPrefixClick,
        ...rest
    } = props;
    const { t } = useTranslation();
    const [inFocus, setInFocus] = useState<boolean>(false);
    const isErrorVisible = error && (typeof touched === 'boolean' ? touched : false);
    const showError = !inFocus && isErrorVisible;
    const InternalInputComponent = InputComponent || 'input';

    const onFocus = useCallback((evt: React.FocusEvent<HTMLInputElement>) => {
        if (typeof propsOnFocus === 'function') {
            propsOnFocus(evt);
        }
        setInFocus(true);
    }, [propsOnFocus]);

    const onBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (typeof propsOnBlur === 'function') {
            propsOnBlur(event);
        }
        setInFocus(false);
    }, [propsOnBlur]);

    const internalOnChange = useMemo(() => {
        if (!formatters || !Array.isArray(formatters)) {
            return onChange;
        }

        return compose(onChange, ...formatters);
    }, [onChange, formatters]);

    const actionChangeData = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        rightModeAction?.(event.target.value);
    }, [rightModeAction]);

    return (
        <div className={cx(className)}>
            <label
                className={cx(styles.root, {
                    [styles.active]: inFocus,
                    [styles.hideLabel]: alwaysSmallLabel,
                    [styles.withIcon]: Icon,
                    [styles.withError]: showError || notValid,
                    [styles.withValue]: typeof hasValue === 'boolean' ? hasValue : value,
                    [styles.withoutLabel]: withoutLabel,
                    [styles.readOnly]: readOnly,
                    [styles.withPrefix]: !!prefixText,
                    [styles.prefixAction]: !!onPrefixClick,
                    [styles.success]: !!props.success,
                })}
            >
                {Icon && <Icon className={styles.icon} />}

                <span className={styles.label}>{props.label}</span>

                <div className={styles.inputs}>
                    {prefixText &&
                        <span className={styles.prefix} onClick={onPrefixClick}>{prefixText}</span>
                    }
                    {!!mask ?
                        <InputMask
                            {...rest}
                            onClick={onClick}
                            onChange={internalOnChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            value={value}
                            mask={String(mask)}
                            disabled={disabled}
                        /> :
                        <InternalInputComponent
                            {...rest}
                            onClick={onClick}
                            onChange={internalOnChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            value={value}
                            ref={inputRef}
                            disabled={props.disabled}
                            inputMode={inputMode === 'numeric' ? 'decimal'  : inputMode as any}
                            type={inputMode === 'numeric' && !inputModeNoNumber ? 'number' : inputMode}
                            maxLength={maxLength}
                            readOnly={readOnly}
                            id={id}
                        />

                    }
                </div>
                {RightIcon && <RightIcon className={styles.rightIcon} />}
                {rightModeData && <RightMode data={rightModeData} actionChangeData={actionChangeData} value={rightModeValue} />}
            </label>

            {showError && <span className={styles.error}>{t(`validation.${error}`)}</span>}
        </div>
    );
}

export default Input

type RightModeT = {
    data: RightDataT[]|string,
    actionChangeData: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    value: string|null
}

const RightMode: React.FC<RightModeT> = ({ data, actionChangeData, value }) => {

    return (
        <div className={styles.rightMode}>
            {(Array.isArray(data) && data.length > 1) ?
                <select onChange={actionChangeData} value={value || ''}>
                    {data.map(d => (
                        <option value={d.value} key={d.label}>{d.label}</option>
                    ))}
                </select>:
                <div className={styles.rightModeLabel}>{Array.isArray(data) ? data[0].label : data}</div>
            }
        </div>
    )
}