import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router";
import { useEffect } from 'react';

import { CartProductT, clearCart, removeCartProduct, setOrderResult, ShopStateT } from 'Store/modules/luggageStore/shop';
import { ShoppingCartMenu, ShoppingCartMainScreen } from 'PageComponents/LuggageStore';
import type { StateT as CatalogStateT } from 'Store/modules/luggageStore/catalog';
import styles from './ShoppingCartContainer.module.scss';
import { runNotify } from 'Helpers/notifications';
import type { AppStateT } from 'Store/modules';
import { SideBarDesktop } from 'Components';

function ShoppingCartContainer() {
    const dispatch = useDispatch();
    const { cart, orderResult } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const { dataIndexed: catalogIndexed } = useSelector<AppStateT, CatalogStateT>(({ store }) => store.catalog);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();

    useEffect(() => {
        if (orderResult.result){
            // clear cart
            dispatch(clearCart());
            navigate("/"+params.claimId);
            runNotify({ message: `The order has been sent`, type: 'success'});
        } else if (orderResult.result === false){
            // send message
            runNotify({ message: orderResult.commentResult, type: 'error'});
            // clear result
            dispatch(setOrderResult({ commentResult: '', process: false, result: null }));
        }
    }, [orderResult, dispatch, navigate, params.claimId]);

    function removeFromCart(data: CartProductT) {
        const quantityCartProducts = cart.products.reduce((acc, product: CartProductT)=> acc + product.quantity, 0);
        const product = catalogIndexed[data.id];
        dispatch(removeCartProduct(data))
        // if left only one product and we remove from cart
        runNotify({ message: t('shop.removeCart', { productName: `<strong>${product.name}</strong>` }), type: 'error' });
        if (quantityCartProducts <= 1){
            navigate("/store/"+params.claimId);
            runNotify({ message: t('shop.emptyCart'), type: 'error'});
        }
    }

    return (
        <>
            <SideBarDesktop animation="left" show={true} containerClassName={styles.sideBarDesktop}>
                <ShoppingCartMenu
                    claimId={params.claimId}
                    cart={cart}
                    removeFromCart={removeFromCart}
                />
            </SideBarDesktop>

            <ShoppingCartMainScreen />
        </>
    );
}

export default ShoppingCartContainer;
