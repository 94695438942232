import Lottie from 'react-lottie';
import cx from 'classnames';
import React from 'react';

import animationData from 'Assets/lotties/loading.json';
import style from './Loading.module.scss';

type MainPropsT = {
    show?: boolean,
    backdrop?: boolean,
    dark?: boolean,
    size?: number,
    subtitle?: string,
    fixed?: boolean,
    className?: string;
}

const Loading: React.FC<MainPropsT> = ({ show = true, backdrop, size = 150, dark, subtitle, fixed, className }) => {

    return (
        <>
            {show && (
                <div className={cx(style.loader, className, { [style.backdrop]: !!backdrop, [style.dark]: !!dark, [style.fixed]: !!fixed })}>
                    <Lottie
                        options={{ animationData }}
                        height={size}
                        width={size}
                        isClickToPauseDisabled
                    />
                    {subtitle &&
                        <div className={style.subtitle}>{subtitle}</div>
                    }
                </div>
            )}
        </>
    );
}
export default Loading;