import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { ReactComponent as ImgLuggage } from 'Assets/luggage_types/luggage.svg';
import { ReactComponent as ImgSpecial } from 'Assets/luggage_types/special.svg';
import { ReactComponent as ImgBackpack } from 'Assets/luggage_types/backpack.svg';
import { ReactComponent as ImgBusiness } from 'Assets/luggage_types/business.svg';
import { ReactComponent as ImgReisetasche } from 'Assets/luggage_types/reisetasche.svg';
import { ReactComponent as ImgSport } from 'Assets/luggage_types/sport.svg';
import { ReactComponent as ImgAnimal } from 'Assets/luggage_types/animal.svg';
import { ReactComponent as ImgStroller } from 'Assets/luggage_types/stroller.svg';
import { ReactComponent as ImgMusic } from 'Assets/luggage_types/music.svg';
import { ReactComponent as ImgMedical } from 'Assets/luggage_types/medical.svg';

import { makeSlug, setLuggageTypeTitleLang } from 'Helpers/strings';
import { changeModalTooltip } from 'Store/modules/page';
import { StateLocalT } from '../../CreateForm';
import style from './LuggageType.module.scss';
import Ico from 'Components/Ico/Ico';

type BaggageT = {
    slug: string,
    title: string,
    originalTitle: string,
    tooltip?: { title: string, content: string }
}

type MainProps = {
    state: StateLocalT,
    setValidStep: (result: boolean) => void,
    updateClaim: (dataType: string, data: any) => void,
    actionSetEditIndex: (data: number|null) => void
}

const LuggageType: React.FC<MainProps> = ({ state, setValidStep, updateClaim, actionSetEditIndex }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [luggageType] = useState(state.claimData.luggageType);
    const [originEditIndex] = useState(state.editStepIndex);
    const [luggageTypes, setLuggageTypes] = useState<BaggageT[]>([]);

    useEffect(() => {
        setValidStep(!!state.claimData.luggageType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.claimData.luggageType]);



    useEffect(() => {

        setLuggageTypes(state.baggageTypes.map((baggage: string) => {

            const slug = makeSlug(baggage);

            let tooltip = undefined;
            if (baggage === 'Luggage'){
                tooltip = { title: t(setLuggageTypeTitleLang(slug)), content: t('createClaim.typeDescriptionLuggage') };
            } else if (baggage === 'Reisetasche'){
                tooltip = { title: t(setLuggageTypeTitleLang(slug)), content: t('createClaim.typeDescriptionReisetasche') };
            } else if (baggage === 'Business'){
                tooltip = { title: t(setLuggageTypeTitleLang(slug)), content: t('createClaim.typeDescriptionBusiness') };
            } else if (baggage === 'Sport'){
                tooltip = { title: t(setLuggageTypeTitleLang(slug)), content: t('createClaim.typeDescriptionSport') };
            } else if (baggage === 'Music'){
                tooltip = { title: t(setLuggageTypeTitleLang(slug)), content: t('createClaim.typeDescriptionMusic') };
            } else if (baggage === 'Baby stroller'){
                tooltip = { title: t(setLuggageTypeTitleLang(slug)), content: t('createClaim.typeDescriptionBaby') };
            }

            return { 
                slug,
                title: t(setLuggageTypeTitleLang(slug)),
                originalTitle: baggage,
                tooltip
            };
        }));

    }, [state.baggageTypes, t]);


    const setImage = useCallback((type: string) => {
        switch(type){

            case 'special-luggage': return <ImgSpecial className={style.ico} />
            case 'backpack': return <ImgBackpack className={style.ico} />
            case 'business': return <ImgBusiness className={style.ico} />
            case 'travel-bag': return <ImgReisetasche className={style.ico} />
            case 'sport-luggage': return <ImgSport className={style.ico} />
            case 'animal-cage': return <ImgAnimal className={style.ico} />
            case 'baby-stroller': case 'baby-equipment': return <ImgStroller className={style.ico} />
            case 'music': return <ImgMusic className={style.ico} />
            case 'medical': return <ImgMedical className={style.ico} />

            default: return <ImgLuggage className={style.ico} />
        }
    }, [])

    const actionChangeLuggageType = useCallback((originalTitle: string) => {
        if (originalTitle === state.claimData.luggageType) return updateClaim('luggageType', '');
        updateClaim('luggageType', originalTitle);
        updateClaim('damage', []);

        if (originEditIndex){
            if (originalTitle === luggageType){
                actionSetEditIndex(originEditIndex);
            } else {
                actionSetEditIndex(null);
            }
        }

    }, [state.claimData.luggageType, updateClaim, originEditIndex, actionSetEditIndex, luggageType]);

    const toolTipInformation = (ev: React.MouseEvent<HTMLElement>, title: string, content: string) => {
        ev.stopPropagation();
        dispatch(changeModalTooltip({ title, content }));
    }

    return (
        <div className={style.root}>
            <div className={style.luggages}>
                {luggageTypes.map(typ => {

                    return (
                        <div
                            className={cx(style.luggage, { [style.selected]: typ.originalTitle === state.claimData.luggageType })}
                            onClick={() => actionChangeLuggageType(typ.originalTitle)}
                            key={`lugg_${typ.slug}`}
                            id={'select_new_claim_type_' + typ.slug}
                        >
                            <div className={style.image}>{setImage(typ.slug)}</div>
                            <div className={style.label}>{typ.title}</div>
                            {typ.tooltip &&
                                <span className={style.headerIco} onClick={(ev) => toolTipInformation(ev, typ.tooltip?.title as string, typ?.tooltip?.content as string)}><Ico action={() => null} /></span>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
export default React.memo(LuggageType);