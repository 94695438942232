import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { ReactComponent as IcoHow } from 'Assets/helpCenter/how_1_.svg';
import { ReactComponent as IcoMedal } from 'Assets/helpCenter/medal_1_.svg';
import { ReactComponent as IcoPlane } from 'Assets/helpCenter/plane_1_.svg';
import { ReactComponent as IcoRepair } from 'Assets/helpCenter/repair_1_.svg';
import { ReactComponent as IcoTrip } from 'Assets/helpCenter/trip_1.svg';
import { ReactComponent as IcoBack } from 'Assets/back-create-claim.svg';
import { ReactComponent as IcoContact } from 'Assets/aboutus/contact.svg';
import { ReactComponent as IcoArrow } from 'Assets/arrow.svg';

import { AccountSettingsValueT } from 'Store/modules/accountSettings';
import style from './HelpCenter.module.scss';
import { AppStateT } from 'Store/modules';

type PropsM = {
    chooseSetting: (settingType: AccountSettingsValueT) => void;
    setting: string;
};

type ArticleT = { title: any, content: any };

type DataT = {
  title: any,
  articles: ArticleT[],
  Icon: JSX.Element,
  id: number,
  url?: string
};

const HelpCenter: React.FC<PropsM> = ({ setting, chooseSetting }) =>  {

    const [selected, setSelected] = useState<{ id: number|null, article: ArticleT | null }>({ id: null, article: null });
    const searchPanelValue = useSelector<AppStateT, string>(({ accountSettings }) => accountSettings.searchPanelValue);
    const { t } = useTranslation();

    useEffect(() => {
        if (searchPanelValue.trim().length > 0){
            setSelected({ id: null, article: null });
        }
    }, [searchPanelValue]);

    const helpCenterData: DataT[] = useMemo(() => [
        { title: t('help_center.airlinesAndRegulations'), articles: [
            { title: parse(t('help_center.hc_acv')), content: 'hc_Acv' },
            { title: parse(t('help_center.hc_repairable')), content: 'hc_repairable' },
            { title: parse(t('help_center.hc_airline_claim')), content: 'hc_airline_claim' },
            { title: parse(t('help_center.hc_damage_report')), content: 'hc_damage_report' },
            { title: parse(t('help_center.hc_fileReference_number')), content: 'hc_fileReference_number' },
            { title: parse(t('help_center.hc_iata_montreal')), content: 'hc_iata_montreal' },
            { title: parse(t('help_center.hc_residual_value')), content: 'hc_residual_value' },
        ], Icon: <IcoPlane />, id: 1, url: 'https://www.dolfi1920.de/faq/category/airlines-and-regulations.html' },
        { title: t('help_center.baggage'), articles: [
            { title: parse(t('help_center.hc_warranty')), content: 'hc_warranty' },
            { title: parse(t('help_center.hc_damages_can_repair')), content: 'hc_damages_can_repair' },
        ], Icon: <IcoMedal />, id: 2, url: 'https://www.dolfi1920.de/faq/category/baggage-repair-under-warranty.html' },
        { title: t('help_center.allAround'), articles: [
            { title: parse(t('help_center.hc_set_combination')), content: 'hc_set_combination' },
            { title: parse(t('help_center.hc_tumi_rimova')), content: 'hc_tumi_rimova' },
        ],Icon: <IcoRepair />, id: 4, url: 'https://www.dolfi1920.de/faq/category/how-to-self-repair-my-baggage.html' },
        { title: t('help_center.howDo'), articles: [
            { title: parse(t('help_center.hc_private_repairs')), content: 'hc_private_repairs' },
            { title: parse(t('help_center.hc_alternate_baby_stroller')), content: 'hc_alternate_baby_stroller' },
            { title: parse(t('help_center.hc_necessary_documents')), content: 'hc_necessary_documents' },
            { title: parse(t('help_center.hc_how_to_picture')), content: 'hc_how_to_picture' },
            { title: parse(t('help_center.hc_types_of_luggage')), content: 'hc_types_of_luggage' },
            { title: parse(t('help_center.hc_replacement')), content: 'hc_replacement' },
            { title: parse(t('help_center.hc_contact_list')), content: 'hc_contact_list' },
        ], Icon: <IcoHow />, id: 5, url: 'https://www.dolfi1920.de/faq/category/dolfi-how-do-we-do-things.html' },
        { title: t('help_center.prepareTrip'), articles: [
            { title: parse(t('help_center.hc_travel_sentry')), content: 'hc_travel_sentry' },
            { title: parse(t('help_center.hc_how_to_pack')), content: 'hc_how_to_pack' },
            { title: parse(t('help_center.hc_perfect_baggage')), content: 'hc_perfect_baggage' },
        ], Icon: <IcoTrip />, id: 6, url: 'https://www.dolfi1920.de/faq/category/prepare-for-your-next-trip.html' },
        { title: t('help_center.contact'), articles: [
            { title: parse(t('help_center.hc_contact')), content: 'hc_contact' },
        ], Icon: <IcoContact style={{ width: 60, height: 80 }} />, id: 7, url: 'https://www.dolfi1920.de/faq/category/prepare-for-your-next-trip.html' },
    ], [t]);

    const actionLabel = (id: number) => {
        if (selected.id === null || (selected.id !== null && selected.id !== id)){

            const articles = helpCenterData.find(d => d.id === id);
            let article = null;
            if (articles && articles.articles.length === 1){
                article = articles.articles[0];
            }

            // if (articles && articles.url){
            //     dispatch(setAccountSettings(''));
            //     window.open(articles.url);
            //     return null;
            // }

            return setSelected({ id, article: article });
        }
        setSelected({ id: null, article: null });
    }

    const actionClickArticle = (id: number| null, article: ArticleT) => {
        setSelected({ id, article: article });
    }

    const actionBackArticle = useCallback(() => {
        if ((helpCenterData.find(d => d.id === selected.id)?.articles?.length || 0) >1){
            return setSelected({ id: selected.id, article: null });
        }
        setSelected({ id: null, article: null });
    }, [selected, helpCenterData]);

    const criteriaSearch = (data: DataT, search: string): boolean => {
        const _search = search.trim().toLowerCase();
        if (search.trim() !==''){
            if (data.title.toLowerCase().indexOf(_search) >= 0 || data.articles.some(a => a.title.toLowerCase().indexOf(_search) >=0)){
                return true;
            }
            return false;
        }
        return true;
    }

    return (
        <div className={style.root}>
            {helpCenterData.filter(data => criteriaSearch(data, searchPanelValue) && !selected.id ? true : selected.id === data.id).map(data => {
                return (
                    <div className={style.rowMain} key={`help_${data.id}`}>
                        <div className={style.rowLabel} onClick={() => (selected.article && selected.id) ? actionBackArticle() : actionLabel(data.id)}>
                            <div className={style.ico}>{data.Icon}</div>
                            <div className={style.label}>
                                {(selected.article && selected.id) ? selected.article.title : data.title}
                                {/* {data.title} */}
                            </div>
                            {data.id === selected.id &&
                                <div className={style.arrowBack}><IcoBack /></div>
                            }
                        </div>
                    </div>
                )
            })}
            {(selected.id !== null && !selected.article) &&
                <div className={style.list}>
                    {helpCenterData.find(data => data.id === selected.id)?.articles.map((article, idx) => {
                        return (
                            <div className={style.article} onClick={() => actionClickArticle(selected.id, article)} key={`articleKey_${idx}`}>
                                <div className={style.arrow}><IcoArrow /></div>
                                <div className={style.title}>
                                    {article.title}
                                </div>
                            </div>
                        )}
                    )}
                </div>
            }
            {(selected.id && selected.article) &&
                <div className={style.articleMain}>
                    {/* <div className={style.header}>
                        <div className={style.title}>
                            {selected.article.title}
                        </div>
                        {(helpCenterData.find(d => d.id === selected.id)?.articles?.length || 0) >1 &&
                            <div className={style.arrowBack} onClick={() => actionBackArticle()}><IcoBack /></div>
                        }
                    </div> */}
                    <div className={style.articleContent}>
                        {/* <RichText render={selected.article.content} /> */}
                        {parse(t(`helpCenterMain.${selected.article.content}`))}
                    </div>
                </div>
            }
        </div>
    )

}

export default HelpCenter;
