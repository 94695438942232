import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { ShopStateT, addCartProductSimple } from 'Store/modules/luggageStore/shop';
import type { StateT as CatalogStateT } from 'Store/modules/luggageStore/catalog';
import { LuggageDetailsMenu, LuggageGallery } from 'PageComponents/LuggageStore';
import type { LuggageProductsSimpleT, LuggageT } from 'api/modules/store';
import { maxProductsInShoppingCart } from "Helpers/rules";
import { runNotify } from 'Helpers/notifications';
import styles from './LuggageDetails.module.scss';
import type { AppStateT } from 'Store/modules';
import { SideBarDesktop } from 'Components';

function LuggageDetails() {
    const dispatch = useDispatch();
    // const luggageId = Number(match.params.id);
    const navigate = useNavigate();
    const [luggage, setLuggage] = useState<LuggageT|null>(null);
    const { t } = useTranslation();
    const { cart } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    // const { claimId, productSimpleId, luggageId } = useParams();
    const params = useParams();

    const { data: catalog } = useSelector<AppStateT, CatalogStateT>(({ store }) => store.catalog);

    const addToCart = useCallback((productSimple: LuggageProductsSimpleT | null, data: LuggageT) => {
        const countProducts = cart.products.reduce((accu, data) => accu + data.quantity, 0);
        if (countProducts >= maxProductsInShoppingCart){
            return runNotify({ message: t('shop.fullCartWarning'), type: 'error' });
        }
        if (productSimple){
            dispatch(addCartProductSimple(productSimple, data));
            navigate(`/store/${params?.claimId}/shopping-cart`);
            runNotify({ message: t('shop.addCart', { productName: `<strong>${productSimple.name}</strong>` }), type: 'success' });
        }
    }, [dispatch, navigate, params?.claimId, t, cart.products]);

    useEffect(() => {
        const _luggage = catalog.find((product: LuggageT) => product.id === +params?.id);
        setLuggage(_luggage || null);
    }, [params?.id, catalog]);

    return (
        <>
            <SideBarDesktop animation="left" show={true} containerClassName={styles.sideBarDesktop}>
                {luggage && <LuggageDetailsMenu luggage={luggage} addToCart={addToCart} claimId={params?.claimId} productSimpleId={params?.productSimpleId} />}
            </SideBarDesktop>

            {luggage && <LuggageGallery key={luggage.id} />}
        </>
    );
}

export default LuggageDetails;
