import { base64ToBlob } from 'base64-blob';
import FileSaver from "file-saver";
import heic2any from "heic2any";

import { IImage } from "PageComponents/Chat/ReportUploadBox/ReportUploadBox";
import { fileToBlob } from "./fileToBlob";
import CONFIG from "Config";

export const getFileData = (filePath: string, clearQuestionMarkName = false) => {
    const m = filePath.split("/");
    const fileName = clearQuestionMarkName ? m[m.length - 1].split('?')[0] : m[m.length - 1];
    const extArray = fileName.split(".");
    const ext = extArray[extArray.length - 1].toLowerCase();
    const name = extArray[extArray.length - 2];

    let mimeType = 'plain/text';
    if (ext === 'jpg' || ext === 'jpeg') mimeType = 'image/jpeg';
    else if (ext === 'png') mimeType = 'image/png';
    else if (ext === 'heic') mimeType = 'image/heic';
    else if (ext === 'pdf') mimeType = 'application/pdf';
    else if (ext === 'gif') mimeType = 'image/gif';
    else if (ext === 'webp') mimeType = 'image/webp';
    else if (ext === 'doc') mimeType = 'application/msword';
    else if (ext === 'docx') mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    else if (ext === 'eot') mimeType = 'application/vnd.ms-fontobject';

    return {
        ext,
        fileName,
        mimeType,
        name,
        type: mimeType.indexOf('image/') >= 0 ? 'image' : 'document'
    }
}

export const fileToBinaryText = async (f: IImage | undefined) => {
    if (!f) return null;

    return await fileToBlob(f.file);
}


export const filesApproved = ['heic'];// nietypowe pliki do przesłania 

export const downloadAs = async (filePath: string, name: string) => {
    try {
        let data = await fetch(filePath).then(r => r.blob());
        const fileData = getFileData(filePath);
        const file = new Blob([data], { type: fileData.mimeType });
        FileSaver.saveAs(file, name);
    } catch {
        window.open(filePath);
    }
}

export const calculateNewHeight = (currentWidth: number, currentHeight: number, newWidth: number | null = null): number => {
    if (!newWidth) {
        throw new Error("At least one of newWidth or newHeight must be provided");
    }

    if (newWidth) {
        const ratio = newWidth / currentWidth;
        return currentHeight * ratio
    } else {
        return currentHeight
    }
}

export const createFile = async (url: string, fileName: string, type: string) => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
        type
    };
    return new File([data], fileName, metadata);
}

export const convertToJpgAndResize = async (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
            const img = new Image();
            // @ts-ignore
            img.src = event.target.result;

            img.onload = function () {
                const canvas = document.createElement('canvas');
                if (img.width > CONFIG.MAX_UPLOAD_IMAGE_WIDTH) {
                    canvas.width = CONFIG.MAX_UPLOAD_IMAGE_WIDTH;
                    canvas.height = calculateNewHeight(
                        img.width,
                        img.height,
                        CONFIG.MAX_UPLOAD_IMAGE_WIDTH
                    );
                } else {
                    canvas.width = img.width;
                    canvas.height = img.height;
                }

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                canvas.toBlob(function (blob) {
                    if (blob) {
                        const fileBlob = new File([blob], file.name, { type: 'image/jpeg' });
                        resolve({
                            file: fileBlob,
                            blob
                        });
                    } else {
                        reject(new Error("Canvas toBlob conversion failed."));
                    }
                }, 'image/jpeg', 0.75);
            };
        };

        reader.onerror = function (error) {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
}

export const urlToBase64 = async (url: string) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const base64 = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
        return base64ToBlob(base64 as any);
    } catch (error) {
        throw new Error('Wystąpił błąd: ' + error);
    }
}

export const urlToObject = async (url: string) => {
    const response = await fetch(url);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', { type: blob.type });
    return file;
}

export const setImageDataUrl = async (url: string): Promise<any> => {
    try {
        const fileData = getFileData(url, true);
        let _file = await createFile(url, fileData.fileName, fileData.mimeType);

        if (fileData.ext === 'heic') {
            try {
                const convertedJpg = await heic2any({
                    blob: _file,
                    toType: "image/jpeg",
                    quality: 0.9
                });
                _file = new File([convertedJpg as any], fileData.name + '.jpg', { type: "image/jpg", lastModified: new Date().getTime() });
            } catch (error) {
                return Promise.reject(new Error('Error converting HEIC to JPG'));
            }
        }

        const dataConverted: any = await convertToJpgAndResize(_file);
        if (!dataConverted) {
            return Promise.reject(new Error('Error converting to JPG'));
        }
        _file = new File([dataConverted.blob], fileData.name + '.jpg', { type: "image/jpg", lastModified: new Date().getTime() });

        return Promise.resolve({
            file: {
                name: fileData.fileName,
                type: fileData.type
            },
            name: fileData.fileName,
            url,
            base64: _file,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export const safeFileName = (originalName: string): string => {

    const forbiddenCharacters = /[^a-zA-Z0-9_.]/g;
    const safeName = originalName.replace(forbiddenCharacters, '');

    return safeName;
}

export const changeFileNewName = (oldFile: File, newName: string): File => {
    if (!(oldFile instanceof File)) {
        throw new Error('This file it is NOT type of File');
    }

    if (typeof newName !== 'string' || newName.trim() === '') {
        throw new Error('This new name is not string');
    }

    const newFile = new File([oldFile], newName, { type: oldFile.type });
    return newFile;
}

export const randomNumber = (min: number, max: number): number => Math.floor(Math.random() * (max - min) + min);

export const changeNameFile = (file: File, newName: string, fileType = ''): File => {
    if (!(file instanceof File)) {
        throw new Error('Argument "file" musi być obiektem typu File');
    }
    const blob = new Blob([file], { type: fileType || file.type });
    const newFile = new File([blob], newName, { type: fileType || file.type });
    return newFile;
}