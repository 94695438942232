import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateId } from 'Helpers/strings';
import cx from 'classnames';

import { ReactComponent as ImgAdd } from 'Assets/add_button.svg';
import { ReactComponent as ImgTrash } from 'Assets/trash-black.svg';

import DropdownMobile from 'Components/DropdownMobile/DropdownMobile';
import { StateLocalT } from '../../CreateForm';
import style from './Damage.module.scss';
import { Dropdown } from 'Components';

type DataOptionT = {
    value: string,
    text: string,
    disabled?: boolean
}

type DamageLocalT = {
    side: string|null,
    type: string|null,
    id: string
}

type MainProps = {
    state: StateLocalT,
    updateClaim: (dataType: string, data: any) => void,
    setValidStep: (result: boolean) => void,
    mobile: boolean
}

const maxDamages = 5;

const Damage: React.FC<MainProps> = ({ state, updateClaim, setValidStep, mobile }) => {

    const dataDamageOptions = useMemo(() => {
        const _data = Array(state.damageOptions.VALUES?.[state.luggageTypes[state.claimData.luggageType]]);
        if (_data.length > 0 && _data[0] && Array.isArray(_data[0])){
            return _data[0].filter(data => data.indexOf("<") < 0).map(data => {
                return { value: data, text: data }
            })
        }
        return [];
    }, [state.damageOptions.VALUES, state.claimData.luggageType, state.luggageTypes]);

    const [damages, setDamages] = useState<DamageLocalT[]>([{ side: null, type: null, id: generateId() }]);
    const [damageOptions] = useState<DataOptionT[]>(
        [
            ...[{ value: '', text: '', disabled: true }],
            ...dataDamageOptions
        ]
    );
    const { t } = useTranslation();

    useEffect(() => {
        const damagesSave = damages.filter(d => d.side && d.type).map(d => {
            return { side: d.side, type: d.type }
        });
        updateClaim('damage', damagesSave);
        setValidStep(damagesSave.length > 0 && !damages.some(d => !d.side || !d.type));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [damages]);

    useEffect(() => {
        setDamages([...(state.claimData.damage.length === 0 ? [{ side: null, type: null, id: generateId() }] : []), ...state.claimData.damage.map(d => {
            return { side: d.side, type: d.type, id: generateId() };
        })]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getTitleSide = React.useMemo(() => (text: string): string => {
        switch (text.toLowerCase()){
            case 'top': return t('createClaim.damageTop');
            case 'left': return t('createClaim.damageLeft');
            case 'bottom': return t('createClaim.damageBottom');
            case 'right': return t('createClaim.damageRight');
            default: return text.toLowerCase();
        }
    }, [t]);

    const actionChangeSide = useCallback((data: DataOptionT, id: string) => {
        setDamages(damages.map(d => {
            if (d.id === id){
                d.side = data.value;
            }
            return d;
        }));
    }, [damages]);

    const actionChangeType = useCallback((data: DataOptionT, id: string) => {
        setDamages(damages.map(d => {
            if (d.id === id){
                d.type = data.value;
            }
            return d;
        }));
    }, [damages]);

    const addNewDamage = useCallback(() => {
        setDamages([...damages, { side: null, type: null, id: generateId() }]);
    }, [damages])

    const removeDamage = useCallback((index: number) => {
        setDamages(damages.filter((d, ind) => ind !== index));
    }, [damages])

    const replaceTextTranslate = useCallback((data: string): string => {
        switch (data.toLowerCase()){
            case 'soft-shell torn': return t('createClaim.dmgSoftShellTorn');
            case 'zipper': return t('createClaim.dmgZipper');
            case 'whell': case 'wheel': return t('createClaim.dmgWhell');
            case 'dog leash clip/ladder buckle': return t('createClaim.dmgDog');
            case 'foot': return t('createClaim.dmgFoot');
            case 'frame': return t('createClaim.dmgFrame');
            case 'handle': return t('createClaim.dmgHandle');
            case 'hard-shell broken': return t('createClaim.dmgHardShell');
            case 'hinges': return t('createClaim.dmgHinges');
            case 'lock': return t('createClaim.dmgLock');
            case 'logo': return t('createClaim.dmgLogo');
            case 'lumbar pad': return t('createClaim.dmgLumbar');
            case 'rain hood/cover': return t('createClaim.dmgRainHood');
            case 'retractable handle': return t('createClaim.dmgRetractableHandle');
            case 'speed buckle': return t('createClaim.dmgSpeedBuckle');
            case 'straps/shoulder strap': return t('createClaim.dmgStrapsSoulder');
            case 'waist belt': return t('createClaim.dmgWaistBelt');

            case 'brake lever': return t('createClaim.dmgBrakeLever');
            case 'canopy': return t('createClaim.dmgCanopy');
            case 'fold joint': return t('createClaim.dmgFoldJoint');
            case 'foot rest': return t('createClaim.dmgFootRest');
            case 'guardrail': return t('createClaim.dmgGuardrail');
            case 'handle bar/grip': return t('createClaim.dmgHandleBarGrip');
            case 'harness strap/buckle': return t('createClaim.dmgHarnessStrapBuckle');
            case 'seat cushion': return t('createClaim.dmgSeatCushion');
            case 'storage basket': return t('createClaim.dmgStorageBasket');

            case 'material soiling': return t('createClaim.dmgMaterialSoliling');
            case 'scratch': return t('createClaim.dmgScratch');
            case 'dent': return t('createClaim.dmgDent');
            case 'zipper rubber': return t('createClaim.dmgZipperRubber');

            default: return data;
        }
    }, [t])

    const translateDamages = useMemo(() => (dataOpts: DataOptionT[]): DataOptionT[] => {
        return dataOpts.map(dop => {
            return {
                ...dop,
                text: replaceTextTranslate(dop.value)
            }
        });
    }, [replaceTextTranslate]);

    const calculateZIndex = useMemo(() => (index: number) => 100 - index, []);

    const filterTypeDamages = useMemo(() => (damagesAll: DataOptionT[], damage: DamageLocalT, newOne: boolean): DataOptionT[] => {
        if (!newOne) return damagesAll;
        return damagesAll.filter(d => !damages.some(d2 => d2.type === d.value && damage.side === d2.side));
    }, [damages]);

    return (
        <div className={style.root}>
            {damages.map((damage, index) => {
                const zIndex = !mobile ? calculateZIndex(index) : undefined;
                return (
                    <div className={cx(style.selects, { [style.mobile]: !!mobile })} key={damage.id} style={{ zIndex }}>
                        {(!mobile && index > 0) && <div className={style.remove} onClick={() => removeDamage(index)}><ImgTrash /></div>}
                        {!mobile ? (
                        <>
                            <div className={style.selectLayer}>
                                <Dropdown
                                    label={t('createClaim.damageSide')}
                                    name='side'
                                    options={[
                                        ...[{ value: '', text: '', disabled: true }],
                                        ...Object.entries(state.damageOptions.SIDES).map(data => {
                                            return { value: data[0].toLowerCase(), text: getTitleSide(data[0]) }
                                        })]
                                    }
                                    onChange={(data) => actionChangeSide(data, damage.id)}
                                    value={damage.side as string}
                                    className={style.inputLayer}
                                    isMobile={mobile}
                                    id={'select_new_claim_damage_' + damage.side}
                                    hasEmptyValue
                                />
                            </div>
                            <div className={style.selectLayer}>
                                <Dropdown
                                    label={t('createClaim.damageType')}
                                    name='type'
                                    options={translateDamages(filterTypeDamages(damageOptions, damage, !damage.type))}
                                    onChange={data => actionChangeType(data, damage.id)}
                                    value={damage.type as string}
                                    className={style.inputLayer}
                                    isMobile={mobile}
                                    disabled={!damage.side}
                                    id={'select_new_claim_damage_' + damage.type}
                                    hasEmptyValue
                                />
                            </div>
                        </>
                        ) : (
                        <>
                            <div className={style.selectLayer}>
                                <DropdownMobile
                                    label={t('createClaim.damageSide')}
                                    name='side'
                                    options={[
                                        ...Object.entries(state.damageOptions.SIDES).map(data => {
                                            return { value: data[0].toLowerCase(), text: getTitleSide(data[0]) }
                                        })]
                                    }
                                    onChange={(data) => actionChangeSide(data, damage.id)}
                                    hasEmptyValue
                                    value={damage.side as string}
                                    className={style.inputLayer}
                                    id={'select_new_claim_damage_' + damage.side}
                                />
                            </div>
                            <div className={style.selectLayer}>
                                <DropdownMobile
                                    label={t('createClaim.damageType')}
                                    name='type'
                                    options={translateDamages(damageOptions)}
                                    onChange={data => actionChangeType(data, damage.id)}
                                    value={damage.type as string}
                                    className={style.inputLayer}
                                    hasEmptyValue
                                    disabled={damage.side ? false : true}
                                    id={'select_new_claim_damage_' + damage.type}
                                />
                            </div>
                        </>
                        )}
                        {(mobile && index > 0) &&
                            <div className={style.removeMobile} onClick={() => removeDamage(index)} id={'button_new_clam_remove_dmage'}><ImgTrash /> <span>{t('common.delete')}</span></div>
                        }
                    </div>
                )
            })}

            {(damages.length < maxDamages && !damages.some(d => !d.side || !d.type)) &&
                <div className={style.addLayer} onClick={addNewDamage} id={"button_new_claim_add_damage"}>
                    <ImgAdd className={style.ico} /> {t('createClaim.damageAdd')}
                </div>
            }
        </div>
    );
}
export default Damage;