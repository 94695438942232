
import { useEffect, useState } from "react";

import { getUrlOfImage } from "services/api";
import { photoMainKey } from "Helpers/claim";
import { ClaimT } from "api/modules/claim";

const useKeyImage = (claim: ClaimT) => {
    const [photoKey, setPhotoKey] = useState<string|null>(null);

	useEffect(() => {
        getUrlOfImage(photoMainKey(claim), (result, dataResult) => result ? setPhotoKey(dataResult) : setPhotoKey(null));
    }, [claim]);

    return photoKey;
}
export default useKeyImage;