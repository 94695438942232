import React from 'react';

export default function useClickOutside(ref: React.RefObject<any>, callback: () => void) {
  function handleClick(e: Event) {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
}
