import { useEffect, useState } from "react";
import { AppStateT } from "Store/modules";
import { useSelector } from "react-redux";

import { ShopStateT } from "Store/modules/luggageStore/shop";
import { PaymentsE } from "Store/Types/order";

const useCodLimit = (cartAmount: number) => {

    const { configShop } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const [codOverLimit, setCodOverLimit] = useState(false);

    useEffect(() => {
        const overLimit = configShop.payment.limits[PaymentsE.CASH_ON_DELIVERY] && configShop.payment.limits[PaymentsE.CASH_ON_DELIVERY].byCurrencyAndAmount[configShop.currency.code] && cartAmount > configShop.payment.limits[PaymentsE.CASH_ON_DELIVERY].byCurrencyAndAmount[configShop.currency.code];

        setCodOverLimit(overLimit);
    }, [cartAmount, configShop.currency.code, configShop.payment.limits]);

    return codOverLimit;

}

export default useCodLimit;