import { combineReducers } from 'redux';
import catalog, { StateT as CatalogStateT } from './catalog';
import details, { StateT as DetailsStateT } from './details';
import shop, { ShopStateT } from './shop';

export type LuggageStoreStateT = {
  catalog: CatalogStateT;
  details: DetailsStateT;
  shop: ShopStateT
};

export default combineReducers({
  catalog,
  details,
  shop
});
