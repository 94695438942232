import React from 'react';
import cx from 'classnames';
import { ReactComponent as CartIcon } from 'Assets/basket.svg';
import { ReactComponent as CartEmptyIcon } from 'Assets/basket-empty.svg';
import styles from './CartButton.module.scss';
import { useState } from 'react';

interface ICartButton {
  onClick: () => void;
  inCart?: number;
  className?: string;
  t: (data: string) => string
}

export default function CartButton(props: ICartButton) {
  const [hover, setHover] = useState(false);
  return (
    <>
      <button
        className={cx(styles.root, props.className)}
        disabled={!props.inCart}
        onClick={props.onClick}
      >

        {!props.inCart ?
          <CartEmptyIcon onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} />:
          <CartIcon onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} />
        }

        {!!props.inCart && <span className={styles.inCart}>{props.inCart}</span>}
      </button>
      {props.inCart === 0 && hover && <div className={styles.message}> {props.t('shop.emptyNow')}</div>}
    </>
  );
}
