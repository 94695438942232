import React from 'react'

import chatStyles from 'PageComponents/Chat/Chat/Chat.module.scss';
import { ChatMessageGroup } from "Components";

type MainPropsT = {
    t: (data: string, params?: any) => string,
    assessmentCreatedAt: string
}

const ChatAssessmentPending: React.FC<MainPropsT> = ({ t, assessmentCreatedAt }) => {

    const introMessages = [
        {
          message: t('chatMessages.assessmentPendingTitle'),
          className: chatStyles.messageIn,
        },
    ];

    return (
            <ChatMessageGroup
                as="MessageGroup"
                direction="incoming"
                avatar="bot"
                data={introMessages}
            />
    );
}
export default ChatAssessmentPending;