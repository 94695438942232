import React, { useCallback, useMemo, useState } from 'react';
import parse from 'html-react-parser';
import cx from 'classnames';

import { calculateDateDifference, getAgeName } from "Helpers/date";
import { formatDateOnly, formatTimeOnly } from "Helpers/date";
import useStatusHasContent from "Hooks/useStatusHasContent";
import { useMobile } from 'Helpers/responsiveContainers';
import style from './StatusRow.module.scss';
import { prepareUrl } from 'Helpers/claim';
import { ClaimT } from "api/modules/claim";

type MainPropsT = {
    claim: ClaimT,
    status: string,
    date: Date,
    id: string,
    index: number,
    t: (data: string) => string,
    showOnlyContent?: boolean,
    className?: string
}

const StatusRow: React.FC<MainPropsT> = ({ claim, date, status, index, t, id, showOnlyContent, className: classNameProps }) => {

    const isMobile = useMobile();
    const [showMore, setShowMore] = useState(true);

    const dataClaimExtend = useMemo(() => {
        const diffData = calculateDateDifference(claim?.verdict?.purchaseDate, claim?.verdict?.assessmentCreatedAt);
        return {
            voucher: claim?.verdict?.resolution?.voucher || 0,
            residualValue: claim?.verdict?.resolution?.residualValue || 0,
			templateValue: claim?.verdict?.resolution?.refund || 0,
			baggageAge: getAgeName((diffData.years * 12) + diffData.months, t),
            currencyIsoCode: claim?.currencyIsoCode,
            airlineName: claim?.airlineName,
            urlForm: prepareUrl(claim, isMobile),
            urlGoogleReview: claim?.case?.review?.googleMapsUrl,
            urlTrustPilot: claim?.case?.review?.trustPilotUrl,
            pickupData: claim?.resolutions && Array.isArray(claim.resolutions) ? ` <strong>${claim.resolutions.find(r => r.status === 'success')?.resolutionData?.date}</strong>` || '' : '',
        }
  	}, [ t, claim, isMobile ]);

    const { hasContent, content: contentExtend } = useStatusHasContent(status, claim?.shipments, dataClaimExtend);

    const actionShowData = useCallback(() => {
        setShowMore(true);
    }, []);

    if (showOnlyContent){
        if (hasContent && contentExtend){
            return (
                <div className={classNameProps}>
                    {parse(contentExtend)}
                </div>
            )
        }
        return <></>;
    }

    return (
        <div className={cx(style.root, classNameProps, { [style.rootFirst]: index === 0 })}>
            <div className={style.dates}>
                <span className={style.date}>{formatDateOnly(date)}</span>
                <span className={style.time}>{formatTimeOnly(date)}</span>
            </div>
            <div className={cx(style.dot, { [style.dotFirst]: index === 0 })} />
            <div className={style.description}>
                {t(`caseStatuses.${status}`)}
                {(hasContent && contentExtend) &&
                    <div>
                        {showMore ?
                            <div className={style.extend}>
                                {parse(contentExtend)}
                            </div>:
                            <div className={style.toExtemd}>
                                <div className={style.button} onClick={actionShowData} title={t('chatMessages.readMore')}><span>...</span></div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}
export default React.memo(StatusRow);