import { useSelector } from "react-redux";
import cx from 'classnames';
import React from 'react';

import { ReactComponent as IconBaggage } from 'Assets/add_claim_baggage_details.svg';
import { ReactComponent as IconDetails } from 'Assets/add_claim_photos.svg';
import { ReactComponent as ContactEmail } from 'Assets/contact_email.svg';
import { ReactComponent as IconChat } from 'Assets/chat.svg';

import { MobileTabContentT, mobileFooterHeight } from "../ClaimListMobile";
import useChatConversationVisible from "Hooks/useChatConversationVisible";
import type { StateT as UserStateT } from 'Store/modules/user';
import type { StateT } from 'Store/modules/claimChat/data';
import style from './ChatFooter.module.scss';
import { ClaimT } from "api/modules/claim";
import { AppStateT } from "Store/modules";

type MainPropsT = {
    claim: ClaimT,
    t: (data: string) => string,
    actionChange: (data: MobileTabContentT) => void,
    tabContent: MobileTabContentT
}

interface IUserSelector {
    user: UserStateT['data'];
}

const ChatFooter: React.FC<MainPropsT> = ({ claim, t, actionChange, tabContent }) => {

    const { chatMessages } = useSelector<AppStateT, StateT>((state) => state.claimChat.data);
    const { user } = useSelector<AppStateT, IUserSelector>(({ user }) => ({ user: user.data }));
    const enabledConversation = useChatConversationVisible(claim?.branch, claim?.status, chatMessages, user.language);

    return (
        <div className={style.root} style={{ height: mobileFooterHeight }}>
            <div className={cx(style.itemButton, { [style.active]: tabContent === 'actual' })} onClick={() => actionChange('actual')}>
                <IconBaggage className={cx(style.ico, style.icoActual)} />
                <span>{t('chatMobile.footerActual')}</span>
            </div>
            <div className={cx(style.itemButton, { [style.active]: tabContent === 'details' })} onClick={() => actionChange('details')}>
                <IconDetails className={cx(style.ico, style.icoDetails)} />
                <span>{t('chatMobile.footerDetails')}</span>
            </div>
            {enabledConversation ?
                <div className={cx(style.itemButton, { [style.active]: tabContent === 'chat', [style.activeChat]: !!chatMessages })} onClick={() => actionChange('chat')}>
                    <div className={style.icoLayer}>
                        <IconChat className={cx(style.ico, style.icoChat)} />
                        {(chatMessages && chatMessages.unreadedMessagesByPax > 0) &&
                            <div className={style.countMessages}>{chatMessages.unreadedMessagesByPax}</div>
                        }
                    </div>
                    <span>{t('chatMobile.footerChat')}</span>
                </div>:
                <div className={cx(style.itemButton, { [style.active]: tabContent === 'contact' })} onClick={() => actionChange('contact')}>
                    <div className={style.icoLayer}>
                        <ContactEmail className={cx(style.ico, style.contact)} />
                    </div>
                    <span>{t('help_center.contact')}</span>
                </div>
            }
        </div>
    );
}
export default React.memo(ChatFooter);