import { VerdictT } from '../api/modules/claim';
import { ClaimHistoryT, /* ResolutionT, */ StatusT } from '../api/modules/case-resolution';
import { StateT } from '../Store/modules/claimChat/data';

export function updateHistoryWithNewStatus(state: StateT, status: StatusT) {
  const statusLength = state.claimHistory?.length || 0;
  const updatedHistory =
    state.claimHistory?.map((historyStatus, index) => {
      if (!!historyStatus.verdict && index === statusLength - 1) {
        return { ...historyStatus, verdict: { ...historyStatus.verdict, isSelected: true } };
      }
      return historyStatus;
    }) || [];
  return [...updatedHistory, { status, verdict: null, report: null, resolution: null }];
}

export function updateHistoryWithNewVerdict(state: StateT, verdict: VerdictT) {
  return (
    state.claimHistory?.reduce((acc: Array<ClaimHistoryT>, curr: ClaimHistoryT):
      | Array<ClaimHistoryT>
      | [] => {
      const statusId = verdict.claimStatusId;
      const current = statusId === curr.status?.id ? { ...curr, verdict } : curr;

      return [...acc, current];
    }, []) || []
  );
}

export function updateHistoryWithNewReport(state: StateT, report: ReportT): Array<ClaimHistoryT> {
  return (
    state.claimHistory?.reduce((acc: Array<ClaimHistoryT>, curr: ClaimHistoryT):
      | Array<ClaimHistoryT>
      | [] => {
      const statusId = report.claimStatusId;
      const current = statusId === curr.status?.id ? { ...curr, report } : curr;

      return [...acc, current];
    }, []) || []
  );
}

export function updateHistoryWithNewResolution(
  state: StateT,
  resolution: any /*ResolutionT*/,
): Array<ClaimHistoryT> {
  return (
    state.claimHistory?.reduce((acc: Array<ClaimHistoryT>, curr: ClaimHistoryT):
      | Array<ClaimHistoryT>
      | [] => {
      const statusId = resolution.claimStatusId;
      const current = statusId === curr.status?.id ? { ...curr, resolution } : curr;

      return [...acc, current];
    }, []) || []
  );
}
