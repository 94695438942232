export enum RESOLUTION_TYPE {
  NONE = '',
  CASH_REFUND = 'cashRefund',
  REPAIR = 'repair',
  VOUCHER = 'VOUCHER',
  REPAIR_DROP_OFF = 'dropOff',
  PICK_UP = 'pickUp',

  // Cash refund subtypes
  PAYPAL = 'paypal',
  BANK = 'wireTransfer',
}
