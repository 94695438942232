import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import React, { useMemo } from 'react';

import { changeModalArticle, changeShowHelpCenter, changeShowModalLanguages } from 'Store/modules/page';
import { ReactComponent as CookieIcon } from 'Assets/cookies-settings-icon.svg';
import SelectLanguage from 'Components/SelectLanguage/SelectLanguage';
import HamburgerMenu from 'Components/HamburgerMenu/HamburgerMenu';
import { clearHelpCenter } from 'Store/modules/accountSettings';
import { helpCenterAvailableLanguages } from 'Helpers/i18';
import HelpCenter from 'Components/HelpCenter/HelpCenter';
import { useMobile } from 'Helpers/responsiveContainers';
import { actionShowCookies } from "Helpers/browser";
import useTranslate from 'Hooks/useTranslate';
import styles from './Footer.module.scss';
import { AppStateT } from 'Store/modules';
import cx from 'classnames';

export type UrlActionTypeT = 'login'|'about'|'terms'|'privacy'|'language'|'help'|'personalInfo' | 'logOut';

const FOOTER_LINKS_FIRST_ROW = [
    { name: 'footer.signIn', path: '/login' },
    { name: 'footer.aboutUs', path: '/about' },
    { name: 'footer.help', path: '/help' },
    { name: 'footer.language', path: '/lang' },
];

const FOOTER_LINKS_SECOND_ROW = [
    { name: 'footer.privacySettings', path: '/privacy' },
    { name: 'footer.termsAndConditions', path: '/terms' },
];

export default function Footer() {
    const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);
    const showModalLanguages = useSelector<AppStateT, boolean>((state) => state.page.showModalLanguages);
    const showHelpCenter = useSelector<AppStateT, boolean>((state) => state.page.showHelpCenter);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t = useTranslate();
    const isMobile = useMobile();

    const _FOOTER_LINKS_FIRST_ROW = useMemo(() => 
        FOOTER_LINKS_FIRST_ROW.filter(r => 
            r.name === 'footer.help' ? helpCenterAvailableLanguages.includes(actualLanguage) : true
        ), 
        [actualLanguage]
    );

    const actionUrlClick = (e: React.MouseEvent, type: UrlActionTypeT) => {
        e.preventDefault();
        setTimeout(() => {
            if (type === 'login'){
                return navigate('/login')
            }
            if (type === 'about'){
                return navigate('/about')
            }
            if (type === 'privacy'){
                return actionShowCookies();
            }
    
            if (type === 'terms'){
                return dispatch(changeModalArticle({ pagesContentKey: 'regs.regulations', title: 'footer.termsAndConditions' }));
            }
    
            if (type === 'language'){
                return dispatch(changeShowModalLanguages(!showModalLanguages));
            }
    
            dispatch(changeShowHelpCenter(!showHelpCenter));
            dispatch(clearHelpCenter());
        }, 200);  
     
    }

    const footerLinks = [..._FOOTER_LINKS_FIRST_ROW, ...(isMobile ? FOOTER_LINKS_SECOND_ROW : FOOTER_LINKS_SECOND_ROW.filter(link => link.name !== 'footer.privacySettings'))];

    return (
        <>
            <footer className={styles.rootDesktop}>
                <div className={styles.iconContainer}>
                    <div
                        className={cx(styles.icon, styles.iconCookie)}
                        onClick={actionShowCookies}
                        title={t('common.cookiesInfo')}
                    > 
                            {!isMobile&&<CookieIcon />}
                    </div>
                    <div className={cx(styles.icon)}>
                        <HamburgerMenu 
                            menuItems={footerLinks} 
                            onMenuItemClick={actionUrlClick} 
                        />
                    </div> 
                </div>    
            </footer>
            <SelectLanguage />
            <HelpCenter />
        </>
    );
}
