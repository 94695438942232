import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import style from './Wrapper.module.scss';
import { useDeviceType } from 'Helpers/responsiveContainers';
import { AppStateT } from 'Store/modules';

type MainProps = {
    wrappingLayer: boolean,
    children: ReactNode
}

const Wrapper: React.FC<MainProps> = ({ wrappingLayer, children }) => {
    const location = useLocation();
    const isLoginRoute = location.pathname === '/login';
    const isRegisterRoute = location.pathname === '/register';
    const isConfirmRoute = location.pathname === '/confirm';
    const { isMobile } = useDeviceType();
    const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);

    if (wrappingLayer) {
        return (
            <div className={cx(style.routerMain, {[style.loginMobile]:(isMobile && (isLoginRoute || isConfirmRoute)), [style.isLoginMobileRegister]:(isMobile && (actualLanguage !== 'de' && isRegisterRoute))})}>
                {children}
            </div>
        );
    }

    return <>{children}</>;
}

export default Wrapper;
