import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import cx from 'classnames';

import { ReactComponent as ArrowDown } from 'Assets/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'Assets/arrow-up.svg';

import { setVoucherInfoArrow, ShopStateT } from 'Store/modules/luggageStore/shop';
import useFormattedPrice from 'Hooks/useFormattedPrice';
import { PageConfigT } from 'Store/modules/page';
import styles from './VoucherBlock.module.scss';
import { AppStateT } from 'Store/modules';

interface IVoucherBlock {
    onClick?: (arg: boolean) => void;
}

export default function  VoucherBlock(props: IVoucherBlock) {

    const { voucherActual, voucher, voucherInfoArrowDown, configShop } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const leftVoucher = voucher-voucherActual;
    const formatPrice = useFormattedPrice(configShop.currency.code, pageConfig.noFractionCurrencies)

    const handleChangeArrowState = useCallback(() => {
        dispatch(setVoucherInfoArrow(!voucherInfoArrowDown));
    }, [dispatch, voucherInfoArrowDown]);

    return (
        <div className={styles.root}>
            <div className={styles.title} onClick={handleChangeArrowState}>{t('shop.voucherTitle')}</div>
            {voucherInfoArrowDown ?
                <ArrowDown className={styles.arrow} />:
                <ArrowUp className={styles.arrow} />
            }
            <div className={styles.subtitle}>* {t('shop.voucherMarked')}</div>
            {/* {!voucherInfoArrowDown && */}
                <div className={cx(styles.values, { [styles.valuesHide]: voucherInfoArrowDown })} id="titleVoucher">
                    <div className={styles.body}>
                        <div className={styles.row}>
                            <div className={cx(styles.col, styles.colLeft)}>{t('shop.voucherValue')}</div>
                            <div className={cx(styles.col, styles.value)}>{formatPrice(voucher)} {configShop.currency.symbol}</div>
                        </div>
                        <div className={styles.row}>
                            <div className={cx(styles.col, styles.colLeft)}>{t('shop.voucherBasket')}</div>
                            <div className={cx(styles.col, styles.value, styles.valueBasket)}>{leftVoucher > 0 ? '-' : ''}{formatPrice(leftVoucher)} {configShop.currency.symbol}</div>
                        </div>
                    </div>
                </div>
            {/* } */}
            <div className={styles.bottom}>
                <div className={styles.header}>
                    {t('shop.voucherBalance')}
                </div>
                <div className={styles.value}>
                    {formatPrice(voucherActual)} {configShop.currency.symbol}
                </div>
            </div>
        </div>
    );
}
