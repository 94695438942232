import { Tooltip as RealTooltip } from 'react-tooltip';
import React, { useEffect } from 'react';
import cx from 'classnames';


import { ReactComponent as Paypal } from 'Assets/paypal.svg';
import { ReactComponent as PaypalActive } from 'Assets/paypal-active.svg';
import { ReactComponent as Bank } from 'Assets/bank.svg';
import { ReactComponent as BankActive } from 'Assets/bank-active.svg';
import { ReactComponent as CreditCard } from 'Assets/credit-card.svg';
import { ReactComponent as CreditCardActive } from 'Assets/credit-card-active.svg';
import { ReactComponent as Money } from 'Assets/money.svg';
import { ReactComponent as MoneyActive } from 'Assets/money-active.svg';

import { PaymentChooseT } from 'Store/modules/luggageStore/shop';
import styled from './PaymentBox.module.scss';
import { PaymentsE } from "Store/Types/order";

type MainProps = {
    title: string,
    method: PaymentChooseT,
    paymentChoose?: PaymentChooseT,
    actionChange: (value: PaymentChooseT) => void,
    mobile?: boolean,
    disabledText?: string
}

const chooseIcon = (method: PaymentChooseT, selected: boolean) => {
    switch (method){

        case PaymentsE.CREDIT_CARD:
            return selected ? <CreditCardActive /> : <CreditCard />;

        case PaymentsE.PAYPAL:
            return selected ? <PaypalActive /> : <Paypal />;

        case PaymentsE.CASH_ON_DELIVERY:
            return selected ? <MoneyActive /> : <Money />;

        default:
            return selected ? <BankActive /> : <Bank />;
    }
}

const PaymentBox: React.FC<MainProps> = ({ title, method, paymentChoose, actionChange, mobile = false, disabledText }) => {

    /*
     * Hack to remove error in development with ResizeObserver
     **/
    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message.indexOf('ResizeObserver loop') >= 0) {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    return (
        <>
            <div
                className={
                    cx(
                        styled.root, 
                        { 
                            [styled.selected]: method === paymentChoose, 
                            [styled.mobile]: mobile,
                            [styled.disabled]: !!disabledText
                        }
                    )}
                    title={title}
                    onClick={!!disabledText ? null : () => actionChange(method)}
                    data-tooltip-id={`id-title-${method}`}
                >
                <div className={styled.icon}>
                    {chooseIcon(method, method === paymentChoose)}
                </div>
                <div className={styled.title}>{title}</div>
            </div>
            {!!disabledText &&
                <RealTooltip
                    id={`id-title-${method}`}
                    content={disabledText}
                    style={{ maxWidth: 400, textAlign: 'center', minWidth: 200 }}
                />
            }
        </>
    );
}
export default PaymentBox;