import React, { useEffect, useState } from 'react';

import { ReactComponent as CrossIcon } from 'Assets/cross-icon.svg';
import styles from './DeliveryOptionsDesktop.module.scss';

import { DeliveryMethodT } from 'Store/modules/luggageStore/shop';
import { DeliveryOptions } from 'PageComponents/LuggageStore';
import { Button, SideBarDesktop } from 'Components';
import { useTranslation } from 'react-i18next';

interface IDeliveryOptionsDesktop {
    show: boolean;
    currentValue: DeliveryMethodT;
    onClose: () => void;
    setDeliveryMethod: (delivery: DeliveryMethodT, sms: boolean) => void;
    sms: boolean
}

export default function DeliveryOptionsDesktop({
    show,
    currentValue,
    onClose,
    setDeliveryMethod,
    sms
}: IDeliveryOptionsDesktop) {
    const [receiveMessage, setReceiveMessage] = useState(sms);
    const [activeDeliveryOption, setActiveDeliveryOption] = useState<DeliveryMethodT>(currentValue);
    const { t } = useTranslation();
    const [content, setContent] = useState('');

    useEffect(() => {
        setActiveDeliveryOption(currentValue);
    }, [currentValue]);

    useEffect(() => {
        const translateKey = `shop.${activeDeliveryOption.name}_DESCRIPTION`;
        const text = t(translateKey);
        if (text !== translateKey){
            return setContent(text);
        }
        setContent('');
    }, [t, activeDeliveryOption.name]);

    return (
        <SideBarDesktop
            backdrop
            show={show}
            closeOnClickOutside
            onClose={onClose}
            containerClassName={styles.deliveryOptionsSideBar}
            headerTitle={() => (
                <>
                    <div className={styles.sideBarTitle}>
                        <div>{t('shop.deliveryOptions')}</div>
                    </div>
                    <CrossIcon className={styles.crossIcon} onClick={onClose} />
                </>
            )}
        >
            <DeliveryOptions
                onValueChange={setActiveDeliveryOption}
                receiveSmsMassage={receiveMessage}
                onReceiveSmsMassageChange={setReceiveMessage}
                value={activeDeliveryOption}
            />

            {content &&
                <div className={styles.content}>
                    {content}
                </div>
            }

            <div className={styles.applyButtonContainer}>
                <Button
                    className={styles.applyButton}
                    onClick={() => setDeliveryMethod(activeDeliveryOption, receiveMessage)}
                >
                    {t('common.apply')}
                </Button>
            </div>
        </SideBarDesktop>
    );
}
