import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';

import ReportUploadBox, { IImage } from 'PageComponents/Chat/ReportUploadBox/ReportUploadBox';
import { IMessage } from 'Components/ChatMessageGroup/ChatMessageGroup';
import runApi, { ResultApiTypeT, sendDataFiles } from 'api/base/runApi';
import { ReactComponent as UploadIcoPdf } from 'Assets/upload-pdf.svg';
import ImageDecrypt from "Components/ImageDecrypt/ImageDecrypt";
import { ClaimT, assessmentResult } from 'api/modules/claim';
import { ClaimHistoryT } from 'api/modules/case-resolution';
import style from './SparePartsPaxValidation.module.scss';
import { changeModalTooltip } from 'Store/modules/page';
import { Button, ChatMessageGroup } from 'Components';
import { runNotify } from 'Helpers/notifications';
import Loading from 'Components/Loading/Loading';
import { getUrlOfImage } from 'services/api';
import Ico from 'Components/Ico/Ico';

type PropsMainT = {
    claim: ClaimT,
    statusInfo: ClaimHistoryT,
}

const SparePartsPaxValidation: React.FC<PropsMainT> = ({ claim, statusInfo }) => {

    const [messages, setMessages] = useState<IMessage[]>([]);
    const [photo, setPhoto] = useState<IImage>(null);
    const [sending, setSending] = useState(false);
    const [assessmentResult, setAssessmentResult] = useState<assessmentResult>(claim?.assessmentResults && Array.isArray(claim.assessmentResults) && claim.assessmentResults.some(data => data.isActive) ? claim.assessmentResults.find(data => data.isActive) : null);
    const { t } = useTranslation();

    useEffect(() => {
        if (claim?.assessmentResults && Array.isArray(claim.assessmentResults) && claim.assessmentResults.some(data => data.isActive)){
            setAssessmentResult(claim.assessmentResults.find(data => data.isActive));
        } else {
            setAssessmentResult(null);
        }
    }, [claim?.assessmentResults]);

    const actionChangeImage = (photo: IImage) => {
        setPhoto(photo);
    }

    const removeDamageImage = () => {
        setPhoto(null);
    }

    const actionSendWithPhoto = useCallback(() => {
        setSending(true);
        sendDataFiles(`claim/${claim.id}/assessment-result/spare-parts/upload-document`, [{ file: photo }], (r: ResultApiTypeT) => {
            setSending(false);
            if (!r.result){
                runNotify({ message: r.error.message || t('registration.errorRegister'), type: 'error' });
            }
        });
    }, [claim.id, t, photo])

    const actionSendResignation = useCallback(() => {
        setSending(true);

        const dataSend = {};

        runApi(`claim/${claim.id}/assessment-result/spare-parts/send-refusal`, dataSend, (r: ResultApiTypeT) => {
            setSending(false);
            if (!r.result){
                runNotify({ message: r.error.message || t('registration.errorRegister'), type: 'error' });
            }
        });
    }, [claim.id, t])

    useEffect(() => {
        const _m: IMessage[] = [
            { message: t('chatMessages.sparePartsHeader') },
            { render: () => (
                    <FormInputData
                        actionSendResignation={actionSendResignation}
                        actionSendWithPhoto={actionSendWithPhoto}
                        actionChangeImage={actionChangeImage}
                        removeDamageImage={removeDamageImage}
                        sending={sending}
                        photo={photo}
                        t={t}
                        assessmentResult={assessmentResult}
                    />
                )
            }
        ];

        setMessages(_m);
    }, [t, photo, sending, actionSendResignation, actionSendWithPhoto, assessmentResult]);

    return (
        <ChatMessageGroup
            data={messages}
            direction={'incoming'}
            statusInfo={statusInfo}
            as="MessageGroup"
            avatar='bot'
        />
    );
}
export default SparePartsPaxValidation;

type FormInputDataT = {
    photo: IImage,
    removeDamageImage: (photo: IImage) => void,
    actionChangeImage: (photo: IImage) => void,
    t: (text: string, data?: any) => string,
    actionSendWithPhoto: () => void,
    actionSendResignation: () => void,
    sending: boolean,
    assessmentResult: assessmentResult,
}

const FormInputData: React.FC<FormInputDataT> = ({ actionChangeImage, photo, removeDamageImage, t, actionSendWithPhoto, actionSendResignation, sending, assessmentResult }) => {
    const dispatch = useDispatch();

    const downloadInfo = useCallback((documentKey: string) => {
        getUrlOfImage(documentKey, (result: boolean, url: string|undefined) => {
            if (result && url){
                window.open(url);
            } else {
                runNotify({ message: 'Error while trying to download the file', type: 'error' });
            }
        });
    }, []);

    const showRepairBudgetExplanation = useCallback(() => {
        dispatch(changeModalTooltip({ 
            title: t('chatMessages.repairBudgetTitle'), 
            content: parse(t('chatMessages.repairBudgetContent'))
        }));
    }, [dispatch, t])

    return (
        <div className={style.root}>
            {(assessmentResult && assessmentResult.resolution.responseData) ?
                <div className={style.dataExist}>
                    {(assessmentResult.resolution.responseData?.sparePartsPhotos && assessmentResult.resolution.responseData.sparePartsPhotos.length > 0) ?
                        <div className={style.photos}>
                            <label>{t('chatMessages.sparePartsSendedPhoto')}</label>
                            <div className={style.photosLayer}>
                                {assessmentResult.resolution.responseData.sparePartsPhotos.map(photoFromAssessment => (
                                    <div className={style.photo} key={`ph-${photoFromAssessment.original.key}`}>
                                        {photo?.file?.type === 'application/pdf' || assessmentResult.resolution.responseData.sparePartsPhotos?.[0]?.name?.endsWith('.pdf') ?
                                            <div className={style.pdfIconContainer} onClick={() => downloadInfo(assessmentResult.resolution.responseData.sparePartsPhotos?.[0].name)}>
                                                <UploadIcoPdf className={style.pdfIcon} />
                                            </div>
                                            :
                                            <ImageDecrypt imageKey={photoFromAssessment.thumbnail.key} />
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>:
                        <div className={style.resign}>{t('chatMessages.sparePartsGiveup')}</div>
                    }
                </div>:
                <>
                    {sending ?
                        <div className={style.loading}>
                            <Loading />
                        </div>:
                        <div>
                            <ReportUploadBox
                                value={photo}
                                removable={true}
                                onRemove={() => removeDamageImage(photo)}
                                onChange={(value) => actionChangeImage(value)}
                                name={'photo-Input'}
                                disabled={sending}
                                hideName
                                acceptableFileFormats={'.jpg, .jpeg, .png, .webp, .pdf'}
                            />
                        </div>
                    }
                    <div className={style.buttons}>
                        <Button color={'secondary'} onClick={actionSendResignation} disabled={sending}>{t('chatMessages.sparePartsButtonCancel')}</Button>
                        <Button disabled={!!!photo || sending} onClick={actionSendWithPhoto}>{t('chatMessages.sparePartsButtonConfirm')}</Button>
                    </div>
                    <div className={style.icoInfo}>
                        <span className={style.headerIco}><Ico action={showRepairBudgetExplanation} /></span>
                    </div>
                </>
            }
        </div>
    )
}