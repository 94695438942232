import React from 'react';
import { Header } from 'Components';
import { Filters } from 'PageComponents/LuggageStore';

export default function FilterPage() {
  return (
    <div>
      <Header />
      <Filters />
    </div>
  );
}
