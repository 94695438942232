import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import 'react-circular-progressbar/dist/styles.css';

import style from './Header.module.scss';
import { ReactComponent as BackIcon } from 'Assets/back-create-claim.svg';
import { ReactComponent as CloseIcon } from 'Assets/cross-icon.svg';
import { ReactComponent as Avatar } from 'Assets/ChatBotBig_Icon.svg';
import { StepsE } from "Store/Enums/NewClime";
import { StateLocalT } from '../CreateForm';


type MainProps = {
    state: StateLocalT,
    actionBack?: () => void,
    closeAction: () => void,
    mobile: boolean,
    t: (param: string, object?: any) => string
}

const stepsNoAvatar = [StepsE.WARNING_ATTACHMENTS];

const Header: React.FC<MainProps> = ({ state, actionBack, closeAction, mobile, t }) => {

    const [canBack, setCanBack] = useState(false);

    useEffect(() => {
        if (!state.step){
            return setCanBack(false);
        }
        const indexActualPrev = state.steps.findIndex(step => step.type === state.step.type);
        let indexGoPrev: number = -1;
        for(let i=state.steps.length;i>=0;i--){
            if (!state.steps[i]?.optional && i < indexActualPrev){
                indexGoPrev = i;
                break;
            }
        }
        setCanBack(indexGoPrev >= 0);
    }, [state.steps, state.step]);

    return (
        <div className={cx(style.header, { [style.headerMobile]: !!mobile, [style.missedDoc]: !!state.step.missedDoc })} id="headerAddClaim">
            <div className={cx(style.stepLabel, { [style.stepShow]: !!state.step?.stepLabel })}>
                {state.step?.stepLabel || ' '}
            </div>
            <div className={cx(style.basicLayer, { [style.layerAvatar]: state.step?.headerType === 'avatar' })}>
                {state.editStepIndex === null &&
                    <>
                        <div className={style.ico}>
                            {(state.step?.type !== 'finish' && canBack) &&
                                <BackIcon className={style.buttonIcoBack} onClick={actionBack} />
                            }
                        </div>
                        {mobile &&
                            <div className={style.rightLabel} onClick={closeAction}>
                                       <CloseIcon />
                            </div>
                        }
                    </>
                }
            </div>
            {!stepsNoAvatar.includes(state.step.type) && 
                <div className={cx(style.avatar, { [style.big]: (state.step?.headerType === 'bigAvatar'), [style.hide]: state.hideHeader })}>
                    <Avatar />
                    <CircularProgressbar
                        value={(state.stepIndex) * 100 / (state.steps.length - 1)}
                        className={style.circlePropgress}
                        strokeWidth={6}
                        background={false}
                        styles={buildStyles({
                            pathColor: '#2FC3DD',
                            trailColor: '#E6E9EA'
                        })}
                    />
                </div>
            }
            {state.step?.headerTitle &&
                <div className={style.headerTitle}>{state.step.headerTitle}</div>
            }
            <div className={cx(style.headerText, { [style.active]: !!state.step?.headerText, [style.topTitle]: !!state.step?.headerTitle, [style.headerTextShort]: state.step?.headerType === 'bigAvatar', [style.headerHide]: state.hideHeader })}>
                {state.step?.headerText}
                {(state.step?.headerNotRequired || false) && <div className={style.notRequired}>- {t('createClaim.notRequired')} -</div>}
            </div>
        </div>
    )
}

export default Header;