import React from 'react';

import style from './InfoChatWaitingOnShopResult.module.scss';
import { ClaimHistoryT } from "api/modules/case-resolution";
import Loading from "Components/Loading/Loading";
import { ChatMessageGroup } from 'Components';

type PropsMainT = {
    t: (text: string, data?: any) => string,
    statusInfo: ClaimHistoryT,
}

const InfoChatWaitingOnShopResult: React.FC<PropsMainT> = ({ t, statusInfo }) => {

    return (
        <ChatMessageGroup
            data={[
                {
                    render: () => (
                        <div className={style.root}>
                            <Loading />
                            <p>{t('shop.waitingShopChat')}</p>
                        </div>
                    )
                }
            ]}
            direction={'incoming'}
            statusInfo={statusInfo}
            as="MessageGroup"
            avatar='bot'
        />
    )
}

export default InfoChatWaitingOnShopResult;