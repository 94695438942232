import React, { useState } from 'react';
import cx from 'classnames';

import style from './Textarea.module.scss';

type MainPropsT = {
    label: string,
    value: string,
    onChangeText: (text: string) => void,
    placeholder?: string,
    className?: string,
    maxLength?: number,
    onFocus?: () => void
    onBlur?: () => void
}

const Textarea: React.FC<MainPropsT> = ({ label, value, onChangeText, placeholder = '', className, maxLength = 10000, onFocus, onBlur }) => {

    const [smallLabel, setSmallLabel] = useState(!!value);

    const changeValue = (text: string) => {
        onChangeText(text);
    }

    return (
        <div className={cx(style.root, className, { [style.inValued]: smallLabel })}>
            <label>{label}</label>
            {(placeholder && smallLabel && value.trim() === '') && <div className={style.placeholder}>{placeholder}</div>}
            <textarea
                onChange={ev => changeValue(ev.target.value)}
                onFocus={() => {
                    setSmallLabel(true);
                    onFocus?.();
                }}
                value={value}
                onBlur={() => {
                    if (value.trim() === '') setSmallLabel(false)
                    else setSmallLabel(true);
                    onBlur?.();
                }}
                maxLength={maxLength}
                
            />
        </div>
    )
}

export default Textarea;