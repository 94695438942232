import { RequiredDocumentT } from "PageComponents/CreateClaim/CreateClaim/CreateForm/CreateForm";
import { isFirefox } from "Helpers/browser";
import { ClaimT } from "api/modules/claim";
import runApi, { ApiSendMethods, ResultApiTypeT } from "api/base/runApi";
import { updateClaim } from "Store/modules/claimChat/data";

export const sortMissedDocument = (a: RequiredDocumentT, b: RequiredDocumentT) => {
    const _isFirefox = isFirefox();
    if (_isFirefox){
        return a.Name.indexOf('DPR Copy') >= 0 ? 1 : 0;
    }
    return a.Name.indexOf('DPR Copy') >= 0 ? -1  : 0;
}

export const getClaimData = (claimId: string, dispatch?: (para: any) => void, callBack?: (result: boolean, data?: ClaimT|null) => void) => {

    runApi(`claim/${claimId}`, {}, (r: ResultApiTypeT) => {
        if (r.result && r.data){
            dispatch?.(updateClaim(r.data));
            return callBack?.(true, r.data);
        }
        callBack?.(false);
    }, ApiSendMethods.get);

}