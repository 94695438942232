import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Loader } from 'Components';
import cx from 'classnames';

import { ReactComponent as UrlIco } from 'Assets/faArrowUpRightFromSquare.svg';

import DropDownSearch from 'Components/DropDownSearch/DropDownSearch';
import type { StateT as UserStateT } from 'Store/modules/user';
import { getPropablyCountryNameByCode } from 'Helpers/strings';
import { StateLocalT, defaultCountry } from '../../CreateForm';
import { changeModalTooltip } from 'Store/modules/page';
import { getSystemLanguage } from 'Helpers/browser';
import style from './ReferenceNumber.module.scss';
import useTranslate from "Hooks/useTranslate";
import styleSteps from '../Steps.module.scss';
import { countrys } from 'Helpers/countrys';
import Examples from "./Examples/Examples";
import { AppStateT } from 'Store/modules';
import Ico from 'Components/Ico/Ico';

type MainProps = {
    state: StateLocalT,
    setValidStep: (result: boolean) => void,
    updateClaim: (dataType: string, data: any) => void,
    actionSetEditIndex: (data: number|null) => void
}

const ReferenceNumber: React.FC<MainProps> = ({ state, setValidStep, updateClaim, actionSetEditIndex }) => {

    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const dispatch = useDispatch();
    const t = useTranslate();
    const [referenceNumber] = useState(state.claimData.referenceNumber);
    const [clientLocationCountryCode] = useState(state.claimData.clientLocationCountry.code);
    const [originEditIndex] = useState(state.editStepIndex);
    const [expandCountry, setExpandCountry] = useState(false);

    const validNumber = useMemo(() => (text: string):boolean => {
        // if (validField('reference-number', text)){
        if (text.length >= 6){
            return true;
        }
        return false;
    }, []);

    useEffect(() => {
        setValidStep(validNumber(state.claimData.referenceNumber) && !!state.claimData.clientLocationCountry.code);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.claimData.referenceNumber, state.claimData.clientLocationCountry.code]);

    useEffect(() => {
        const _countryName = user.location.country || countrys.find(co => co.value.toLowerCase() === user?.location?.countryCode.toLowerCase())?.text;
        if (user?.location?.countryCode && _countryName){
            updateClaim('clientLocationCountry', {
                code: user.location.countryCode,
                name: _countryName
            });
        } else {
            const c = getSystemLanguage().codeUpperCase;
            const country = countrys.find(co => co.value.toLowerCase() === c.toLowerCase());
            if (country){
                updateClaim('clientLocationCountry', {
                    code: user?.location?.countryCode || country.value,
                    name: _countryName || getPropablyCountryNameByCode(country.value || defaultCountry)
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, countrys]);

    const changeTextField = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const vNumber = ev.target.value.toUpperCase();
        updateClaim('referenceNumber', vNumber);
        validNumber(vNumber)

        updateClaim('referenceNumberStatus', 'notChecked');
        if (originEditIndex){
            if (vNumber === referenceNumber && clientLocationCountryCode === state.claimData.clientLocationCountry.code){
                actionSetEditIndex(originEditIndex);
            } else {
                actionSetEditIndex(null);
            }
        }
    }

    const toolTipAction = () => {
        dispatch(changeModalTooltip({ 
            title: t('createClaim.refTopTitle'), 
            content: t('createClaim.refTopContent'),
            object: (
                <>
                    {state.referenceNumberExamples.length > 0 &&
                        <Examples examplesUrl={state.referenceNumberExamples} t={t} />
                    }
                    <a className={style.topMore} href={`https://www.dolfi1920.de/faq/damage-report.html?___store=${user.language === 'pl' ? 'de_eur_polish' : 'de_eur_english'}`} target='_blank' rel="noreferrer">{t('common.seeMore')} <UrlIco className={style.moreUrl} /></a>
                </>
            )
        }));
    }

    const actionChangeCountry = (data: { text: string, value: string }) => {
        updateClaim('clientLocationCountry', {
            code: data.value,
            name: data.text
        });

        if (originEditIndex){
            if (state.claimData.referenceNumber === referenceNumber && clientLocationCountryCode === data.value){
                actionSetEditIndex(originEditIndex);
            } else {
                actionSetEditIndex(null);
            }
        }

    }

    const actionExpandCountry = (value: boolean) => {
        setExpandCountry(value)
    }

    return (
        <div className={style.root}>

            <div className={cx(style.refNumber, { [style.hide]: expandCountry })}>
                <div className={style.header}>{t('createClaim.refType')} <span className={styleSteps.ico}><Ico action={toolTipAction} /></span></div>

                <Input
                    label={t('createClaim.ref')}
                    value={state.claimData.referenceNumber}
                    onChange={changeTextField}
                    maxLength={30}
                    id={'input_new_claim_ref_number'}
                />
            </div>

            {state.claimData.clientLocationCountry.code &&
                <div className={style.countryLayer}>

                    <label>
                        {t('createClaim.refCountry')}
                        <span>{t('createClaim.refCountryWhere')}</span>
                    </label>

                    <DropDownSearch
                        label={t('common.chooseCountry')}
                        data={countrys.map(c => {
                            return { text: c.text, value: c.value }
                        })}
                        onChange={(data) => actionChangeCountry(data)}
                        value={state.claimData.clientLocationCountry.name}
                        t={t}
                        search
                        onExpand={value => actionExpandCountry(value)}
                        id={'search_new_claim_ref_number_country'}
                    />

                </div>
            }

            {state.loading &&
                <div className={style.loading}>
                    <Loader />
                    <div className={style.loadingContent}>
                        {t('createClaim.searchingReferenceNumberContent')}
                    </div>
                </div>
            }

        </div>
    )
}

export default ReferenceNumber;