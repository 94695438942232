export const languagePivot = (language: string): string => {

    switch(language){

        case 'en-us': return 'en';
        case 'pt-pt': return 'pt';
        case 'cs-cz': return 'cz';

        default: return language;

    }
}