import React, { useEffect, useMemo, useReducer, useState } from 'react';
import codes, { ICountryCodeItem } from 'country-calling-code';
import { getCountries } from 'react-phone-number-input';
import cx from 'classnames';

import DropDownSearch from 'Components/DropDownSearch/DropDownSearch';
import { ClientDataT } from 'Store/modules/luggageStore/shop';
import { shopRequireNipCountrys } from "Helpers/rules";
import styles from './ClientAdress.module.scss';
import { Button, Input } from 'Components';
import Store from './Store';

type PropsT = {
    clientData: ClientDataT,
    actionChange: (type: string, data: string, addressType?: string) => void,
    clientAddressType: string,
    validField: (type: string, data: any) => boolean,
    mobile?: boolean,
    countryName?: string,
    blurAction?: (type: string) => void,
    t: (data: string, obj?: any) => string,
    shipping?: boolean,
    actionCopyDataOrigin?: () => void,
    setFocusValue: (data: string) => void,
    requireNip?: boolean
}

export type LocalStateT = {
    name: boolean,
    surname: boolean,
    street: boolean,
    apt: boolean,
    city: boolean,
    zipCode: boolean,
    country: boolean,
    email: boolean,
    phoneNumber: boolean,
}

const initialLocalState: LocalStateT = {
    name: false,
    surname: false,
    street: false,
    apt: false,
    city: false,
    zipCode: false,
    country: false,
    email: false,
    phoneNumber: false,
}

const ClientAdress: React.FC<PropsT> = ({ clientData, actionChange, clientAddressType, validField, mobile, countryName = '', blurAction, t, shipping, actionCopyDataOrigin, setFocusValue, requireNip }) => {

    const [state, setState] = useReducer(Store, initialLocalState);
    const [emailError, setEmailError] = useState(false);
    const [copiedData, setCopiedData] = useState(false);
    const [countrys] = useState(codes.filter((country: ICountryCodeItem) => getCountries().includes(country.isoCode2 as any) ));

    useEffect(() => {
        if (shipping){
            if (!validField('email', String(clientData.email?.replace(' ', '').toLowerCase()))){
                return setEmailError(true);
            }
            setEmailError(false);
        }
    }, [shipping, clientData.email, validField]);

    const setSt = (dataType: string, data: any) => {
        setState({ type: 'SET_DATA', dataType, data });
    }

    const actionInputChange = (type: string, data: string) => {
        actionChange(type, data, clientAddressType);
    }

    const actionFocusInput = (type: string) => {
        setFocusValue(clientData[type]);
        setSt(type, false);
    }

    const actionCopyDataContact = () => {
        actionCopyDataOrigin();
        setCopiedData(true);
    }

    const errorNip = useMemo(() => {
        if (requireNip && shopRequireNipCountrys.includes(clientData.countryCode)){
            if (clientData.countryCode === 'BR' && !validField('clientAddress-nip-brazil', clientData.companyTaxNumber)){
                return true;
            }
            return !validField(`${clientAddressType}-companyTaxNumber`, clientData.companyTaxNumber);
        }
        return false;
    }, [requireNip, clientData.countryCode, clientData.companyTaxNumber, validField, clientAddressType]);

    const actionCheckInput = (type: string, data: string) => {
        const _data = data.trim();
        if (_data.length === 0){
            setSt(type, false);
        } else {
            let _err = false;
            if (type === 'name' && !validField(clientAddressType+'-name', _data)){
                _err = true;
            } else if (type === 'surname' && !validField(clientAddressType+'-surname', _data)){
                _err = true;
            } else if (type === 'street' && !validField(clientAddressType+'-street', _data)){
                _err = true;
            } else if (type === 'city' && !validField(clientAddressType+'-city', _data)){
                _err = true;
            } else if (type === 'zipCode' && !validField(clientAddressType+'-zipCode', _data)){
                _err = true;
            } else if (type === 'zipCodeCommon' && !validField(clientAddressType+'-zipCodeCommon', _data)){
                _err = true;
            } else if (shipping && (!clientData.email?.replace(' ', '').toLowerCase() || !validField('email', String(clientData.email?.replace(' ', '').toLowerCase())))){
                console.log('not email valid');
                _err = true;
            }
            setSt(type ==='zipCodeCommon' ? 'zipCode' : type, _err);
        }
        blurAction?.(type);
    }

    return (
        <div className={styles.root}>

            {(shipping && !copiedData && !clientData.city && !clientData.zipCode) &&
                <div className={styles.buttonCopy}>
                    <Button type="button" className={styles.btn} onClick={actionCopyDataContact} color={'secondary'}>
                        {t('shop.buttonCopyContact')}
                    </Button>
                </div>
            }

            <div className={cx(styles.row, { [styles.rowMobile]: mobile })}>
                <Input
                    label={t('shop.formFirstName')}
                    value={clientData.name}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => actionInputChange('name', ev.target.value)}
                    className={cx(styles.input, { [styles.error]: !validField(`${clientAddressType}-name`, clientData.name), [styles.inputFull]: mobile } )}
                    onFocus={() => actionFocusInput('name')}
                    onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => actionCheckInput('name', ev.target.value)}
                    maxLength={50}
                />
                <Input
                    label={t('shop.formLastName')}
                    value={clientData.surname}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => actionInputChange('surname', ev.target.value)}
                    className={cx(styles.input, { [styles.error]: !validField(`${clientAddressType}-surname`, clientData.surname), [styles.inputFull]: mobile } )}
                    onFocus={() => actionFocusInput('surname')}
                    onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => actionCheckInput('surname', ev.target.value)}
                    maxLength={50}
                />
            </div>
            <div className={cx(styles.row, { [styles.rowMobile]: mobile })}>
                <Input
                    label={t('shop.companyName')}
                    value={clientData.companyName}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => actionInputChange('companyName', ev.target.value)}
                    className={cx(styles.input, { [styles.inputFull]: mobile } )}
                    onFocus={() => actionFocusInput('companyName')}
                    onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => actionCheckInput('companyName', ev.target.value)}
                    maxLength={100}
                />
                {(shipping || requireNip) &&
                    <Input
                        label={t('shop.companyTaxNumber')}
                        value={clientData.companyTaxNumber}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => actionInputChange('companyTaxNumber', ev.target.value)}
                        className={cx(styles.input, { [styles.inputFull]: mobile, [styles.error]: errorNip } )}
                        onFocus={() => actionFocusInput('companyTaxNumber')}
                        onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => actionCheckInput('companyTaxNumber', ev.target.value)}
                        maxLength={40}
                    />
                }
            </div>
            <div className={cx(styles.row, { [styles.rowMobile]: mobile })}>
                <Input
                    label={t('shop.formStreet')}
                    value={clientData.street}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => actionInputChange('street', ev.target.value)}
                    className={cx(styles.input, { [styles.error]: !validField(`${clientAddressType}-street`, clientData.street) } )}
                    onFocus={() => actionFocusInput('street')}
                    onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => actionCheckInput('street', ev.target.value)}
                    maxLength={100}
                />
                <Input
                    label={t('shop.formApt')}
                    value={clientData.apt}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => actionInputChange('apt', ev.target.value)}
                    className={cx(styles.input, { [styles.error]: state.apt } )}
                    onBlur={() => blurAction?.('apt')}
                    maxLength={15}
                />
            </div>
            <div className={cx(styles.row, { [styles.rowMobile]: mobile })}>
                <Input
                    label={t('labels.city')}
                    value={clientData.city}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => actionInputChange('city', ev.target.value)}
                    className={cx(styles.input, { [styles.error]: !validField(`${clientAddressType}-city`, clientData.city) } )}
                    onFocus={() => actionFocusInput('city')}
                    onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => actionCheckInput('city', ev.target.value)}
                    maxLength={40}
                />
                <Input
                    label={t('labels.zip')}
                    value={clientData.zipCode}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => actionInputChange('zipCode', ev.target.value)}
                    className={cx(styles.input, { [styles.error]: state.zipCode } )}
                    onFocus={() => actionFocusInput('zipCode')}
                    onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => actionCheckInput(clientData.countryCode.toLowerCase() === 'pl' ? 'zipCode' : 'zipCodeCommon', ev.target.value)}
                    mask={clientData.countryCode.toLowerCase() === 'pl' ? '99-999' : null}
                    maxLength={15}
                />
            </div>
            <div className={cx(styles.row, { [styles.rowMobile]: mobile })}>
                <DropDownSearch
                    label={t('common.chooseCountry')}
                    data={countrys.map(c => {
                        return { text: c.country, value: c.isoCode2 }
                    })}
                    onChange={(data) => actionInputChange('country', data.text)}
                    value={clientData?.country || countryName}
                    t={t}
                    search
                    className={styles.country}
                    disabled
                />
            </div>
            {shipping &&
                <div className={cx(styles.emailLayer, styles.row, { [styles.rowMobile]: mobile })}>
                    <Input
                        label={t('labels.email')}
                        value={clientData.email?.replace(' ', '').toLowerCase() || ''}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => actionInputChange('email', ev.target.value?.replace(' ', '').toLowerCase())}
                        className={cx(styles.input, { [styles.error]: emailError } )}
                        onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => actionCheckInput('email', ev.target.value?.replace(' ', '').toLowerCase())}
                    />
                    <Input
                        label={t('labels.phone')}
                        value={clientData.phoneNumber}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => actionInputChange('phoneNumber', ev.target.value)}
                        className={cx(styles.input, { [styles.error]: state.phoneNumber } )}
                        onFocus={() => actionFocusInput('phoneNumber')}
                        onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => actionCheckInput('phoneNumber', ev.target.value)}
                    />
                </div>
            }
        </div>
    );
}
export default ClientAdress;