import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { LuggageCustomOptionT, LuggageExtConfigurableProductOptionsT, LuggageProductOptionsValues, LuggageProductsSimpleT, LuggageT } from 'api/modules/store';
import { initialsFilters, initialState, setConfigShop, setProductsLimit, setShopFiltersLocal, setShopFiltersReset, ShopStateT, StoreCategoryT } from 'Store/modules/luggageStore/shop';
import { FilterBox, FilterColor, FilterRange, SortBy } from 'PageComponents/LuggageStore';
import { isFiltersChange, ShopAttributeSizeId, productMaterial } from 'Helpers/store';
import type { StateT as CatalogStateT } from 'Store/modules/luggageStore/catalog';
import { materialsData } from 'Helpers/useMaterials';
import styles from './StoreFormDesktop.module.scss';
import { isFirefox } from "Helpers/browser";
import { AppStateT } from 'Store/modules';
import { Button } from 'Components';

type IProps = {
    onSubmit: () => void;
    catalog: LuggageT[] | null;
    mobile?: boolean,
    closeModalAction?: () => void,
    applyFromOutside?: boolean
};

type DataT = {
    title: string, 
    value: string, 
    name: string,
    materialId?: number,
    quantity?: number
}

type MaterialsDataT = {
    nameId: number,
    label: string,
}

const StoreFormDesktop: React.FC<IProps> = ({ onSubmit, mobile = false, closeModalAction, applyFromOutside }) => {

    const { filters, filtersLocal, categoriesMarged, categorysId, configShop, maxPrice, voucherInfoArrowDown } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const { data: catalog } = useSelector<AppStateT, CatalogStateT>(({ store }) => store.catalog);
    const dispatch = useDispatch();
    const [filtersData, setFiltersData] = useState<{ materials: DataT[], sizes: DataT[], colors: DataT[], manufacturers: DataT[], categories: DataT[] }>({ materials: [], sizes: [], colors: [], manufacturers: [], categories: [] });
    const { t } = useTranslation();

    const sortBy = [
        { name: 'default', title: t('shop.sortDefault'), value: true },
        { name: 'newest', title: t('shop.sortNewest'), value: false },
        { name: 'priceDesc', title: t('shop.sortPriceLow'), value: false },
        { name: 'priceAsc', title: t('shop.sortPriceHight'), value: false },
    ];

    useEffect(() => {
        if (filtersData.colors.length > 0 && configShop.colors.length === 0){
            dispatch(setConfigShop({...configShop, colors: filtersData.colors}));
        }
    }, [filtersData, dispatch, configShop]);

    const findCategories = useCallback((catId: number, categories: StoreCategoryT[]): StoreCategoryT[] => {
        let resultCategorys: StoreCategoryT[] = [];
        let found = false;
        categories.filter((cat: StoreCategoryT) => cat.is_active).forEach((cat: StoreCategoryT) => {
            if (cat.children_data.length>0){
                if (cat.children_data.some((c: StoreCategoryT) => c.parent_id === catId)){
                    resultCategorys = [...cat.children_data];
                    found = true;
                } else if (!found){
                    resultCategorys = [...findCategories(catId, cat.children_data)];
                }
            }
        })
        return resultCategorys;
    }, []);

    useEffect(() => {
        if (applyFromOutside){
            onSubmit();
            closeModalAction?.();
        }
    }, [applyFromOutside, closeModalAction, onSubmit]);

    useEffect(() => {
        if (!categoriesMarged) return;
        const Materials: DataT[] = [];
        const Sizes: DataT[] = [];
        const Colors: DataT[] = [];
        const Manufacturers: DataT[] = [];
        const Categories: DataT[] = [];
        catalog.forEach((cat: LuggageT) => {
            const material = cat.extension_attributes.custom_options.find((m: LuggageCustomOptionT) => m.attribute_code === 'material');
            productMaterial(cat)
            const materialLabel = materialsData.find((m: MaterialsDataT) => m.nameId === material?.id)?.label
            const materialValue = materialLabel ? t(materialLabel) : '';
            if (material && !Materials.some((m: DataT) => m.materialId === material.id)){
                Materials.push({ title: materialValue, value: materialValue, name: materialValue, materialId: material.id });
            }
            cat.extension_attributes.configurable_product_options.filter((option: LuggageExtConfigurableProductOptionsT) => +option.attribute_id === ShopAttributeSizeId ).forEach((option: LuggageExtConfigurableProductOptionsT) => {
                if (!Sizes.some((si: DataT) => si.value === (option.values as LuggageProductOptionsValues[])[0].extension_attributes.option.value)){
                    Sizes.push({ title: (option.values as LuggageProductOptionsValues[])[0].extension_attributes.option.value, value: (option.values as LuggageProductOptionsValues[])[0].extension_attributes.option.value , name: 'sizes' });
                }
            });
            if (cat.extension_attributes.simple_products){
                cat?.extension_attributes?.simple_products.filter((product) => product.color?.type === 1 && product.color?.group_swatch_value && product.color?.group_swatch_value.indexOf("#") === 0).forEach((product: LuggageProductsSimpleT) => {
                    if (!Colors.some((c: DataT) => c.value === product.color?.group_swatch_value)){
                        const _color = { 
                            title: product.color.group_value, 
                            name: product.color.group_value, 
                            value: product.color?.group_swatch_value
                        };
                        Colors.push(_color);
                    }
                });
            }

            // const manufacturer = cat.custom_attributes.find((m: LuggageCustomAttributeT) => m.attribute_code === 'manufacturer');
            const manufacturer = cat.extension_attributes.simple_products[0]?.manufacturer || '';
            if (!!manufacturer && !Manufacturers.some((m: DataT) => m.value === manufacturer)){
                Manufacturers.push({ title: manufacturer, value: manufacturer, name: manufacturer });
            }

            if (categorysId.length > 0){
                const cats = configShop.categories[0].children_data.find((c: StoreCategoryT) => c.id === categorysId[0])?.children_data||[];
                if (cats.length > 0){
                    for(let cat of cats){
                        if (!Categories.some((data: DataT) => data.value === String(cat.id))){
                            Categories.push({ title: cat.name, value: String(cat.id), name: cat.name });
                        }
                    }
                }
            }
    
        });

        // setFiltersData({ materials: Materials, sizes: Sizes, colors: Colors, manufacturers: Manufacturers.sort((a,b) => a.name > b.name ? 0 : -1), categories: Categories });
        setFiltersData({ materials: Materials, sizes: Sizes, colors: Colors, manufacturers: Manufacturers.sort((a,b) => a.name > b.name ? (isFirefox() ? 1 : 0) : (isFirefox() ? 0 : -1)), categories: Categories });
    }, [catalog, categoriesMarged, categorysId, configShop.categories, findCategories, t]);

    const handleReset = useCallback(() => {
        dispatch(setShopFiltersReset());
        dispatch(setProductsLimit(initialState.productsLimit));
        closeModalAction?.();
    }, [dispatch, closeModalAction])

    const setStateValue = useCallback((label: string, value: any) => {
        dispatch(setShopFiltersLocal({
        ...filtersLocal,
        [label]: value
        }));
    }, [dispatch, filtersLocal])

    const handleSizeClick = useCallback((data: string) => {
        dispatch(setShopFiltersLocal({
            ...filtersLocal,
            sizes: filtersLocal.sizes.includes(data) ? filtersLocal.sizes.filter(el => el !==data) : [...filtersLocal.sizes, data]
        }));
    }, [dispatch, filtersLocal])

    const handleCategorieClick = useCallback((data: string) => {
        dispatch(setShopFiltersLocal({
            ...filtersLocal,
            categories: filtersLocal.categories.includes(data) ? filtersLocal.categories.filter(el => el !==data) : [...filtersLocal.categories, data]
        }));
        // dispatch(setCategotysId(categorysId.includes(+data) ? categorysId.filter((c: number) => c !== +data) : [...categorysId, ...[+data]]));
    }, [dispatch, filtersLocal])

    const handleMaterialsClick = useCallback((data: string) => {
        dispatch(setShopFiltersLocal({
            ...filtersLocal,
            materials: filtersLocal.materials.includes(data) ? filtersLocal.materials.filter(el => el !== data) : [...filtersLocal.materials, data]
        })); 
    }, [dispatch, filtersLocal])

    const handleManufacturersClick = useCallback((data: string) => {
        dispatch(setShopFiltersLocal({
            ...filtersLocal,
            manufacturers: filtersLocal.manufacturers.includes(data) ? filtersLocal.manufacturers.filter(el => el !==data) : [...filtersLocal.manufacturers, data]
        }));
    }, [dispatch, filtersLocal])

    const handleColorClick = useCallback((data: string) => {
        dispatch(setShopFiltersLocal({
            ...filtersLocal,
            colors: filtersLocal.colors.includes(data) ? filtersLocal.colors.filter(el => el !== data) : [...filtersLocal.colors, data]
        }));
    }, [dispatch, filtersLocal]);

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onSubmit();
        },
        [onSubmit]
    );

    return (
            <form onSubmit={handleSubmit} className={cx(styles.form, {[styles.mobile]: mobile, [styles.short]: voucherInfoArrowDown })}>
                <div className={styles.content}>
                    <div className={styles.block}>
                        <div className={styles.title}>{t('shop.sort')}</div>

                        <SortBy elems={sortBy} state={filtersLocal.sortBy} actionChange={(data: string) => setStateValue('sortBy', data)} />

                        {maxPrice>0 &&
                            <FilterRange
                                setFieldValue={setStateValue}
                                range={[0, maxPrice]}
                                state={filtersLocal.price}
                                setInputValues={(v) => setStateValue('price', v)}
                                inputValues={filtersLocal.price}
                                currency={configShop.currency.symbol}
                            />
                        }
                    </div>

                    <div className={styles.block}>
                        <div className={styles.title}>{t('shop.filter')}</div>
                        {filtersData.categories.length>0 &&
                            <FilterBox
                                title={t('shop.categories')}
                                elems={filtersData.categories}
                                state={filtersLocal.categories}
                                rounded="top"
                                actionHandleClick={handleCategorieClick}
                            />
                        }

                        {filtersData.sizes.length>0 &&
                            <FilterBox
                                title={t('shop.filterSize')}
                                elems={filtersData.sizes}
                                state={filtersLocal.sizes}
                                rounded={filtersData.categories.length > 0 ? undefined : 'top'}
                                actionHandleClick={handleSizeClick}
                            />
                        }

                        {filtersData.colors.length > 0 &&
                            <FilterColor
                                colors={filtersData.colors.map(color => {
                                    return {
                                        value: color.value,
                                        swatch_value: color.title,
                                        id: 0,
                                        type: 1,
                                        group_swatch_value: '',
                                        group_value: ''
                                    }
                                })}
                                title={t('shop.color')}
                                state={filtersLocal.colors}
                                actionHandleClick={handleColorClick}
                            />
                        }

                        {filtersData.materials.length > 0 &&
                            <FilterBox
                                title={t('shop.filterMaterial')}
                                elems={filtersData.materials.filter(m => m.value !== '')}
                                state={filtersLocal.materials}
                                // rounded="bottom"
                                actionHandleClick={handleMaterialsClick}
                            />
                        }
                        {filtersData.manufacturers.length > 0 &&
                            <FilterBox
                                title={t('shop.manufacturers')}
                                elems={filtersData.manufacturers}
                                state={filtersLocal.manufacturers}
                                rounded="bottom"
                                actionHandleClick={handleManufacturersClick}
                            />
                        }
                        {mobile &&
                            <div className={cx(styles.buttonContainer, styles.buttonContainerMobile)}>
                                <Button color="secondary" className={styles.button} onClick={handleReset} disabled={!isFiltersChange(filters, {...initialsFilters, price: [0, maxPrice]})}>
                                    {t('shop.filterReset')}
                                </Button>
                                {!mobile &&
                                    <Button type="submit" color="primary" className={styles.button} disabled={!isFiltersChange(filters, filtersLocal)}>
                                        {t('shop.filterApply')}
                                    </Button>
                                }
                            </div>
                        }
                    </div>
                </div>
                {!mobile &&
                    <div className={styles.buttonContainer}>
                        <Button color="secondary" className={styles.button} onClick={handleReset} disabled={!isFiltersChange(filters, {...initialsFilters, price: [0, maxPrice]})}>
                            {t('shop.filterReset')}
                        </Button>
                        {!mobile &&
                            <Button type="submit" color="primary" className={styles.button} disabled={!isFiltersChange(filters, filtersLocal)}>
                                {t('shop.filterApply')}
                            </Button>
                        }
                    </div>
                }
            </form>
    );
}

export default StoreFormDesktop;
