import React, { useRef, useCallback } from 'react';
import cx from 'classnames';

import { ReactComponent as BackIcon } from 'Assets/back.svg';
import { AnimatedModal } from 'Components';
import styles from './Modal.module.scss';
import { ReactComponent as CloseIcon } from 'Assets/cross-icon.svg';

interface IModal {
  open: boolean;
  node?: Element | null;
  children: React.ReactNode | string;
  title?: string | React.ReactNode | (() => React.ReactNode);
  onClose: (evt: React.MouseEvent<any>) => void;
  closeOnClickOutside?: boolean;
  headerClassName?: string;
  headerRight?: React.ReactNode | null;
  contentClassName?: string;
  animatedContentClassName?: string;
  back?: () => void;
  contentRef?: React.RefObject<HTMLDivElement>;
  onOpened?: () => void;
  onClosed?: () => void;
  hideCloseButton?: boolean;
  hideTitle?: boolean,
  titleNoCapitalize?: boolean;
  renderCloseButtonInHeader?: boolean; // Dodana nowa właściwość
}

export default function Modal(props: IModal) {
  const {
    open,
    closeOnClickOutside,
    animatedContentClassName,
    contentClassName,
    onClose,
    onOpened,
    onClosed,
    contentRef,
    titleNoCapitalize,
    renderCloseButtonInHeader = false, // Domyślnie wartość false
  } = props;
  const internalContentRef = useRef<null | HTMLDivElement>(null);

  const attachContentRef = useCallback(
    (ref: HTMLDivElement) => {
      internalContentRef.current = ref;
      if (contentRef) {
        (contentRef.current as any) = ref;
      }
    },
    [contentRef],
  );

  return (
    <AnimatedModal
    node={props.node}
    open={open}
    onClose={(event) => onClose(event)}
    closeOnClickOutside={closeOnClickOutside}
    contentClassName={animatedContentClassName}
    onOpened={onOpened}
    onClosed={onClosed}
    scrollableContentRef={internalContentRef}
    hideCloseButton={renderCloseButtonInHeader}
    renderCloseButtonInModalHeader={renderCloseButtonInHeader}
  >
    {!props.hideTitle && (
      <header
        className={cx(styles.header, props.headerClassName, {
          [styles.padded]: Boolean(props.headerRight || props.back),
          [styles.titleNoCapitalize]: titleNoCapitalize,
        })}
      >
        {Boolean(props.back) && (
          <button className={styles.backButton} onClick={props.back}>
            <BackIcon />
          </button>
        )}
        {typeof props.title === 'function' ? props.title() : props.title}
        {props.headerRight && (
          <div className={styles.headerActions}>
            {props.headerRight}
            {renderCloseButtonInHeader && !props.hideCloseButton && (
              <button className={styles.closeButton} onClick={onClose}>
                <CloseIcon />
              </button>
            )}
          </div>
        )}
      </header>
    )}
  
    <div className={cx(styles.content, contentClassName)} ref={attachContentRef} id="contentModal">
      {props.children}
    </div>
  </AnimatedModal>
  
  );
}