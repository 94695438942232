import { useEffect, useMemo, useState } from 'react';
import { ClaimCreateT } from "../../CreateForm";
import { PageConfigT } from "Store/modules/page";


const useValidPhone = (contact: ClaimCreateT['contact'], inputs: PageConfigT['inputs']) => {
    
    const validPhoneCheck = useMemo(() => !!(
        !!contact.prefixPhone ?
            ('+' + contact.prefixPhone + contact.phone).match(inputs.phone.regexp) && contact.phone.length >= inputs.phone.minLength - (contact.prefixPhone.length + 1) && contact.phone.length <= inputs.phone.maxLength - (contact.prefixPhone.length + 1): 
            contact.phone.match(inputs.phone.regexp) && contact.phone.length >= inputs.phone.minLength && contact.phone.length <= inputs.phone.maxLength
    ),[contact, inputs.phone]);

    const [valid, setValid] = useState(validPhoneCheck);

    useEffect(() => {
        setValid(validPhoneCheck);
    }, [validPhoneCheck]);

    return valid;

}
export default useValidPhone;