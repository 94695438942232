import { useNavigate } from "react-router";
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { changeModalArticle, changeShowHelpCenter, changeShowModalLanguages } from 'Store/modules/page';
import Footer from 'Components/Footer/Footer';
import styles from './Header.module.scss';
import HamburgerMenu from "Components/HamburgerMenu/HamburgerMenu";
import { useDeviceType } from 'Helpers/responsiveContainers';
import { ReactComponent as BackIcon } from 'Assets/back.svg';
import { ReactComponent as Logo } from 'Assets/logo.svg';
import { helpCenterAvailableLanguages } from 'Helpers/i18';
import { AppStateT } from "Store/modules";
import { actionShowCookies } from "Helpers/browser";
import { clearHelpCenter } from 'Store/modules/accountSettings';
import SelectLanguage from "Components/SelectLanguage/SelectLanguage";
import cx from 'classnames';
import HelpCenter from 'Components/HelpCenter/HelpCenter';

interface IProps {
  back?: boolean | string;
  footer?: boolean;
}
export type UrlActionTypeT = 'login'|'about'|'terms'|'privacy'|'language'|'help'|'personalInfo' | 'logOut';

const FOOTER_LINKS_FIRST_ROW = [
    { name: 'footer.signIn', path: '/login' },
    { name: 'footer.aboutUs', path: '/about' },
    { name: 'footer.help', path: '/help' },
    { name: 'footer.language', path: '/lang' },
];

const FOOTER_LINKS_SECOND_ROW = [
    { name: 'footer.privacySettings', path: '/privacy' },
    { name: 'footer.termsAndConditions', path: '/terms' },
];

export default function Header({ back, footer }: IProps) {
  const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();
  const dispatch = useDispatch();
  const showModalLanguages = useSelector<AppStateT, boolean>((state) => state.page.showModalLanguages);
  const showHelpCenter = useSelector<AppStateT, boolean>((state) => state.page.showHelpCenter);

  const _FOOTER_LINKS_FIRST_ROW = useMemo(() => 
    FOOTER_LINKS_FIRST_ROW.filter(r => 
        r.name === 'footer.help' ? helpCenterAvailableLanguages.includes(actualLanguage) : true
    ), 
    [actualLanguage]
);

const actionUrlClick = (e: React.MouseEvent, type: UrlActionTypeT) => {
    e.preventDefault();
    setTimeout(() => {
        if (type === 'login'){
            return navigate('/login')
        }
        if (type === 'about'){
            return navigate('/about')
        }
        if (type === 'privacy'){
            return actionShowCookies();
        }

        if (type === 'terms'){
            return dispatch(changeModalArticle({ pagesContentKey: 'regs.regulations', title: 'footer.termsAndConditions' }));
        }

        if (type === 'language'){
            return dispatch(changeShowModalLanguages(!showModalLanguages));
        }

        dispatch(changeShowHelpCenter(!showHelpCenter));
        dispatch(clearHelpCenter());
    }, 200);  
 
}

const footerLinks = [..._FOOTER_LINKS_FIRST_ROW, ...(isMobile ? FOOTER_LINKS_SECOND_ROW : FOOTER_LINKS_SECOND_ROW.filter(link => link.name !== 'footer.privacySettings'))];

  const onBackClick = useCallback(() => {
    if (typeof back === 'string') {
      return navigate(back);
    }

    navigate(-1);
  }, [navigate, back]);

  const goToLogin = () => {
    navigate('/login');
  };
  return (
    <header className={styles.root}>
      {back && isMobile && (
        <button onClick={onBackClick} className={styles.back}>
          <BackIcon />
        </button>
      )}

      <div className={!isMobile ? styles.logoDesktop : styles.logo} onClick={goToLogin}><Logo /></div>
      {isMobile &&
        <div className={cx(styles.icon)}>
          <HamburgerMenu 
            menuItems={footerLinks} 
            onMenuItemClick={actionUrlClick} 
          />
          <SelectLanguage />
          <HelpCenter />
        </div>                               
      }
      {footer && <Footer />}
    </header>
  );
}
