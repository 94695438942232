import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IImage } from 'PageComponents/Chat/ReportUploadBox/ReportUploadBox';
import { ReportUploadBox } from 'PageComponents/Chat';
import style from './PhotosLuggage.module.scss';
import { StateLocalT } from '../../CreateForm';

type MainProps = {
    state: StateLocalT,
    setValidStep: (result: boolean) => void,
    updateClaim: (dataType: string, data: any) => void,
    mobile: boolean
}

export const luggagePhotos = ['front', 'back', 'top', 'bottom', 'left', 'right'];

const PhotosLuggage: React.FC<MainProps> = ({ state, updateClaim, setValidStep, mobile }) => {

    // const [isFrontRequire] = useState(state.requiredDocuments.some(req => req.slug === 'picture-from-the-front'));
    const { t } = useTranslation();

    useEffect(() => {
        // setValidStep(!!state.claimData.photos.logo && (isFrontRequire ? !!state.claimData.photos.front : true));
        setValidStep(!!state.claimData.photos.back && !!state.claimData.photos.front && !!state.claimData.photos.left && !!state.claimData.photos.right && !!state.claimData.photos.top && !!state.claimData.photos.bottom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.claimData.photos.back, state.claimData.photos.front, state.claimData.photos.left, state.claimData.photos.right, state.claimData.photos.top, state.claimData.photos.bottom]);

    // const toolTipInformation = useCallback(() => {
    //     dispatch(changeModalTooltip({ title: t('createClaim.dmgPhotoluggageTip'), content: t('createClaim.dmgPhotoluggageContent') }));
    // }, [dispatch, t]);

    const actionChangeImage = useCallback((dataType: string, image: IImage | null) => {
        updateClaim('photos', {
            ...state.claimData.photos,
            [dataType]: image
        });
    }, [state.claimData.photos, updateClaim]);

    const getTitle = useMemo(() => (label: string): string => {
        switch (label){
            case 'front': return t('createClaim.sumFront');
            case 'back': return t('createClaim.sumBack');
            case 'top': return t('createClaim.damageTop');
            case 'bottom': return t('createClaim.damageBottom');
            case 'left': return t('createClaim.damageLeft');
            case 'right': return t('createClaim.damageRight');
        }
        return label;
    }, [t]);

    return (
        <div className={style.root}>

            <div className={style.header}>
                {t('createClaim.dmgPhotoluggageLabel')}
                {/* <span className={style.headerIco}><Ico action={toolTipInformation} /></span> */}
            </div>

            <div className={style.images}>

                {luggagePhotos.map(label => {
                    return (
                        <div className={style.imagePhoto} key={`l${label}`}>
                            <div className={style.imageLabel}>{getTitle(label)}</div>
                            <ReportUploadBox
                                value={state.claimData.photos[label] as IImage}
                                removable={true}
                                onChange={(value) => actionChangeImage(label, value)}
                                name={label}
                                hideName
                                fitWidth
                                onlyImages
                                excludeImages={state.imagesInClaim}
                                id={'file_new_claim_damage_'+label.toLowerCase()}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
export default PhotosLuggage;