import { call, put, takeLatest, select } from 'redux-saga/effects';
import api from 'api';
import {
  SendReportChatMessagesRequestT,
  SEND_REPORT_CHAT_MESSAGES_REQUEST,
  sendAndClearMessagesSuccess,
  sendAndClearMessagesFailure,
  clearReportChatMessages,
} from 'Store/modules/reportChat';
import type { IMessage } from 'api/modules/claim-report';

function messagesAdapter(messages: IMessage[]): string[] {
  return messages.map(({ value }) => value);
}

function* sendChatReportSaga({ data: claimId }: SendReportChatMessagesRequestT): any {
  try {
    const messages = yield select(({ reportChat }) => reportChat.data);

    if (!claimId || !messages || messages.length === 0) {
      yield put(clearReportChatMessages());
      return;
    }

    yield call(api().claimReport.sendMessages, claimId, messagesAdapter(messages));
    yield put(sendAndClearMessagesSuccess());
    yield put(clearReportChatMessages());
  } catch (err) {
    yield put(sendAndClearMessagesFailure(err));
  }
}

export default function* report(): Generator {
  yield takeLatest(SEND_REPORT_CHAT_MESSAGES_REQUEST, sendChatReportSaga);
}
