import React, { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import cx from 'classnames';

import ExternalnfoData from "Components/ExternalnfoData/ExternalnfoData";
import { MissingDocExampleT } from "Store/modules/claim";
import style from './InputData.module.scss';
import Ico from 'Components/Ico/Ico';

type MainPropsT = {
    type: string,
    actionChange: (type: string, data: string) => void,
    value: string,
    title: string,
    maxLength?: number,
    success?: boolean,
    exampleData: MissingDocExampleT|null
    id?: string
}

const InputData: React.FC<MainPropsT> = ({ type, actionChange, value, title, maxLength = 40, success, exampleData, id }) => {

    const [showExamples, setShowExamples] = useState(false);
    const { t } = useTranslation();

    const actionOpenExample = useCallback(() => {
        setShowExamples(old => !old);
    }, []);

    return (
        <div className={cx(style.root, { [style.success]: success })}>
            <div className={style.title}>
                <span>{title}</span>
                {exampleData &&
                    <div className={style.icoTooltip}>
                        <Ico action={() => actionOpenExample()} />
                    </div>
                }
            </div>
            <input
                value={value}
                maxLength={maxLength}
                onChange={(data) => actionChange(type, data.target.value)}
                id={id}
            />
            {showExamples &&
                <div className={style.examplesExternalInfo}>
                    <ExternalnfoData
                        t={t}
                        missingType={exampleData.SFTag}
                        missingDocumentsAdditionalInfo={exampleData}
                    />
                </div>
            }
        </div>
    );
}
export default InputData;