import { StoreStates } from './StoreModal.contants';

enum ACTIONS {
  SET_STORE_STATE = 'SET_STORE_STATE',
  SET_FILTERS_IS_OPEN = 'SET_FILTERS_IS_OPEN',
  SET_SEARCH_IS_OPEN = 'SET_SEARCH_IS_OPEN',
  SET_SEARCH_PANEL_VALUE = 'SET_SEARCH_PANEL_VALUE',
  RESET_STATE = 'RESET_STATE',
}

export type StoreComponentState = {
  state: StoreStates;
  filtersIsOpen: boolean;
  searchIsOpen: boolean;
  searchPanelValue: string;
};

export const storeInitialState: StoreComponentState = {
  state: StoreStates.LIST,
  filtersIsOpen: false,
  searchIsOpen: false,
  searchPanelValue: '',
};

export default function storeStateReducer(
  state = storeInitialState,
  action: { type: ACTIONS; data?: any },
) {
  switch (action.type) {
    case ACTIONS.SET_STORE_STATE: {
      return {
        ...state,
        state: action.data,
      };
    }
    case ACTIONS.SET_FILTERS_IS_OPEN: {
      return {
        ...state,
        filtersIsOpen: action.data,
      };
    }
    case ACTIONS.SET_SEARCH_IS_OPEN: {
      return {
        ...state,
        searchIsOpen: action.data,
      };
    }
    case ACTIONS.SET_SEARCH_PANEL_VALUE: {
      return {
        ...state,
        searchPanelValue: action.data,
      };
    }
    case ACTIONS.RESET_STATE: {
      return storeInitialState;
    }
    default: {
      return state;
    }
  }
}

export function setStoreState(data: StoreStates) {
  return {
    type: ACTIONS.SET_STORE_STATE,
    data,
  };
}

export function setFiltersIsOpen(data: boolean) {
  return {
    type: ACTIONS.SET_FILTERS_IS_OPEN,
    data,
  };
}

export function setSearchIsOpen(data: boolean) {
  return {
    type: ACTIONS.SET_SEARCH_IS_OPEN,
    data,
  };
}

export function setSearchPanelValue(data: string) {
  return {
    type: ACTIONS.SET_SEARCH_PANEL_VALUE,
    data,
  };
}

export function resetState() {
  return {
    type: ACTIONS.RESET_STATE,
  };
}

export const actions = {
  setStoreState,
  setFiltersIsOpen,
  setSearchIsOpen,
  setSearchPanelValue,
  resetState,
};
