import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import NoImageSvgPath from 'Assets/no-image.svg';

import { ShopStateT } from 'Store/modules/luggageStore/shop';
import type { LuggageImagesT } from 'api/modules/store';
import styles from './LuggageGallery.module.scss';
import { AppStateT } from 'Store/modules';

function LuggageGallery() {
    const { actualProductSimple, configShop } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const [activeImg, setActiveImg] = useState<LuggageImagesT|null>(actualProductSimple?.media_gallery?.[0]||null);

    useEffect(() => {
        setActiveImg(actualProductSimple?.media_gallery?.[0]||null);
    }, [actualProductSimple]);

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <div className={styles.imgContainer}>
                    <img src={activeImg ? configShop.mediaURL + activeImg?.file : NoImageSvgPath} className={styles.img} alt="luggage" />
                </div>
                <div className={styles.moreImg}>
                    {actualProductSimple?.media_gallery.map((item, index) => {
                        return (
                            <div
                                className={cx(styles.smallImgContainer, { [styles.active]: activeImg?.file === item.file })}
                                onClick={() => setActiveImg(item)}
                                key={index}
                            >
                                <img src={item.file ? configShop.mediaURL + item.file : NoImageSvgPath} className={styles.smallImg} alt="luggage" />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default LuggageGallery;