import { useTranslation } from "react-i18next";
import { defaultLanguage } from "Helpers/i18";

const useTranslate = () => {
    const { t } = useTranslation();

    const fn = (wordKey: string, attributeData: any = null) => {
        const resultOrigin = t(wordKey, attributeData);
        if (resultOrigin === wordKey || !wordKey) return t(wordKey, attributeData, defaultLanguage);
        return resultOrigin;
    }

    return fn;
    
}
export default useTranslate;