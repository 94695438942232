import savedRatesService, { SavedRatesDictT } from 'Helpers/saved-rate';

export const SAVE_RATING = 'SAVE_RATING';

export type SaveRatingT = {
  type: typeof SAVE_RATING;
  claimId: string;
  rate: string | number;
};

export type StateT = SavedRatesDictT;

const initialState: StateT = savedRatesService.getList();

type ActionTypeT = SaveRatingT;

export default function savedRatings(state: StateT = initialState, action: ActionTypeT): StateT {
  switch (action.type) {
    case SAVE_RATING: {
      savedRatesService.rate(action.claimId, action.rate);
      return savedRatesService.getList();
    }
    default: {
      return state;
    }
  }
}

export function markClaimToResolve(claimId: string, rate: string | number): SaveRatingT {
  return {
    type: SAVE_RATING,
    claimId,
    rate,
  };
}
