import React, { useState } from 'react';

import style from './PhoneDirect.module.scss';
import { Button, Modal } from "Components";

type MainPropsT = {
    value: string|undefined|null,
    actionChange: (value: string) => void,
    actionClose: () => void,
    t: (data: string) => string
}

const PhoneDirect: React.FC<MainPropsT> = ({ actionChange, value: valueParam, actionClose, t }) => {

    const [value, setValue] = useState(valueParam);

    return (
        <Modal open onClose={actionClose} hideTitle>
            <div className={style.root}>
                <div className={style.inputs}>
                    <span>+</span>
                    <input
                        value={value}
                        onChange={ev => setValue(ev.target.value.replace(/[^0-9-]/g, ''))}
                        maxLength={6}
                        autoFocus
                        id={'input_new_claim_direct_direct'}
                    />
                </div>
                <div className={style.buttons}>
                    <Button onClick={actionClose} color={'secondary'} id={'button_new_claim_direct_cancel'}>{t('common.cancel')}</Button>
                    <Button onClick={() => actionChange(value)} disabled={value.trim().length < 2} id={'button_new_claim_direct_confirm'}>{t('common.confirm')}</Button>
                </div>
            </div>
        </Modal>
    );
}
export default PhoneDirect;