export const isProduction = (): boolean => window.location.host.indexOf('fixi.dolfi1920.de') >=0 ;
export const isTest = (): boolean => window.location.host.indexOf('app.dev-fixi.pl') >=0 ;
export const isUat = (): boolean => window.location.host.indexOf('app-uat.dev-fixi.pl') >=0 ;
export const isLocal = (): boolean => {
    return Boolean(
        window.location.hostname === 'localhost' ||
        window.location.hostname === '[::1]' ||
        window.location.hostname.match(/^(127|192)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    );
};

export const  getEnvName = (): 'localhost'|'test'|'uat'|'production'| null => {
    
    if (isLocal()) return 'localhost';
    if (isProduction()) return 'production';
    if (isTest()) return 'test';
    if (isUat()) return 'uat';

    return null;
}
