import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { ReactComponent as ArrowLeft } from 'Assets/arrow.svg';

import type { LuggageCategoryT, LuggageCustomOptionT, LuggageExtConfigurableProductOptionsT, LuggageProductOptionsValues, LuggageProductsSimpleT, LuggageT } from 'api/modules/store';
import { initialState, setProductsLimit, setShopFiltersReset, ShopStateT, StoreCategoryT } from 'Store/modules/luggageStore/shop';
import { cPriceVoucher, productImageSmallUrl, ShopAttributeSizeId } from 'Helpers/store';
import { Colors } from 'Components/LuggageShopCard/LuggageShopCard';
import { useDeviceType } from "Helpers/responsiveContainers";
import styles from './StoreCatalog.module.scss';
import { LuggageShopCard } from 'Components';
import { AppStateT } from 'Store/modules';

interface IStoreCatalog {
    onItemSelect: (itemId: number) => void;
    catalog: LuggageT[];
}

function StoreCatalog(props: IStoreCatalog) {
    const { onItemSelect } = props;
    const { filters, categorysId, configShop, voucherActual, productsLimit } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const [productsFilters, setProductsFilters] = useState(props.catalog);
    const [products, setProducts] = useState(props.catalog.length >productsLimit ? props.catalog.slice(0, productsLimit) : props.catalog);
    const [lastProductSlide, setLastProductSlide] = useState(0);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isMobile } = useDeviceType();

    /*
     * MAIN PRODUCTS FILTER
     **/
    useEffect(() => {

        let _products = [...props.catalog];

        _products = _products.filter((catalog: LuggageT) => {
            let result = filters.showListPriceWithVoucher && voucherActual > 0 ? Math.max(catalog.price - voucherActual, 0) >= filters.price[0] && Math.max(catalog.price - voucherActual, 0) <= filters.price[1] :  catalog.price >= filters.price[0] && catalog.price <= filters.price[1];
            if (result && String(filters.search.trim()) !== ''){
                const producerName = catalog?.extension_attributes?.simple_products[0]?.manufacturer || '';
                result = producerName.toLocaleLowerCase().indexOf(filters.search.toLocaleLowerCase()) >= 0 || catalog.name.toLocaleLowerCase().indexOf(filters.search.toLocaleLowerCase()) >= 0;
            }
            if (result && catalog?.extension_attributes?.custom_options && catalog.extension_attributes.custom_options.length>0){
                if (filters.materials.length>0){
                    result = catalog.extension_attributes.custom_options.some((attr: LuggageCustomOptionT) => attr.attribute_code === 'material' && filters.materials.includes(String(attr.id)));
                }
                if (result && filters.manufacturers.length>0){
                    result = catalog.extension_attributes.simple_products.some((attr: LuggageProductsSimpleT) => filters.manufacturers.includes(attr?.manufacturer || ''));
                }
            }
            if (result && filters.sizes.length > 0){
                const objOption = catalog.extension_attributes.configurable_product_options.find((option: LuggageExtConfigurableProductOptionsT) => +option.attribute_id === ShopAttributeSizeId);
                if (objOption){
                    if (!(objOption.values as LuggageProductOptionsValues[]).some((data: LuggageProductOptionsValues) => filters.sizes.includes(data.extension_attributes.option?.value))){
                        result = false;
                    }
                }
            }
            if (result && filters.colors.length > 0){
                result = catalog.extension_attributes.simple_products.some((product: LuggageProductsSimpleT) => filters.colors.includes(product.color?.group_swatch_value));
            }
            // if check any categories
            if (result && categorysId.length > 0){
                const mainCat = configShop.categories[0].children_data.find((cat: StoreCategoryT) => categorysId.includes(cat.id) && cat.children_data.length>0 );
                // if check 1 castegory and it has no selected subcategories
                if (mainCat && categorysId.length === 1 && catalog.extension_attributes.category_links){
                    const subIds = mainCat.children_data.map((cat: StoreCategoryT) => cat.id);
                    result = catalog.extension_attributes.category_links.some((categoryLink: LuggageCategoryT) => [...subIds, ...[mainCat.id]].includes(+categoryLink.category_id));
                } else if (!mainCat && catalog.extension_attributes.category_links){
                    result = catalog.extension_attributes.category_links.some((categoryLink: LuggageCategoryT) => categorysId.includes(+categoryLink.category_id));
                } else if (catalog.extension_attributes.category_links){
                    // razem z główną kategorią
                    // result = catalog.extension_attributes.category_links.some((categoryLink: LuggageCategoryT) => categorysId.includes(+categoryLink.category_id) && (mainCat && categorysId.length > 1 && categoryLink.category_id === mainCat.id ? false : true));

                    // tylko podopcje
                    result = catalog.extension_attributes.category_links.some((categoryLink: LuggageCategoryT) => categorysId.filter((data, index) => index > 0).includes(+categoryLink.category_id));
                }
            }
            return result;
        });

        if (filters.sortBy === 'priceDesc'){
            _products.sort((a, b) => a.price > b.price ? 1 : -1);
        } else if (filters.sortBy === 'priceAsc'){
            _products.sort((a, b) => a.price < b.price ? 1 : -1);
        } else if (filters.sortBy === 'newest'){
            _products.sort((a, b) => a.created_at < b.created_at ? 1 : -1);
        }

        setProductsFilters(_products);
        setProducts(_products.slice(0, productsLimit));

    }, [props.catalog, filters, categorysId, configShop.categories, voucherActual, productsLimit]);

    useLayoutEffect(() => {
        if (lastProductSlide > 0 && productsLimit !== initialState.productsLimit){
            setTimeout(() => {
                document.querySelector("#producstList .product-"+(lastProductSlide + 1))?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 100);
        }
    }, [lastProductSlide, productsLimit]);

    const handleResetFilters = useCallback(() => {
        dispatch(setShopFiltersReset(true));
    }, [dispatch]);

    const handleShowMoreAction = useCallback(() => {
        setLastProductSlide(products.length - 1);
        dispatch(setProductsLimit(productsLimit + configShop.productsPortion))
    }, [productsLimit, dispatch, configShop.productsPortion, products.length]);

    return (
        <>
            {products.length > 0 ?
                <>
                    <div className={styles.title}>{t('shop.ourCatalog')}</div>
                    <div className={styles.cardContainer} id="producstList">
                        {products.map((item, index) => {

                            let _colors: Colors[] = [];
                            let simpleProductId = item.id;                            
                            if (item?.extension_attributes?.simple_products && item.extension_attributes.simple_products.length > 0){
                                if (isMobile){
                                    simpleProductId = item.extension_attributes.simple_products[0].id;
                                }
                                _colors = item.extension_attributes.simple_products.filter((data, index) => index < 7 && data.color?.type === 1).map((productSimple: LuggageProductsSimpleT) => {
                                    return {
                                        color: productSimple.color.swatch_value,
                                        image: ''
                                    };
                                });
                            }

                            const imageUrl = productImageSmallUrl(item, configShop);



                            return (
                                <div className={cx(styles.luggage, 'product-' + index )} key={index}>
                                    <LuggageShopCard
                                        img={imageUrl}
                                        title={item?.extension_attributes?.simple_products[0]?.manufacturer || ''}
                                        subTitle={item.name}
                                        discountPrice={cPriceVoucher(item.price, voucherActual)}
                                        totalPrice={item.price}
                                        colors={_colors}
                                        containerClassName={styles.luggageShopCard}
                                        imageContainerClassName={styles.imgContainer}
                                        onClick={() => onItemSelect(simpleProductId)}
                                        showListPriceWithVoucher={filters.showListPriceWithVoucher && voucherActual>0}
                                        currencySymbol={configShop.currency.symbol}
                                        currencyCode={configShop.currency.code}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={cx(styles.moreProducts, { [styles.block]: !(productsLimit < productsFilters.length) })}>
                        <button
                            onClick={handleShowMoreAction}
                        >
                            {t('shop.buttonMoreCases')}
                        </button>
                    </div>
                </>:
                <div className={styles.cardContainerNoResults}>

                    <div className={styles.backPath} onClick={handleResetFilters}><ArrowLeft className={styles.arrow} /> {t('shop.backCases')}</div>

                    <div className={styles.emptyRect} />

                    <div className={styles.emptyComm}>
                        {filters.search !== '' ?
                            <>{t('shop.searchNoResults', { search: filters.search })}</>:
                            <>{t('shop.searchNoFilters')}</>
                        }
                    </div>

                </div>
            }

        </>
    );
}

export default StoreCatalog;