import { useEffect, useMemo, useState } from 'react';

import { OrderStatusE, OrderT } from "api/modules/claim";

/*
 * Check correct order from array of orders
 **/
const useOrder = (orders: OrderT[] | undefined) => {
    const checkOrders = useMemo(() => (): OrderT | null => {
        if (!orders || !(!!orders && Array.isArray(orders) && orders.length > 0)) return null;
        if (orders.length === 1) return orders[0];
        const _order = orders.slice().reverse().find(o => o.status !== OrderStatusE.DEACTIVATED);
        return _order || orders[orders.length - 1];
    }, [orders])

    const [order, setOrder] = useState<OrderT | null>(checkOrders());

    useEffect(() => {
        setOrder(checkOrders());
    }, [orders, checkOrders]);

    return order;
}
export default useOrder;