import { useEffect, useState } from "react";

import { getUrlOfImages } from "services/api";
import { photoMainKey } from "Helpers/claim";
import { ClaimT } from "api/modules/claim";

const useKeyImages = (claims: ClaimT[], imageKeys?: string[]) => {
    const [photoKeys, setPhotoKeys] = useState<{ key: string, url: string }[]>([]);

    useEffect(() => {
        getUrlOfImages(!!imageKeys && Array.isArray(imageKeys) && imageKeys.length > 0 ? imageKeys : claims.map(claim => {
            return photoMainKey(claim);
        }), (result, dataResult) => result ? setPhotoKeys(dataResult) : setPhotoKeys([]));
    }, [claims, imageKeys]);

    return photoKeys;
}
export default useKeyImages;