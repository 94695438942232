import React, {useMemo} from 'react';
import cx from 'classnames';

import styles from './ComicBubble.module.scss';

interface ComicBubbleProps {
  onClose?: () => void;
  type: string;
  content: string;
  width?: string;
  margin?: string;
  style?: React.CSSProperties;
}

const ComicBubble: React.FC<ComicBubbleProps> = ({
  type = 'left',
  content = "Przykładowy tekst",
  width,
  margin,
  style,
}) => {

  const selectedStyles = useMemo(() => {
    const typeArray = type.split(' ');
    return typeArray.map((t) => {
      switch (t) {
        case 'left':
          return styles.left;
        case 'right':
          return styles.right;
        case 'think':
          return styles.think;
        case 'yell':
          return styles.yell;
        default:
          return '';
      }
    });
  }, [type]);

  return (
    <div
      className={cx(styles.bubble, ...selectedStyles)}
      style={{ '--width': width, '--margin': margin, ...style } as React.CSSProperties}
    >
      {content.split('\n').map((text, index) => (
        <div className='bubbleText'key={index}>
          {text}
          <br />
        </div>
      ))}
    </div>
  );
};

export default React.memo(ComicBubble);
