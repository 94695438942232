import React from 'react';
import styles from './ChatBotAnimatedDots.module.scss';

const ChatBotAnimatedDots: React.FC<{ mgClass: string }> = ({ mgClass }) => {
  return (
    <div className={`${styles.wave} ${mgClass}`}>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
    </div>
  );
};

export default ChatBotAnimatedDots;
