import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import cx from 'classnames';

import { markClaimToResolve as markClaimToResolveAction } from '../../../../Store/modules/markedClaims';
import InfoChatWaitingOnShopResult from "./InfoChatWaitingOnShopResult/InfoChatWaitingOnShopResult";
import SparePartsPaxValidation from './SparePartsPaxValidation/SparePartsPaxValidation';
import { ClaimHistoryT, IMyResolution } from '../../../../api/modules/case-resolution';
import SparePartsResolution from './SparePartsResolution/SparePartsResolution';
import type { StateT as ChatDataStateT } from 'Store/modules/claimChat/data';
import CaseResolutionBlock from '../CaseResolutionBlock/CaseResolutionBlock';
import InfoChatIrreparable from './InfoChatIrreparable/InfoChatIrreparable';
import { OrderStatusE, VerdictT } from '../../../../api/modules/claim';
import MoneyCompensation from './MoneyCompensation/MoneyCompensation';
import { getUrlShopToken, ReturnShopUrlTokenT } from "services/shop";
import useVerdictMessages from '../VerdictMessages/VerdictMessages';
import chatStyles from 'PageComponents/Chat/Chat/Chat.module.scss';
import { changeModalArticle, StateT } from 'Store/modules/page';
import { variableCheckModalStoreSuccess } from "Helpers/rules";
import { useDeviceType } from 'Helpers/responsiveContainers';
import { RESOLUTION_TYPE } from '../../Chat/Chat.constants';
import LoadingCss from "Components/LoadingCss/LoadingCss";
import { IOneResolutionOption } from '../../Chat/Chat';
import { CatalogsE } from 'Store/Enums/urlsCatalogs';
import style from './VerdictBlock.module.scss';
import type { AppStateT } from 'Store/modules';
import { ChatMessageGroup } from 'Components';

export default function VerdictBlock({
    verdict,
    onChooseResolution,
    setCaseResolutionModal,
    openStore,
    optionsForHistCurrResolution,
    statusInfo,
    lastResolution,
    firstVerdict,
    t
}: {
    verdict: VerdictT;
    onChooseResolution: (value: RESOLUTION_TYPE) => void;
    setCaseResolutionModal: (value: string) => void;
    openStore: () => void;
    optionsForHistCurrResolution: IOneResolutionOption[];
    statusInfo: ClaimHistoryT;
    lastResolution?: IMyResolution | null;
    firstVerdict?: VerdictT | null;
    t: (data: string) => string
}) {
    const dispatch = useDispatch();
    const { isNotMobile } = useDeviceType();
    const { claim } = useSelector<AppStateT, Pick<ChatDataStateT, 'claim'>>(({ claimChat }) => ({
        claim: claimChat.data.claim,
    }));
    const { pageConfig } = useSelector<AppStateT, StateT|null>((state) => state.page);
    const [catalogs, setCatalogs] = useState([]);
    const [isReplacement, setIsReplacement] = useState(false);
    const [isMoneyCompensation, setIsMoneyCompensation] = useState(false);
    const [isSparePartsResolution, setIsSparePartsResolution] = useState(false);
    const [isSparePartsPaxValidation, setIsSparePartsPaxValidation] = useState(false);
    const [isActiveOrder, setIsActiveOrder] = useState(claim?.orders && claim.orders.length > 0 && claim.orders.some(o => o.status !== OrderStatusE.DEACTIVATED));
    const [generatingUrl, setGeneratingUrl] = useState<CatalogsE|null>(null);
    const { action } = useParams<any>();
    const [waitingShopResult, setWaitingShopResult] = useState(false);

    useEffect(() => {
        if (action === variableCheckModalStoreSuccess){
            setWaitingShopResult(true);
        }
    }, [action]);

    useEffect(() => {
        if (claim?.catalogs && Array.isArray(claim.catalogs) && claim.catalogs.length > 0) {
            let specialFound = false;
            if (claim.catalogs.includes(CatalogsE.ONE_TO_ONE_REPLACEMENT)) {
                specialFound = true;
                setIsReplacement(true);
            } else {
                setIsReplacement(false);
            }
            if (claim.catalogs.includes(CatalogsE.MONEY_COMPENSATION)) {
                specialFound = true;
                setIsMoneyCompensation(true);
            } else {
                setIsMoneyCompensation(false);
            }
            if (claim.catalogs.includes(CatalogsE.SPARE_PARTS_RESOLUTION)) {
                specialFound = true;
                setIsSparePartsResolution(true);
            } else {
                setIsSparePartsResolution(false);
            }
            if (claim.catalogs.includes(CatalogsE.SPARE_PARTS_PAX_VALIDATION)) {
                specialFound = true;
                setIsSparePartsPaxValidation(true);
            } else {
                setIsSparePartsPaxValidation(false);
            }
            if (!specialFound) {
                setCatalogs(claim.catalogs.sort((a) => a === CatalogsE.MAGENTO ? -1 : 1));
            }
        }
    }, [claim?.catalogs]);

    useEffect(() => {
        if (claim?.orders && claim.orders.length > 0 && claim.orders[claim.orders.length - 1].status !== OrderStatusE.DEACTIVATED) {
            return setIsActiveOrder(true);
        }
        setIsActiveOrder(false);
    }, [claim?.orders]);

    const claimMarked = useSelector<AppStateT>(({ markedClaims }) =>
        claim ? markedClaims[claim.id] : null,
    );
    const showStartResolutionButton = claim && verdict && !claimMarked && !claim.orders;
    const markClaimToResolve = () => {
        if (claim) {
            dispatch(markClaimToResolveAction(claim.id));
        }
    };

    const setTooltipModal = (value: string) => {
        dispatch(changeModalArticle({ pagesContentKey: value }));
    }

    const { verdictMessages, verdictMetaMessage, verdictRabat } = useVerdictMessages({
        claim,
        verdict,
        isNotMobile,
        setTooltipModal
    });

    const isCatalogMagento = (): boolean => {
        return claim?.catalogs && Array.isArray(claim?.catalogs) && claim?.catalogs.length > 0 && claim?.catalogs.some(s => s === CatalogsE.MAGENTO);
    }

    const canShowMagento = (): boolean => {
        return !claim?.catalogs || isCatalogMagento();
    }

    const actionRunShop = useCallback((event: React.MouseEvent<HTMLElement>, catalog: CatalogsE) => {
        if (event) event.preventDefault();
        setGeneratingUrl(catalog);
        getUrlShopToken(catalog, claim?.id, dispatch, (result: boolean, data: ReturnShopUrlTokenT) => {
            if (!data.url){
                if (catalog !== CatalogsE.MAGENTO){
                    window.open(pageConfig.claim.catalogsUrls[catalog]);
                } else {
                    openStore();
                }
            }
            setGeneratingUrl(null);
        });
    }, [dispatch, claim?.id, pageConfig.claim.catalogsUrls, openStore]);

    const getShopName = useMemo(() => (name: string): string => {
        switch (name) {
            case CatalogsE.ENTER_AIR: return 'ENTER AIR CATALOG';
            case CatalogsE.LCC: return t('chatMessages.shopNameLcc');
            case CatalogsE.QCC: return t('chatMessages.shopNameQsc');
            case CatalogsE.QSC: return t('chatMessages.shopNameQsc');
            default: return t('chatMessages.shopNameMagento');
        }
    }, [t]);

    return (
        <>
            {isReplacement ?
                <>
                    <ChatMessageGroup
                        statusInfo={statusInfo}
                        as="MessageGroup"
                        direction="incoming"
                        avatar="bot"
                        data={[
                            {
                                message: t('chatMessages.pendingOrderReplacement'),
                            }
                        ]}
                    />

                </> :
                <>
                    {verdictMessages && (
                        <ChatMessageGroup
                            statusInfo={statusInfo}
                            as="MessageGroup"
                            direction="incoming"
                            avatar="bot"
                            data={verdictMessages}
                        />
                    )}

                    {(!waitingShopResult && !isReplacement && !isSparePartsPaxValidation && !isSparePartsResolution && !isMoneyCompensation && claim?.verdict?.verdictType === 'irreparable') &&
                        <InfoChatIrreparable
                            claim={claim}
                            statusInfo={statusInfo}
                            t={t}
                        />
                    }

                    {waitingShopResult &&
                        <InfoChatWaitingOnShopResult t={t} statusInfo={statusInfo} />
                    }

                    {isMoneyCompensation &&
                        <MoneyCompensation
                            claim={claim}
                            statusInfo={statusInfo}
                        />
                    }

                    {isSparePartsResolution &&
                        <SparePartsResolution
                            claim={claim}
                            statusInfo={statusInfo}
                        />
                    }

                    {isSparePartsPaxValidation &&
                        <SparePartsPaxValidation
                            claim={claim}
                            statusInfo={statusInfo}
                        />
                    }

                    {(!waitingShopResult && verdictMetaMessage && canShowMagento()) && (
                        <ChatMessageGroup
                            statusInfo={statusInfo}
                            as="MessageGroup"
                            direction="incoming"
                            data={verdictMetaMessage}
                            className={chatStyles.verdictResolution}
                        />
                    )}
                    
                    {verdictRabat.length > 0 &&
                        <ChatMessageGroup
                            statusInfo={statusInfo}
                            as="MessageGroup"
                            direction="incoming"
                            avatar="bot"
                            data={verdictRabat}
                        />
                    }

                    {!waitingShopResult && (!claim?.isBlocked && (!(claim?.catalogs && Array.isArray(claim.catalogs) && claim.catalogs.length > 0 && claim.catalogs.some(c => c !== CatalogsE.MAGENTO)) || isActiveOrder)) &&
                        <CaseResolutionBlock
                            statusInfo={statusInfo}
                            as="MessageGroup"
                            claim={claim}
                            showStartResolutionButton={showStartResolutionButton}
                            markClaimToResolve={markClaimToResolve}
                            isNotMobile={isNotMobile}
                            onChooseResolution={onChooseResolution}
                            setCaseResolutionModal={setCaseResolutionModal}
                            optionsForHistCurrResolution={optionsForHistCurrResolution}
                            lastResolution={lastResolution}
                            firstVerdict={firstVerdict}
                        // shortcut={shortcut}
                        />
                    }

                    {((Array.isArray(claim?.catalogs) && !claim.isBlocked && (claim.catalogs.some(c => c === CatalogsE.MAGENTO) ? catalogs.length > 1 : catalogs.length >= 1)) && !isActiveOrder && !waitingShopResult) &&
                        <div className={style.catalogLinks}>
                            <header>{t('chatMessages.shopsHeader')}</header>
                            <div className={style.content}>
                                {catalogs.length === 1 ?
                                    t('chatMessages.shopsContentOne') :
                                    t('chatMessages.shopsContentMore')
                                }
                                <ul>
                                    {catalogs.map(c => {
                                        const isLoading = c === generatingUrl;
                                        return (
                                            <li key={`${c}`}>
                                                <div className={cx(style.item, { [style.dot]: !isLoading })} onClick={(event) => !generatingUrl ? actionRunShop(event, c) : event.preventDefault()} rel={'noreferrer'}>
                                                    <span className={style.space}>{isLoading && <LoadingCss />}</span>
                                                    <span className={style.text}>
                                                        <strong>{`${getShopName(c)}`}</strong> - {' '}
                                                        {c === CatalogsE.MAGENTO ? t('chatMessages.shopContentMagento') : t('chatMessages.shopContentOther')}
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    }
                </>
            }

        </>
    );
}
