import { CreateClaimFormDataT } from 'api/modules/claim';
import { loading } from 'Helpers/store-async';
export const CREATE_CLAIM_REQUEST = 'CREATE_CLAIM_REQUEST';
export const CREATE_CLAIM_RESET = 'CREATE_CLAIM_RESET';
export const ADD_REFERENCE_NUMBER_TO_QUEUE = 'ADD_REFERENCE_NUMBER_TO_QUEUE';
export const SEND_MISSING_DOC_TAG = 'SEND_MISSING_DOC_TAG';

export type CreateClaimRequestT = {
  type: typeof CREATE_CLAIM_REQUEST;
  data: CreateClaimFormDataT;
  onClose?: () => void;
};

export type CreateClaimResetT = {
  type: typeof CREATE_CLAIM_RESET;
};

export type AddReferenceNumberToQueueT = {
  type: typeof ADD_REFERENCE_NUMBER_TO_QUEUE;
  data: string;
};

export type ActionTypeT =
  | CreateClaimRequestT
  | CreateClaimResetT;

export type StateT = {
  loading: boolean;
  loaded: boolean;
  data: Array<any>;
  error: string | null;
};

const initialState: StateT = {
  loading: false,
  loaded: false,
  data: [],
  error: null,
};

export default function createClaimReducer(
  state: StateT = initialState,
  action: ActionTypeT,
): StateT {
  switch (action.type) {
    case CREATE_CLAIM_REQUEST: {
      return loading<StateT>(state);
    }
    case CREATE_CLAIM_RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export function createClaimRequest(
  data: CreateClaimFormDataT,
  onClose?: () => void,
): CreateClaimRequestT {
  return {
    type: CREATE_CLAIM_REQUEST,
    data,
    onClose,
  };
}

export function resetCreateClaimState(): CreateClaimResetT {
  return {
    type: CREATE_CLAIM_RESET,
  };
}

export function addReferenceNumberToQueue(referenceNumber: string): AddReferenceNumberToQueueT {
  return {
    type: ADD_REFERENCE_NUMBER_TO_QUEUE,
    data: referenceNumber,
  };
}
