import { useEffect, useState } from 'react';

import AssessmentTimeoutBlock from '../AssessmentTimeoutBlock/AssessmentTimeoutBlock';
import AssessmentTimerBlock from '../AssessmentTimerBlock/AssessmentTimerBlock';
// import chatStyles from 'PageComponents/Chat/Chat/Chat.module.scss';
import { ClaimHistoryT } from 'api/modules/case-resolution';
// import { formatTimeOnly } from 'Helpers/date';
// import { ChatMessageGroup } from 'Components';
// import useTimer from 'Helpers/useTimer';
// import CONFIG from 'Config';

export default function AssessmentProcessBlock({
	assessmentCreatedAt,
	showLoader,
	isAssessmentTimeOut,
	statusInfo,
	showAssessmentTimeOut
}: {
	assessmentCreatedAt: string;
	showLoader: boolean;
	isAssessmentTimeOut: boolean;
	statusInfo: ClaimHistoryT;
	showAssessmentTimeOut: boolean,
}) {
  // const { t } = useTranslation();
  const [localIsAssessmentTimeOut, setLocalIsAssessmentTimeOut] = useState(isAssessmentTimeOut);
  const [localShowAssessmentTimeOut, setLocalShowAssessmentTimeOut] = useState(showAssessmentTimeOut);
  const [localShowLoader, setLocalShowLoader] = useState(showLoader);
  // const [leftSeconds] = useTimer(addSecondsToDate(assessmentCreatedAt, CONFIG.CLAIM_TIME_OUT), assessmentCreatedAt);

	useEffect(() => {
		setLocalShowLoader(showLoader);
	}, [showLoader]);

  // const creationTime = useMemo(() => formatTimeOnly(new Date(assessmentCreatedAt)), [
  //   assessmentCreatedAt,
  // ]);

  // const introMessages = [
  //   {
  //     message: t('chatMessages.intro'),
  //     time: creationTime,
  //     className: chatStyles.messageIn,
  //   },
  // ];

	const actionTimeout = () => {
		setLocalIsAssessmentTimeOut(true);
		setLocalShowAssessmentTimeOut(true);
		setLocalShowLoader(false);
	}

	return (
		<>
			{/* <ChatMessageGroup statusInfo={statusInfo} as="MessageGroup" direction="incoming" avatar="bot" data={introMessages} /> */}

			{/* Show timer if it's a newly created claim and there is no verdict */}
			{(!statusInfo.verdict && localShowLoader) && <AssessmentTimerBlock assessmentCreatedAt={assessmentCreatedAt} actionTimeout={actionTimeout} />}

			{/* Show time out messages */}
			{(!statusInfo.verdict && localIsAssessmentTimeOut && localShowAssessmentTimeOut) && <AssessmentTimeoutBlock assessmentCreatedAt={assessmentCreatedAt} />}
		</>
	);
}
