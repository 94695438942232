import { generateId } from "Helpers/strings";
import { LocalStateT } from "./ClaimMessage";

const Store = (state: LocalStateT, action: any): LocalStateT => {

    switch(action.type){

        case 'SET_DATA': return {
            ...state,
            [action.dataType]: action.data
        }

        case 'SET_TEXT': return {
            ...state,
            text: action.data,
        }

        case 'SET_CHARS_LEFT': return {
            ...state,
            charsLeft: action.data,
        }

        case 'SET_SAVE_TEXT': return {
            ...state,
            textSaved: state.text
        }

        case 'SET_DATA_MESSAGES': return {
            ...state,
            dataMessages: action.data
        }

        case 'ADD_MESSAGE_FROM_WEBSOCKETS': return {
            ...state,
            dataMessages: {
                ...state.dataMessages,
                messages: [...state.dataMessages.messages, ...[action.data]]
            }
        }

        case 'ADD_MESSAGE': return {
            ...state,
            dataMessages: state.dataMessages ? {
                ...state.dataMessages,
                messages: [...state.dataMessages.messages, ...[action.data]]
            } : {
                claimId: action.claimId,
                createdAt: (new Date()).toISOString(),
                id: generateId(),
                messages: [action.data],
                unreadedMessagesByPax: 0,
                unreadedMessagesByAssessor: 0,
                updatedAt: (new Date()).toISOString()
            },
            text: '',
            faqSelectedIdx: null
        }

        case 'SAVE_DATE_END_ADD_INPUT': return {
            ...state,
            timerAddMessageEnd: action.data
        }

        case 'SET_CHAT_STATUS': return {
            ...state,
            chatStatus: action.data,
            blockedChange: false
        }

        case 'GO_CHAT': return {
            ...state,
            chatStatus: 'chat',
            faqSelectedIdx: null,
            blockedChange: true
        }

        case 'SET_MIN_TEXT_LENGTH': return {
            ...state,
            minTextLength: action.data
        }

        case 'SET_HEIGHT': return {
            ...state,
            height: action.data
        }

        case 'SET_SHOW_NO_QUESTION': return {
            ...state,
            chatStatus: 'faq-no-question'
        }

        case 'SET_ADDITIONAL_HEIGHT': return {
            ...state,
            additionalHeight: action.data
        }

    }

    return state;
}

export default Store;