export enum RESPONSE_STATUSES {
  INCORRECT_CONFIRM_CODE = 'INCORRECT_CONFIRM_CODE',
}

export enum NOTIFICATION_STATUSES {
  STATUS_UPDATE = 'STATUS_UPDATE',
  SHIPMENT_UPDATE = 'SHIPMENT_UPDATE',
}

export enum CLAIM_STATUSES {
  ASSESSMENT_AUTHORIZATION = 'ASSESSMENT_AUTHORIZATION',
  ASSESSMENT_DECISION = 'ASSESSMENT_DECISION',
  ASSESSMENT_GALERIA_OFFER = 'ASSESSMENT_GALERIA_OFFER',
  ASSESSMENT_PENDING = 'ASSESSMENT_PENDING',
  ASSESSMENT_PROCESS = 'ASSESSMENT_PROCESS',
  ASSESSMENT_SELECTION = 'ASSESSMENT_SELECTION',
  BLOCKED = 'BLOCKED',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  ESCALATED = 'ESCALATED',
  INVOICING = 'INVOICING',
  REGISTERED = 'REGISTERED',
  REOPENED = 'REOPENED',
  SHIPMENT_COMPLETED = 'SHIPMENT_COMPLETED',
  SHIPMENT_IN_PROCESS = 'SHIPMENT_IN_PROCESS',
  SHIPMENT_ON_HOLD = 'SHIPMENT_ON_HOLD',
  SHIPMENT_OUT_FOR_DELIVERY = 'SHIPMENT_OUT_FOR_DELIVERY',
  SHIPMENT_PENDING = 'SHIPMENT_PENDING',
  SHIPMENT_PARTLY_SHIPPED = 'SHIPMENT_PARTLY_SHIPPED',
  SOLUTION_DROP_OFF = 'SOLUTION_DROP_OFF',
  SOLUTION_GALERIA_INVOICING = 'SOLUTION_GALERIA_INVOICING',
  SOLUTION_PENDING_GALERIA = 'SOLUTION_PENDING_GALERIA',
  SOLUTION_PENDING_INVOICE = 'SOLUTION_PENDING_INVOICE',
  SOLUTION_PENDING_ORDER = 'SOLUTION_PENDING_ORDER',
  SOLUTION_PENDING_SPARE_PARTS = 'SOLUTION_PENDING_SPARE_PARTS',
  SOLUTION_PICKUP_COMPLETED = 'SOLUTION_PICKUP_COMPLETED',
  SOLUTION_PICKUP_PENDING = 'SOLUTION_PICKUP_PENDING',
  SOLUTION_PICKUP_PROCESS = 'SOLUTION_PICKUP_PROCESS',
  SOLUTION_REFUND = 'SOLUTION_REFUND',
  SOLUTION_REFUND_PENDING = 'SOLUTION_REFUND_PENDING',
  SOLUTION_REFUND_PROCESS = 'SOLUTION_REFUND_PROCESS',
  SOLUTION_REPAIR_BY_EXTERNAL_PARTNER = 'SOLUTION_REPAIR_BY_EXTERNAL_PARTNER',
  SOLUTION_REPAIR_COMPLETED = 'SOLUTION_REPAIR_COMPLETED',
  SOLUTION_REPAIR_IN_PROGRESS = 'SOLUTION_REPAIR_IN_PROGRESS',
  SOLUTION_REPLACEMENT_BY_EXTERNAL_PARTNER = 'SOLUTION_REPLACEMENT_BY_EXTERNAL_PARTNER',
  SOLUTION_REPLACEMENT_IN_PROGRESS = 'SOLUTION_REPLACEMENT_IN_PROGRESS',
  VALIDATION_AL = 'VALIDATION_AL',
  VALIDATION_PAX = 'VALIDATION_PAX',
}

export const CLAIM_STATUS_TO_MESSAGE_AMOUNT: { [key: string]: number } = {
  [CLAIM_STATUSES.SOLUTION_PICKUP_PENDING]: 2,
  [CLAIM_STATUSES.SOLUTION_PICKUP_PROCESS]: 3,
  [CLAIM_STATUSES.SOLUTION_PICKUP_COMPLETED]: 2,

  [CLAIM_STATUSES.SOLUTION_DROP_OFF]: 2,

  [CLAIM_STATUSES.SOLUTION_PENDING_SPARE_PARTS]: 2,
  [CLAIM_STATUSES.SOLUTION_REPAIR_COMPLETED]: 2,

  [CLAIM_STATUSES.SHIPMENT_PENDING]: 2,
  [CLAIM_STATUSES.SHIPMENT_IN_PROCESS]: 3,
  [CLAIM_STATUSES.SHIPMENT_COMPLETED]: 1,
  [CLAIM_STATUSES.SHIPMENT_ON_HOLD]: 3,

  [CLAIM_STATUSES.CLOSED]: 3,
  [CLAIM_STATUSES.REOPENED]: 1,
  [CLAIM_STATUSES.CANCELLED]: 4,
  [CLAIM_STATUSES.SOLUTION_REFUND]: 1,
  [CLAIM_STATUSES.SOLUTION_REFUND_PENDING]: 1,
  [CLAIM_STATUSES.ASSESSMENT_SELECTION]: 1,
  [CLAIM_STATUSES.VALIDATION_AL]: 2,
};

export const IGNORED_STATUSES = [
  /* skip/not used */
  CLAIM_STATUSES.SOLUTION_REPLACEMENT_IN_PROGRESS,
  CLAIM_STATUSES.INVOICING,
  CLAIM_STATUSES.SOLUTION_PENDING_INVOICE,
  CLAIM_STATUSES.SOLUTION_PENDING_ORDER,
  CLAIM_STATUSES.ESCALATED,

  /* handled on the level of chat blocks */
  CLAIM_STATUSES.REGISTERED,
  CLAIM_STATUSES.ASSESSMENT_PROCESS,
  CLAIM_STATUSES.ASSESSMENT_DECISION,
  // CLAIM_STATUSES.VALIDATION_AL,
  CLAIM_STATUSES.VALIDATION_PAX,
];
