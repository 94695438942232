import { LocalStoreT } from "./ShoppingCartVoucher";


const Store = (state: LocalStoreT, action: any): LocalStoreT => {

    switch (action.type) {

        case 'SET_DATA':
            return {
                ...state,
                [action.dataType]: action.data
            }

        case 'SET_VOUCHER':
            return {
                ...state,
                resultVaucher: action.resultVaucher||'positive',
                voucher: action.voucher,
                voucherValue: action.voucher?.value||0
            }

        case 'CLEAR_VOUCHER':
            return {
                ...state,
                resultVaucher: action.resultVaucher||'noResult',
                voucher: null,
                voucherValue: 0
            }
    
    }

    return state;
}

export default Store;