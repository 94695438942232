import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { useDispatch } from 'react-redux';
import Lottie from 'react-lottie';
import cx from 'classnames';

import animationDataSuccess from 'Assets/lotties/success.json';
import animationDataError from 'Assets/lotties/error.json';

import { useDeviceType } from 'Helpers/responsiveContainers';
import { reload } from 'Store/modules/claimChat/data';
import { setReloadClaims } from "Store/modules/page";
import { Button, Loader, Modal } from 'Components';
import style from './ModalSuccess.module.scss';
import runApi from 'api/base/runApi';

const isLoginUser = (): boolean => {
    return !!(localStorage.getItem('token') || '');
}

type MainProps = {
    actionClose: () => void,
    actionGoClaim?: () => void,
    open: boolean,
    t: (data: string) => string,
    dataPayment?: { claimId: string, orderId: string, paymentCode: string, paymentStatus: 'success'|'error' } | null | undefined
}

const ModalSuccess: React.FC<MainProps> = ({ actionClose, actionGoClaim, open, t, dataPayment = null }) => {

    const { isMobile } = useDeviceType();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (dataPayment?.orderId && dataPayment?.paymentCode &&  dataPayment?.paymentStatus && dataPayment?.claimId && dataPayment?.paymentStatus){
            setLoading(true);
            runApi(`claim/${dataPayment.claimId}/shop/payment/validate`, { orderId: dataPayment.orderId, code: dataPayment.paymentCode, type: dataPayment.paymentStatus.toUpperCase() }, (r: any) => {
                setLoading(false);
                if (isLoginUser()){
                    // reload 
                    dispatch(setReloadClaims());
                    dispatch(reload());
                }
            });
        }
    }, [dataPayment?.orderId, dataPayment?.paymentCode, dataPayment?.paymentStatus, dataPayment?.claimId, dispatch]);

    const actionCloseLocal = () => {
        if (!loading){
            if (dataPayment && isLoginUser()){
                navigate('/' + dataPayment.claimId, { replace: true });
            } else if (dataPayment){
                navigate('/', { replace: true });
            }
            actionClose();
        }
    }

    return (
        <Modal animatedContentClassName={cx(style.root, { [style.mobile]: isMobile })} open={open} onClose={actionCloseLocal} hideTitle>
            <div className={style.body}>
                {open &&
                    <>
                        {!dataPayment ?
                            <>
                                <div className={style.lottie}>
                                    <Lottie options={{ loop: false, animationData: animationDataSuccess }} height={150} width={150} />
                                </div>
                                <div className={style.header}>
                                    {t('shop.orderSuccessHeader')}
                                </div>
                                <div className={style.description}>
                                    {t('shop.orderSuccessDescription')}
                                </div>
                            </>:
                            <>
                                <div className={style.lottie}>
                                    <Lottie options={{ loop: false, animationData: dataPayment.paymentStatus ==='success' ? animationDataSuccess : animationDataError }} height={150} width={150} />
                                </div>
                                <div className={style.header}>
                                    <>
                                        {loading ?
                                            <>
                                                {t('shop.payPrecessing3D')}
                                            </>:
                                            <>
                                                {dataPayment.paymentStatus ==='success' ? t('shop.paymentSuccessTitle') : t('shop.paymentErrorTitle')}
                                            </>
                                        }
                                    </>
                                </div>
                                {(!loading && dataPayment.paymentStatus ==='success') &&
                                    <div className={style.description}>
                                        {t('shop.orderSuccessDescription')}
                                    </div>
                                }
                            </>
                        }
                        {loading &&
                            <div className={style.loader}>
                                <Loader />
                            </div>
                        }
                        <div className={style.buttons}>
                            <Button onClick={actionCloseLocal} color={'secondary'} disabled={loading}>
                                {t('common.close')}
                            </Button>
                            {/* <Button onClick={actionGoClaim} className={style.buttonAction}>
                                {t('shop.orderSuccessButtonClaim')}
                            </Button> */}
                        </div>
                    </>
                }
            </div>
        </Modal>
    );
}
export default React.memo(ModalSuccess);
