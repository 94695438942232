import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import cx from 'classnames';

import type { StateT as ChatDataStateT } from 'Store/modules/claimChat/data';
import chatStyles from 'PageComponents/Chat/Chat/Chat.module.scss';
import useFormattedPrice from 'Hooks/useFormattedPrice';
import { AnimatedVerdict } from 'PageComponents/Chat';
import useValueVoucher from "Hooks/useValueVoucher";
import { PageConfigT } from 'Store/modules/page';
import { ClaimT } from "api/modules/claim";
import { AppStateT } from 'Store/modules';

interface IUseVerdictMetaMessage {
  claim: ChatDataStateT['claim'];
  verdict: ChatDataStateT['verdict'];
  isNotMobile: boolean;
  setTooltipModal?: (value: string) => void
}

function useVerdictMetaMessage({
  claim,
  verdict,
  isNotMobile,
}: IUseVerdictMetaMessage) {
  const { t } = useTranslation();
  const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
  const formatPrice = useFormattedPrice(claim.currencyIsoCode, pageConfig.noFractionCurrencies);
  const rabatData = useValueVoucher(claim, pageConfig.airlinesWithRabatVoucher);

  if (verdict && verdict.verdictType === 'repairable'){
    return null;
  }

  const isRepairable = claim.assessmentResults ? 
    Array.isArray(claim.assessmentResults) && claim.assessmentResults[0]?.verdictType === 'repairable' && claim.assessmentResults[0]?.resolution.repair :
    claim.verdict && claim.verdict?.verdictType === 'repairable' && claim.verdict?.resolution.repair

  if (!claim || !verdict || (claim.orders && claim.orders.length > 0) || +verdict.resolution.voucher <=0 || isRepairable) {
    return null;
  }

  // const claimCreationTime = new Date(claim.createdAt).getTime();
  // const verdictCreationTime = new Date(verdict.createdAt).getTime();
  // const diff = verdictCreationTime - claimCreationTime;
  // const finalTime = +(diff / 1000).toFixed(0);
  // const resolutionTime = finalTime < CONFIG.CLAIM_TIME_OUT ? finalTime : CONFIG.CLAIM_TIME_OUT;

  return [
    {
      className: cx(chatStyles.customMessage, chatStyles.verdictResolutionContent),
      render: () => (
        <div
          className={cx(chatStyles.verdictResolutionContainer, {
            [chatStyles.verdictResolutionContainerDesktop]: isNotMobile,
          })}
        >
          <div className={chatStyles.icoOneCach}>
            <div
              className={cx(chatStyles.col, {
                [chatStyles.colDesktop]: isNotMobile,
              })}
            >
              <AnimatedVerdict />

              <div className={chatStyles.value}>
                {parse(t('common.valueWithCurrency', {
                  value: `<span class="price">${formatPrice(rabatData.values.residualValue)}</span>`,
                  currency: `<span class="price">${claim.currencyIsoCode}</span>`,
                }))}
              </div>
              
                <div className={chatStyles.descriptionContainer} style={{ display: 'block', textAlign: 'center' }}>
                  <span className={chatStyles.description} style={{ display: 'block', textAlign: 'center', paddingRight: 0 }}>
                    {t('chatMessages.valueOfRefund')}
                  </span>

                  {/* <span
                    className={chatStyles.info}
                    onClick={() => {
                      setActiveFAQModal({ value: FAQ_MODAL_ID.FAQ_CASH_REFUND, resolution: null });
                    }}
                  >
                    i
                  </span> */}
                </div>
            </div>
          </div>
        </div>
      ),
    }
  ];
}

interface IUseVerdictMessages {
  verdict: ChatDataStateT['verdict'];
  setTooltipModal?: (value: string) => void
}

function useVerdictMessages({ verdict, setTooltipModal }: IUseVerdictMessages) {
  const { t } = useTranslation();
  if (!verdict) {
    return null;
  }

  return [
    {
      message: t('chatMessages.verdictReady'),
      className: chatStyles.messageIn,
    },
    {
      render: () => (
        <span>
          {t('chatMessages.caseIs')}{' '}
          <strong>{t(`verdictTypes.${verdict.verdictType}`)}</strong>
          {verdict.verdictType === 'irreparable' ? t('chatMessages.caseIsExtend') : ''}
          {/* <span
            className={chatStyles.verdictType}
            onClick={() => {
              setTooltipModal?.('tooltip.verdict.' + (verdict.verdictType === 'repairable' ? 'repairable' : 'irrepairable'));
            }}
          >
            {t(`verdictTypes.${verdict.verdictType}`)}
          </span> */}
        </span>
      ),
      className: chatStyles.messageIn,
    },
  ];
}
// ,
//     {
//         message: parse(t('chatMessages.plus8RabatInfo', { amount_old: rabatData.oldValue, currency: claim.currencyIsoCode, amount: `<strong>${rabatData.voucher}${claim.currencyIsoCode}</strong>` }))
//     }

const useVerdictRabatAdditional = (claim: ClaimT) => {
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const formatPrice = useFormattedPrice(claim.currencyIsoCode, pageConfig.noFractionCurrencies);
    const rabatData = useValueVoucher(claim, pageConfig.airlinesWithRabatVoucher);
    const { t } = useTranslation();
    if (!rabatData.additionalRabat){
      return [];
    }

    return [
      {
          render: () => (
            <>
              {parse(t('chatMessages.plus8RabatInfo', { amount_old: formatPrice(rabatData.oldValue), currency: claim.currencyIsoCode, amount: `<span class="price">${formatPrice(rabatData.voucher)} ${claim.currencyIsoCode}</span>` }))}
            </>
          ),
          
      }
    ]

}

export default function useChatMessages(props: IUseVerdictMetaMessage & IUseVerdictMessages) {
  const verdictMessages = useVerdictMessages(props);
  const verdictMetaMessage = useVerdictMetaMessage(props);
  const verdictRabat = useVerdictRabatAdditional(props.claim);

  return {
    verdictMessages,
    verdictMetaMessage,
    verdictRabat
  };
}
