export const SET_ACCOUNT_SETTING = 'SET_ACCOUNT_SETTING';
export const SET_SEARCH_PANEL_VALUE = 'SET_SEARCH_PANEL_VALUE';
export const CLEAR_HELP_CENTER = 'CLEAR_HELP_CENTER';

export enum Option {
  ACCOUNT_SETTINGS = 'Account settings',
  PERSONAL_INFORMATION = 'personalInformation.modalTitle',
  HELP_CENTER = 'helpCenter.modalTitle',
  HELP_CENTER_DESCRIPTIONS = 'Help centre description',
}

export type AccountSettingsValueT = Option | '';

export type SetAccountSettingsT = {
  type: typeof SET_ACCOUNT_SETTING;
  data: AccountSettingsValueT;
};

export type SetSearchPanelValueT = {
  type: typeof SET_SEARCH_PANEL_VALUE;
  data: string;
};

export type ClearHelpCenterT = {
  type: typeof CLEAR_HELP_CENTER;
};

export type StateT = {
  setting: AccountSettingsValueT;
  searchPanelValue: string;
};

const initialState: StateT = {
  setting: '',
  searchPanelValue: '',
};

type ActionTypeT = SetAccountSettingsT | SetSearchPanelValueT | ClearHelpCenterT;

export default function accountSettings(state: StateT = initialState, action: ActionTypeT): StateT {
  switch (action.type) {
    case SET_ACCOUNT_SETTING: {
      return {
        ...state,
        setting: action.data,
      };
    }
    case SET_SEARCH_PANEL_VALUE: {
      return {
        ...state,
        searchPanelValue: action.data,
      };
    }
    case CLEAR_HELP_CENTER: {
      return {
        ...state,
        searchPanelValue: '',
        setting: ''
      };
    }
    default: {
      return state;
    }
  }
}

export function setAccountSettings(data: AccountSettingsValueT): SetAccountSettingsT {
  return {
    type: SET_ACCOUNT_SETTING,
    data,
  };
}

export function setSearchPanelValue(data: string): SetSearchPanelValueT {
  return {
    type: SET_SEARCH_PANEL_VALUE,
    data,
  };
}

export function clearHelpCenter(): ClearHelpCenterT {
  return {
    type: CLEAR_HELP_CENTER
  };
}
