import React from 'react';

import style from './TextValue.module.scss';
import { InfoEdit } from '../Summary';

type MainPropsT = {
  label: string;
  value: string;
  stepIndex: string;
  color?: string | undefined;
  action?: (slug: string) => void;
};

const TextValue: React.FC<MainPropsT> = ({ label, value, color, action, stepIndex }) => {
  return (
    <div className={style.root}>
      <div className={style.label}>{label}</div>
      <div className={style.value}>
        {color ? (
          <div className={style.color} style={{ backgroundColor: color }}>
            <span>{value[0] + value.slice(1).toLowerCase()}</span>
          </div>
        ) : (
          value
        )}
      </div>
      {action && <InfoEdit action={() => action?.(stepIndex)} />}
    </div>
  );
};
export default TextValue;
