export type SavedRatesDictT = {
  [key: string]: string | number;
};

class SavedRates {
  static STORAGE_KEY = 'SAVED_RATES';
  dictionary: SavedRatesDictT;

  constructor() {
    try {
      const data = localStorage.getItem(SavedRates.STORAGE_KEY) || '{}';
      this.dictionary = JSON.parse(data);
    } catch {
      this.dictionary = {};
      this.save();
    }
  }

  save(): void {
    try {
      const data = JSON.stringify(this.dictionary);
      localStorage.setItem(SavedRates.STORAGE_KEY, data);
    } catch (err) {
      console.error(err);
    }
  }

  rate(claimId: string, rate: string | number): void {
    this.dictionary = {
      ...this.dictionary,
      [claimId]: rate,
    };

    this.save();
  }

  getList(): SavedRatesDictT {
    return this.dictionary;
  }
}

const savedRates = new SavedRates();

export default savedRates;
