import loadshMerge from 'lodash.merge';

export function loading<T>(state: T): T {
  return {
    ...state,
    loading: true,
    loaded: false,
    error: null,
  };
}

export function loaded<T, D = undefined, TI = undefined>(state: T, data?: D, dataIndexed?: TI): T {
  return {
    ...state,
    loading: false,
    loaded: true,
    error: null,
    ...(data ? { data } : {}),
    ...(dataIndexed ? { dataIndexed } : {}),
  };
}

export function error<T>(state: T, error: string): T {
  return {
    ...state,
    loading: false,
    loaded: false,
    error,
  };
}

export function merge<T>(state: T, data: unknown): T {
  return loadshMerge({}, state, data);
}
