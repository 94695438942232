export type MarkedClaimsDictT = {
  [key: string]: number;
};

class MarkedClaims {
  static STORAGE_KEY = 'MARKED_CLAIMS';
  dictionary: MarkedClaimsDictT;

  constructor() {
    try {
      const data = localStorage.getItem(MarkedClaims.STORAGE_KEY) || '{}';
      this.dictionary = JSON.parse(data);
    } catch {
      this.dictionary = {};
      this.save();
    }
  }

  save(): void {
    try {
      const data = JSON.stringify(this.dictionary);
      localStorage.setItem(MarkedClaims.STORAGE_KEY, data);
    } catch (err) {
      console.error(err);
    }
  }

  mark(claimId: string): void {
    this.dictionary = {
      ...this.dictionary,
      [claimId]: Date.now(),
    };

    this.save();
  }

  getList(): MarkedClaimsDictT {
    return this.dictionary;
  }
}

const markedClaims = new MarkedClaims()

export default markedClaims;
