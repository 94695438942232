import cx from 'classnames';
import React from 'react';

import style from './RadioButton.module.scss';

type MainProps = {
    on?: boolean,
    action?: (value: boolean) => void,
    disabled?: boolean,
    id?: string
}

const RadioButton: React.FC<MainProps> = ({ on, action, disabled, id }) => {
    return (
        <div className={cx(style.root, { [style.disabled]: !!disabled } )} onClick={() => !disabled ? action?.(!!!on) : null} id={id}>
            {(on || !!disabled) && <div />}
        </div>
    );
}
export default RadioButton;