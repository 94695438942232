import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import cx from 'classnames';
import NoImageSvgPath from 'Assets/no-image.svg';

import useFormattedPrice from 'Hooks/useFormattedPrice';
import styles from './LuggageShopCard.module.scss';
import { PageConfigT } from 'Store/modules/page';
import { AppStateT } from 'Store/modules';

export type Colors = {
  color: string;
  image: string;
};

type Props = {
  containerClassName?: string;
  img?: string;
  colors?: Colors[];
  title?: string;
  subTitle?: string;
  discountPrice?: number;
  totalPrice?: number;
  imageContainerClassName?: string;
  onClick?: () => void;
  showListPriceWithVoucher?: boolean,
  currencySymbol: string,
  currencyCode: string
};

const LuggageShopCard: React.FC<Props> = ({ containerClassName, colors, img, title, subTitle, discountPrice, totalPrice, imageContainerClassName, onClick, showListPriceWithVoucher = true, currencySymbol, currencyCode }) => {
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const formatPrice = useFormattedPrice(currencyCode, pageConfig.noFractionCurrencies);
    
    const colorsItems = useMemo(() => {
        return (
            colors && colors.map((item, index) => {
                return (
                <div
                    key={index}
                    className={styles.colorsItem}
                    style={{
                        backgroundColor: item.color,
                    }}
                />
                );
            })
        );
    }, [colors]);

    return (
        <div className={cx(styles.root, containerClassName)} onClick={onClick}>
            <div className={cx(styles.imgContainer, imageContainerClassName)}>
                <img src={img || NoImageSvgPath} className={styles.img} alt="luggage" />
            </div>
            <div className={styles.colorsContainer}>{colorsItems}</div>
            <div className={styles.textContainer}>
                {title && <div className={styles.title}>{title}</div>}
                <div className={styles.subTitle}>{subTitle}</div>
                {showListPriceWithVoucher ?
                    <div className={styles.priceContainer}>
                        <div className={styles.totalPrice}>{totalPrice?.toFixed(2)}</div>
                        <div className={styles.discountPrice}>{formatPrice(discountPrice)} {currencySymbol}</div>
                    </div>:
                    <div className={styles.priceContainer}>
                        <div className={styles.officialPrice}>{formatPrice(totalPrice)} {currencySymbol}</div>
                    </div>
                }
            </div>
        </div>
    );
}

export default LuggageShopCard;
