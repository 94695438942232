import React from 'react';
import cx from 'classnames';
import styles from './Tab.module.scss';

interface ITab {
  children: string;
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  active?: boolean;
  className?: string;
}

export default function Tab(props: ITab) {
  return (
    <button
      onClick={props.onClick}
      className={cx(styles.root, props.className, { [styles.active]: props.active })}
    >
      {props.children}
    </button>
  );
}
