import React from 'react';
import cx from 'classnames';

import { ReactComponent as CrossIcon } from 'Assets/cross-icon.svg';

import styles from './SearchPanel.module.scss';

type Props = {
  onChange: (evt: React.FormEvent<HTMLInputElement>) => void;
  value: string;
  onClose?: () => void;
  maxLength?: number;
  labelClassName?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onEnter?: (keyName: string) => void,
  t: (data: string) => string,
  onBlur?: () => void
};

function SearchPanel({ onChange, value, onClose, maxLength, labelClassName, placeholder, autoFocus, onEnter, t, onBlur }: Props) {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    if (!maxLength || (maxLength && inputValue.length <= maxLength)) {onChange(event)}
  }

  return (
    <label
      className={cx(styles.inputLabel, labelClassName, {
        [styles.emptyField]: !value.length,
      })}
    >
      <input
        type="text"
        placeholder={placeholder ? placeholder : t('common.search')}
        autoFocus={autoFocus ? autoFocus : undefined}
        onChange={handleInputChange}
        value={value}
        onKeyDown={(event) => onEnter?.(event.key)}
        onBlur={onBlur}
      />
      <button className={styles.clear}>
        <CrossIcon onClick={onClose} />
      </button>
    </label>
  );
}

export default SearchPanel;
