import React from 'react';

import ChatMessageGroup from "Components/ChatMessageGroup/ChatMessageGroup";
import style from './ChatMessage.module.scss';

type MainPropsT = {
    data: JSX.Element|JSX.Element[]|string,
    className?: string,
    showAvatar?: boolean
}

const ChatMessage: React.FC<MainPropsT> = ({ data, className, showAvatar }) => {

    return (
        <div className={style.root}>
            {!showAvatar && <div className={style.space} />}
            <div className={style.chat}>
                <ChatMessageGroup
                    direction="incoming"
                    data={[
                        {
                            render: () => data,
                            className
                        },
                    ]}
                    avatar={showAvatar && 'bot'}
                />
            </div>
        </div>
    );
}
export default ChatMessage;