import { useEffect, useMemo, useState } from 'react';

import { ClaimHistoryT } from "api/modules/case-resolution";
import { ClaimT, VerdictT } from "api/modules/claim";

const useHistory = (claim: ClaimT) => {

    const actionSetHistory = useMemo(() => (): ClaimHistoryT[] => {
        const status: ClaimHistoryT[] = [];
        claim?.statusHistory?.forEach(st => {
            status.push({
                report: claim?.reports?.find(report => report.claimStatusId === st.id) || null,
                verdict: claim?.assessmentResults ? (claim?.assessmentResults as any)?.find((ar: VerdictT) => ar.claimStatusId === st.id && ar.isActive) || null : null,
                status: st,
                resolution: claim?.resolutions ? (claim?.resolutions as any[])?.find(ar => ar.claimStatusId === st.id) || null : null
            })
        });
        return status;
    }, [claim?.assessmentResults, claim?.reports, claim?.resolutions, claim?.statusHistory])

    const [history, setHistory] = useState<ClaimHistoryT[]>(actionSetHistory());

    useEffect(() => {
        setHistory(actionSetHistory());
    }, [claim?.assessmentResults, claim?.reports, claim?.resolutions, claim?.statusHistory, actionSetHistory]);

    return history;
}
export default useHistory;