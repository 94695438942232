import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux";

import { ClaimChatMessagesT } from "api/modules/claim";
import { PageConfigT } from "Store/modules/page";
import { languagePivot } from "Helpers/chat";
import { AppStateT } from "Store/modules";

const useChatConversationVisible = (branch: string|undefined, claimStatus: string, chatMessages: ClaimChatMessagesT, language: string) => {

    const { claimChat } = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);

    const isEnabled = useMemo(() => (): boolean => {
        return (branch && claimStatus) && (
            (chatMessages?.messages && chatMessages.messages.length > 0) ||
            (
                claimChat.AVAILABLE_BRANCHES.includes(branch?.toUpperCase()) &&
                claimChat.AVAILABLE_STATUES.includes(claimStatus) &&
                claimChat.AVAILABLE_LANGUAGE_SKILLS.includes(languagePivot(language).toUpperCase())
            )
        )
    }, [branch, claimStatus, chatMessages?.messages, claimChat, language]);

    const [enabled, setEnabled] = useState(isEnabled);

    useEffect(() => {
        setEnabled(isEnabled());
    }, [isEnabled]);

    return enabled;

}

export default useChatConversationVisible;