import { useDispatch, useSelector } from 'react-redux';

import { clearHelpCenter } from 'Store/modules/accountSettings';
import { StateT as UserStateT } from 'Store/modules/user';
import { actionShowCookies } from "Helpers/browser";
import styles from './HeaderDesktop.module.scss';
import useTranslate from "Hooks/useTranslate";
import { AppStateT } from 'Store/modules';
import { useCallback, useState } from "react";
import HamburgerMenu from 'Components/HamburgerMenu/HamburgerMenu';
import { UrlActionTypeT } from 'Components/Footer/Footer';
import { changeShowHelpCenter, changeShowPersonalInformation } from 'Store/modules/page';
import { logout } from 'Store/modules/authorization';
import { clearCart } from 'Store/modules/luggageStore/shop';
import HelpCenter from 'Components/HelpCenter/HelpCenter';
import PersonalInformation from 'Components/HelpCenter/PersonalInformation';
import runApi, { ApiSendMethods, ResultApiTypeT } from 'api/base/runApi';
import { setUserData } from 'Store/modules/user';
import { runNotify } from 'Helpers/notifications';
import { updateUserReset } from 'Store/modules/user';
import { ReactComponent as CookieIcon } from 'Assets/cookies-settings-icon.svg';
import cx from 'classnames';


export default function HeaderDesktop() {
    const dispatch = useDispatch();
    const t = useTranslate();
    const showHelpCenter = useSelector<AppStateT, boolean>((state) => state.page.showHelpCenter);
    const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);
    const showPersonalInfo = useSelector<AppStateT, boolean>((state) => state.page.showPersonalInformation);
    const [saving, setSaving] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const { accountInfo } = useSelector<AppStateT, UserStateT>((state) => state.user);
    const resetPersonalInfoFormState = useCallback(() => {
        dispatch(updateUserReset());
    }, [dispatch]);
    interface IUserSelector {
        user: UserStateT['data'];
        firstTimeLogin: UserStateT['firstTimeLogin'];
    }
    const { user } = useSelector<AppStateT, IUserSelector>(({ user }) => ({
        user: user.data,
        firstTimeLogin: user.firstTimeLogin
    }));
    const HAMBURGER_ELEMENTS = [
        ...(actualLanguage === 'pl' || actualLanguage === 'en-us' ? [{ name: t('common.helpCenter'), path: '/help' }] : []),
        { name: t('common.personalInformation'), path: '/personalInfo' },
        { name: t('common.logOut'), path: '/logOut' }
    ];
    const logoutHandler = useCallback(() => {
        dispatch(logout());
        dispatch(clearCart(true));
        window.location.href = '/';
    }, [dispatch]);
    const changePersonalInfo = useCallback((personalInfo) => {
        setSaving(true);
        runApi('user', personalInfo, (r: ResultApiTypeT) => {
            if (r.result){
                dispatch(setUserData(r.data));
                runNotify({ message: t('common.updateChanged'), type: 'success' });
            } else {
                runNotify({ message: r.error?.message || t('registration.errorRegister'), type: 'error' });
                if (r.error.code === 'USER_EMAIL_EXIST'){
                    setErrorEmail(true);
                }
            }
            setSaving(false);
        }, ApiSendMethods.patch);
    }, [dispatch, t]);
    const actionUrlClick = (e: React.MouseEvent, type: UrlActionTypeT) => {
        e.preventDefault();
        setTimeout(() => { 
            if (type === 'privacy'){
                return actionShowCookies();
            }
            if (type === 'personalInfo'){
                dispatch(changeShowPersonalInformation(!showPersonalInfo));
            }
            if(type==='help'){
                dispatch(changeShowHelpCenter(!showHelpCenter));
                dispatch(clearHelpCenter());
            }
            if(type==='logOut'){
         logoutHandler()
            }
        }, 200);  
     
    }

    return (
        <header className={styles.root}>
            <div className={styles.iconContainer}>
                 <div
                    className={cx(styles.icon, styles.iconCookie)}
                    onClick={actionShowCookies}
                    title={t('common.cookiesInfo')}
                > 
                    <CookieIcon />
                </div> 
                <div className={cx(styles.icon)}>
                    <HamburgerMenu className="header-action"  menuItems={HAMBURGER_ELEMENTS} onMenuItemClick={actionUrlClick} />   
                    { (actualLanguage === 'pl' || actualLanguage==='en-us') &&
                            <HelpCenter />    
                    }
                    {showPersonalInfo && (
                        <>      
                            <PersonalInformation
                                userData={user}
                                onSubmit={changePersonalInfo}
                                submitted={accountInfo.loaded}
                                error={accountInfo.error}
                                reset={resetPersonalInfoFormState}
                                isSubmitting={saving}
                                errEmail={errorEmail}
                                setErrEmail={setErrorEmail}
                            />
                        </>
                    )} 
                </div>
            </div>
        </header>
  );
}
