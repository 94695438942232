const GOOGLE_SEARCH_URL = 'https://www.google.com/maps/search/?api=1';

function getLatLngParam(lat: number, lng: number): string {
  return `query=${lat},${lng}`;
}

function getPlaceIdParam(placeId: string): string {
  return `query_place_id=${placeId}`;
}

export default function createGoogleMapsLink(lat?: number, lng?: number, placeId?: string) {
  let params = '';

  if (lat && lng) {
    params += `&${getLatLngParam(lat, lng)}`;
  }

  if (placeId) {
    params += `&${getPlaceIdParam(placeId)}`;
  }

  return `${GOOGLE_SEARCH_URL}${params}`;
}
