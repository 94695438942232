import { getSystemLanguage } from "Helpers/browser";

const defaultLang: { code: string, codeUpperCase: string, codeLowerCase: string, fullCode: string } = getSystemLanguage();
const availableLangs = ['en-us', 'pl', 'de', 'sk', 'cz-cz'];
export const preferedorderLanguages = [ 'en-us', 'de', 'pl', 'cz-cz' ];// Order of prefered languages if we dont recognize or dont have in aour pule

const CONFIG = {
  API_ENDPOINT: {
    DEVELOPMENT: 'http://localhost:8082/',
    PRODUCTION: 'http://dolfi-dev.eu-central-1.elasticbeanstalk.com/',
  },
  SOCKET_ENDPOINT: {
    DEVELOPMENT: 'http://localhost:8082/',
    PRODUCTION: 'http://dolfi-dev.eu-central-1.elasticbeanstalk.com/',
  },
  GOOGLE_API: {
    KEY: process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyCCZmo8GkgfyVTvGkGi7HzL2Clxz1eC_3k',
    LIBRARIES: ['places'],
  },
  CLAIM_TIME_OUT: 60,
  LANGUAGE_LOCAL_STORAGE_KEY: 'APP_LANGUAGE',
  DEFAULT_LANGUAGE: 
    defaultLang.codeLowerCase && availableLangs.includes(defaultLang.fullCode.toLowerCase()) ? 
    `${defaultLang.code}-${defaultLang.codeLowerCase}` : 
    (availableLangs.includes(defaultLang.codeLowerCase) ? defaultLang.codeLowerCase : availableLangs[0]),
  FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID || 605043347135379,
  MAX_IMAGE_UPLOUD_SIZE_MB: 10,
  MAX_UPLOAD_IMAGE_WIDTH: 1920,
};

function apiURL(): string {
  if (process.env.REACT_APP_BACKEND_URL) {
    return process.env.REACT_APP_BACKEND_URL;
  } else {
    switch (process.env.NODE_ENV) {
      case 'development':
        return CONFIG.API_ENDPOINT.DEVELOPMENT;

      case 'production':
        return CONFIG.API_ENDPOINT.PRODUCTION;

      default:
        return CONFIG.API_ENDPOINT.DEVELOPMENT;
    }
  }
}

export const BACKEND_URL = apiURL();
export const WEBSOCKET_URL = BACKEND_URL;
export default CONFIG;
