import API from '../base/api';
import type { ReqTypeT, ResTypeT } from '../';

export type LoginT = {
  email: string;
};

export type RegisterT = {
  firstName: string;
  lastName: string;
  title?: string;
  salutation: string;
  email: string;
  phoneNumber: string;
  location: { countryCode: string } | null;
  language?: string,
  timeZone?: string
};

export type VerifyT = {
  email: string;
  verificationCode: string;
};

export type UserT = {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  salutation: string;
  id: string;
  phoneNumber: string;
  language: string;
  location: LocationT;
};

export type AuthSuccessResponseT = {
  token: string;
  user: UserT;
};

export type UserInfoT = {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phoneNumber: string;
  language: string;
};

export class UserApi extends API {
  check = (token: string): ResTypeT<UserT> => {
    return this.r({
      method: 'GET',
      url: '/user/me',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  update = (data: UserInfoT): ResTypeT<UserT> => {
    return this.r({
      method: 'PATCH',
      url: '/user',
      data,
    });
  };
}

export default function user(request: ReqTypeT) {
  return new UserApi(request);
}
