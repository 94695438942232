export type RedirectDataT = {
    type: 'paypal'|'card'|'orderAlready'|null,
    url: string|null,
    content?: string|JSX.Element,
    buttonLabel?: string
}

export enum PaymentsE {
    WIRE_TRANSFER = 'WIRE_TRANSFER',
    CREDIT_CARD = 'CREDIT_CARD',
    PAYPAL = 'PAYPAL',
    CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
}
