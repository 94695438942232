import { isEqual } from 'lodash';

import { CartT, ConfigShopT, DeliveryMethodT, MostPopAttrsT, RabatCodeT, StoreFormFiltersT } from "Store/modules/luggageStore/shop";
import { LuggageCustomAttributeT, LuggageCustomOptionT, LuggageProductsSimpleT, LuggageT } from "api/modules/store";
import { ClaimCreateT } from "PageComponents/CreateClaim/CreateClaim/CreateForm/CreateForm";
import { IImage } from "PageComponents/Chat/ReportUploadBox/ReportUploadBox";
import { fileToBinaryText } from "./files";
import { removeSpaces } from "./strings";

export const ShopAttributeColorId = 135;
export const ShopAttributeSizeId = 137;

/*
 * Return product price with voucher 
 **/
export const cPriceVoucher = (price: number, voucher: number): number => {
    if (voucher <= 0) return price;
    const _price = price - voucher;
    if (_price <= 0) return 0;
    return _price;
}

/*
 * Return string size of product
 **/
export const productSize = (product: LuggageT): string => {
    return product.custom_attributes.find((attr: LuggageCustomAttributeT) => attr.attribute_code === 'real_size')?.value||'';
}

/*
 * Return string material of product
 **/
export const productMaterial = (product: LuggageT): string => {
    const material = product.extension_attributes.custom_options.find((attr: LuggageCustomOptionT) => attr.attribute_code === 'material')
    if (material?.value && removeSpaces(material.value) === "ABS/PC") {
        material.id = 6816
    }
    return material?.value || '';
}

/*
 * Return string description of product
 **/
export const productDescription = (product: LuggageT): string => {
    return product.custom_attributes.find((attr: LuggageCustomAttributeT) => attr.attribute_code === 'description')?.value||'';
}

/*
 * Return string url image small
 **/
export const productImageSmallUrl = (product: LuggageT, config: ConfigShopT, simpleProductId: number = 0): string|null => {
    if (simpleProductId > 0){
        const productSimple = product.extension_attributes.simple_products.find((simple: LuggageProductsSimpleT) => simple.id === simpleProductId);
        if (productSimple){
            if (productSimple.media_gallery[0]?.file){
                return config.mediaURL + productSimple.media_gallery[0].file;
            }
            return null;
        }
    }
    if (product.extension_attributes.simple_products[0].media_gallery && Array.isArray(product.extension_attributes.simple_products[0].media_gallery) && product.extension_attributes.simple_products[0].media_gallery.length > 0){
        return config.mediaURL + product.extension_attributes.simple_products[0].media_gallery[0].file;
    }
    return null;
}

/*
 * Return string url image regular
 **/
export const productImageUrl = (product: LuggageT): string => {
    return product.custom_attributes.find((attr: LuggageCustomAttributeT) => attr.attribute_code === 'image')?.value||'';
}

/*
 * Return string url image big
 **/
export const productImageBigUrl = (product: LuggageT): string => {
    return product.custom_attributes.find((attr: LuggageCustomAttributeT) => attr.attribute_code === 'image')?.value||'';
}

/*
 * Return if is differents filters
 **/
export const isFiltersChange = (filters: StoreFormFiltersT, filtersLocal: StoreFormFiltersT): boolean => {

    if (!isEqual(filters.categories, filtersLocal.categories)){
        return true;
    }
    if (!isEqual(filters.colors, filtersLocal.colors)){
        return true;
    }
    if (!isEqual(filters.manufacturers, filtersLocal.manufacturers)){
        return true;
    }
    if (!isEqual(filters.materials, filtersLocal.materials)){
        return true;
    }
    if (!isEqual(filters.price, filtersLocal.price)){
        return true;
    }
    if (!isEqual(filters.sizes, filtersLocal.sizes)){
        return true;
    }
    if (filters.sortBy !== filtersLocal.sortBy){
        return true;
    }

    return false;
}

type resultCalculateCartT = {
    sumProducts: number,
    sumWithVoucher: number,
    sumWithRabatCode: number,
    valueRabat: number,
    total: number,
    isTotalSpecial: boolean
}

export const calculateCart = (cart: CartT, voucher: number, rabat: RabatCodeT, deliveryValue: number, thresholdValue: number): resultCalculateCartT => {

    const minMax = (value: number, max: number = 100, min: number = 0): number => {
        if (value < min) return min;
        if (value > max) return max;
        return value;
    }

    const valueRabat = rabat.value > 0 ? (rabat.type === 0 ? Math.floor(cart.amountSum * rabat.value / 100) : rabat.value) : 0;

    const sumWithVoucher = minMax(cart.amountSum > 0 && voucher > 0 ? cart.amountSum - voucher : cart.amountSum, cart.amountSum);
    const sumWithRabatCode = minMax(cart.amountSum > 0 && valueRabat > 0 ? cart.amountSum - valueRabat : cart.amountSum, cart.amountSum);
    const total = (cart.amountSum - voucher - valueRabat) + deliveryValue;
    const isTotalSpecial = total <= thresholdValue;

    return {
        sumProducts: cart.amountSum,
        sumWithVoucher,
        sumWithRabatCode,
        valueRabat,
        total: total < 0 ? 0 : total,
        isTotalSpecial: isTotalSpecial
    };

}

export const getMostPopularValue = (tag: MostPopAttrsT, returnMaterialId: boolean = true): string => {
    if (typeof tag.value === 'object') {
        return tag.value?.name || ''
    }
    if (returnMaterialId && tag.label.toLowerCase() === 'material') {
        return String(tag.materialId)
    }
    return tag.value
}

export const mapMaterialId = (value: string) => {
    switch (value.trim().toLowerCase()) {
        case 'hardside abs': return 6807;
        case 'hardside polypropylen': return 6812;
        default: return null;
    }
}

export const saveDataNewClaimLocalStorage = async (claimData: ClaimCreateT, termsConcentData: boolean, termsConditions: boolean) => {
    try{

        const newDataSave = {
            claimData: {
                ...claimData,
                photos: {
                    ...claimData.photos,
                    tagFront: await fileToBinaryText(claimData.photos.tagFront as IImage),
                    tagBack: await fileToBinaryText(claimData.photos.tagBack as IImage),
                    airTicket: await fileToBinaryText(claimData.photos.airTicket as IImage),
                    damage1: await fileToBinaryText(claimData.photos.damage1 as IImage),
                    damage2: await fileToBinaryText(claimData.photos.damage2 as IImage),
                    luggageTop: await fileToBinaryText(claimData.photos.luggageTop as IImage),
                    luggageBottom: await fileToBinaryText(claimData.photos.luggageBottom as IImage),
                    luggageFront: await fileToBinaryText(claimData.photos.luggageFront as IImage),
                    luggageBack: await fileToBinaryText(claimData.photos.luggageBack as IImage),
                    luggageLeft: await fileToBinaryText(claimData.photos.luggageLeft as IImage),
                    luggageRight: await fileToBinaryText(claimData.photos.luggageRight as IImage),
                }
            },
            termsConcentData: termsConcentData,
            termsConditions: termsConditions
        };

        // localStorage.setItem("photo1", JSON.stringify({ photo: await fileToBinaryText(claimData.photos.tagFront) }));
        // localStorage.setItem("photo2", JSON.stringify({ photo: await fileToBinaryText(claimData.photos.tagBack) }));
        // localStorage.setItem("photo3", JSON.stringify({ photo: await fileToBinaryText(claimData.photos.airTicket) }));
        // localStorage.setItem("photo4", JSON.stringify({ photo: await fileToBinaryText(claimData.photos.damage1) }));
        // localStorage.setItem("photo5", JSON.stringify({ photo: await fileToBinaryText(claimData.photos.damage2) }));
        // localStorage.setItem("photo6", JSON.stringify({ photo: await fileToBinaryText(claimData.photos.luggageTop) }));
        // localStorage.setItem("photo7", JSON.stringify({ photo: await fileToBinaryText(claimData.photos.luggageBottom) }));
        // localStorage.setItem("photo8", JSON.stringify({ photo: await fileToBinaryText(claimData.photos.luggageFront) }));
        // localStorage.setItem("photo9", JSON.stringify({ photo: await fileToBinaryText(claimData.photos.luggageBack) }));
        // localStorage.setItem("photo10", JSON.stringify({ photo: await fileToBinaryText(claimData.photos.luggageLeft) }));
        // localStorage.setItem("photo11", JSON.stringify({ photo: await fileToBinaryText(claimData.photos.luggageRight) }));

        // localStorage.setItem("draftClaim", JSON.stringify(newDataSave));
        console.log('newDataSave: ', newDataSave);
    } catch(err){
        console.log('error adding data storage: ', err);
    }
}

export const filterDelivery = (paymentMethod: string, deliveries: DeliveryMethodT[], paymentMethods: string[]) => {
    const isPaymentMethodInDelivery = deliveries.some((delivery) =>
        delivery.name.includes(paymentMethod),
    );
    
    if (isPaymentMethodInDelivery) {
        return deliveries.filter((delivery) =>
            delivery.name.includes(paymentMethod),
        );
    } else {
        return deliveries.filter((delivery) => {
            return !paymentMethods.some((payment) => delivery.name.includes(payment));
        });
    }
}