import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import io from 'socket.io-client';
import { WEBSOCKET_URL } from 'Config';

interface SocketContextType {
  send: (event: string, data: any) => void;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocket = () => {
    const context = useContext(SocketContext);
    if (context === undefined) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return context;
};

interface SocketProviderProps {
    children: ReactNode;
    jwtToken: string;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children, jwtToken }) => {

    // @ts-ignore
    const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null);

    useEffect(() => {
        if (jwtToken){
            const newSocket = io(`${WEBSOCKET_URL}user`, {
                query: { token: jwtToken },
            });
            setSocket(newSocket);

            return () => {
                newSocket.close();
            };
        }
    }, [jwtToken]);

    const send = (event: string, data: any) => {
        if (socket) socket.emit(event, data);
    };

    return (
        <SocketContext.Provider value={{ send }}>
            {children}
        </SocketContext.Provider>
    );
};