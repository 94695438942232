import axios, { AxiosInstance, AxiosPromise, AxiosResponse } from 'axios';
import user, { UserApi } from './modules/user';
import claim, { ClaimApi } from './modules/claim';
import store, { StoreApi } from './modules/store';
import caseResolution, { CaseResolutionApi } from './modules/case-resolution';
import claimReport, { ClaimReport } from './modules/claim-report';
import i18n, { I18nApi } from './modules/i18n';
import webPush, { WebPushApi } from './modules/web-push';
import page, { PageApi } from './modules/page';

const REQUEST_TIMEOUT = 5 * 60 * 1000; // 5 minutes

export type ReqTypeT = AxiosInstance;
export type ResTypeT<T> = AxiosPromise<T>;
export type ResponseT<T> = AxiosResponse<T>;

class Client {
  req: AxiosInstance;
  token?: string;
  // modules
  user: UserApi;
  claim: ClaimApi;
  caseResolution: CaseResolutionApi;
  store: StoreApi;
  claimReport: ClaimReport;
  webPush: WebPushApi;
  page: PageApi;
  i18n: I18nApi;

  constructor(baseURL: string) {
    this.req = this.createAxiosInstance(baseURL);
    this.attachInterceptors();

    // Set modules
    this.user = user(this.req);
    this.claim = claim(this.req);
    this.caseResolution = caseResolution(this.req);
    this.store = store(this.req);
    this.claimReport = claimReport(this.req);
    this.i18n = i18n(this.req);
    this.webPush = webPush(this.req);
    this.page = page(this.req);
  }

  createAxiosInstance(baseURL: string): AxiosInstance {
    return axios.create({
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      baseURL,
      timeout: REQUEST_TIMEOUT,
    });
  }

  attachInterceptors() {
    this.req.interceptors.request.use((config) => {
      if (!this.token) {
        return config;
      }

      config.headers = Object.assign({}, config.headers, {
        Authorization: `Bearer ${this.token}`,
      });

      return config;
    });
  }

  setToken(token?: string): void {
    this.token = token;
  }
}

let instance: Client;
export default function api(baseUrl?: string): Client {
  if (!instance && !baseUrl) {
    throw new Error('API was called before it was initialized');
  }

  if (!instance && baseUrl) {
    instance = new Client(baseUrl);
  }

  return instance;
}
