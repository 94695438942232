import { call, takeLatest, put } from 'redux-saga/effects';
import api from 'api';
import { SET_PAGE_CONFIG, setPageConfig } from 'Store/modules/page';

function* getConfig(): any {

  try {
    const { data } = yield call(api().page.getConfig);

    yield put(setPageConfig({
      ...data,
      UPLOAD_MAX_ALLOWED_FILES_SIZE: data.UPLOAD_MAX_ALLOWED_FILES_SIZE * 1000000
    }));

  } catch {
    // Unlucky
    console.log('error reading config');
  }
}

export default function* page(): Generator {
  yield call(getConfig);
  yield takeLatest(SET_PAGE_CONFIG, getConfig);
}
