import React, { useMemo } from 'react';
import cx from 'classnames';
import { ChatMessageGroup, CircularTimer } from 'Components';
import CONFIG from 'Config';
import chatStyles from 'PageComponents/Chat/Chat/Chat.module.scss';

export default function AssessmentTimerBlock({
  assessmentCreatedAt,
  actionTimeout
}: {
  assessmentCreatedAt: string;
  actionTimeout?: () => void
}) {
  const timerMessage = useMemo(() => {
    const now = Date.now();
    const claimCreationTime = new Date(assessmentCreatedAt).getTime();
    const initialTimerValue = +((now - claimCreationTime) / 1000).toFixed(0);

    return [
      {
        className: chatStyles.customMessage,
        render: () => (
          <CircularTimer limit={CONFIG.CLAIM_TIME_OUT} initialTimerValue={initialTimerValue} onTimeOut={() => actionTimeout?.()} />
        ),
      },
    ];
  }, [assessmentCreatedAt, actionTimeout]);

  return (
    <ChatMessageGroup
      as="MessageGroup"
      direction="incoming"
      data={timerMessage}
      className={cx(chatStyles.messageGroup, chatStyles.timer)}
    />
  );
}
