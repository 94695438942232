import { useEffect, useState } from 'react';
import { PhotoKeys } from "../../CreateForm";

const usePhotoKey = (missingType: string) => {
    const [photoKey, setPhotoKey] = useState(PhotoKeys[missingType]);

    useEffect(() => {
        setPhotoKey(PhotoKeys[missingType]);
    }, [missingType]);

    return photoKey;
}
export default usePhotoKey;