import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import type { StateT as ChatDataStateT } from 'Store/modules/claimChat/data';
import { ClaimStatusHistoryItemT } from "api/modules/claim";
import { CLAIM_STATUSES } from "Config/statuses";
import StatusRow from "./StatusRow/StatusRow";
import styles from './CaseStatus.module.scss';
import { AppStateT } from "Store/modules";
import { useSelector } from "react-redux";

type StatusHistoryDataT = {
	status: CLAIM_STATUSES,
	date: Date,
	id: string
}

const remapStatusHistory = (data: ClaimStatusHistoryItemT): StatusHistoryDataT => {
	return {
		status: data.status,
		date: new Date(data.createdAt),
		id: data.id
	}
}

const filterStatusHistory = (data: ClaimStatusHistoryItemT, idx: number, obj: ClaimStatusHistoryItemT[]) => {
	return !(idx > 0 && obj[idx - 1]?.status === data.status)
}

const CaseStatus: React.FC = () => {
	const { t } = useTranslation();
	const { claim } = useSelector<AppStateT, Pick<ChatDataStateT, 'claim'>>(({ claimChat }) => ({ claim: claimChat.data.claim }));
	const formattedDataOrder: StatusHistoryDataT[] = useMemo(() => claim?.statusHistory && Array.isArray(claim.statusHistory) && claim.statusHistory.length > 0 ? [...claim.statusHistory.filter(filterStatusHistory).map(remapStatusHistory)].reverse() : [], [claim?.statusHistory]);

	return (
		<div className={styles.caseStatus}>
			<span className={styles.title}>{t('caseDetails.caseStatus')}</span>
			{formattedDataOrder.map((d, idx) => (
				<StatusRow
					claim={claim}
					date={d.date}
					status={d.status}
					index={idx}
					t={t}
					id={d.id}
					key={`d-${claim.id}-${d.id}`}
				/>
			))}
		</div>
	);
}

export default CaseStatus;
