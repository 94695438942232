import { loading, loaded, error } from 'Helpers/store-async';
import { LuggageT } from 'api/modules/store';

export const STORE_CATALOG_REQUEST = 'STORE_CATALOG_REQUEST';
export const STORE_CATALOG_SUCCESS = 'STORE_CATALOG_SUCCESS';
export const STORE_CATALOG_FAILURE = 'STORE_CATALOG_FAILURE';

export type FetchStoreCatalogRequestT = {
  type: typeof STORE_CATALOG_REQUEST;
  claimId: string,
  storeView: string,
};

export type FetchStoreCatalogSuccessT = {
  type: typeof STORE_CATALOG_SUCCESS;
  data: LuggageT[];
  dataIndexed: LuggageT[];
};

export type FetchStoreCatalogFailureT = {
  type: typeof STORE_CATALOG_FAILURE;
  error: string;
};

export type StateT = {
  data: LuggageT[];
  dataIndexed: LuggageT[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
};

const initialState: StateT = {
  data: [],
  dataIndexed: [],
  loading: false,
  loaded: false,
  error: null,
};

type ActionTypeT =
  | FetchStoreCatalogRequestT
  | FetchStoreCatalogSuccessT
  | FetchStoreCatalogFailureT;

export default function markedClaims(state: StateT = initialState, action: ActionTypeT): StateT {
  switch (action.type) {
    case STORE_CATALOG_REQUEST: {
      return loading<StateT>(state);
    }
    case STORE_CATALOG_SUCCESS: {
      return loaded<StateT, LuggageT[], LuggageT[]>(state, action.data, action.dataIndexed);
    }
    case STORE_CATALOG_FAILURE: {
      return error<StateT>(state, action.error);
    }
    default: {
      return state;
    }
  }
}

export function fetchStoreCatalog(claimId: string, storeView: string): FetchStoreCatalogRequestT {
  return {
    type: STORE_CATALOG_REQUEST,
    claimId,
    storeView
  };
}

export function fetchStoreCatalogSuccess(data: LuggageT[], dataIndexed: LuggageT[]): FetchStoreCatalogSuccessT {
  return {
    type: STORE_CATALOG_SUCCESS,
    data,
    dataIndexed
  };
}

export function fetchStoreCatalogFailure(error: string): FetchStoreCatalogFailureT {
  return {
    type: STORE_CATALOG_FAILURE,
    error,
  };
}
