import cx from 'classnames';

import { useDeviceType } from 'Helpers/responsiveContainers';

import styles from './SortBy.module.scss';

interface Element {
  name: string;
  title: string;
}

interface IProps {
  elems: any;
  state: string;
  actionChange?: (data: string) => void
}

export default function Sortby(props: IProps) {
  const { isNotMobile } = useDeviceType();

  const handleItemClick = (name: string) => {
    props.actionChange?.(name);
  };

  return (
    <ul className={styles.block}>
      {props.elems.map((v: Element, idx: number) => (
        <li
          key={idx}
          className={cx(styles.elem, {
            [styles.notMobileElem]: isNotMobile,
            [styles.top]: idx === 0,
            [styles.bottom]: idx === props.elems.length - 1,
          })}
          onClick={() => handleItemClick(v.name)}
        >
          <div className={styles.label}>{v.title}</div>

          <div
            className={cx(styles.input, {
              [styles.isChecked]: props.state === v.name,
            })}
          >
            <input
              className={styles.hidden}
              type="radio"
              name="sortBy"
              value={v.name}
              id={v.name}
            />
          </div>
        </li>
      ))}
    </ul>
  );
}
