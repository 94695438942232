import { loading, loaded, error, merge } from 'Helpers/store-async';
import config from 'Config';
import type { SupportedLanguageT } from 'api/modules/i18n';

export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const LOAD_ARTICLES_REQUEST = 'LOAD_ARTICLES_REQUEST';
export const LOAD_ARTICLES_SUCCESS = 'LOAD_ARTICLES_SUCCESS';
export const LOAD_ARTICLES_FAILURE = 'LOAD_ARTICLES_FAILURE';
export const LOAD_SUPPORTED_LANGUAGES_REQUEST = 'LOAD_SUPPORTED_LANGUAGES_REQUEST';
export const LOAD_SUPPORTED_LANGUAGES_SUCCESS = 'LOAD_SUPPORTED_LANGUAGES_SUCCESS';
export const LOAD_SUPPORTED_LANGUAGES_FAILURE = 'LOAD_SUPPORTED_LANGUAGES_FAILURE';

export type ChangeLanguageT = {
  type: typeof CHANGE_LANGUAGE;
  data: string;
};

export type LanguageChangedT = {
  type: typeof LANGUAGE_CHANGED;
  data: string;
};

export type SetLanguageT = {
  type: typeof SET_LANGUAGE;
  data: string;
};

export type LoadArticlesRequestT = {
  type: typeof LOAD_ARTICLES_REQUEST;
  data: string;
};

export type LoadArticlesFailureT = {
  type: typeof LOAD_ARTICLES_FAILURE;
  error: string;
};

export type LoadSupportedLanguagesRequestT = {
  type: typeof LOAD_SUPPORTED_LANGUAGES_REQUEST;
};

export type LoadSupportedLanguagesSuccessT = {
  type: typeof LOAD_SUPPORTED_LANGUAGES_SUCCESS;
  data: SupportedLanguageT[];
};

export type LoadSupportedLanguagesFailureT = {
  type: typeof LOAD_SUPPORTED_LANGUAGES_FAILURE;
  error: string;
};

export type ActionTypeT =
  | LanguageChangedT
  | SetLanguageT
  | LoadArticlesRequestT
  | LoadArticlesFailureT
  | LoadSupportedLanguagesRequestT
  | LoadSupportedLanguagesSuccessT
  | LoadSupportedLanguagesFailureT
  | ChangeLanguageT;

export type StateT = {
  language: string;
  articles: {
    loading: boolean;
    loaded: boolean;
    error: string | null;
  };
  supportedLanguages: {
    loading: boolean;
    loaded: boolean;
    error: string | null;
    data: SupportedLanguageT[];
  };
};

const initialState: StateT = {
  language: config.DEFAULT_LANGUAGE,
  articles: {
    loading: false,
    loaded: false,
    error: null,
  },
  supportedLanguages: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
};

export default function i18nReducer(state: StateT = initialState, action: ActionTypeT): StateT {
  switch (action.type) {
    case SET_LANGUAGE: {
      return merge(state, {
        language: action.data,
        articles: {
          current: {
            language: action.data,
            helpCenter: [],
            reportAProblem: [],
            faqArticles: {}
          },
        },
      });
    }
    case LOAD_ARTICLES_REQUEST: {
      return merge(state, {
        articles: {
          loading: true,
        },
      });
    }
    case LOAD_ARTICLES_FAILURE: {
      return {
        ...state,
        articles: error(state.articles, action.error),
      };
    }
    case LOAD_SUPPORTED_LANGUAGES_REQUEST: {
      return {
        ...state,
        supportedLanguages: loading(state.supportedLanguages),
      };
    }
    case LOAD_SUPPORTED_LANGUAGES_SUCCESS: {
      return {
        ...state,
        supportedLanguages: loaded(state.supportedLanguages, action.data),
      };
    }
    case LOAD_SUPPORTED_LANGUAGES_FAILURE: {
      return {
        ...state,
        supportedLanguages: error(state.supportedLanguages, action.error),
      };
    }
    case CHANGE_LANGUAGE: return {
      ...state,
      language: action.data
    }
    default: {
      return state;
    }
  }
}

export function setLanguage(language: string): SetLanguageT {
  return {
    type: SET_LANGUAGE,
    data: language,
  };
}

export function changeLanguage(language: string): ChangeLanguageT {
  return {
    type: CHANGE_LANGUAGE,
    data: language,
  };
}

export function languageChanged(language: string): LanguageChangedT {
  return {
    type: LANGUAGE_CHANGED,
    data: language,
  };
}

export function loadArticlesRequest(language: string): LoadArticlesRequestT {
  return {
    type: LOAD_ARTICLES_REQUEST,
    data: language,
  };
}

export function loadArticlesFailure(error: string): LoadArticlesFailureT {
  return {
    type: LOAD_ARTICLES_FAILURE,
    error,
  };
}

export function loadSupportedLanguagesRequest(): LoadSupportedLanguagesRequestT {
  return {
    type: LOAD_SUPPORTED_LANGUAGES_REQUEST,
  };
}

export function loadSupportedLanguagesSuccess(
  data: SupportedLanguageT[],
): LoadSupportedLanguagesSuccessT {
  return {
    type: LOAD_SUPPORTED_LANGUAGES_SUCCESS,
    data,
  };
}

export function loadSupportedLanguagesFailure(error: string): LoadSupportedLanguagesFailureT {
  return {
    type: LOAD_SUPPORTED_LANGUAGES_FAILURE,
    error,
  };
}
