import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import styles from './HamburgerMenu.module.scss';
import WrapperLink from 'Components/Footer/WrapperLink/WrapperLink';
import { ReactComponent as Logo } from 'Assets/logo.svg';
import { useSelector } from 'react-redux';
import { AppStateT } from 'Store/modules';
import  HelpLink from '../HelpLink/HelpLink'

interface HamburgerMenuProps {
    className?: string;
    menuItems: { name: string, path: string }[];
    onMenuItemClick: (e: React.MouseEvent, type: string) => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ menuItems, onMenuItemClick }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [isIconTransitioning, setIsIconTransitioning] = useState(false);
    const [helpUrl, setHelpUrl] = useState('https://www.dolfi1920.de/faqs.html');
    const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);
    const isLoginUser = !!localStorage.getItem('token');
    
    const languageMap = useMemo(() => ({
        'de': 'german',
        'cs-cz': 'czech',
        'pt-pt': 'portuguese',
        'it': 'italian',
        'hu': 'hungarian',
        'jp': 'japanese',
        'sk': 'slovak',
        'es': 'spanish',
        'kr': 'korean',
        'cn': 'chinese',
        'fr': 'french',
        'lv': 'latvian',
        'nl': 'dutch'
    }), []);
    
    const generateHelpUrl = useCallback(() => {
        const language = actualLanguage;
    
        if (language && languageMap[language]) {
            return `https://www.dolfi1920.de/faqs.html?___store=de_eur_${languageMap[language]}`;
        }
    
        return 'https://www.dolfi1920.de/faqs.html';
    },[languageMap, actualLanguage]);

    useEffect(() => {
        setHelpUrl(generateHelpUrl());
    }, [generateHelpUrl]);

    const timeouts = useRef<any[]>([]); 

    const clearAllTimeouts = () => {
        timeouts.current.forEach(timeout => clearTimeout(timeout));
        timeouts.current = [];
    };

    const renderHamburgerIcon = () => (
        <>
            <div className={styles.hamburgerMenuLines}></div>
            <div className={styles.hamburgerMenuLines}></div>
            <div className={styles.hamburgerMenuLines}></div>
        </>
    );
    
    const renderCloseIcon = () => (
        <div className={styles.closeIcon}>✖</div>
    );

    const toggleMenu = () => {
        if (isMenuOpen) {
            setIsIconTransitioning(true);
            const iconTimeout = setTimeout(() => {
                setIsClosing(true);
                const closeTimeout = setTimeout(() => {
                    setIsMenuOpen(false);
                    setIsClosing(false);setIsClosing(false);
                    setIsOverlayVisible(false);
                    setIsIconTransitioning(false);
                }, 300);
                timeouts.current.push(closeTimeout); 
            }, 300);
            timeouts.current.push(iconTimeout); 
        } else {
            setIsMenuOpen(true);
            setIsOverlayVisible(true);
            setIsIconTransitioning(false);
        }
    };

    useEffect(() => {
        return () => {
            clearAllTimeouts(); 
        };
    }, [generateHelpUrl]);

    return (
        <>
            <div className={`${styles.hamburgerMenu} ${isIconTransitioning ? styles.transitioning : ''}`} onClick={toggleMenu}>
                {isMenuOpen ? renderCloseIcon() : renderHamburgerIcon()}
            </div>
            
            {isOverlayVisible && (
                <div 
                    className={`${styles.overlay} ${isClosing ? styles.closing : ''}`} 
                    onClick={toggleMenu}
                ></div>
            )}
    
            <div className={`${styles.menu} ${isMenuOpen ? styles.open : ''} ${isClosing ? styles.closing : ''}`}>
                <div className={styles.logo}>
                    <Logo />
                </div>
                {isLoginUser && actualLanguage !== 'pl' && actualLanguage !== 'en-us' && (
                   <HelpLink helpUrl={helpUrl} />
                    )}  
                {menuItems.map(({ name, path }) => (
                    <div
                        className={styles.menuItem}
                        key={`menu3_${name}`}
                        onClick={toggleMenu}
                    >
                        <WrapperLink name={name} path={path} actionUrlClick={onMenuItemClick} />
                    </div>
                ))}
           {!isLoginUser && actualLanguage !== 'pl' && actualLanguage !== 'en-us' && (
                   <HelpLink helpUrl={helpUrl} />
                    )}  
            </div>
        </>
    );
};

export default HamburgerMenu;
