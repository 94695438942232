export enum MissingBaseTypesE {
    FILE = 'file',
    TEXT = 'text'
}

export enum StepsE {
    BEGIN = 'begin',
    REFERENCE_NUMBER = 'reference',
    WARNING_ATTACHMENTS = 'warning',
    AIRLINE = 'airline',
    MISSING_DOCUMENTS = 'missingDocuments',
    MISSING_DOCUMENTS_ORIGINAL = 'documentsOriginal',
    WAIT_0 = 'waiter',
    TYPE_OF_LUGGAGE = 'typeof',
    COLOR = 'color',
    MATERIAL = 'material',
    WAIT_1 = 'waiter2',
    DETAILS = 'details',
    DAMAGE = 'damage',
    WAIT_PHOTOS = 'waiterPhotos',
    
    TAG_NUMBER = 'Baggage Tag copy',
    DAMAGE_RAPORT = 'Damage Report Copy',
    EMAIL_FROM_AIRLINE = 'Email from the Airline',
    FLIGHT_TICKET = 'Flight Ticket copy',
    RND_COPY = 'R&D copy',
    BOOKING_CONFIRMATION = 'Booking Confirmation',
    BOARDING_CARD = 'Boarding Card copy',
    DPR_COPY = 'DPR copy',
    ETICKET = 'E-Ticket',
    PURCHASE_RECEIPT_COPY = 'Purchase Receipt copy',
    AIRLINES_AUTHORISATION_LETTER_COPY = "Airline's Authorization Letter copy",
    STAMPED_LEAFLET = 'Stamped leaflet',
    WARRANTY_CARD_COPY = 'Warranty Card copy',
    AIRLINE_PROCESSING_NUMBER = 'Airline Processing Number',
    HAQIBA_COPY = 'Haqiba Copy',
    PERMISSION_FROM_AIRLINE = 'Permision from the airline',
    PO_NUMBER = 'PO Number',

    DAMAGES = 'damages',
    LUGGAGE = 'photosLuggage',
    WAIT_2 = 'waiter3',
    CONTACT = 'contact',
    WAIT_3 = 'aiter4',
    SUMMARY = 'summary',
    FINISH = 'finish'
}

export const dependeciesReferenceNumber = [
    StepsE.DAMAGE_RAPORT,
    StepsE.EMAIL_FROM_AIRLINE,
    StepsE.AIRLINE_PROCESSING_NUMBER // Number from the airline
]

export enum ClaimResolutionSourceEnumE {
    ASSESSOR = 'ASSESSOR',
    AUTOMATIC = 'AUTOMATIC',
    INTERNAL = 'INTERNAL',
    SALESFORCE = 'SALESFORCE',
}