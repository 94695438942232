import { loading, loaded, error } from 'Helpers/store-async';
import type { IMessage } from 'api/modules/claim-report';

export const SET_REPORT_CHAT_MESSAGES = 'SET_REPORT_CHAT_MESSAGES';
export const SEND_REPORT_CHAT_MESSAGES_REQUEST = 'SEND_REPORT_CHAT_MESSAGES_REQUEST';
export const SEND_REPORT_CHAT_MESSAGES_SUCCESS = 'SEND_REPORT_CHAT_MESSAGES_SUCCESS';
export const SEND_REPORT_CHAT_MESSAGES_FAILURE = 'SEND_REPORT_CHAT_MESSAGES_FAILURE';
export const CLEAR_REPORT_CHAT_MESSAGES = 'CLEAR_REPORT_CHAT_MESSAGES';

export type SetReportChatMessagesT = {
  type: typeof SET_REPORT_CHAT_MESSAGES;
  data: IMessage[];
};

export type SendReportChatMessagesRequestT = {
  type: typeof SEND_REPORT_CHAT_MESSAGES_REQUEST;
  data: string;
};

export type SendReportChatMessagesSuccessT = {
  type: typeof SEND_REPORT_CHAT_MESSAGES_SUCCESS;
};

export type SendReportChatMessagesFailureT = {
  type: typeof SEND_REPORT_CHAT_MESSAGES_FAILURE;
  error: string;
};

export type SendReportChatMessagesT = {
  type: typeof SET_REPORT_CHAT_MESSAGES;
  data: IMessage[];
};

export type ClearReportChatMessagesT = {
  type: typeof CLEAR_REPORT_CHAT_MESSAGES;
};

export type ActionTypeT =
  | SetReportChatMessagesT
  | SendReportChatMessagesRequestT
  | SendReportChatMessagesSuccessT
  | SendReportChatMessagesFailureT
  | ClearReportChatMessagesT;

export type StateT = {
  data: IMessage[] | null;
  loading: boolean;
  loaded: boolean;
  error: string | null;
};

const initialState: StateT = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

export default function claimReducer(state: StateT = initialState, action: ActionTypeT): StateT {
  switch (action.type) {
    case SET_REPORT_CHAT_MESSAGES: {
      return {
        ...state,
        data: action.data,
      };
    }
    case CLEAR_REPORT_CHAT_MESSAGES: {
      return initialState;
    }
    case SEND_REPORT_CHAT_MESSAGES_REQUEST: {
      return loading<StateT>(state);
    }
    case SEND_REPORT_CHAT_MESSAGES_SUCCESS: {
      return loaded<StateT>(state);
    }
    case SEND_REPORT_CHAT_MESSAGES_FAILURE: {
      return error<StateT>(state, action.error);
    }
    default: {
      return state;
    }
  }
}

export function setReportChatMessages(data: IMessage[]): SetReportChatMessagesT {
  return {
    type: SET_REPORT_CHAT_MESSAGES,
    data,
  };
}

export function sendAndClearMessages(claimId: string): SendReportChatMessagesRequestT {
  return {
    type: SEND_REPORT_CHAT_MESSAGES_REQUEST,
    data: claimId,
  };
}

export function sendAndClearMessagesSuccess(): SendReportChatMessagesSuccessT {
  return {
    type: SEND_REPORT_CHAT_MESSAGES_SUCCESS,
  };
}

export function sendAndClearMessagesFailure(error: string): SendReportChatMessagesFailureT {
  return {
    type: SEND_REPORT_CHAT_MESSAGES_FAILURE,
    error,
  };
}

export function clearReportChatMessages(): ClearReportChatMessagesT {
  return {
    type: CLEAR_REPORT_CHAT_MESSAGES,
  };
}
