import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CrossIcon } from 'Assets/cross-icon.svg';
import { ReactComponent as SearchIcon } from 'Assets/search.svg';
import { ReactComponent as BackIcon } from 'Assets/back.svg';

import { useMobile } from 'Helpers/responsiveContainers';
import styles from './HeaderLabel.module.scss';
import { useTranslation } from 'react-i18next';
import { AppStateT } from 'Store/modules';
import { SearchInput } from 'Components';
import {
    StateT as AccountSettingsStateT,
    setSearchPanelValue,
    setAccountSettings
} from 'Store/modules/accountSettings';

type MainProps = {
    closeModal: () => void
}

const HeaderLabel: React.FC<MainProps> = ({ closeModal }) => {

    const { t } = useTranslation();
    const [showSearch, setShowSearch] = useState(false);
    const { setting, searchPanelValue } = useSelector<AppStateT, AccountSettingsStateT>((state) => state.accountSettings);
    const dispatch = useDispatch();
    const isMobile = useMobile();
    const showPersonalInfo = useSelector<AppStateT, boolean>((state) => state.page.showPersonalInformation);
    const showHelpCenter = useSelector<AppStateT, boolean>((state) => state.page.showHelpCenter);

    const handleCrossClick = () => {
        closeModal();
    }

    const handleBackClick = () => {
        dispatch(setAccountSettings(''));
    }

    const handleCloseSearch = () => {
        setShowSearch(false);
        dispatch(setSearchPanelValue(''));
    }

    const handleCloseShowSearch = () => {
        setShowSearch(true);
    }

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchPanelValue(event.target.value));
    }

    return (
        <div className={styles.main}>
            {setting ?
                <div className={styles.backLayer}><BackIcon onClick={handleBackClick} className={styles.backIcon} /></div>:
                <>
                    {showSearch ?
                        <SearchInput
                            clear={handleCloseSearch}
                            onChange={handleChangeSearch}
                            value={searchPanelValue}
                            close={handleCloseSearch}
                        />:
                        <>
                            <SearchIcon className={styles.searchIcon} onClick={handleCloseShowSearch} />
                            <label><label>
                                {showHelpCenter ? t('helpCenter.modalTitle') : showPersonalInfo ? t('common.personalInformation') : ''}
                            </label></label>
                        </>
                    }
                </>
            }
            {!isMobile &&
                <CrossIcon className={styles.closeIcon} onClick={handleCrossClick} />
            }
        </div>
    )
}


export default HeaderLabel;