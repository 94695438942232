import { useSelector, useDispatch } from 'react-redux';
import { SupportedLanguageT } from 'api/modules/i18n';
import { useMemo } from 'react';

import { changeLanguage } from 'Store/modules/i18n';
import useTranslate from 'Hooks/useTranslate';
import { AppStateT } from 'Store/modules';

type TranslatedLanguageT = {
    value: string;
    displayName: string;
};

const useChangeLanguage = () => {
    const dispatch = useDispatch();
    const languages = useSelector<AppStateT, SupportedLanguageT[]>(({ i18n }) => i18n.supportedLanguages?.data || []);
    const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);
    const t = useTranslate();

    const sortedTranslatedLanguages: TranslatedLanguageT[] = useMemo(() => {
        return [...languages]
            .map(language => ({
                value: language.value,
                key: language.value,
                displayName: `${t(`languages.${language.value}`)} (${language.text})`,
            }))
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
    }, [languages, t]);
    
    const changeAppLanguage = (languageValue: string) => {
        dispatch(changeLanguage(languageValue));
        localStorage.setItem('language', languageValue);
    };

    return { sortedTranslatedLanguages, actualLanguage, changeAppLanguage };
}

export default useChangeLanguage;
