import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Modal, SearchInput } from 'Components';
import { AccountSettings } from 'PageComponents/Settings';
import {
  Option,
  StateT as AccountSettingsStateT,
  setAccountSettings,
  setSearchPanelValue as setSearchPanelValueAction,
} from 'Store/modules/accountSettings';
import type { AppStateT } from 'Store/modules';
import { ReactComponent as Search } from 'Assets/search.svg';
import styles from './ProfileSettingsModal.module.scss';

interface IProfileSettingsModal {
  open: boolean;
  onClose: (evt: React.MouseEvent<any>) => void;
}

function getTitle(setting: AccountSettingsStateT['setting']) {
  switch (setting) {
    case Option.PERSONAL_INFORMATION: {
      return 'personalInformation.modalTitle';
    }
    case Option.HELP_CENTER: {
      return 'helpCenter.modalTitle';
    }
    case Option.HELP_CENTER_DESCRIPTIONS: {
      return '';
    }
    default: {
      return 'accountSettings.modalTitle';
    }
  }
}

export default function ProfileSettingsModal({ open, onClose }: IProfileSettingsModal) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchPanelIsVisible, setSearchPanelIsVisible] = useState(false);
  const { setting, searchPanelValue } = useSelector<AppStateT, AccountSettingsStateT>(
    (state) => state.accountSettings,
  );
  const helpCenterIsOpen = setting === Option.HELP_CENTER;
  const setSearchPanelValue = useCallback(
    (value: string) => {
      dispatch(setSearchPanelValueAction(value));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!open) {
      setSearchPanelIsVisible(false);
    }
  }, [open]);

  const setSettings = useCallback(
    (setting: AccountSettingsStateT['setting']) => {
      dispatch(setAccountSettings(setting));
    },
    [dispatch],
  );

  function title() {
    if (!searchPanelIsVisible) {
      return t(getTitle(setting));
    }

    if (setting === Option.HELP_CENTER_DESCRIPTIONS) {
      return '';
    }

    return (
      <SearchInput
        placeholder={t('common.search')}
        value={searchPanelValue}
        onChange={(evt) => setSearchPanelValue(evt.target.value)}
        clear={() => {
          setSearchPanelValue('');
        }}
        close={() => {
          setSearchPanelIsVisible(false);
        }}
      />
    );
  }

  const back = useMemo(() => {
    if (!setting || (searchPanelIsVisible && setting !== Option.HELP_CENTER_DESCRIPTIONS)) {
      return undefined;
    }

    let nextSetting: AccountSettingsStateT['setting'];

    switch (setting) {
      case Option.HELP_CENTER_DESCRIPTIONS: {
        nextSetting = Option.HELP_CENTER;
        break;
      }
      default: {
        nextSetting = '';
      }
    }

    return () => {
      setSettings(nextSetting);
    };
  }, [setting, searchPanelIsVisible, setSettings]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      back={back}
      headerRight={
        !searchPanelIsVisible && helpCenterIsOpen ? (
          <div className={styles.searchButtonContainer}>
            <Search onClick={() => setSearchPanelIsVisible(true)} />
          </div>
        ) : undefined
      }
      contentClassName={cx({ [styles.noPadding]: helpCenterIsOpen })}
    >
      <AccountSettings />
    </Modal>
  );
}
