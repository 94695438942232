import React from 'react';

import styles from './Switcher.module.scss';

type Props = {
  value: boolean;
  onChange: () => void;
  name: string;
};

function Switcher({ value, onChange, name }: Props) {
  return (
    <div className={styles.root}>
      <input
        name={name}
        type="checkbox"
        id={`switch-${name}`}
        checked={value}
        onChange={onChange}
      />
      <label htmlFor={`switch-${name}`} />
    </div>
  );
}

export default Switcher;
