import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { SideBarDesktop, SearchPanel } from 'Components';
import { AccountSettings } from 'PageComponents/Settings';
import {
  Option,
  StateT as AccountSettingsStateT,
  setAccountSettings,
  setSearchPanelValue as setSearchPanelValueAction,
} from 'Store/modules/accountSettings';
import { useMobile } from 'Helpers/responsiveContainers';
import { ReactComponent as Search } from 'Assets/search.svg';
import { ReactComponent as LeftArrow } from 'Assets/left-arrow.svg';
import { ReactComponent as CrossIcon } from 'Assets/cross-icon.svg';
import type { AppStateT } from 'Store/modules';

import styles from './AccountSettingsSidebar.module.scss';
import { initialState, setProductsLimit } from 'Store/modules/luggageStore/shop';

function AccountSettingsSidebar() {
  const { t } = useTranslation();
  const [showSearchPanel, setSearchPanel] = useState(false);
  const isMobile = useMobile();

  const dispatch = useDispatch();

  const { setting, searchPanelValue } = useSelector<AppStateT, AccountSettingsStateT>(
    (state) => state.accountSettings,
  );

  const setSearchPanelValue = useCallback(
    (value: string) => {
      dispatch(setSearchPanelValueAction(value));
    },
    [dispatch],
  );

  const writeQuestion = useCallback(
    (evt) => {
      setSearchPanelValue(evt.target.value);
    },
    [setSearchPanelValue],
  );

  function chooseSetting(value: AccountSettingsStateT['setting']) {
    dispatch(setAccountSettings(value));
  }

  function onCloseItems() {
    chooseSetting('');
    setSearchPanel(false);
  }

  const onCloseSearchPanel = useCallback(() => {
    setSearchPanel(false);
    setSearchPanelValue('');
    dispatch(setProductsLimit(initialState.productsLimit));
  }, [setSearchPanelValue, dispatch]);

  function leftSideTitle() {
    switch (setting) {
      case Option.HELP_CENTER:
        if (showSearchPanel) {
          return (
            <SearchPanel
              onChange={writeQuestion}
              value={searchPanelValue}
              maxLength= {40}
              onClose={onCloseSearchPanel}
              autoFocus
              t={t}
            />
          );
        } else {
          return (
            <Search
              className={styles.searchIcon}
              onClick={() => setSearchPanel(!showSearchPanel)}
            />
          );
        }
      case Option.PERSONAL_INFORMATION:
        return isMobile && <LeftArrow onClick={() => chooseSetting(Option.ACCOUNT_SETTINGS)} />;
      case Option.HELP_CENTER_DESCRIPTIONS:
        return <LeftArrow onClick={() => chooseSetting(Option.HELP_CENTER)} />;
    }
  }

  return (
    <SideBarDesktop
      show={!!setting}
      backdrop
      animation="right"
      closeOnClickOutside
      onClose={() => chooseSetting('')}
      containerClassName={styles.accountSettingsSideBar}
      headerTitle={() => (
        <div
          className={cx(styles.sideBarTitle, {
            [styles.sideBarTitleHelpCenter]: setting === Option.HELP_CENTER,
            [styles.sideBarTitleWithSearch]: showSearchPanel && setting === Option.HELP_CENTER,
          })}
        >
          <div className={styles.leftIcon}>{leftSideTitle()}</div>
          {!showSearchPanel && setting !== Option.HELP_CENTER_DESCRIPTIONS && t(setting)}
          <CrossIcon className={styles.crossIconHeader} onClick={onCloseItems} />
        </div>
      )}
    >
      <AccountSettings />
    </SideBarDesktop>
  );
}

export default AccountSettingsSidebar;
