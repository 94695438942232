import React, { useCallback, useRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
import cx from 'classnames';

import { useDeviceType } from 'Helpers/responsiveContainers';
import { CreateClaim } from 'PageComponents/CreateClaim';
import styles from './CreateClaimPage.module.scss';

export default function CreateClaimPage() {
  const { t } = useTranslation();
  const createClaimRef = useRef<{ close: () => void | null }>(null);
  const { isMobile } = useDeviceType();
  const navigate = useNavigate();

  const navigateBackToList = useCallback(() => {
    navigate('/');
  }, [navigate]);

  function close() {
    createClaimRef.current?.close();
  }

  useEffect(() => {
    if (!isMobile){
      navigate('/createDesktop');
    }
  }, [isMobile, navigate]);

  const createClaimTitleData = useMemo(() => ({
      subtitle: 'createClaim.subtitle',
      buttonRight: (buttonClassName: string) => (
        <button className={cx(buttonClassName, styles.backButton)} onClick={close}>
          {t('common.close')}
        </button>
      ),
    }),
  [t]);

  return (
    <>

      <CreateClaim
        controlRef={createClaimRef}
        onClose={navigateBackToList}
        titleData={createClaimTitleData}
        draftType="compact"
      />

    </>
  );
}
