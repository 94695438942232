import './configure-api';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import App from 'Router/Router';
import store from 'Store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';
import { createRoot } from 'react-dom/client';

import './styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from "@sentry/react";
import { getEnvName } from 'Helpers/env';
import React from "react";

const envName = getEnvName();

const container = document.getElementById('root');
const root = createRoot(container!);

window.addEventListener('load', () => {
    i18n().initialize(store);
    const AppWithTranslations = withTranslation()(App);

    if (envName) {
        Sentry.init({
            dsn: "https://758c9ac0152446f1a04ddb7c001f0a02@o4504281603702784.ingest.sentry.io/4504315279704064",
            environment: envName,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
            enabled: true,
            // debug: true
        });
    }

    root.render(
        <Provider store={store}>
            <BrowserRouter>
                <AppWithTranslations useSuspense={false} />
            </BrowserRouter>
        </Provider>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
});

// OFB 44
