import { LocalStateT } from "./ClientAdress";

const Store = (state: LocalStateT, action: any): LocalStateT => {

    switch(action.type){

        case 'SET_DATA':

            return {
                ...state,
                [action.dataType]: action.data
            }

    }

    return state;
}

export default Store;