import react, { useCallback, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import styles from './HelpCenter.module.scss';

import { AccountSettingsValueT, setAccountSettings } from 'Store/modules/accountSettings';
import HelpCenterMain from 'PageComponents/Settings/HelpCenter/HelpCenter';
import SideBarDesktop from 'Components/SideBarDesktop/SideBarDesktop';
import AnimatedModal from 'Components/AnimatedModal/AnimatedModal';
import { useDeviceType } from 'Helpers/responsiveContainers';
import { changeShowHelpCenter } from 'Store/modules/page';
import HeaderLabel from './Header/HeaderLabel';
import { AppStateT } from 'Store/modules';

const HelpCenter: react.FC = () => {

    const showHelpCenter = useSelector<AppStateT, boolean>((state) => state.page.showHelpCenter);
    const setting = useSelector<AppStateT, AccountSettingsValueT>((state) => state.accountSettings.setting);
    const dispatch = useDispatch();
    const { isMobile } = useDeviceType();

    const onCloseAction = useCallback(() => {
        dispatch(changeShowHelpCenter(false));
    }, [dispatch])

    const chooseSetting = useCallback((value: AccountSettingsValueT) => {
        dispatch(setAccountSettings(value));
    }, [dispatch])

    useLayoutEffect(() => {
        if (!isMobile && showHelpCenter){
            const listenerOutside = (event: any) => {

                // Check if this click is not on helpCenter menu link
                const elementMenuLanguages = document.querySelector('.helpCenterLink');
                if (!(elementMenuLanguages && elementMenuLanguages.contains(event.target))){

                    const elementModaBackdrop = document.querySelector('[class^="SideBarDesktop_backdrop"]');
                    const elementModaContent = document.querySelector('[class^="SideBarDesktop_root"]');
                    if (elementModaBackdrop && elementModaContent){
                        if (!elementModaBackdrop.contains(event.target) && !elementModaContent.contains(event.target)) {
                            return onCloseAction();
                        }
                    }
                }

            }
        
            const listenerKeyEscape = (event: KeyboardEvent) => {
                if (event.key === 'Escape'){
                    onCloseAction();
                }
            }

            document.addEventListener("mousedown", listenerOutside);
            document.addEventListener("keydown", listenerKeyEscape);

            return () => {
                document.removeEventListener("mousedown", listenerOutside);
                document.removeEventListener("keydown", listenerKeyEscape);
            }
        }
    }, [isMobile, showHelpCenter, onCloseAction]);

    if (isMobile){
        return <AnimatedModal
            children={(
                <div className={styles.helpCenter}>
                    <HeaderLabel closeModal={onCloseAction} />
                    <div className={cx(styles.helpCenterMobileBody, setting !== '' ? styles.helpCenterBodyContent : null)}>
                        <HelpCenterMain
                            chooseSetting={chooseSetting}
                            setting={setting}
                        />
                    </div>
                </div>
            )}
            onClose={onCloseAction}
            open={showHelpCenter}
            closeOnClickOutside
            containerClassName={styles.messageModalContainer}
        />
    }

    return (
        <>
            <SideBarDesktop
                children={(
                    <div className={styles.helpCenter}>
                        <HeaderLabel closeModal={onCloseAction} />
                        <div className={cx(styles.helpCenterBody, setting !== '' ? styles.helpCenterBodyContent : null)}>
                            <HelpCenterMain
                                chooseSetting={chooseSetting}
                                setting={setting}
                            />
                        </div>
                    </div>
                )}
                show={showHelpCenter}
                backdrop
                animation={"right"}
                closeOnClickOutside
                onClose={onCloseAction}
                backdropClassName={styles.backgropDesktop}
                containerClassName={styles.contentDesktop}
            />
        </>)
}

export default HelpCenter;