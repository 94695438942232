import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ShopStateT } from 'Store/modules/luggageStore/shop';
import type { LuggageImagesT, LuggageT } from 'api/modules/store';
import styles from './LuggageGalleryCarousel.module.scss';
import NoImageSvgPath from 'Assets/no-image.svg';
import { AppStateT } from 'Store/modules';
import MyCarousel from 'Components/Carousel/Carousel';

interface ILuggageGalleryCarousel {
    luggage: LuggageT;
}

export default function LuggageGalleryCarousel(props: ILuggageGalleryCarousel) {

    const { actualProductSimple, configShop } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);

    const images = useMemo(() => {
        if (actualProductSimple){
            return [...actualProductSimple?.media_gallery.map((photo: LuggageImagesT) => {
                return photo.file;
            })];
        }
        return [];
    }, [actualProductSimple]);

    return (
        <div className={styles.root}>
            <MyCarousel
                className={styles.carousel}
                imageWrapClassName={styles.carouselItem}
                images={images.map((img) => img ? configShop.mediaURL + img : NoImageSvgPath)}
            />

        </div>
    );
}
