import React from "react";
import cx from 'classnames';

import { ColorConfigT, MostPopAttrsT } from "Store/modules/luggageStore/shop";
import style from './MostPopularTag.module.scss';

import { ReactComponent as IcoClose } from 'Assets/tag_close.svg';
import { ReactComponent as IcoAdd } from 'Assets/tag_plus.svg';
import { setCategoryTranslateName, setColorTranslateName, setMaterialTranslateName } from "Helpers/strings";
import { getMostPopularValue } from "Helpers/store";


type MainProps = {
    tag: MostPopAttrsT,
    selected?: boolean,
    actionClick: (tag: MostPopAttrsT) => void,
    colorsPalette: ColorConfigT[],
    t: (data: string) => string
}

const MostPopularTag: React.FC<MainProps> = ({ tag, selected, actionClick, colorsPalette, t }) => {

    const getTagInfo = () => {
        if (tag.label.toLowerCase() === 'bag size') return <><span className={style.labelInfo}>{t('shop.size')}:</span> {getMostPopularValue(tag)}</>;
        if (tag.label.toLowerCase() === 'bag color'){
            const color = colorsPalette.find(c => c.name === getMostPopularValue(tag));
            if (color){
                return <><span className={style.labelColor} style={{ backgroundColor: color.value }} /> {t(setColorTranslateName(getMostPopularValue(tag)))}</>;
            }
            return <>{t(setColorTranslateName(getMostPopularValue(tag)))}</>;
        }
        if (tag.label === 'Categories'){
            return <>{t(setCategoryTranslateName(getMostPopularValue(tag)))}</>
        }

        if (tag.label === 'Material'){
            return <>{t(setMaterialTranslateName(getMostPopularValue(tag, false)))}</>
        }

        return <>{getMostPopularValue(tag)}</>;
    }

    return (
        <div className={cx(style.tag, { [style.selected]: selected })} onClick={() => actionClick(tag)}>
            <span className={style.title}>
                {getTagInfo()}
                {selected ?
                    <IcoClose className={style.tagIco} />:
                    <IcoAdd className={style.tagIco} />
                }
            </span>
        </div>
    )
}

export default React.memo(MostPopularTag);