import React, { useState, useRef, useImperativeHandle, useLayoutEffect, useCallback } from 'react';

import InfoWarningClose from '../InfoWarningClose/InfoWarningClose';
import { CLAIM_STORE_KEY, clearDraftData } from 'Helpers/draft';
import { useMobile } from 'Helpers/responsiveContainers';
import CreateForm from './CreateForm/CreateForm';

type TitleDataT = {
  subtitle: string;
  buttonLeft?: (className: string) => React.ReactNode;
  buttonRight?: (className: string) => React.ReactNode;
};

interface ICreateClaim {
  onClose?: () => void;
  titleData?: TitleDataT;
  controlRef?: React.Ref<any>;
  draftType?: 'modal' | 'compact';
}

const CreateClaim: React.FC<ICreateClaim> = (props) =>  {
  const { onClose } = props;
  const [draftModalIsOpen, setDraftModalIsOpen] = useState(false);
  const [actionSaveDraft, setActionSaveDraft] = useState(false);
  // const { draftData } = useDraftData(CLAIM_STORE_KEY);
  const formRef = useRef<HTMLFormElement | null>(null);
  const isMobile = useMobile();
  const [contentHeight, setContentHeight] = useState(0);
  const [canShowClose, setCanShowClose] = useState(true);

  const setContentHeightAction = useCallback(() => {
    if (!isMobile) return setContentHeight(document.body.scrollHeight >= 1000 ? 780 : document.body.scrollHeight - 70);
    setContentHeight(document.body.scrollHeight - 30);
  }, [setContentHeight, isMobile]);

  useLayoutEffect(() => {
      window.addEventListener('resize', setContentHeightAction);
      setContentHeightAction();
      return () => {
        window.removeEventListener('resize', setContentHeightAction);
      }
  }, [isMobile, setContentHeightAction]);

  useImperativeHandle(props.controlRef, () => ({
    close: () => {
      if (!canShowClose){
        return onClose?.();
      }
      if (formRef.current?.isDirty()) {
        setDraftModalIsOpen(true);
      } else {
        close();
      }
    },
  }));

  function close() {

    if (draftModalIsOpen) {
      setDraftModalIsOpen(false);
    }

    if (typeof onClose === 'function') {
      // onClose();
      setDraftModalIsOpen(true);
    }
  }

  function saveDraft() {
    // setDraftModalIsOpen(false);
    // onClose?.();
    setActionSaveDraft(true);
  }

  function discardDraft() {
    setDraftModalIsOpen(false);
    onClose?.();
    clearDraftData(CLAIM_STORE_KEY);
  }

  const closeAction = () => {
    setDraftModalIsOpen(false);
    setActionSaveDraft(false);
    onClose?.();
  }

  return (
    <>

      <CreateForm
        height={contentHeight}
        mobile={isMobile}
        onClose={closeAction}
        actionSaveDraft={actionSaveDraft}
        setCanShowClose={setCanShowClose}
      />

      {/* <SaveDataAsDraftModal
        open={draftModalIsOpen}
        onClose={() => setDraftModalIsOpen(false)}
        onConfirm={saveDraft}
        onDeny={discardDraft}
        type={props.draftType}
      /> */}
      <InfoWarningClose
        open={draftModalIsOpen}
        onClose={() => setDraftModalIsOpen(false)}
        onConfirm={saveDraft}
        onDeny={discardDraft}
        type={props.draftType}
      />
    </>
  );
}

export default React.memo(CreateClaim);