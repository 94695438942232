import { useCallback } from 'react';

const ORIGINAL_TAG_TO_TRANSLATION: { [key: string]: string } = {
    BOARDING_CARD_ORIGINAL: 'reportTags.boardingCardOriginal',
    FLIGHT_TICKET_ORIGINAL: 'reportTags.flightTicketOriginal',
    BAGGAGE_TAG_ORIGINAL: 'reportTags.baggageTagOriginal',
    DPR_ORIGINAL: 'reportTags.dprOriginal',
};

const normalizeTagOrName = (tagOrName: string) => {
    return tagOrName.trim().toLowerCase().replace(/ /g, '_');
};
  
const useReportTagTranslation = (t: (data: string) => string) => {
    const getTitle = useCallback((tag: string) => {
        const normalizedTag = normalizeTagOrName(tag);
        const translationKey = ORIGINAL_TAG_TO_TRANSLATION[normalizedTag.toUpperCase()];
        return translationKey ? t(translationKey) : tag;
    }, [t]);
  
    return getTitle;
};
  
export default useReportTagTranslation;
