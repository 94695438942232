import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import ImgDots from 'Assets/pattern-dots.png';
import ImgLines from 'Assets/pattern-lines.png';
import ImgFlowers from 'Assets/pattern-flowers.png';

import { ColorsT, StateLocalT } from '../../CreateForm';
import style from './Colors.module.scss';

type MainProps = {
    state: StateLocalT,
    setValidStep: (result: boolean) => void,
    updateClaim: (dataType: string, data: any) => void,
    mobile?: boolean
}

const Colors: React.FC<MainProps> = ({ state, setValidStep, updateClaim, mobile }) => {

    const { t } = useTranslation();
    const [luggageColors, setLuggageColors] = useState<ColorsT[]>([]);

    const setColorLang = useMemo(() => (colorName: string): string => {
        switch(colorName.toLowerCase()){
            case 'black': return t('createClaim.colorBlack');
            case 'white': return t('createClaim.colorWhite');
            case 'grey': return t('createClaim.colorGrey');
            case 'blue': return t('createClaim.colorBlue');
            case 'red': return t('createClaim.colorRed');
            case 'violet': case 'purple': return t('createClaim.colorViolet');
            case 'yellow': return t('createClaim.colorYellow');
            case 'beigne': case 'beige': return t('createClaim.colorBeige');
            case 'brown': return t('createClaim.colorBrown');
            case 'green': return t('createClaim.colorGreen');
            case 'multi-coloured': case 'multiColoured': case 'multi color': case 'multicolor': case 'multi': return t('createClaim.colorMulti');
            case 'pattern': return t('createClaim.colorPattern');

            default: return colorName;
        }
    }, [t]);

    useEffect(() => {
        setLuggageColors(Object.values(state.colors).map(o => {
            return { slug: o.slug, values: o.values, title: setColorLang(o.slug) };
        }));
    }, [state.colors, setColorLang]);

    useEffect(() => {
        setValidStep(!!state.claimData.luggageColor);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.claimData.luggageColor]);

    const actionChangeLuggageType = useCallback((slug: string) => {
        if (slug === state.claimData.luggageColor) return updateClaim('luggageColor', '');
        updateClaim('luggageColor', slug);
    }, [state.claimData.luggageColor, updateClaim]);

    const selectPattern = useMemo(() => (index: number) => {
        switch(index){
            case 0: return ImgDots;
            case 1: return ImgLines;
            default: return ImgFlowers;
        }
    }, []);

    return (
        <div className={style.root}>
            <div className={cx(style.layerElements, { [style.mobile]: mobile })}>
                {luggageColors.map(color => {

                    return (
                        <div
                            className={cx(style.item, { [style.selected]: color.slug === state.claimData.luggageColor })}
                            onClick={() => actionChangeLuggageType(color.slug)}
                            key={`lugg_${color.slug}`}
                            id={'select_new_claim_color_' + color.slug.toLowerCase()}
                        >
                            <div className={style.colors}>
                                {color.values.map((colorHex: string, index: number) => {
                                    // const  = value;
                                    return (
                                        <div
                                            className={cx(style.color, { [style.border]: colorHex.toLowerCase() === '#ffffff' })}
                                            style={{ backgroundColor: colorHex, backgroundImage: color.slug.toLowerCase() === 'pattern' ? `url(${selectPattern(index)})` : 'none'}}
                                            key={`color_${color.slug}_${colorHex}_${index}`}
                                        />
                                    )
                                })}
                            </div>
                            <div className={style.label}>{color.title}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
export default Colors;