import React, { useEffect, useRef, useState } from 'react';

import ImageGallery from "Components/ImageGallery/ImageGallery";
import Loading from "Components/Loading/Loading";
import { getUrlOfImage } from "services/api";

type MainPropsT = {
    imageKey: string,
    className?: string
}

const ImageDecrypt: React.FC<MainPropsT> = ({ imageKey, className }) => {
    const [url, setUrl] = useState('');
    const isMounted = useRef(true);
    const [openGalleryIndex, setOpenGalleryIndex] = useState(-1);

    useEffect(() => {
        // Ustawiamy flagę na true, gdy komponent jest montowany
        isMounted.current = true;

        if (imageKey){
            getUrlOfImage(imageKey, (result, newUrl) => {
                if (isMounted.current && result && newUrl){
                    setUrl(newUrl);
                }
            });
        }

        return () => {
            isMounted.current = false;
        };
    }, [imageKey]);

    if (!url){
        return <Loading size={50} />;
    }

    return (
        <>
            <img src={url} className={className} alt="" onClick={() => setOpenGalleryIndex(0)} style={{ cursor: 'pointer' }} />
            {(openGalleryIndex >=0) &&
                <ImageGallery
                    actionClose={() => setOpenGalleryIndex(-1)}
                    images={[url]}
                    startIndex={openGalleryIndex}
                />
            }
        </>
    );
}
export default React.memo(ImageDecrypt);