import { useSelector, useDispatch } from 'react-redux';

import { initialState, setCategotysId, setProductsLimit, setShopFilters, ShopStateT, StoreCategoryT } from 'Store/modules/luggageStore/shop';
import { VoucherBlock, StoreFormDesktop } from 'PageComponents/LuggageStore';
import styles from './StoreDesktopMenu.module.scss';
import { LuggageT } from 'api/modules/store';
import { SideBarDesktop } from 'Components';
import { AppStateT } from 'Store/modules';

type MainPropsT = {
  catalog: LuggageT[] | null;
}

function StoreDesktopMenu(props: MainPropsT) {
  const { filtersLocal, filters, configShop, categorysId } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
  const dispatch = useDispatch();

  const handleSetFilters = () => {
    dispatch(setShopFilters({
      ...filtersLocal,
      search: filters.search
    }));
    dispatch(setProductsLimit(initialState.productsLimit));

    if (filtersLocal.categories.length > 0){
      const cats = configShop.categories[0].children_data.find((c: StoreCategoryT) => c.id === categorysId[0]);
      if (cats){
        dispatch(setCategotysId([...[cats.id], ...filtersLocal.categories.map((nr: string) => +nr)]));
      }
    }

  }

  return (
    <>
      <SideBarDesktop animation="left" show={true} containerClassName={styles.sideBarDesktop}>
        <div className={styles.root}>
          <div className={styles.content}>
            <VoucherBlock />
            <StoreFormDesktop onSubmit={handleSetFilters} catalog={props.catalog} />
          </div>
        </div>
      </SideBarDesktop>
    </>
  );
}

export default StoreDesktopMenu;
