import { fetchClaimListSuccess, fetchMissongDocs, MissingDocExampleT } from "Store/modules/claim";
import runApi, { ApiSendMethods, ResultApiTypeT } from "api/base/runApi";
import { setClaimArchived } from "Store/modules/page";
import { ClaimT } from "api/modules/claim";

export const getUrlOfImage = (key: string, callBack: (result: boolean, url?: string) => void) => {
    if (!key) return callBack(false);
    runApi(`media/file/${encodeURIComponent(key)}`, {}, (r: ResultApiTypeT) => {
        if (r.result) {
            callBack(true, r.data.url);
        } else {
            callBack(false);
        }
    }, ApiSendMethods.get);
}

export const getUrlOfImages = (keys: string[], callBack: (result: boolean, data?: { key: string, url: string }[]) => void) => {
    if (keys.length === 0) return callBack(false);
    runApi(`media/files/get`, keys.filter(k => !!k), (r: ResultApiTypeT) => {
        if (r.result) {
            callBack(true, r.data);
        } else {
            callBack(false);
        }
    }, ApiSendMethods.post);
}

export const getClaims = (isArchived: boolean, dispatch: (data: any) => void, callBack?: (result: boolean, data: ClaimT[]) => void) => {
    runApi(`${isArchived ? 'archive/' : ''}claim`, {}, (r: ResultApiTypeT) => {
        if (r.result){
            dispatch(fetchClaimListSuccess(r.data));
            dispatch(setClaimArchived(isArchived));
        } else {
            dispatch(fetchClaimListSuccess([]));
        }
        dispatch(fetchMissongDocs());
        callBack?.(r.result, r?.data || []);
    }, ApiSendMethods.get);
}

export const getExamplesMissingDocuments = (claimId: string, callBack: (result: boolean, data?: MissingDocExampleT[]) => void) => {
    runApi(`claim/${claimId}/missing-documents`, {}, (r: ResultApiTypeT) => {
        if (r.result) {
            callBack(true, r.data);
        } else {
            callBack(false);
        }
    }, ApiSendMethods.get);
}

export type LoginDataT = {
    message: string,
    verificationCode: string
}

export const loginUser = (email: string, callBack: (result: boolean, data?: LoginDataT) => void) => {
    runApi(`user/login`, { email }, (r: ResultApiTypeT) => {
        callBack?.(r.result, r?.data);
    }, ApiSendMethods.post);
}