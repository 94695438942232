import React from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { LoadableContent } from 'Components';
import config from 'Config';

import GoogleApiInitializedContext from 'Helpers/googleApiContext';

interface IWithGoogleApi {
  children: React.ReactNode | React.ReactNode[];
}

export default function WithGoogleApi(props: IWithGoogleApi) {
  const { isLoaded: googleApiLoaded } = useJsApiLoader({
    googleMapsApiKey: config.GOOGLE_API.KEY,
    libraries: config.GOOGLE_API.LIBRARIES as any,
  });

  return (
    <GoogleApiInitializedContext.Provider value={googleApiLoaded}>
      <LoadableContent loading={!googleApiLoaded}>{props.children}</LoadableContent>
    </GoogleApiInitializedContext.Provider>
  );
}
