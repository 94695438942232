import { OrderT } from 'Store/modules/luggageStore/shop';
import { CartDataSendT } from 'Helpers/useDataCart';
import type { ReqTypeT, ResTypeT } from '../';
import API from '../base/api';

type ColorsT = {
  color: string;
  image: string;
};

export type LuggageImagesT = {
  title?: string,
  file: string,
  url_small?: string,
}

export type LuggageProductOptionsValues = { 
  extension_attributes: { 
    option: { id: number, value: string }
  },
  value_index: number 
}

export type LuggageProductSwatchOptionsValues = { 
  extension_attributes: { 
    option_swatch: { id: number, swatch_value: string, type: number, value: string }
  },
  value_index: number 
}

export type LuggageExtConfigurableProductOptionsT = {
  id: number,
  attribute_id: number,
  label: string,
  // values: { value_index: string }[],
  values: LuggageProductOptionsValues[] | LuggageProductSwatchOptionsValues[],
}

export type LuggageCategoryT = {
  position: number,
  category_id: number
}

export type LuggageCustomOptionT = {
  attribute_code: string,
  id: number,
  value: string
}

export type LuggageExtAttrsT = {
  category_links: LuggageCategoryT[],
  configurable_product_options: LuggageExtConfigurableProductOptionsT[],
  simple_products: LuggageProductsSimpleT[],
  custom_options: LuggageCustomOptionT[]
}

export type LuggageProductsSimpleColorT = {
  id: number,
  swatch_value: string,
  value: string,
  type?: number,
  group_swatch_value?: string,
  group_value: string
}

export type LuggageProductsCartT = {
  id: number,
  name: string,
  sku: string,
  price: number,
}

export type LuggageProductsSimpleT = {
  base_currency: string,
  base_price: number,
  id: number,
  name: string,
  sku: string,
  currency: string,
  price: number,
  photos: LuggageImagesT[],
  color: LuggageProductsSimpleColorT,
  manufacturer?: string,
  media_gallery: {
    type?: string,
    file: string
  }[]
}

export type LuggageProductsLinksT = {
  sku: string,
  link_type: string,
  id: number,
  extension_attributes: {
    linked_product: {
      id: number
    }
  }
}

export type LuggageCustomAttributeT = {
  attribute_code: string,
  value: string
}

export type LuggageGalleryT = {
  id: number,
  media_type: string,
  label: string|null,
  position: number,
  disabled: boolean,
  types: string[],
  file: string,
  url?: string
}

export interface LuggageNewT{
  id: number,
  sku: string,
  name: string,
  price: number,
  status: number,
  visibility: number,
  type_id: string,
  created_at: string,
  weight: number,
  extension_attributes: LuggageExtAttrsT,
  product_links?: LuggageProductsLinksT[],
  custom_attributes: LuggageCustomAttributeT[]
}

export interface LuggageT extends LuggageNewT{
  // model: string,
  // typeModel: string,
  // sale: number,
  // totalPrice: number,
  // size: string[],
  // material: string,
  // capacity: string,
  // productDescription: string,
  // imgUrl: string,
  // moreImg: string[],
  // type: string,
  colors: {
    color: string,
    image: string,
  }[]
}

export type LuggageOldT = {
  imgUrl: string;
  moreImg: string[];
  model: string;
  typeModel: string;
  type: string;
  sale: number;
  title: string;
  // discountPrice: number;
  totalPrice: number;
  material: string;
  weight: string;
  capacity: string;
  size: string[];
  productDescription?: string;
  created_at: string,
  id: string;
  colors: ColorsT[];
};

export class StoreApi extends API {
  // catalog = (): ResTypeT<LuggageT[]> => {
  //   return this.r({
  //     method: 'GET',
  //     url: 'store/catalog',
  //   });
  // };
  catalog = (claimId: string) => {
    return this.r({
      method: 'GET',
      url: `claim/${claimId}/shop/products`,
    });

    // return Promise.resolve({ data: luggageList });
  };

  // zapisanie stanu koszyka
  saveCart = (claimId: string, data: CartDataSendT) => {
    return this.r({
      method: 'POST',
      url: `claim/${claimId}/shop/cart`,
      data
    });

    // return Promise.resolve({ data: luggageList });
  };

  // odczytanie stanu koszyka
  readCart = (claimId: string): ResTypeT<CartDataSendT> => {
    return this.r({
      method: 'GET',
      url: `claim/${claimId}/shop/cart`,
    });

    // return Promise.resolve({ data: luggageList });
  };

  // categories = () => {
  //   // this.r({
  //   //   method: 'GET',
  //   //   url: 'shop/categories',
  //   // });

  //   return Promise.resolve({ data: categories });
  // };

  // fetchLuggageDetails = (luggageId: string): ResTypeT<LuggageT> => {
  //   return this.r({
  //     method: 'GET',
  //     url: `store/catalog/${luggageId}`,
  //   });
  // };

  fetchShopConfig = (claimId: string) => {
    return this.r({
      method: 'GET',
      url: `claim/${claimId}/shop/config`,
    });
    // return Promise.resolve({ data: shopConfig });
  };

  fetchRabatCode = (claimId: string, code: string) => {
    // return Promise.resolve({ data: { value: 15, code, type: 0, name: 'Example rabat code name' } });

    return this.r({
      method: 'POST',
      url: `/claim/${claimId}/shop/coupon`,
      data: { coupon: code }
    });
  };

  fetchOrderSend = (order: OrderT) => {
    // return Promise.resolve({ data: { success: true } });

    return this.r({
      method: 'POST',
      url: `/shop/order`,
      data: { order }
    });
  };

}

export default function store(request: ReqTypeT) {
  return new StoreApi(request);
}
