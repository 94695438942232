import { useSelector } from "react-redux";

import { CartProductT, CartT, ClientDataT, ConfigShopT, DeliveryMethodOrderT, DeliveryOrderT, DeliveryT, RabatCodeT, ShopStateT } from "Store/modules/luggageStore/shop";
import { StateT as UserStateT } from 'Store/modules/user';
import { ClaimDetailsT } from "api/modules/claim";
import { AppStateT } from "Store/modules";
import { calculateCart } from "./store";
import { StoreConfigT } from "Store/modules/page";
import { LuggageT } from "api/modules/store";

const useDataCart = () => {
    const { delivery, cart, rabatCode, voucher, claim, termsAccept, configShop } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);

    return getDataCart(claim, rabatCode, voucher, cart, delivery, termsAccept, configShop);
};

export type CartDataSendT = {
    claimId: string,
    rabat: RabatCodeT,
    voucher: number,
    sumOrder: number,
    totalOrder: number,
    products: CartProductT[],
    delivery: DeliveryT,
    termsAccept: boolean,
    note?: string
}

export const getDataCart = (claim: ClaimDetailsT | null, rabatCode: RabatCodeT, voucher: number, cart: CartT, delivery: DeliveryT, termsAccept: boolean, storeConfig: ConfigShopT, note = ''): CartDataSendT => {

    const amountsData = calculateCart(cart, voucher, rabatCode, delivery.deliveryMethod?.value||0, storeConfig.paymentSkipThreshold?.amount);

    return {
        claimId: claim?.id || '',
        rabat: {
            code: rabatCode.result ? rabatCode.code : '',
            value: rabatCode.result ? rabatCode.value : 0,
            type: rabatCode.type || 0,
            name: rabatCode.name || '',
        },
        voucher: voucher,
        sumOrder: amountsData.sumProducts,
        totalOrder: amountsData.total,
        products: cart.products,
        delivery: delivery,
        termsAccept,
        note
    }
}

export type ProductOrderT = {
    magentoSku: string,

    amount: number,
    quantity: number,

    name: string,
    color: string,
    size: string,
    weight: number,
    material: string,
    manufacturer: string,
    magentoConfigurableId: number,
    magentoConfigurableSku: string,
    magentoId: number
}

export interface ICreditCard {
    pan?: number;
    cvc2?: string;
    expireMonth?: number;
    expireYear?: number;
    firstname?: string,
    lastname?: string,
}
// export interface ICreditCard {
//     firstName: string,
//     lastName: string
//     cardNumber?: string,
//     cvv?: string,
//     exp?: string,
// }

export interface IPaypal {
    id?: string,
}

export interface ILocation {
    formattedAddress?: string,
    address: string,
    city: string,
    country: string,
    countryCode: string,
    zipCode: string,
    flor: string,
    coords?: {
        lat: number,
        lng: number,
    },
}

export interface ILocationBillong {
    formattedAddress?: string,
    address: string,
    city: string,
    country: string,
    countryCode: string,
    zipCode: string,
    flor: string,
    coords?: {
        lat: number,
        lng: number,
    },
}

export interface IShipment {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    deliveryMethod: DeliveryMethodOrderT,
    location: ILocation,
    companyName: string,
    note: string,
    // companyTaxNumber: string,
}

export type BillingT = {
    firstName: string,
    lastName: string,
    companyName: string,
    companyTaxNumber: string,
    email: string,
    phoneNumber: string,
    location: ILocationBillong
}

export type OrderDataSendT = {
    amount: number,
    amountVoucher: number,
    discountAmount: number,
    discountRabat: number,
    discountName: string,
    discountCode: string,
    amountTotal: number,
    magentoStoreView: string,
    products: ProductOrderT[],
    currency: string,
    payment: {
        selectedType: string,
        creditCard: ICreditCard,
        paypal?: IPaypal,
    },
    shipment: IShipment,
    billing: BillingT,
}


export const getDataOrder = (rabatCode: RabatCodeT, voucher: number, cart: CartT, delivery: DeliveryOrderT, userData: UserStateT['data'], storeConfig: StoreConfigT, note: string, catalog: LuggageT[]): OrderDataSendT => {

    const amountsData = calculateCart(cart, voucher, rabatCode, delivery.deliveryMethod.value, storeConfig.paymentSkipThreshold?.amount);

    const getBillingData = (): BillingT => {
        let obj: ClientDataT = delivery.clientAddress;
        if (!delivery.clientAddressDeliveryOn) {
            obj = delivery.clientAddressDelivery;
        }
        return {
            firstName: obj.name,
            lastName: obj.surname,
            companyName: obj.companyName,
            companyTaxNumber: obj.companyTaxNumber,
            email: obj.email || userData.email,
            phoneNumber: obj.phoneNumber || userData.phoneNumber,
            location: {
                address: delivery.clientAddress.street,
                city: delivery.clientAddress.city,
                country: delivery.clientAddress.country,
                countryCode: delivery.clientAddress.countryCode || userData.location.countryCode,
                zipCode: delivery.clientAddress.zipCode,
                formattedAddress: `${delivery.clientAddress.zipCode} ${delivery.clientAddress.city}, ${delivery.clientAddress.street}${delivery.clientAddress.apt ? ' ' + delivery.clientAddress.apt : ''}, ${delivery.clientAddress.country}`,
                coords: userData?.location.coords,
                flor: delivery.clientAddress.apt
            }
        }
    }

    const creditCardDates = delivery.payment.creditCard.exp.split('/');

    let products: CartProductT[] = [];
    cart.products.forEach((product: any) => {
        for (let i = 1; i <= product.quantity; i++) {

            if (!product.configurableId || !product.configurableSku || !product.productSimpleManufacturer) {
                const _product = catalog.find(ca => ca.extension_attributes.simple_products.some(sp => sp.id === product.productSimpleId));
                if (_product) {
                    const simpleProduct = _product.extension_attributes.simple_products.find(sp => sp.id === product.productSimpleId)
                    if (simpleProduct) {
                        if (simpleProduct.manufacturer) product.manufacturer = simpleProduct.manufacturer;
                        if (_product.id) product.magentoConfigurableId = _product.id;
                        if (_product.sku) product.magentoConfigurableSku = _product.sku;
                        product.productSimpleSku = simpleProduct.id;
                        product.magentoId = simpleProduct.id;
                        product.productSimpleName = simpleProduct.name;
                        product.productSimpleColor = simpleProduct.color?.swatch_value || '';
                        product.productSimpleManufacturer = simpleProduct?.manufacturer || '';
                    }
                }
            }

            products.push({
                ...product,
                quantity: 1
            });
        }
    });

    return {
        amount: amountsData.sumProducts,
        amountVoucher: voucher,
        discountAmount: rabatCode.value,
        discountRabat: amountsData.valueRabat || 0,
        discountName: rabatCode.name,
        discountCode: rabatCode.code,
        amountTotal: amountsData.isTotalSpecial ? 0 : +amountsData.total.toFixed(2),
        magentoStoreView: storeConfig?.storeView || '',
        currency: storeConfig.currency.code,
        products: products.map(product => {

            const productData = {
                magentoSku: product.productSimpleSku,
                amount: product.amountOrigin,
                quantity: product.quantity,
                name: product.productSimpleName,
                color: product.productSimpleColor,
                size: product.size || null,
                weight: product.weight,
                material: product.material,
                manufacturer: product.productSimpleManufacturer,
                magentoConfigurableId: product.configurableId,
                magentoConfigurableSku: product.configurableSku,
                magentoId: product.productSimpleId
            }
            if (!productData.size) {
                delete productData.size;
            }

            return productData;
        }),
        payment: delivery.paymentChoose ? {
            selectedType: delivery.paymentChoose as string,
            creditCard: delivery.paymentChoose === 'CREDIT_CARD' ? {
                cvc2: delivery.payment.creditCard?.cvv,
                expireMonth: isNaN(+creditCardDates[0]) ? 0 : +creditCardDates[0],
                expireYear: +(creditCardDates?.[1] || 0),
                pan: +delivery.payment.creditCard.cardNumber.replaceAll("-", ""),
                firstname: userData.firstName,
                lastname: userData.lastName
            } : null,
        } : null,
        shipment: {
            phoneNumber: userData?.phoneNumber || '',
            email: userData?.email || '',
            firstName: delivery.clientAddress.name,
            lastName: delivery.clientAddress.surname || '',
            deliveryMethod: delivery.deliveryMethod,
            companyName: delivery.clientAddress.companyName,
            location: {
                address: delivery.clientAddress.street,
                city: delivery.clientAddress.city,
                country: delivery.clientAddress.country,
                countryCode: delivery.clientAddress.countryCode || userData.location.countryCode,
                zipCode: delivery.clientAddress.zipCode,
                formattedAddress: `${delivery.clientAddress.zipCode} ${delivery.clientAddress.city}, ${delivery.clientAddress.street}${delivery.clientAddress.apt ? ' ' + delivery.clientAddress.apt : ''}, ${delivery.clientAddress.country}`,
                coords: userData?.location.coords,
                flor: delivery.clientAddress.apt
            },
            note
        },
        billing: getBillingData(),
    }
}

export default useDataCart;