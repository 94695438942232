import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Portal } from 'react-portal';
import cx from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ReactComponent as CloseIcon } from 'Assets/cross-icon.svg';
import styles from './AnimatedModal.module.scss';
import { useDeviceType } from 'Helpers/responsiveContainers';

interface IAnimatedModal {
  open: boolean;
  node?: Element | null;
  children: React.ReactNode | string;
  onClose: (evt: React.MouseEvent<HTMLElement>) => void;
  closeOnClickOutside?: boolean;
  containerClassName?: string;
  contentClassName?: string;
  onOpened?: () => void;
  onClosed?: () => void;
  hideCloseButton?: boolean;
  scrollableContentRef?: React.RefObject<HTMLElement>;
  renderCloseButtonInModalHeader?: boolean;
}

const backdropClassNames = {
  enter: styles['backdrop-enter'],
  enterActive: styles['backdrop-active-enter'],
  exit: styles['backdrop-exit'],
  exitActive: styles['backdrop-active-exit'],
};

const contentClassNames = {
  enter: styles['container-enter'],
  enterActive: styles['container-active-enter'],
  exit: styles['container-exit'],
  exitActive: styles['container-active-exit'],
};

const MODAL_TIMEOUT = 175;

/* Animation Sequence:
 *  open => render => animation in
 *  close => animation out => unmount
 * */
function useModalAnimationController(open: boolean) {
  const [render, setRender] = useState<boolean>(open);
  const [animationIn, setAnimationIn] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  // open/animation out
  useEffect(() => {
    if (open) {
      setRender(true);
    } else {
      setAnimationIn(false);
    }
  }, [open]);

  // Animation in
  useLayoutEffect(() => {
    if (render) {
      setTimeout(() => {
        setAnimationIn(true);
      });
    }
  }, [render]);

  const onClosed = useCallback(() => {
    setRender(false);
  }, []);

  return {
    render,
    animationIn,
    modalRef,
    onClosed,
  };
}

export default function AnimatedModal(props: IAnimatedModal) {
  const {
    open,
    closeOnClickOutside,
    contentClassName,
    containerClassName,
    onOpened,
    onClosed: propsOnClosed,
    scrollableContentRef,
    renderCloseButtonInModalHeader = false,
  } = props;
  const backdropRef = useRef(null);
  const { isNotMobile } = useDeviceType();
  const { render, animationIn, modalRef, onClosed } = useModalAnimationController(open);

  // awaryjne odblokowanie przewijania
  useLayoutEffect(() => {
    return () => {
      const element = document.querySelector("#contentModal");
      if (element){
        enableBodyScroll(element);
      }
    }
  }, []);

  useEffect(() => {
    if (!scrollableContentRef) {
      return;
    }

    const cleanUpElement = scrollableContentRef.current;

    const tOut = setTimeout(() => {
      const element = scrollableContentRef.current;

      if (!element) {
        return;
      }

      if (render && open) {
        disableBodyScroll(element);
      } else if (render && !open) {
        enableBodyScroll(element);
      }
    }, 0);

    return () => {
      clearTimeout(tOut);
      if (cleanUpElement) {
        enableBodyScroll(cleanUpElement);
      }
    };
  }, [scrollableContentRef, open, render, modalRef]);

  const handleModalClose = useCallback(() => {
    if (propsOnClosed) {
      propsOnClosed();
    }

    onClosed();
  }, [propsOnClosed, onClosed]);

  if (!render) {
    return null;
  }

  return (
    <Portal node={props.node}>
      <CSSTransition
        in={animationIn}
        timeout={MODAL_TIMEOUT}
        unmountOnExit
        nodeRef={backdropRef}
        classNames={backdropClassNames}
      >
        <div
          className={styles.backdrop}
          onClick={closeOnClickOutside ? props.onClose : undefined}
          ref={backdropRef}
        />
      </CSSTransition>

      <CSSTransition
        in={animationIn}
        timeout={MODAL_TIMEOUT}
        onEntered={onOpened}
        onExited={handleModalClose}
        unmountOnExit
        nodeRef={modalRef}
        classNames={contentClassNames}
      >
        <div
          className={cx(styles.container, containerClassName, {
            [styles.containerDesktop]: isNotMobile,
          })}
          ref={modalRef}
        >
            <div className={cx(styles.contentContainer, contentClassName)}>
              {!renderCloseButtonInModalHeader && !props.hideCloseButton && (
                <div className={styles.closeRow}>
                  <button className={styles.closeButton} onClick={props.onClose}>
                    <CloseIcon />
                  </button>
                </div>
                )}
              {props.children}
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
}
