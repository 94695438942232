import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { ReactComponent as IcoEdit } from 'Assets/info-pencil.svg';

import { MissingDocumentT, RequiredDocumentT, StateLocalT } from '../../CreateForm';
import { IImage } from 'PageComponents/Chat/ReportUploadBox/ReportUploadBox';
import { ReportUploadBox } from 'PageComponents/Chat';
import style from './MissingDocuments.module.scss';
import { fixDocumentName } from 'Helpers/strings';
import { getUrlMissingDoc } from 'Helpers/claim';
import { makeSlug } from 'Helpers/strings';

const maxFiles = 2;

type MainPropsT = {
    state: StateLocalT,
    setValidStep: (result: boolean) => void,
    updateClaim: (dataType: string, data: any) => void,
    countryCode: string
}

const MissingDocuments: React.FC<MainPropsT> = ({ state, setValidStep, updateClaim, countryCode }) => {

    const { t } = useTranslation();

    useEffect(() => {
        let valid = true;
        state.requierdMissingDocuments.filter(doc => doc.Obligatory__c && doc.Url__c).forEach(req => {
            const documents = state.claimData.missingDocuments.filter(data => data.requireDocument.Name === req.Name);
            if (documents.length === 0) valid = false;
        });
        setValidStep(valid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.requierdMissingDocuments, state.claimData.missingDocuments]);

    const actionNewImage = useCallback((req: RequiredDocumentT, file: IImage|null) => {
        if (state.claimData.missingDocuments.some(doc => doc.requireDocument.Name === req.Name && doc.document.name === file?.name)) return;
        updateClaim('missingDocuments', [
            ...state.claimData.missingDocuments,
            {
                document: file,
                requireDocument: req
            }
        ]);
    }, [state.claimData.missingDocuments, updateClaim]);

    const actionChangeImage = (req: RequiredDocumentT, oldFile: MissingDocumentT, newFile: IImage) => {
        updateClaim('missingDocuments', state.claimData.missingDocuments.map(doc => {
            if (doc.document.file === oldFile.document.file && doc.requireDocument.Name === req.Name){
                doc.document = newFile;
            }
            return doc;
        }));
    }

    const actionRemoveImage = useCallback((req: RequiredDocumentT, doc: MissingDocumentT) => {
        updateClaim('missingDocuments', state.claimData.missingDocuments.filter(_doc => !(_doc.requireDocument.Name === req.Name && _doc.document.name === doc.document.name)));
    }, [state.claimData.missingDocuments, updateClaim])

    const downloadFile = useCallback((url: string) => {
        window.open(url);
    }, []);

    return (
        <div className={style.root}>

            {state.requierdMissingDocuments.filter(doc => doc.Obligatory__c && doc.Url__c).map(req => {

                const documents = state.claimData.missingDocuments.filter(data => data.requireDocument.Name === req.Name);

                const url = getUrlMissingDoc(req?.Url__c || '', countryCode);

                return (
                    <div className={style.group} key={`req_name_${req.Name}`}>
                        <div className={style.content}>
                            {t('createClaim.missContent')}
                        </div>
                        <div className={style.header}>
                            <div className={style.title}>{fixDocumentName(req.Missing_Document_Type__c, t)}</div>
                            {url &&
                                <button className={style.download} onClick={() => downloadFile(url)}>
                                    {t('createClaim.missDownload')}
                                </button>
                            }
                        </div>
                        <div className={style.body}>                            
                            {documents.length > 0 &&
                                <>
                                    {documents.map((doc, idx) => {
                                        return (
                                            <div className={cx(style.document, { [style.documentMargin]: documents.length >=3 })} key={`req_name_${req.Name}_${doc.document.file.lastModified}`}>
                                                <ReportUploadBox
                                                    value={doc.document}
                                                    removable={true}
                                                    onChange={(file) => actionChangeImage(req, doc, file as IImage)}
                                                    onRemove={() => actionRemoveImage(req, doc)}
                                                    name={req.Name}
                                                    hideName
                                                    noInternalState
                                                    id={'file_new_claim_missed_document_' + makeSlug(doc.requireDocument.Missing_Document_Type__c.toLocaleLowerCase()) + '_' + idx}
                                                />
                                            </div>
                                        )
                                    })}
                                </>
                            }
                            {documents.length <= maxFiles &&
                                <div className={cx(style.addNew, { [style.addNewOne]: documents.length === 0 })}>
                                    <ReportUploadBox
                                        value={undefined}
                                        removable={false}
                                        onChange={(file) => actionNewImage(req, file)}
                                        name={req.Name}
                                        hideName
                                        noInternalState
                                        id={'file_new_claim_missed_document_' + makeSlug(req.Missing_Document_Type__c)}
                                    />
                                    {documents.length === 0 &&
                                        <div className={style.firstTitleUpload}>
                                            {t('createClaim.missPleaseUpload', { fileName: fixDocumentName(req.Name, t) })}.
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                )
            })}

        </div>
    )
}

type IcoProps = {
    action: () => void
}

export const InfoEdit: React.FC<IcoProps> = ({ action }) => {
    return (
        <div className={style.ico} onClick={action}><IcoEdit /></div>
    )
}

export default React.memo(MissingDocuments);