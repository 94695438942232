import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import createSagaMiddleware from "redux-saga";

import { rootReducer, browserHistory } from "./modules/index";
import sagas from "./sagas";

const saga = createSagaMiddleware();
const routerMiddleware = createRouterMiddleware(browserHistory);
const composedMiddleware = composeWithDevTools(
  applyMiddleware(saga, routerMiddleware)
);
const store = createStore(rootReducer, composedMiddleware);

sagas.forEach(saga.run);

export default store;

// only for build MC 13_.03.2023 x2
