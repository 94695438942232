import React, { Fragment } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { ChatMessageGroup, Button, LoadableContent } from 'Components';
import { RepairLocationT } from 'api/modules/case-resolution';
import createGoogleMapsLink from 'Helpers/createGoogleMapsLink';
import { getWorkingPeriod } from 'Helpers/date';
import { useDeviceType } from 'Helpers/responsiveContainers';
import MapPinIcon from 'Assets/map-pin.svg';
import { ReactComponent as PinIcon } from 'Assets/pin.svg';
import { ReactComponent as PhoneIcon } from 'Assets/phone.svg';
import { ReactComponent as TimeClockIcon } from 'Assets/time-clock-circle.svg';
import type { AppStateT } from 'Store/modules';
import type { StateT as RepairStateT } from 'Store/modules/claimChat/repair';
import chatStyles from 'PageComponents/Chat/Chat/Chat.module.scss';
import styles from './RepairDropOffLocation.module.scss';

interface IRepairDropOffLocation {
  location: RepairLocationT;
  onPickUpSelected: () => void;
  onDropOffSelected: () => void;
}

const MAP_CONTAINER_STYLE = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
};

function RepairDropOffLocation({
  location,
  onPickUpSelected,
  onDropOffSelected,
}: IRepairDropOffLocation) {
  const { t } = useTranslation();
  const { isNotMobile } = useDeviceType();
  const [lat, lng] = location.coordinates;
  const loading = useSelector<AppStateT, RepairStateT['loading']>(
    ({ claimChat }) => claimChat.repair.loading,
  );

  return (
    <div className={styles.root}>
      <LoadableContent loading={loading}>
        <ChatMessageGroup
          as="MessageGroup"
          direction="incoming"
          avatar="bot"
          data={[
            {
              render: () => (
                <Trans i18nKey="repair.dropOffMessage" values={{ distance: location.distance }}>
                  <span className={styles.distance} />
                </Trans>
              ),
              className: chatStyles.messageIn,
            },
          ]}
          className={styles.messageGroup}
        />
        <div className={cx({ [styles.content]: isNotMobile })}>
          <div className={styles.mapContainer}>
            <GoogleMap
              mapContainerStyle={MAP_CONTAINER_STYLE as any}
              center={{ lat, lng }}
              zoom={17}
              // @ts-ignore
              options={{
                disableDefaultUI: true,
              }}
            >
              <a href={createGoogleMapsLink(lat, lng)} className={styles.viewOnMaps}>
                {t('repair.viewOnGoogleMaps')}
              </a>
              <Marker position={{ lat, lng }} icon={MapPinIcon} />
            </GoogleMap>
          </div>

          <div className={styles.repairDropOffInfo}>
            <p>
              <PinIcon /> {location.address}
            </p>

            <div>
              <TimeClockIcon />
              <div>
                {getWorkingPeriod(location.workingHours).map((period: string) => (
                  <Fragment key={period}>
                    {period} <br />
                  </Fragment>
                ))}
              </div>
            </div>

            <p>
              <PhoneIcon /> {location.telephoneNumber}
            </p>
          </div>

          <div className={styles.actions}>
            {/* // xxx */}
            <Button color="primary" onClick={onPickUpSelected}>
              {t('repair.pickItUp')}
            </Button>

            {/* <Button color="primary" onClick={onDropOffSelected}>
              {t('repair.illDropItOff')}
            </Button> */}
          </div>
        </div>
      </LoadableContent>
    </div>
  );
}

export default RepairDropOffLocation;
