import { useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { StoreDesktopMainScreen, StoreDesktopMenu } from 'PageComponents/LuggageStore';
import type { StateT as CatalogStateT } from 'Store/modules/luggageStore/catalog';
import { AppStateT } from 'Store/modules';

function LuggageStoreContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: catalog } = useSelector<AppStateT, CatalogStateT>(({ store }) => store.catalog);

  const navigateToDetails = useCallback((luggageId: number) => {
      navigate(`${location.pathname}/luggage-details/${luggageId}`);
  }, [navigate, location]);

  return (
    <>
      <StoreDesktopMenu catalog={catalog} />
      <StoreDesktopMainScreen
        sliderConfig={{
          width: 262,
          height: 451,
          visibleSlides: 3.5,
          arrows: true,
          isIntrinsicHeight: false,
        }}
        onItemSelect={navigateToDetails}
        catalog={catalog}
      />
    </>
  );
}

export default LuggageStoreContainer;
