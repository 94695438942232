import { TypeOptions } from 'react-toastify'
import cx from 'classnames';
import React from 'react';

import { ReactComponent as SuccessIcon } from 'Assets/okey.svg';
import { ReactComponent as ErrorIcon } from 'Assets/close.svg';
import style from './IcoNotify.module.scss';

type MainProps = {
    type?: TypeOptions,
}

const IcoNotify: React.FC<MainProps> = (props) => {

    const { type } = props;

    const classAdd = {
        [style.success]: type === 'success',
        [style.info]: type === 'info' || type === 'default',
        [style.error]: type === 'warning' || type === 'error'
    };


    if (type === 'success'){
        return (
            <SuccessIcon
                className={cx(style.icoRootNotify, classAdd)}
            />
        )
    }

    if (type === 'info' || type === 'default'){
        return (
            <SuccessIcon
                className={cx(style.icoRootNotify, classAdd)}
            />
        )
    }

    return (
        <ErrorIcon
            className={cx(style.icoRootNotify, classAdd)}
        />
    )

}

export default IcoNotify;