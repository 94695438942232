import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import cx from 'classnames';

import { ReactComponent as CloseIcon } from 'Assets/cross-icon.svg';

import { changeModalArticle, dataModalArticleT } from 'Store/modules/page';
import SideBarDesktop from 'Components/SideBarDesktop/SideBarDesktop';
import AnimatedModal from 'Components/AnimatedModal/AnimatedModal';
import { StateT as UserStateT } from '../../Store/modules/user';
import { useDeviceType } from 'Helpers/responsiveContainers';
import styles from './ArticleModal.module.scss';
import { AppStateT } from 'Store/modules';
import { isHtml } from 'Helpers/strings';

/*
 * Article show modal 
 * data from showModalArticle (pure data title and content or from prismic)
 */
const ArticleModal: React.FC = () => {

    const showModalArticle = useSelector<AppStateT, dataModalArticleT|null>((state) => state.page.showModalArticle);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const { isMobile } = useDeviceType();
    const { loggedIn } = useSelector<AppStateT, UserStateT>(({ user }) => user);
    const { t } = useTranslation();

    useEffect(() => {
        setShowModal(!!showModalArticle);
    }, [showModalArticle]);

    const closeAction = useCallback(() => {
        dispatch(changeModalArticle(null));
    }, [dispatch]);

    useLayoutEffect(() => {
        if (showModal){

            const listenerOutside = (event: any) => {

                // Check if this click is not on language menu link
                const elementMenuLanguages = document.querySelector('.changeLanguageLink');
                if (!(elementMenuLanguages && elementMenuLanguages.contains(event.target))){

                    const elementModaBackdrop = document.querySelector('[class^="SideBarDesktop_backdrop"]');
                    const elementHeaderDesktop = document.querySelector('[class^="HeaderDesktop_root"]');
                    if (elementModaBackdrop && elementHeaderDesktop){
                        if (elementModaBackdrop.contains(event.target) || elementHeaderDesktop.contains(event.target)) {
                            return closeAction();
                        }
                    }

                }

            }
        
            const listenerKeyEscape = (event: KeyboardEvent) => {
                if (event.key === 'Escape'){
                    closeAction();
                }
            }

            document.addEventListener("mousedown", listenerOutside);
            document.addEventListener("keydown", listenerKeyEscape);

            return () => {
                document.removeEventListener("mousedown", listenerOutside);
                document.removeEventListener("keydown", listenerKeyEscape);
            }
        }
    }, [showModal, closeAction]);

    const Content = useCallback(() => {
        if (showModalArticle?.pagesContentKey){
            const data = t(showModalArticle.pagesContentKey);
            return isHtml(data) ? parse(t(showModalArticle.pagesContentKey)) : t(showModalArticle.pagesContentKey);
        }
    }, [showModalArticle, t])

    const Title = useCallback(() => {
        return showModalArticle?.title ? t(showModalArticle.title) : (showModalArticle?.title || '');
    }, [showModalArticle, t])

    if (isMobile){
        return <AnimatedModal
            children={<div className={styles.bodyMobile}>
                <div className={styles.headerTitleTop}>
                    {Title()}
                </div>
                <div className={styles.contentMobile}>
                    {Content()}
                </div>
            </div>}
            onClose={closeAction}
            open={showModal}
            closeOnClickOutside
        />
    }

    return (
        <>
            <SideBarDesktop
                children={(
                    <div className={styles.content}>
                        {Content()}
                    </div>
                )}
                show={showModal}
                backdrop
                animation="right"
                closeOnClickOutside
                onClose={closeAction}
                backdropClassName={cx(styles.backgropDesktop, {[styles.backgropDesktopLogin]: loggedIn})}
                headerTitle={() => (
                    <div className={styles.sideBarTitle}>
                        <div className={styles.title}>
                            {Title()}
                        </div>
                        <div className={styles.closeIconHeader} onClick={closeAction}>
                            <CloseIcon />
                        </div>
                    </div>
                )}
                containerClassName={cx(styles.reportProblemSidebarDesktop, {[styles.reportProblemSidebarDesktopLogin]: loggedIn})}
            />
        </>)
}

export default ArticleModal;