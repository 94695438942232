import { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { AppStateT } from "Store/modules";

import type { StateT as ClaimListStateT } from 'Store/modules/claim';

const useFindReferenceNumber = (referenceNumbers: string[]) => {
    const claimList = useSelector<AppStateT, ClaimListStateT>(({ claim }) => claim);
    const findClaim = useMemo(() => () => claimList.data.find(cl => referenceNumbers.includes(cl.referenceNumber)), [claimList, referenceNumbers]);

    const [claimFound, setClaimFound] = useState(null);

    useEffect(() => {
        setClaimFound(findClaim());
    }, [claimList, referenceNumbers, findClaim]);

    return claimFound;
}
export default useFindReferenceNumber;