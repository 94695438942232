import { call, put, takeLatest } from 'redux-saga/effects';

import { addSecondsToDate } from 'Helpers/date';
import {
  LOG_OUT,
} from 'Store/modules/authorization';
import {
  SetUserT,
  SET_USER,
  setUser,
  setAppInitialized,
  resetUser,
} from 'Store/modules/user';
import api from 'api';

function setAuthorizationTokenSaga({ token }: SetUserT) {
  localStorage.setItem('token', token);
  api().setToken(token);
}

function* checkAuth() {
  try {
    const token = localStorage.getItem('token');

    if (token) {
      const { data: user } = yield api().user.check(token);
      const dataEnd = localStorage.getItem('tokenEnd');

      if (dataEnd && new Date() >= new Date(dataEnd)){
        localStorage.removeItem('tokenEnd');
        localStorage.removeItem('token');
        yield put(resetUser());
      } else {
        const secondsLogout = 3600 * 24 * 365;
        localStorage.setItem('tokenEnd', addSecondsToDate((new Date()).toISOString(), secondsLogout));
        yield put(setUser(user, token));
      }

    }
    // yield put(setBlockServerError(null));
  } catch {
    // yield put(setBlockServerError(null));
  } finally {
    yield put(setAppInitialized());
  }
}

function* logoutSaga() {
  try {
    const MARKED_CLAIMS = localStorage.getItem('MARKED_CLAIMS');
    localStorage.removeItem('token');
    if (MARKED_CLAIMS) {
      localStorage.setItem('MARKED_CLAIMS', MARKED_CLAIMS);
    }
    yield put(resetUser());
  } catch {}
}

export default function* authorization(): Generator {
  yield takeLatest(SET_USER, setAuthorizationTokenSaga);
  yield takeLatest(LOG_OUT, logoutSaga);

  yield call(checkAuth);
}
