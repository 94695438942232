import React from 'react';

import { ConfigShopT } from 'Store/modules/luggageStore/shop';
import styled from './WireTransfer.module.scss';

type MainPropsT = {
   configWireTransfer: ConfigShopT['payment']['wireTransferData']
   t: (data: string, obj?: any) => string,
   currency: string,
   orderId?: string,
}

const WireTransfer: React.FC<MainPropsT> = ({ t, orderId, configWireTransfer }) => {

   return (
        <div className={styled.root}>
            <div className={styled.header}>
                  {t('shop.WireTransferDescription')}
            </div>
            {configWireTransfer.fullNameOfTheRecipient &&
               <div className={styled.line}>
                  <span>{t('shop.WireTransferRecipient')}:</span> {configWireTransfer.fullNameOfTheRecipient}
               </div>
            }
            {configWireTransfer.agency &&
               <div className={styled.line}>
                  <span>{t('labels.shopWireTransferAgency')}:</span>  {configWireTransfer.agency}
               </div>
            }
            {configWireTransfer.bankName &&
               <div className={styled.line}>
                  <span>{t('shop.WireTransferBank')}:</span> {configWireTransfer.bankName}
               </div>
            }
            {configWireTransfer.accountNumber &&
               <div className={styled.line}>
                  <span>{t('shop.accountNumber')}:</span> {configWireTransfer.accountNumber}
               </div>
            }
             {configWireTransfer.iban &&
               <div className={styled.line}>
                  <span>{t('labels.iban')}:</span> {configWireTransfer.iban}
               </div>
             }
             {configWireTransfer.swift &&
               <div className={styled.line}>
                  <span>{t('labels.swiftBic')}:</span>  {configWireTransfer.swift}
               </div>
             }
             {configWireTransfer.vatNumber &&
               <div className={styled.line}>
                  <span>{t('labels.vatNumber')}:</span>  {configWireTransfer.vatNumber}
               </div>
             }
             {orderId &&
               <div className={styled.line}>
                  <span>{t('shop.WireTransferOrderId')}:</span> {orderId}
               </div>
             }
            {/* 
             <div className={styled.infoBottom}>
                <InfoIcon />
                {t('shop.WireTransferExtraFee', { currency, amount: 4.99 })}
             </div> */}
        </div>
    );
}
export default WireTransfer;