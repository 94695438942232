import { DeliveryT } from 'Store/modules/luggageStore/shop';

function formatChunk(chunk: string | undefined, sequenceChunk: boolean = true) {
  if (!chunk) {
    return '';
  }

  const prefix = sequenceChunk ? ', ' : '';

  return `${prefix}${chunk}`;
}

export default function formatDeliveryAddress(data: DeliveryT | null) {
  if (!data) {
    return '';
  }

  const sequence = [
    data.clientAddress.country,
    data.clientAddress.street,
    data.clientAddress.city,
    data.clientAddress.zipCode,
  ];

  return sequence.reduce((acc, chunk) => {
    if (!chunk) {
      return acc;
    }
    acc += formatChunk(chunk, Boolean(acc));
    return acc;
  }, '');
}
