import { FaqT } from "PageComponents/ClaimMessage/ClaimMessage";

export const dataFaq: FaqT[] = [
    { question: 'help_center.hc_acv', answer: 'helpCenterMain.hc_Acv', grouped: 'assessment', answerType: 'text', assignStatus: ['ASSESSMENT_SELECTION']  },
    { question: 'chatContact.faqOrderQuestion', answerType: 'order', grouped: 'assessment', assignStatus: ['ASSESSMENT_SELECTION']  },
    { question: 'chatContact.faqRefNumberQuestion', answer: 'createClaim.refTopContent', grouped: 'dataValidation', answerType: 'text'  },
    { question: 'chatContact.faqSendReportProblemQuestion', answerType: 'text', answer: 'chatContact.faqSendReportProblemAnswer', assignStatus: ['VALIDATION_PAX'], grouped: 'dataValidation' },
    { question: 'help_center.hc_tumi_rimova', answerType: 'text', assignStatus: ['VALIDATION_PAX'], answer: 'helpCenterMain.hc_tumi_rimova', grouped: 'dataValidation' },
    { question: 'help_center.hc_damages_can_repair', answerType: 'text', assignStatus: ['ASSESSMENT_PENDING'], answer: 'helpCenterMain.hc_damages_can_repair', grouped: 'rest' },
    { question: 'about.whatWarranty', answerType: 'text', assignStatus: ['ASSESSMENT_PENDING'], answer: 'helpCenterMain.hc_warranty', grouped: 'rest' },
];