import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import parse from 'html-react-parser';
import cx from 'classnames';

import { ReactComponent as BotIcon } from 'Assets/chat-bot.svg';
import { ReactComponent as CodeIcon } from 'Assets/code.svg';

import runApi, { ApiSendMethods, ErrorsApiE, ResultApiTypeT } from 'api/base/runApi';
import { TitleBlock, Button, LoadableContent, Input, Loader } from 'Components';
import { formatCountdown, getSecondsUntil, stringToDate } from "Helpers/date";
import { setUser, setUserLanguage } from 'Store/modules/user';
import { useDeviceType } from 'Helpers/responsiveContainers';
import { correctLanguageCode } from 'Helpers/languageCode';
import Store, { initialStateConfirmLogin } from "./Store";
import { changeModalArticle } from 'Store/modules/page';
import style from './CodeConfirmationForm.module.scss';
import { SupportedLanguageT } from 'api/modules/i18n';
import { changeLanguage } from 'Store/modules/i18n';
import Checkbox from 'Components/Checkbox/Checkbox';
import { runNotify } from 'Helpers/notifications';
import type { AppStateT } from 'Store/modules';

interface ICodeConfirmationForm {
    email: string | null;
    resending: boolean;
}

let timer: any = null;
let resendCodeTimer: any = null;

const CodeConfirmationForm: React.FC<ICodeConfirmationForm> = ({ email, resending }) => {

    const { isNotMobile } = useDeviceType();
    const [state, setState] = useReducer(Store, initialStateConfirmLogin);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const firstTimeLogin = useSelector<AppStateT, boolean>(({ user }) => user.firstTimeLogin);
    const navigate = useNavigate();
    const languages = useSelector<AppStateT, SupportedLanguageT[]>(({ i18n }) => i18n.supportedLanguages?.data || []);
    const { isMobile } = useDeviceType();

    const goToLogin = useCallback(() => {
        navigate('/login');
    }, [navigate]);

    useEffect(() => {
        const dateTryEnd = localStorage.getItem('dateTryCodeLogin');
        if (dateTryEnd && new Date(dateTryEnd) > new Date()){
            setState({ type: 'START_COUNTER_FROM_LS', data: new Date(dateTryEnd) });
        }
        return clearTimeout(timer);
    }, []);

    useEffect(() => {
        const storedExpireTime = localStorage.getItem('resendCodeTimerExpireTime');
    
        if (storedExpireTime) {
            const expireTime = parseInt(storedExpireTime, 10);
            const remainingTime = Math.max(0, expireTime - Date.now() / 1000);
            setState({ type: 'SET_INITIAL_VERIFICATION_CODE', data: +remainingTime.toFixed(0) });

            if (remainingTime > 0) {
                setState({ type: 'SET_RESEND_BUTTON', data: true });

                // Initialize the timer here
                resendCodeTimer = setTimeout(() => {
                    setState({ type: 'SET_RESEND_BUTTON', data: false });
                    localStorage.removeItem('resendCodeTimerExpireTime');
                    setState({ type: 'SET_INITIAL_VERIFICATION_CODE', data: initialStateConfirmLogin.initialVerificationCodeCounter });
                }, remainingTime * 1000);
            }

            if (remainingTime === 0) {
                localStorage.removeItem('resendCodeTimerExpireTime');
                setState({ type: 'SET_INITIAL_VERIFICATION_CODE', data: initialStateConfirmLogin.initialVerificationCodeCounter });
            }
        }
    
        return () => {
            clearTimeout(resendCodeTimer);
        };
    }, []);

    useEffect(() => {
        if (state.error){
            timer = setTimeout(() => {
                setState({ type: 'SET_ERROR', data: false });
            }, 2000);
        }
    }, [state.error]);

    const actionSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        actionSend();
    }

    const actionChangeCode = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length <= 6){
            setState({ type: 'SET_CODE', data: ev.target.value });
        }
    }, [])

    const handleFooterClick = (ev: React.MouseEvent<HTMLElement>) => {
        ev.stopPropagation();
        setState({ type: 'TOGGLE_AGREE' });
    }

    const handleUrlClick = (ev: React.MouseEvent, type: 'terms'|'privacy') => {
        ev.preventDefault();
        ev.stopPropagation();
        if (type === 'privacy'){
            return dispatch(changeModalArticle({ pagesContentKey: 'regs.privacy', title: 'footer.privacySettings' }));
        }

        // Show modal article from prismic [terms]
        if (type === 'terms'){
            return dispatch(changeModalArticle({ pagesContentKey: 'regs.regulations', title: 'footer.termsAndConditions' }));
        }
    }

    const actionCheck = (check: boolean) => {
        setState({ type: 'SET_ERROR_CHECK', data: false });
    }

    const saveCorrectCode = (personalInfo: any, correctCode?: string) => {
        if (correctCode){
            // write correct code to user
            const dataSend: any = {
                firstName: personalInfo.firstName,
                lastName: personalInfo.lastName,
                language: correctCode,
                phoneNumber: personalInfo.phoneNumber,
                salutation: personalInfo.salutation
            };
            if (personalInfo.showTitle && !!personalInfo.title){
                dataSend.title = personalInfo.title
            }
            runApi('user', dataSend, null, ApiSendMethods.patch);
            dispatch(changeLanguage(correctCode));
        }
    }

    const actionResend = useCallback(() => {
        setState({ type: 'SET_RESEND_BUTTON', data: true });
        const dataSend = {
            email,
        }
        setState({ type: 'SET_SENDING', data: true });
        runApi('user/login', dataSend, (r: ResultApiTypeT) => {
            setState({ type: 'SET_SENDING', data: false });
            if (r.result){
                runNotify({ message: t('confirmation.subtitle'), type: 'success' });
            } else {
                setState({ type: 'SET_ERROR', data: true })
                runNotify({ message: r.error.message || t('registration.errorRegister'), type: 'error' });
            }
        });

        const expireTime = Date.now() / 1000 + state.initialVerificationCodeCounter;
        localStorage.setItem('resendCodeTimerExpireTime', expireTime.toString());

        resendCodeTimer = setTimeout(() => {
            setState({ type: 'SET_RESEND_BUTTON', data: false });
            localStorage.removeItem('resendCodeTimerExpireTime');
            setState({ type: 'SET_INITIAL_VERIFICATION_CODE', data: initialStateConfirmLogin.initialVerificationCodeCounter });
        }, state.initialVerificationCodeCounter * 1000);
    }, [email, t, state.initialVerificationCodeCounter])

    const actionSend = () => {
        if (state.code.trim().length < 6 || !state.agreeTerms){

            if (state.code.trim().length < 6){
                setState({ type: 'SET_ERROR_NAME', data: true });
                runNotify({ message: t('confirmation.invalidCode'), type: 'error', dismiss: 5000  });
            }
            if (!state.agreeTerms){
                setState({ type: 'SET_ERROR_CHECK', data: true });
            }

            return;
        }
        const dataSend = {
            email,
            verificationCode: state.code
        }
        setState({ type: 'SET_SENDING', data: true });
        runApi('user/confirm', dataSend, (r: ResultApiTypeT) => {
            setState({ type: 'SET_SENDING', data: false });
            if (r.result){

                let correctCodeData = null;
                dispatch(setUser({
                    ...r.data.user,
                    language: correctLanguageCode(r.data.user.language, languages, correctCode => {
                        correctCodeData = correctCode;
                    })
                }, r.data.token));
                setState({ type: 'CLEAR_TRY' });

                if (firstTimeLogin) {
                    navigate('/create');
                } else {
                    navigate('/');
                }

                if (correctCodeData){
                    saveCorrectCode(r.data.user, correctCodeData);
                }

                // if language avaiable to set
                const lang = localStorage.getItem('languageTrySet');
                if (lang){
                    dispatch(changeLanguage(lang));
                    const personalInfo = {
                        firstName: r.data.user.firstName,
                        lastName: r.data.user.lastName,
                        phoneNumber: r.data.user.phoneNumber,
                        language: lang,
                        title: r.data.user.title
                    }
                    runApi('user', personalInfo, (r: any) => {
                        if (r.result){
                            dispatch(setUserLanguage(lang));
                        }
                    }, ApiSendMethods.patch);
                    localStorage.removeItem('languageTrySet');
                }
            } else {
                setState({ type: 'SET_ERROR', data: true });
                setState({ type: 'TRY_COUNTER_INCREASE' });
                if (r.error.code === ErrorsApiE.INCORRECT_VERIFICATION_CODE){
                    setState({ type: 'SET_ERROR_INVALID_CODE', data: true });
                    runNotify({ message: t('confirmation.errorInwalidCode'), type: 'error' });
                } else if (r.error.code === ErrorsApiE.VERIFICATION_CODE_EXPIRED) {
                    setState({ type: 'SET_VERIFICATION_EXPIRED', data: true });
                    runNotify({ message: t('confirmation.verificationCodeExpired'), type: 'error' });
                } else if (r.error.code === ErrorsApiE.ACCOUNT_TEMPORARILY_BLOCKED && r.error?.data?.accountBlockedUntil) {
                    setState({ type: 'END_TRY', data: stringToDate(r.error.data.accountBlockedUntil) });
                } else {
                    runNotify({ message: r.error.message || t('registration.errorRegister'), type: 'error' });
                }
            }
        });
    }

    return (
        <div className={cx(style.root, { [style.isNotMobile]: !isMobile })}>
        <form onSubmit={actionSubmit} className={isNotMobile ? style.rootDesktop : style.root}>
            {state.dateTry && state.dateTry > new Date() ?
                <div className={style.waitStopCounter}>
                    <div className={style.comm}>
                        {parse(t('login.blockAccount'))}
                    </div>

                    <div className={style.counter}>    
                        <CountdownCircleTimer
                            isPlaying
                            initialRemainingTime={getSecondsUntil(state.dateTry)}
                            duration={getSecondsUntil(state.dateTry)}
                            colors={'#2fc3dd'}
                            size={60}
                            strokeWidth={5}
                            onComplete={() => setState({ type: 'CLEAR_TRY' })}
                        >
                            {({ remainingTime }) => formatCountdown(remainingTime) }
                        </CountdownCircleTimer>
                    </div>
                    
                </div>:
                <>
                    {state.sending &&
                        <div className={style.loader}>
                            <Loader />
                        </div>
                    }
                    <div className={style.box}>
                    {isNotMobile && <BotIcon className={style.bot}/>}
                    <TitleBlock
                        title={t('confirmation.title')}
                        subtitle={t('confirmation.subtitle')}
                        withIcon={false}
                        className={style.title}
                    />
                    </div>
                    {state.errorInvalidCode &&
                        <div className={style.errorLayer}>
                            {t('confirmation.errorInwalidCode')}
                        </div>
                    }

                    {state.verificationCodeExpired &&
                        <div className={style.errorLayer}>
                            {t('confirmation.verificationCodeExpired')}
                        </div>
                    }

                    <Input
                        label={t('labels.verificationCode')}
                        Icon={CodeIcon}
                        value={state.code}
                        onChange={actionChangeCode}
                        inputMode={'numeric'}
                        className={cx(style.input, { [style.error]: state.error || state.errorName })}
                        maxLength={6}
                        onFocus={() => {
                            setState({ type: 'INPUT_FOCUS' })
                            clearTimeout(timer);
                        }}
                        id={'input_code_confirmation'}
                    />
                    <div className={cx(style.requiredField, { [style.error]: state.error || state.errorName })}>{t('common.requiredField')}</div>

                    <LoadableContent loading={resending}>
                        <div className={style.resendContainer}>
                            <button className={style.resendButton} onClick={actionResend} type="button" disabled={state.resendButtonDisabled} id={'input_confirmation_resend'}>
                                {t('confirmation.resend')}
                            </button>
                            <div className={style.countdownTimer} id={'countdown_timer_confirmation'}>
                                {state.resendButtonDisabled && (
                                    <CountdownCircleTimer
                                        isPlaying
                                        initialRemainingTime={state.initialVerificationCodeCounter}
                                        duration={initialStateConfirmLogin.initialVerificationCodeCounter}
                                        colors={'#2fc3dd'}
                                        size={40}
                                        strokeWidth={3}
                                    >
                                        {({ remainingTime }) => remainingTime }
                                    </CountdownCircleTimer>
                                )}
                            </div>

                        </div>
                    </LoadableContent>

                    <div className={style.footer} onClick={handleFooterClick}>
                        <Checkbox checked={state.agreeTerms} name={'agree-terms'} onChange={actionCheck} className={cx(style.checkbox, { [style.error]: state.errorCheck })} id={firstTimeLogin ? 'checkbox_code_agree_register' : 'checkbox_code_agree_login'}/>
                        <span className={style.note}>
                            <Trans
                                dangerouslySetInnerHTML={{__html: t('foo')}}
                                i18nKey={'confirmation.agreement'}
                                defaults={t('confirmation.signUpContent')}
                                components={{
                                    terms: <a className={style.url} id={'button_confirmation_terms'} href="https://www.dolfi1920.de/terms_of_services.html?___store=de_eur_english" target={'_blank'} rel="noreferrer" onClick={(event: React.MouseEvent) => handleUrlClick(event, 'terms')}>terms</a>,
                                    privacy: <a className={style.url} id={'button_confirmation_privacy'} href="https://www.dolfi1920.de/privacy_policy.html?___store=de_eur_english" target={'_blank'} rel="noreferrer" onClick={(event: React.MouseEvent) => handleUrlClick(event, 'privacy')}>privacy</a>,
                                }}
                            />
                        </span>
                    </div>
                    <Button className={style.button} type="submit" id={'button_confirmation_submit'}>
                        {firstTimeLogin ? t('confirmation.signUp') : t('confirmation.logIn')}
                    </Button>
                    {!isMobile && 
                    <Button  className={style.buttonBack} onClick={goToLogin} id={'button_confirmation_back'}>
                        {t('common.goBack')} 
                    </Button>}
                </>
            }


        </form>
        </div>
    );
}

export default CodeConfirmationForm;