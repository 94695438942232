import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { ChatMessageGroup } from 'Components';
import { formatTimeOnly } from 'Helpers/date';
import { ReactComponent as YogaIcon } from 'Assets/yoga.svg';
import CONFIG from 'Config';
import chatStyles from 'PageComponents/Chat/Chat/Chat.module.scss';

export default function AssessmentTimeoutBlock({
  assessmentCreatedAt,
}: {
  assessmentCreatedAt: string;
}) {
  const { t } = useTranslation();

  const timeoutTime = useMemo(
    () => formatTimeOnly(new Date(assessmentCreatedAt).getTime() + CONFIG.CLAIM_TIME_OUT * 1000),
    [assessmentCreatedAt],
  );

  const timeoutMessages = useMemo(() => {
    return [
      {
        message: t('chatMessages.timeoutP1'),
        time: timeoutTime,
        className: chatStyles.messageIn,
      },
      {
        render: () => <YogaIcon />,
        className: cx(
          chatStyles.message,
          chatStyles.messageIn,
          chatStyles.customMessage,
          chatStyles.timeoutMessage,
        ),
      },
      {
        message: t('chatMessages.timeoutP2'),
        className: chatStyles.messageIn,
      },
      {
        message: t('chatMessages.timeoutP3'),
        className: chatStyles.messageIn,
      },
    ];
  }, [timeoutTime, t]);

  return (
    <ChatMessageGroup as="MessageGroup" direction="incoming" avatar="bot" data={timeoutMessages} />
  );
}
