import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ChatMessageGroup, { IMessage } from 'Components/ChatMessageGroup/ChatMessageGroup';
import ClaimBlockedOnChat from "Components/ClaimBlockedOnChat/ClaimBlockedOnChat";
import { ClaimHistoryT } from 'api/modules/case-resolution';
import { useMobile } from 'Helpers/responsiveContainers';
import useFormattedPrice from 'Hooks/useFormattedPrice';
import { CLAIM_STATUSES } from 'Config/statuses';
import { PageConfigT } from 'Store/modules/page';
import { prepareUrl } from 'Helpers/claim';
import { ClaimT } from 'api/modules/claim';
import { AppStateT } from 'Store/modules';

type PropsMainT = {
    claim: ClaimT,
    statusInfo: ClaimHistoryT,
}

const MoneyCompensation: React.FC<PropsMainT> = ({ claim, statusInfo }) => {

    const [messages, setMessages] = useState<IMessage[]>([]);
    const { t } = useTranslation();
    const isMobile = useMobile();
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);

    const formatPrice = useFormattedPrice(claim.currencyIsoCode, pageConfig.noFractionCurrencies)

    useEffect(() => {

        const assessmentResult = claim.assessmentResults && claim.assessmentResults?.find(a => a.isActive);
        if (assessmentResult && ((assessmentResult.verdictType === 'irreparable' && (assessmentResult.resolution.voucher || assessmentResult.value)) || assessmentResult.verdictType === 'repairable')) {

            const _m: IMessage[] = [
                { render: () => (
                    <>
                        {claim.status !== CLAIM_STATUSES.SOLUTION_REFUND ?
                            <>
                                {parse(t('chatMessages.moneyCompensationResultLabel', {
                                    price: `<span class="price">${formatPrice(assessmentResult.resolution.voucher || assessmentResult.value)} ${claim.currencyIsoCode}</span>`,
                                    url: `<a href="${prepareUrl(claim, isMobile)}" class="linkChat" target="_blank">${t('common.link')}</a>` 
                                }))}
                            </>:
                            <>
                                {parse(t('chatMessages.moneyCompensationSuccess', {
                                    price: `<span class="price">${formatPrice(assessmentResult.resolution.voucher || assessmentResult.value)} ${claim.currencyIsoCode}</span>`,
                                }))}
                            </>
                        }
                    </>
                ) },
            ];
    
            setMessages(_m);
        } else {
            setMessages([])
        }

    }, [t, claim.currencyIsoCode, claim.assessmentResults, isMobile, claim, claim.status, formatPrice]);

    if (!claim?.assessmentResults?.find(r => r.isActive && !!r.resolution?.moneyCompensationUrl)){
        return <ClaimBlockedOnChat claim={claim} reason={t('chatMessages.sparePartsNoUrl')} />
    }

    return (
        <ChatMessageGroup
            data={messages}
            direction={'incoming'}
            statusInfo={statusInfo}
            as="MessageGroup"
            avatar='bot'
        />
    );
}
export default MoneyCompensation;