import React from "react";
import cx from 'classnames';

import styles from './ListLanguages.module.scss';
import useChangeLanguage from 'Hooks/useChangeLanguage';
import { useTranslation } from "react-i18next";

type MainPropsT = {
    closeAction: () => void,
    showHeader?: boolean
}

const ListLanguages: React.FC<MainPropsT> = ({ closeAction, showHeader = true }) => {
    const { sortedTranslatedLanguages, actualLanguage, changeAppLanguage } = useChangeLanguage();
    const { t } = useTranslation();

    const actionSelectLanguage = (event: React.SyntheticEvent, languageValue: string) => {
        event.preventDefault();
        changeAppLanguage(languageValue);
        closeAction();
    }

    return (
        <div>
            {showHeader && (
                <div className={styles.header}>
                    <div className={styles.labels}>
                        {/* <label>Language</label> */}
                        <h3>{t('labels.selectLanguage')}</h3>
                    </div>
                </div>
            )}
            <div className={styles.body}>
                {sortedTranslatedLanguages.map((language) => (
                    <a 
                        href="/"
                        className={cx(styles.language, actualLanguage === language.value ? styles.languageSelected : null)} 
                        key={`language_${language.value}`} 
                        onClick={(event: React.SyntheticEvent) => {
                            actionSelectLanguage(event, language.value);
                        }}
                    >
                        {language.displayName}
                    </a>
                ))}
            </div>
        </div>
    );
}

export default ListLanguages;
