import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';

import { changeShopCounter, PaymentChooseT, setDelivery, setTryDeliveryMethod, ShopStateT } from 'Store/modules/luggageStore/shop';
import WireTransfer from './WireTransfer/WireTransfer';
import CreditCard from './CreditCard/CreditCard';
import PaymentBox from './PaymentBox/PaymentBox';
import { filterDelivery } from "Helpers/store";
import { PaymentsE } from "Store/Types/order";
import useCodLimit from "Hooks/useCodLimit";
import styles from './Payments.module.scss';
import { AppStateT } from 'Store/modules';
import Paypal from './Paypal/Paypal';
import { Button } from 'Components';


type PropsT = {
    actionChangeMethod?: (paymentMethod: PaymentChooseT) => void
    validField: (type: string, data: any) => boolean,
    actionClose?: () => void,
    mobile?: boolean,
    cartAmount: number
}

const Payments: React.FC<PropsT> = ({ validField, mobile = false, actionChangeMethod, cartAmount, actionClose }) => {

    const { delivery, configShop } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const dispatch  = useDispatch();
    const { t } = useTranslation();
    const codOverLimit = useCodLimit(cartAmount);

    useEffect(() => {
        if (codOverLimit && delivery.paymentChoose === 'CASH_ON_DELIVERY'){
            dispatch(setDelivery({
                ...delivery,
                paymentChoose: null
            }));
        }
    }, [codOverLimit, delivery.paymentChoose, dispatch, delivery]);

    /*
     * Dla zmiany metody płatności zmieniamy jeśli potrzeba sposób dostawy
     **/
    useEffect(() => {
        const checkDeliveryMethod = () => {
            const deliveryMethods = filterDelivery(delivery.paymentChoose, configShop.deliveryMethods, configShop.payment.types);
            dispatch(setTryDeliveryMethod(deliveryMethods, delivery.deliveryMethod.name));
        }
        
        if (configShop.deliveryMethods.length > 1){
            checkDeliveryMethod();
        }
    }, [delivery.paymentChoose, configShop.deliveryMethods, configShop.payment.types, delivery.deliveryMethod.name, dispatch]);

    const actionChange = useCallback((method: PaymentChooseT) => {
        if (!mobile || (mobile && (method === PaymentsE.PAYPAL || method === PaymentsE.CASH_ON_DELIVERY))){
            dispatch(setDelivery({
                ...delivery,
                paymentChoose: delivery.paymentChoose !== method ? method : null
            }));
            dispatch(changeShopCounter());
        } else {
            actionChangeMethod?.(method);
        }
    }, [delivery, dispatch, mobile, actionChangeMethod]);

    const actionChangeCard = useCallback((type: string, data: string) => {
        dispatch(setDelivery({
            ...delivery,
            payment: {
                ...delivery.payment,
                creditCard: {
                    ...delivery.payment.creditCard,
                    [type]: data
                }
            }
        }));
    }, [delivery, dispatch]);

    const actionChangeDataPaypal = useCallback((type: string, data: string) => {
        dispatch(setDelivery({
            ...delivery,
            payment: {
                ...delivery.payment,
                paypal: {
                    ...delivery.payment.paypal,
                    [type]: data
                }
            }
        }));
    }, [delivery, dispatch]);

    const actionConfirm = useCallback(() => {
        if (mobile && delivery.paymentChoose) {
            actionClose();
        } else return
    }, [delivery.paymentChoose, actionClose, mobile])

    const blurActionPayment = () => {
        dispatch(changeShopCounter());
    }

    return (
        <div className={styles.root}>
            <div className={cx(styles.paymentBoxes, { [styles.mobile]: mobile })}>
                {configShop.payment.types.includes(PaymentsE.WIRE_TRANSFER) &&
                    <PaymentBox
                        title={t('shop.bankWire')}
                        method={PaymentsE.WIRE_TRANSFER}
                        actionChange={actionChange}
                        paymentChoose={delivery.paymentChoose}
                        mobile={mobile}
                    />
                }
                {configShop.payment.types.includes(PaymentsE.CREDIT_CARD) &&
                    <PaymentBox
                        title={t('shop.creditCard')}
                        method={PaymentsE.CREDIT_CARD}
                        actionChange={actionChange}
                        paymentChoose={delivery.paymentChoose}
                        mobile={mobile}
                    />
                }
                {configShop.payment.types.includes(PaymentsE.PAYPAL) &&
                    <PaymentBox
                        title={t('shop.paypal')}
                        method={PaymentsE.PAYPAL}
                        actionChange={actionChange}
                        paymentChoose={delivery.paymentChoose}
                        mobile={mobile}
                    />
                }
                {configShop.payment.types.includes(PaymentsE.CASH_ON_DELIVERY) &&
                    <PaymentBox
                        title={t('shop.cashOnDelivery')}
                        method={PaymentsE.CASH_ON_DELIVERY}
                        actionChange={actionChange}
                        paymentChoose={delivery.paymentChoose}
                        mobile={mobile}
                        disabledText={codOverLimit ? t('shop.cod_over_limit', { amount: `${configShop.payment.limits[PaymentsE.CASH_ON_DELIVERY].byCurrencyAndAmount[configShop.currency.code]}${configShop.currency.code}` }) : undefined}
                    />
                }
            </div>

            {(delivery.paymentChoose && !mobile) &&
                <>
                    {(delivery.paymentChoose === PaymentsE.WIRE_TRANSFER && configShop.payment.wireTransferData) && 
                        <WireTransfer t={t} currency={configShop.currency.symbol} configWireTransfer={configShop.payment.wireTransferData} />
                    }
                    {(delivery.paymentChoose === PaymentsE.CREDIT_CARD && delivery.payment.creditCard) && 
                        <CreditCard
                            actionChange={actionChangeCard}
                            payments={delivery.payment}
                            validField={validField}
                            blurAction={blurActionPayment}
                            t={t}
                            currency={configShop.currency.symbol}
                        />
                    }
                    {(delivery.paymentChoose === PaymentsE.PAYPAL && delivery.payment.wireTransfer) && 
                        <Paypal
                            actionChange={actionChangeDataPaypal}
                            payments={delivery.payment}
                            validField={validField}
                            blurAction={blurActionPayment}
                            t={t}
                            currency={configShop.currency.symbol}
                        />
                    }
                </>
            }

            {mobile &&
                <>
                    <div className={styles.buttonContainer}>
                        <Button type="submit" className={styles.btn} disabled={!delivery.paymentChoose} onClick={actionConfirm}>
                            {t('common.confirm')}
                        </Button>
                    </div>
                </>
            }


        </div>
    );
}
export default Payments;