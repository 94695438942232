import React from 'react';
import Loading from 'Components/Loading/Loading';

interface ILoadableContent {
  loading: boolean;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

export default function LoadableContent(props: ILoadableContent) {
  if (props.loading) {
    return <Loading className={props.className} />
  }

  return (
    <>{props.children}</>
  );
}
