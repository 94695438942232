import React from 'react';
import cx from 'classnames';
import { AnimatedModal } from 'Components';
import styles from './MessageModal.module.scss';

interface IMessageModal {
  open: boolean;
  children: React.ReactNode | string;
  onClose: (evt: React.MouseEvent<HTMLElement>) => void;
  closeOnClickOutside?: boolean;
  centered?: boolean;
  error?: boolean;
  hideCloseButton?: boolean;
}

export default function MessageModal(props: IMessageModal) {
  const { open, closeOnClickOutside = true, onClose } = props;

  return (
    <AnimatedModal
      open={open}
      onClose={onClose}
      closeOnClickOutside={closeOnClickOutside}
      containerClassName={cx(styles.messageModalContainer, {
        [styles.centered]: props.centered,
      })}
      contentClassName={cx(styles.messageModalContent, {
        [styles.centered]: props.centered,
        [styles.error]: props.error,
      })}
      hideCloseButton={props.hideCloseButton}
    >
      {props.children}
    </AnimatedModal>
  );
}