import React from 'react';
import { Header, Footer } from 'Components';
import { useDeviceType } from 'Helpers/responsiveContainers';
interface IProps {
  children: React.ReactNode | React.ReactNode[];
  back?: boolean | string;
  footer?: boolean;
}

export default function Layout(props:IProps) {
  const {isNotMobile}= useDeviceType();
  return (
    <>
      <Header back={props.back} footer={isNotMobile}/>
      {props.children}
      {props.footer && !isNotMobile && (
        <Footer />
      )}
    </>
  )
}