import React, { useState } from 'react';
import cx from 'classnames';

import { PaymentsT } from 'Store/modules/luggageStore/shop';
import { Input } from 'Components';

import styled from './Paypal.module.scss';

type MainProps = {
    actionChange: (type: string, data: string) => void,
    payments: PaymentsT,
    validField: (type: string, data: any) => boolean,
    blurAction?: () => void,
    t: (data: string, obj?: any) => string,
    currency: string
}

const Paypal: React.FC<MainProps> = ({ actionChange, payments, validField, blurAction, t, currency }) => {

    const [err, setErr] = useState(false);

    const actionValidate = (data: string) => {
        const _data = data.trim();
        blurAction?.();
        if (_data.length === 0){
            setErr(false);
        } else {
            return setErr(!validField('paypal-id', _data));
        }
    }

    return (
        <div className={styled.root}>
            <div className={styled.rowNames}>
                <Input
                    label={t('shop.paypalPlaceholder')}
                    value={payments.paypal.id}
                    onChange={(data: React.ChangeEvent<HTMLInputElement>) => actionChange('id', data.target.value)}
                    className={cx(styled.input, { [styled.error]: err })}
                    onFocus={() => setErr(false)}
                    onBlur={(data: React.ChangeEvent<HTMLInputElement>) => actionValidate(data.target.value)}
                />
            </div>
             {/* <div className={styled.infoBottom}>
                <InfoIcon />
                {t('shop.WireTransferExtraFee', { currency, amount: 4.99 })}
             </div> */}
        </div>
    );
}
export default Paypal;