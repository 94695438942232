import React, { useCallback, useEffect } from 'react';
import { useNavigate } from "react-router";
import Modal from "Components/Modal/Modal";
import { useDispatch } from "react-redux";
import Lottie from 'react-lottie';
import cx from 'classnames';

import { variableCheckModalStoreSuccess } from "Helpers/rules";
import { setOpenModalStoreSuccess } from "Store/modules/page";
import { useDeviceType } from "Helpers/responsiveContainers";
import animationSuccess from 'Assets/lotties/success.json';
import style from './ModalShopSuccess.module.scss';
import useTranslate from "Hooks/useTranslate";
import Button from "Components/Button/Button";

const lottieOptions = {
    loop: true,
};

let timout: any  = null;

const ModalWaitRedirectShop: React.FC = () => {

    const { isMobile } = useDeviceType();
    const t = useTranslate();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const actionclose = useCallback(() => {
        dispatch(setOpenModalStoreSuccess(false));
        const data = window.location.pathname?.split("/").filter(d => !!d);
        if (data[1] && data[1] === variableCheckModalStoreSuccess && data[0].length >= 20){
            navigate(`/${data[0]}`);

        }
    }, [dispatch, navigate])

    useEffect(() => {
        timout = setTimeout(() => {
            actionclose();
        }, 20000);
        return () => {
            clearTimeout(timout);
        }
    }, [actionclose]);

    return (
        <Modal open onClose={actionclose} hideTitle animatedContentClassName={cx(style.root, { [style.mobile]: isMobile })}>
            <div className={style.body}>
                <div className={style.content}>
                    <Lottie
                        options={{
                            ...lottieOptions, 
                            animationData: animationSuccess
                        }} 
                        height={120} 
                        width={120}
                    />
                    <div className={style.contentText}>
                        {t('shop.contentModalSuccessShop')}
                    </div>
                </div>
                <div className={style.buttons}>
                    <Button onClick={actionclose}>{t('common.close')}</Button>
                </div>
            </div>
        </Modal>
    );
}
export default React.memo(ModalWaitRedirectShop);