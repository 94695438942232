import API from '../base/api';
import type { ReqTypeT } from '../';

export interface IMessage {
  value: string;
  timestamp: number;
}

export class ClaimReport extends API {
  sendMessages = (claimId: string, data: string[]) => {
    return this.r({
      method: 'POST',
      url: `claim/${claimId}/report`,
      data,
    });
  };
}

export default function claimReport(request: ReqTypeT) {
  return new ClaimReport(request);
}
