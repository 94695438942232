import { loading, loaded, error } from 'Helpers/store-async';

export const SEND_REPORT_REQUEST = 'SEND_REPORT_REQUEST';
export const SEND_REPORT_SUCCESS = 'SEND_REPORT_SUCCESS';
export const SEND_REPORT_FAILURE = 'SEND_REPORT_FAILURE';
export const RESET_REPORT_STATE = 'RESET_REPORT_STATE';

export type SendReportRequestT = {
  type: typeof SEND_REPORT_REQUEST;
  data: {
    report: ReportT;
    data: any;
  };
};

export type SendReportSuccessT = {
  type: typeof SEND_REPORT_SUCCESS;
  data: ReportT;
};

export type SendReportFailureT = {
  type: typeof SEND_REPORT_FAILURE;
  error: string;
};

export type ResetReportStateT = {
  type: typeof RESET_REPORT_STATE;
};

export type ActionTypeT =
  | SendReportRequestT
  | SendReportSuccessT
  | SendReportFailureT
  | ResetReportStateT;

export type StateT = {
  loading: boolean;
  loaded: boolean;
  error: string | null;
};

const initialState: StateT = {
  loading: false,
  loaded: false,
  error: null,
};

export default function reportReducer(state: StateT = initialState, action: ActionTypeT): StateT {
  switch (action.type) {
    case SEND_REPORT_REQUEST: {
      return loading<StateT>(state);
    }
    case SEND_REPORT_SUCCESS: {
      return loaded<StateT>(state);
    }
    case SEND_REPORT_FAILURE: {
      return error<StateT>(state, action.error);
    }
    case RESET_REPORT_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export function sendReportRequest(data: SendReportRequestT['data']): SendReportRequestT {
  return {
    type: SEND_REPORT_REQUEST,
    data,
  };
}

export function sendReportSuccess(data: ReportT): SendReportSuccessT {
  return {
    type: SEND_REPORT_SUCCESS,
    data,
  };
}

export function sendReportFailure(error: string): SendReportFailureT {
  return {
    type: SEND_REPORT_FAILURE,
    error,
  };
}

export function resetReportState(): ResetReportStateT {
  return {
    type: RESET_REPORT_STATE,
  };
}
