import type { ReqTypeT } from '../';
import API from '../base/api';

export class PageApi extends API {
  getConfig = () => {
    return this.r({
      method: 'GET',
      url: "/appPax/general/config",
      data: {}
    });
  };
}

export default function page(request: ReqTypeT) {
  return new PageApi(request);
}
