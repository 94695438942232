import { VerdictT } from 'api/modules/claim';

const DEFAULT_RESOLUTION: VerdictT['resolution'] = {
  refund: false,
  residualValue: false,
  voucher: false,
  repair: false,
};

export function verdictResolutionAdapter(data: any): VerdictT {
  const resolutionData = data.resolution;

  if (!resolutionData) {
    data.resolution = DEFAULT_RESOLUTION;
    return data;
  }

  data.resolution = {
    ...DEFAULT_RESOLUTION,
    ...data.resolution,
    refund:
      typeof data.resolution.refund === 'string'
        ? Number(data.resolution.refund)
        : data.resolution.refund,
    voucher:
      typeof data.resolution.voucher === 'string'
        ? Number(data.resolution.voucher)
        : data.resolution.voucher,
  };

  return data;
}
