import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ModalSuccess from 'Pages/StoreDesktop/ModalSuccess/ModalSuccess';
import styles from './PaymentResult.module.scss';
import { Layout } from 'Components';

const isLoginUser = (): boolean => {
    return !!(localStorage.getItem('token') || '');
}

const PaymentResult = () => {

    const { t } = useTranslation();
    const params = useParams<any>();
    const navigate = useNavigate();

    const actionClose = () => {
        if (isLoginUser()){
            navigate(`/${params?.id || ''}`);
        } else {
            navigate(`/`);
        }
    }

    return (
        <Layout>
            <div className={styles.main}>
            <ModalSuccess
                actionClose={actionClose}
                open
                t={t}
                dataPayment={{
                    claimId: params.id,
                    orderId: params.orderId,
                    paymentCode: params.paymentCode,
                    paymentStatus: params.paymentStatus as any
                }}
            />
            </div>
        </Layout>
    )
}

export default PaymentResult;