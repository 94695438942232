import { useNavigate } from "react-router";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { StateT as AuthorizationStateT } from 'Store/modules/authorization';
import type { StateT as UserStateT } from 'Store/modules/user';
import { Layout, CodeConfirmationForm } from 'Components';
import type { AppStateT } from 'Store/modules';

export default function Confirmation() {
    const { resend } = useSelector<AppStateT, AuthorizationStateT>(({ authorization }) => authorization);
    const { email } = useSelector<AppStateT, UserStateT>(({ user }) => user);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            navigate('/', { replace: true });
        }
    }, [email, navigate]);

    return (
        <Layout back="/login">
            <CodeConfirmationForm
                email={email}
                resending={resend.loading}
            />
        </Layout>
    );
}
