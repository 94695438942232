import TextareaAutosize from 'react-textarea-autosize';
import React, { useCallback } from 'react';

interface MultilineInputProps {
	maxRows?: number;
	onChange?: (value: string) => void;
	className: string,
	value: string,
	actionAddHeight: (addHeight: number) => void,
	actionOnBlur: () => void
}

const InputText: React.FC<MultilineInputProps> = ({ maxRows = 4, onChange, className, value, actionAddHeight, actionOnBlur }) => {

	const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newValue = event.target.value;
		if (onChange) {
			onChange(newValue);
		}
	}, [onChange]);

	return (
		<TextareaAutosize
			value={value}
			onChange={handleChange}
			rows={1}
			maxRows={5}
			className={className}
			onHeightChange={h => actionAddHeight(h - 34)}
			onBlur={actionOnBlur}
		/>
	);
};

export default InputText;