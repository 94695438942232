import { LuggageProductsSimpleT, LuggageT } from "api/modules/store";
import { CartDataSendT } from "Helpers/useDataCart";
import { getMostPopularValue } from "Helpers/store";
import { ClaimDetailsT } from "api/modules/claim";
import { PaymentsE } from "Store/Types/order";

export const SET_VOUCHER_ACTUAL = 'SET_VOUCHER_ACTUAL';
export const SET_SHOP_FILTERS = 'SET_SHOP_FILTERS';
export const SET_SHOP_FILTERS_LOCAL = 'SET_SHOP_FILTERS_LOCAL';
export const SET_SHOP_CLAIM = 'SET_SHOP_CLAIM';
export const SET_SHOP_FILTERS_RESET = 'SET_SHOP_FILTERS_RESET';
export const SET_SHOP_PRODUCT_SIMPLE = 'SET_SHOP_PRODUCT_SIMPLE';
export const ADD_CART_PRODUCT = 'ADD_CART_PRODUCT';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const REMOVE_CART_PRODUCT = 'REMOVE_CART_PRODUCT';
export const SET_CATEGORY_ID = 'SET_CATEGORY_ID';
export const SET_CATEGORYS_ID = 'SET_CATEGORYS_ID';
export const CLEAR_FILTERS_CATEGORIES = 'CLEAR_FILTERS_CATEGORIES';
export const SET_MAX_PRICE = 'SET_MAX_PRICE';
export const SET_VOUCHER_INFO_ARROW = 'SET_VOUCHER_INFO_ARROW';
export const SET_DELIVERY = 'SET_DELIVERY';
export const SET_DELIVERY_DATA = 'SET_DELIVERY_DATA';
export const SET_CONFIG_SHOP = 'SET_CONFIG_SHOP';
export const SET_RABAT_CODE = 'SET_RABAT_CODE';
export const SET_ORDER_RESULT = 'SET_ORDER_RESULT';
export const FETCH_CONFIG_SHOP = 'FETCH_CONFIG_SHOP';
export const FETCH_RABAT_CODE = 'FETCH_RABAT_CODE';
export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_SAVE_CART = 'FETCH_SAVE_CART';
export const FETCH_READ_CART = 'FETCH_READ_CART';
export const SET_AGREE_TERMS = 'SET_AGREE_TERMS';
export const SET_VALID_FORM = 'SET_VALID_FORM';
export const SET_PRODUCTS_LIMIT = 'SET_PROFDUCTS_LIMIT';
export const CLEAR_CART = 'CLEAR_CART';
export const CHANGE_SHOP_COUNTER = 'CHANGE_SHOP_COUNTER';
export const SET_STORE_FROM_CLAIM = 'SET_STORE_FROM_CLAIM';
export const CLEAR_DATA_STORE = 'CLEAR_DATA_STORE';
export const SET_ORDER_SENDING = 'SET_ORDER_SENDING';
export const CLEAR_MOST_POPULAR = 'CLEAR_MOST_POPULAR';
export const CLEAR_RABAT_CODE = 'CLEAR_RABAT_CODE';
export const SHOW_MODAL_ORDER_SUCCESS = 'SHOW_MODAL_ORDER_SUCCESS';
export const SET_NOTE = 'SET_NOTE';
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';
export const SET_TRY_DELIVERY_METHOD = 'SET_TRY_DELIVERY_METHOD';

export type ClearPaymentT = {
    type: typeof CLEAR_PAYMENT;
};

export type FetchStoreCategoryRequestT = {
    type: typeof FETCH_CATEGORIES;
};

export type FetchConfigShopT = {
    type: typeof FETCH_CONFIG_SHOP;
    claimId: string
};

export type SetTryDeliveryMethodT = {
    type: typeof SET_TRY_DELIVERY_METHOD;
    deliveryMethods: DeliveryMethodT[],
    oldDeliveryMethodName: string
};

export type FetchRabatCodeT = {
    type: typeof FETCH_RABAT_CODE;
    claimId: string,
    code: string
};

export type FetchOrderT = {
    type: typeof FETCH_ORDER;
    order: OrderT
};

export type FetchSaveCartT = {
    type: typeof FETCH_SAVE_CART;
    claimId: string,
    data: CartDataSendT
};

export type FetchReadCartT = {
    type: typeof FETCH_READ_CART;
    claimId: string
};

export type FetchCurrencyDataT = {
    claimId: string,
    currencyCode: string,
    storeView: string
}

export type SetVoucherActualT = {
    type: typeof SET_VOUCHER_ACTUAL;
    data: number
};

export type ClearMostPopularT = {
    type: typeof CLEAR_MOST_POPULAR;
};

export type ClearRabatCodeT = {
    type: typeof CLEAR_RABAT_CODE;
};

export type SetProductsLimitT = {
    type: typeof SET_PRODUCTS_LIMIT;
    data: number
};

export type SetOrderSendingT = {
    type: typeof SET_ORDER_SENDING;
    data: boolean
};

export type SetStoreFromClaimT = {
    type: typeof SET_STORE_FROM_CLAIM;
    data: CartDataSendT
};

export type SetClearCartT = {
    type: typeof CLEAR_CART;
    clearVoucher: boolean
};

export type ChangeShopCounterT = {
    type: typeof CHANGE_SHOP_COUNTER
};

export type SetValidFormT = {
    type: typeof SET_VALID_FORM;
    data: boolean
};

export type SetAgreeTermsT = {
    type: typeof SET_AGREE_TERMS;
    termsAccept: boolean
};

export type SetNoteT = {
    type: typeof SET_NOTE;
    note: string
};

export type ShowModalOrderSuccessT = {
    type: typeof SHOW_MODAL_ORDER_SUCCESS;
    data: boolean
};

export type ClearDataStoreT = {
    type: typeof CLEAR_DATA_STORE;
};

export type FilterSizesT = {
    title: string,
    value: string,
    name: string,
}

export type CartProductT = {
    amount: number,
    amountOrigin: number,
    quantity: number,
    // productSimple: LuggageProductsSimpleT,
    // luggage: LuggageT,
    material: string,
    size: string,
    weight: number,
    configurableId: number,
    configurableSku: string,
    productSimpleSku: string,
    productSimpleName: string,
    productSimpleColor: string,
    productSimpleManufacturer: string,
    id: number,
    productSimpleId: number
}

export type CartT = {
    amountSum: number,
    products: CartProductT[]
}

export type OrderResultT = {
    result: null|true|false,
    process: boolean,
    commentResult: string
}

export type OrderT = {
    claimId: string,
    rabat: RabatCodeT,
    voucher: number,
    sumOrder: number,
    totalOrder: number,
    products: CartProductT[],
    delivery: DeliveryT
}

export type StoreFormFiltersT = {
    sortBy: string;
    colors: string[];
    materials: string[];
    price: number[];
    sizes: string[];
    categories: string[];
    manufacturers: string[];
    search: string,
    showListPriceWithVoucher: boolean,
    categoryId: number
};

export type StoreCategoryT = {
    id: number,
    parent_id: number,
    name: string,
    is_active: boolean,
    position: number,
    level: number,
    product_count: number,
    children_data: StoreCategoryT[]
}

export type StoreCategoryMarged = {
    [key: number]: StoreCategoryT;
}

export type SetShopFiltersT = {
    type: typeof SET_SHOP_FILTERS;
    data: StoreFormFiltersT
}

export type SetDeliveryT = {
    type: typeof SET_DELIVERY;
    delivery: DeliveryT
}

export type SetDeliveryDataT = {
    type: typeof SET_DELIVERY_DATA;
    clientAddress: ClientDataT,
    clientAddressDelivery: ClientDataT
}

export type SetConfigShopT = {
    type: typeof SET_CONFIG_SHOP;
    configShop: ConfigShopT
}

export type SetRabatCodeT = {
    type: typeof SET_RABAT_CODE;
    rabatCode: RabatCodeT
}

export type SetOrderResultT = {
    type: typeof SET_ORDER_RESULT;
    orderResult: OrderResultT
}

export type SetVoucherInfoArrowT = {
    type: typeof SET_VOUCHER_INFO_ARROW;
    down: boolean
}

export type SetMaxPriceT = {
    type: typeof SET_MAX_PRICE;
    price: number
};

export type SetCategoryIdT = {
    type: typeof SET_CATEGORY_ID;
    id: number
};

export type SetCategorysIdT = {
    type: typeof SET_CATEGORYS_ID;
    data: number[]
};

export type clearFilterCategoriesT = {
    type: typeof CLEAR_FILTERS_CATEGORIES
};

export type SetShopFiltersLocalT = {
    type: typeof SET_SHOP_FILTERS_LOCAL;
    data: StoreFormFiltersT
};

export type SetShopFiltersResetT = {
    type: typeof SET_SHOP_FILTERS_RESET;
    clearSearch?: boolean | undefined
};

export type SetShopClaimT = {
    type: typeof SET_SHOP_CLAIM;
    claim: ClaimDetailsT | null
};

export type SetShopProductSimpleT = {
    type: typeof SET_SHOP_PRODUCT_SIMPLE;
    actualProductSimple: LuggageProductsSimpleT | null
};

export type AddCartProductT = {
    type: typeof ADD_CART_PRODUCT;
    productSimple: LuggageProductsSimpleT,
    luggage: LuggageT,
    quantity?: number
};

export type RemoveCartProductT = {
    type: typeof REMOVE_CART_PRODUCT;
    product: CartProductT,
    quantity?: number
};

export type ClientDataT = {
    name: string,
    surname: string,
    companyName: string,
    companyTaxNumber: string,
    street: string,
    apt: string,
    city: string,
    zipCode: string,
    country: string,
    countryCode: string,
    email?: string,
    phoneNumber: string,
    [key: string]: any
}

export type DeliveryMethodT = {
    currency: {
        code: string,
        value: number,
    },
    name: string,
    value: number
};

export type DeliveryMethodOrderT = {
    currency: {
        code: string,
        symbol: string,
    },
    name: string,
    value: number
};

export type CurrencyT = {
    code: string,
    symbol: string,
}

export type MostPopAttrsValueT = {
    id: number,
    name: string,
    parent_id: number
}

export type MostPopAttrsT = {
    cnt: number,
    id: number,
    materialId?: number
    value: string | MostPopAttrsValueT,
    label: string,
    selected?: boolean
}

export type ColorConfigT = {
    name: string,
    title: string,
    value: string
}

export type ConfigShopT = {
    storeView: string,
    storeId: number,
    deliveryMethods: DeliveryMethodT[],
    categories: StoreCategoryT[],
    paymentTags: string[],
    currency: CurrencyT,
    productsPortion: number,
    mediaURL: string,
    availableLanguages: string,
    availableCurrencies: string[],
    noFractionCurrencies: string[],
    mostPopAttrs: MostPopAttrsT[],
    colors: ColorConfigT[],
    catergoryDefaultId: number, // Top category default,
    payment: {
        types: string[],
        wireTransferData?: {
            fullNameOfTheRecipient: string,
            bankName: string,
            iban: string,
            swift: string,
            agency: number,
            vatNumber: string,
            accountNumber: string
        },
        limits: {
            [key: string]: {
                byCurrencyAndAmount: {
                    [key: string]: number        
                }
            }
        }
    },
    paymentSkipThreshold?: {
        amount: number,
        currency: string
    }
}

type PaymentPaypal = {
    id: string
}

export type PaymentTypes = PaymentsE.WIRE_TRANSFER|PaymentsE.CREDIT_CARD|PaymentsE.PAYPAL|PaymentsE.CASH_ON_DELIVERY;

export type PaymentChooseT = PaymentTypes|null;
export type PaymentsT = {
    wireTransfer: null,
    creditCard: PaymentCreditCard,
    paypal: PaymentPaypal,
}


export type DeliveryT = {
    clientAddress: ClientDataT,
    clientAddressDeliveryOn: boolean,
    clientAddressDelivery: ClientDataT,
    deliveryMethod: DeliveryMethodT,
    deliverySms: boolean,
    payment: PaymentsT,
    paymentChoose: PaymentChooseT,
    deliveryChangeCounter: number
}

export type DeliveryOrderT = {
    clientAddress: ClientDataT,
    clientAddressDeliveryOn: boolean,
    clientAddressDelivery: ClientDataT,
    deliveryMethod: DeliveryMethodOrderT,
    deliverySms: boolean,
    payment: PaymentsT,
    paymentChoose: PaymentChooseT,
    deliveryChangeCounter: number
}

export type RabatCodeT = {
    value: number,
    code: string,
    name: string,
    type: 0|1|2,// 0 - %, 1 - amount, 2 - ?
    result?: null|true|false
}

export type ShopStateT = {
    voucher: number,
    rabatCode: RabatCodeT,
    voucherActual: number,
    filters: StoreFormFiltersT,
    productsLimit: number,
    filtersLocal: StoreFormFiltersT,
    claim: ClaimDetailsT | null,
    actualProductSimple: LuggageProductsSimpleT | null,
    cart: CartT,
    categoriesMarged: StoreCategoryMarged, 
    categoryId: number,
    categorysId: number[],
    maxPrice: number,
    voucherInfoArrowDown: boolean,
    counterResetSearch: number,
    delivery: DeliveryT,
    configShop: ConfigShopT,
    termsAccept: boolean,
    note: string,
    validForm: boolean,
    orderResult: OrderResultT,
    changeShopCounter: number,
    orderSending: boolean,
    showModalSuccess: boolean,
};

export function setVoucher(data: number): SetVoucherActualT {
    return {
        type: SET_VOUCHER_ACTUAL,
        data,
    };
}

export function clearMostPopular(): ClearMostPopularT {
    return {
        type: CLEAR_MOST_POPULAR
    };
}

export function clearRabatCode(): ClearRabatCodeT {
    return {
        type: CLEAR_RABAT_CODE
    };
}

export function setShopFilters(data: StoreFormFiltersT): SetShopFiltersT {
    return {
        type: SET_SHOP_FILTERS,
        data,
    };
}

export function setShopFiltersLocal(data: StoreFormFiltersT): SetShopFiltersLocalT {
    return {
        type: SET_SHOP_FILTERS_LOCAL,
        data,
    };
}

export function setShopFiltersReset(clearSearch?: boolean): SetShopFiltersResetT {
    return {
        type: SET_SHOP_FILTERS_RESET,
        clearSearch: clearSearch||false
    };
}

export function setShopClaim(claim: ClaimDetailsT | null): SetShopClaimT {
    return {
        type: SET_SHOP_CLAIM,
        claim,
    };
}

export function setShopProductSimple(actualProductSimple: LuggageProductsSimpleT | null): SetShopProductSimpleT {
    return {
        type: SET_SHOP_PRODUCT_SIMPLE,
        actualProductSimple,
    };
}

export function addCartProductSimple(productSimple: LuggageProductsSimpleT, luggage: LuggageT, quantity: number = 1): AddCartProductT {
    return {
        type: ADD_CART_PRODUCT,
        productSimple,
        luggage,
        quantity
    };
}

export function removeCartProduct(product: CartProductT, quantity: number = 1): RemoveCartProductT {
    return {
        type: REMOVE_CART_PRODUCT,
        product,
        quantity
    };
}

export function setCategotyId(id: number): SetCategoryIdT {
    return {
        type: SET_CATEGORY_ID,
        id
    };
}

export function setCategotysId(data: number[]): SetCategorysIdT {
    return {
        type: SET_CATEGORYS_ID,
        data
    };
}

export function setMaxPrice(price: number): SetMaxPriceT {
    return {
        type: SET_MAX_PRICE,
        price
    };
}

export function setVoucherInfoArrow(down: boolean): SetVoucherInfoArrowT {
    return {
        type: SET_VOUCHER_INFO_ARROW,
        down
    };
}

export function setDelivery(delivery: DeliveryT): SetDeliveryT {
    return {
        type: SET_DELIVERY,
        delivery
    };
}

export function setDeliveryData(clientAddress: ClientDataT, clientAddressDelivery: ClientDataT): SetDeliveryDataT {
    return {
        type: SET_DELIVERY_DATA,
        clientAddress,
        clientAddressDelivery
    };
}

export function setConfigShop(configShop: ConfigShopT): SetConfigShopT {
    return {
        type: SET_CONFIG_SHOP,
        configShop
    };
}

export function setRabatCode(rabatCode: RabatCodeT): SetRabatCodeT {
    return {
        type: SET_RABAT_CODE,
        rabatCode
    };
}

export function setOrderResult(orderResult: OrderResultT): SetOrderResultT {
    return {
        type: SET_ORDER_RESULT,
        orderResult
    };
}

export function setAgreeTerms(termsAccept: boolean): SetAgreeTermsT {
    return {
        type: SET_AGREE_TERMS,
        termsAccept
    };
}

export function setValidForm(data: boolean): SetValidFormT {
    return {
        type: SET_VALID_FORM,
        data
    };
}

export function setProductsLimit(data: number): SetProductsLimitT {
    return {
        type: SET_PRODUCTS_LIMIT,
        data
    };
}

export function setOrderSending(data: boolean): SetOrderSendingT {
    return {
        type: SET_ORDER_SENDING,
        data
    };
}

export function clearCart(clearVoucher: boolean = true): SetClearCartT {
    return {
        type: CLEAR_CART,
        clearVoucher
    };
}

export function clearFiltersCategories(): clearFilterCategoriesT {
    return {
        type: CLEAR_FILTERS_CATEGORIES
    };
}

export function fetchConfigShop(claimId: string): FetchConfigShopT {
    return {
        type: FETCH_CONFIG_SHOP,
        claimId
    };
}

export function fetchRabatCode(claimId: string, code: string): FetchRabatCodeT {
    return {
        type: FETCH_RABAT_CODE,
        claimId,
        code
    };
}

export function fetchOrder(order: OrderT): FetchOrderT {
    return {
        type: FETCH_ORDER,
        order
    };
}

export function fetchSaveCart(claimId: string, data: CartDataSendT): FetchSaveCartT {
    return {
        type: FETCH_SAVE_CART,
        claimId,
        data
    };
}

export function fetchReadCart(claimId: string): FetchReadCartT {
    return {
        type: FETCH_READ_CART,
        claimId
    };
}

export function showModalOrderSuccess(data: boolean): ShowModalOrderSuccessT {
    return {
        type: SHOW_MODAL_ORDER_SUCCESS,
        data
    };
}

// ustawienie countera, który odpowiada za zapis stanu koszyka do bazy
export function changeShopCounter(): ChangeShopCounterT {
    return {
        type: CHANGE_SHOP_COUNTER
    };
}

// ustawienie countera, który odpowiada za zapis stanu koszyka do bazy
export function setStoreFromClaim(data: CartDataSendT): SetStoreFromClaimT {
    return {
        type: SET_STORE_FROM_CLAIM,
        data
    };
}

export function setNote(note: string): SetNoteT {
    return {
        type: SET_NOTE,
        note
    };
}

export function clearPayment(): ClearPaymentT {
    return {
        type: CLEAR_PAYMENT
    };
}

export function clearDataStore(): ClearDataStoreT {
    return {
        type: CLEAR_DATA_STORE
    };
}

export function setTryDeliveryMethod(deliveryMethods: DeliveryMethodT[], oldDeliveryMethodName: string): SetTryDeliveryMethodT {
    return {
        type: SET_TRY_DELIVERY_METHOD,
        deliveryMethods,
        oldDeliveryMethodName
    };
}

export const initialsFilters = {
    sortBy: 'default',
    colors: [],
    materials: [],
    price: [0,1000],
    sizes: [],
    categories: [],
    manufacturers: [],
    search: '',
    showListPriceWithVoucher: true,
    categoryId: 0,
};

const defaltCart: CartT = {
    amountSum: 0,
    products: []
}

type PaymentCreditCard = {
    firstName?: string,
    lastName?: string,
    cardNumber: string,
    exp: string,
    cvv: string,
    [key: string]: any
}

const initiClientData: ClientDataT = {
    name: '',
    surname: '',
    companyName: '',
    companyTaxNumber: '',
    apt: '',
    city: '',
    country: '',
    countryCode: '',
    street: '',
    zipCode: '',
    phoneNumber: '',
    email: ''
}

const initialPayment: PaymentsT = {
    paypal: { id: '' },
    wireTransfer: null,
    creditCard: {
        firstName: '',
        lastName: '',
        cardNumber: '',
        cvv: '',
        exp: '',
    }
}

const initialDelivery: DeliveryT = {
    clientAddress: initiClientData,
    clientAddressDeliveryOn: true,
    clientAddressDelivery: initiClientData,
    deliveryMethod: {
        currency: {
            code: '',
            value: 0
        },
        name: "",
        value: 0
    },
    deliverySms: true,
    payment: initialPayment,
    paymentChoose: null,
    deliveryChangeCounter: 0
}

const initialConfigShop: ConfigShopT = {
    storeView: 'pl_eur_polish',
    storeId: 0,
    deliveryMethods: [],
    categories: [],
    paymentTags: [],
    currency: {
        code: 'Eur',
        symbol: '€'
    },
    productsPortion: 3,
    mediaURL: 'https://pl.dolfi.stage.work/pub/media/catalog/product',// default images
    availableLanguages: '',
    availableCurrencies: [],
    noFractionCurrencies: [],
    mostPopAttrs: [],
    colors: [],
    catergoryDefaultId: 0,
    payment: {
        types: [],
        limits: {}
    },
    paymentSkipThreshold: {
        amount: 5,
        currency: 'EUR'
    }
}

const defaultOrderResult: OrderResultT = {
    commentResult: '',
    process: false,
    result: null
}

export const initialState: ShopStateT = {
    voucher: 0,
    rabatCode: { value: 0, code: '', result: null, type: 0, name: '' },
    voucherActual: 0,
    filters: initialsFilters,
    filtersLocal: initialsFilters,
    claim: null,
    actualProductSimple: null,
    cart: defaltCart,
    productsLimit: 12,
    categoriesMarged: {},
    categoryId: 0,
    categorysId: [],
    maxPrice: 0,
    voucherInfoArrowDown: false,
    counterResetSearch: 0,
    delivery: initialDelivery,
    configShop: initialConfigShop,
    termsAccept: false,
    validForm: false,
    note: '',
    orderResult: defaultOrderResult,
    changeShopCounter: 0,
    orderSending: false,
    showModalSuccess: false,
};

// recursive get category structure
// const margeCategories = (categories: StoreCategoryT[], deep: number = 0): StoreCategoryMarged => {
//     let resultCategorys: StoreCategoryMarged = {};
//     categories.filter((cat: StoreCategoryT) => cat.is_active).forEach((cat: StoreCategoryT) => {
//         resultCategorys[cat.id] = cat;
//         if (deep <=1 && cat.children_data.length > 0) {
//             for(let catIn of cat.children_data){
//                 resultCategorys[catIn.id] = catIn;
//             }
//             resultCategorys = {...resultCategorys, ...margeCategories(cat.children_data, deep + 1)};
//         }
//     })
//     return resultCategorys;
// }

type ActionTypeT = SetVoucherActualT | SetShopFiltersT | SetShopClaimT | SetShopFiltersLocalT | SetShopFiltersResetT | SetShopProductSimpleT | AddCartProductT | RemoveCartProductT | SetCategoryIdT | SetCategorysIdT | clearFilterCategoriesT | SetMaxPriceT | SetVoucherInfoArrowT | SetDeliveryT | SetConfigShopT | SetAgreeTermsT | SetValidFormT | SetProductsLimitT | SetRabatCodeT | SetOrderResultT | SetClearCartT | ChangeShopCounterT | SetStoreFromClaimT | SetOrderSendingT | ClearMostPopularT | ClearRabatCodeT | ShowModalOrderSuccessT | SetNoteT | ClearPaymentT | SetTryDeliveryMethodT | ClearDataStoreT | SetDeliveryDataT;

const shopReducer = (state: ShopStateT = initialState, action: ActionTypeT): ShopStateT => {

    const { storeView } = state.configShop;
    const isKRStoreView = storeView.includes('kr_');

    switch (action.type){

        case CHANGE_SHOP_COUNTER:

            return {
                ...state,
                changeShopCounter: state.changeShopCounter + 1
            }

        case SET_VOUCHER_ACTUAL:

            return {
                ...state,
                voucherActual: action.data,
            }

        case SET_TRY_DELIVERY_METHOD: return {
            ...state,
            delivery: {
                ...state.delivery,
                deliveryMethod: !action.deliveryMethods.some(delivery => delivery.name === action.oldDeliveryMethodName) ?
                    action.deliveryMethods[0]:
                    state.delivery.deliveryMethod
            }
        }

        case SET_SHOP_FILTERS:

            // remove most populars
            const configmostPouplar = state.configShop.mostPopAttrs.map((attr) => {
                if (attr.selected){
                    if (attr.label.toLowerCase() === 'bag size' && !action.data.sizes.includes(getMostPopularValue(attr))){
                        attr.selected = false;
                    } else if (attr.label.toLowerCase() === 'bag color'){
                        const _color = state.configShop.colors.find(color => color.name === attr.value);
                        if (!action.data.colors.includes(String(_color?.value))){
                            attr.selected = false;
                        }
                    } else if (attr.label.toLowerCase() === 'material' && !action.data.materials.includes(getMostPopularValue(attr))){
                        attr.selected = false;
                    } else if (attr.label.toLowerCase() === 'manufacturer' && !action.data.manufacturers.includes(getMostPopularValue(attr))){
                        attr.selected = false;
                    }
                }
                return attr;
            });

            return {
                ...state,
                filters: action.data,
                configShop: {
                    ...state.configShop,
                    mostPopAttrs: configmostPouplar
                }
            }

        case SET_SHOP_PRODUCT_SIMPLE:

            return {
                ...state,
                actualProductSimple: action.actualProductSimple,
            }

        case SET_CATEGORY_ID:

            return {
                ...state,
                categoryId: action.id,
            }

        case SET_VOUCHER_INFO_ARROW:

            return {
                ...state,
                voucherInfoArrowDown: action.down
            }

        case SET_AGREE_TERMS:

            return {
                ...state,
                termsAccept: action.termsAccept
            }

        case SET_VALID_FORM:

            return {
                ...state,
                validForm: action.data
            }

        case SHOW_MODAL_ORDER_SUCCESS:

            return {
                ...state,
                showModalSuccess: action.data
            }

        case SET_ORDER_SENDING:

            return {
                ...state,
                orderSending: action.data
            }

        case SET_PRODUCTS_LIMIT:

            return {
                ...state,
                productsLimit: action.data
            }

        case SET_DELIVERY:

            return {
                ...state,
                delivery: action.delivery
            }

        case SET_DELIVERY_DATA:

            return {
                ...state,
                delivery: {
                    ...state.delivery,
                    clientAddress: action.clientAddress,
                    clientAddressDelivery: action.clientAddressDelivery
                }
            }

        case SET_MAX_PRICE:

            return {
                ...state,
                maxPrice: action.price,
                filters: {
                    ...state.filters,
                    price: [0,action.price]
                }
            }

        case SET_CATEGORYS_ID:

            return {
                ...state,
                categorysId: action.data,
            }

        case SET_NOTE:

            return {
                ...state,
                note: action.note,
            }

        case CLEAR_DATA_STORE: return {
            ...state,
            cart: defaltCart,
            rabatCode: initialState.rabatCode,
            delivery: initialState.delivery,
            termsAccept: initialState.termsAccept,
            voucher: initialState.voucher,
            voucherActual: initialState.voucherActual,
            note: initialState.note
        }

        case SET_STORE_FROM_CLAIM:

            return {
                ...state,
                rabatCode: { 
                    code: action.data.rabat.code, 
                    result: action.data.rabat.code ? true : null, 
                    value: action.data.rabat.value, 
                    type: action.data.rabat.type, 
                    name: action.data.rabat.name 
                },
                delivery: {
                    ...action.data.delivery,
                    clientAddress: {
                        ...action.data.delivery.clientAddress,
                        city: action.data.delivery.clientAddress.city || state.delivery.clientAddress.city,
                        street: action.data.delivery.clientAddress.street || state.delivery.clientAddress.street,
                        zipCode: action.data.delivery.clientAddress.zipCode || state.delivery.clientAddress.zipCode,
                    },
                    clientAddressDelivery: {
                        ...action.data.delivery.clientAddressDelivery,
                        city: action.data.delivery.clientAddressDelivery.city || state.delivery.clientAddressDelivery.city,
                        street: action.data.delivery.clientAddressDelivery.street || state.delivery.clientAddressDelivery.street,
                        zipCode: action.data.delivery.clientAddressDelivery.zipCode || state.delivery.clientAddressDelivery.zipCode,
                    }
                },
                termsAccept: action.data.termsAccept,
                voucher: action.data.voucher,
                voucherActual: action.data.sumOrder > action.data.voucher ? 0 : action.data.voucher - action.data.sumOrder,
                cart: {
                    amountSum: action.data.sumOrder,
                    products: action.data.products
                },
                note: action.data?.note || ''
            }

        case CLEAR_CART:

            return {
                ...state,
                cart: defaltCart,
                delivery: initialDelivery,
                orderResult: defaultOrderResult,
                rabatCode: action.clearVoucher ? { value: 0, code: '', result: null, type: 0, name: '' } : state.rabatCode,
                voucher: action.clearVoucher ? 0 : state.voucher,
                voucherActual: action.clearVoucher ? 0 : state.voucherActual,
                validForm: false
            }

        case SET_CONFIG_SHOP:

            return {
                ...state,
                configShop: {
                    ...action.configShop,
                    catergoryDefaultId: action.configShop.categories[0]?.id || 0
                },
                delivery: {
                    ...state.delivery,
                    deliveryMethod: action.configShop.deliveryMethods[0]
                },
            }

        case CLEAR_MOST_POPULAR:

            return {
                ...state,
                configShop: {
                    ...state.configShop,
                    mostPopAttrs: state.configShop.mostPopAttrs.map((attr: MostPopAttrsT) => {
                        attr.selected = false;
                        return attr;
                    })
                }
            }

        case CLEAR_RABAT_CODE:

            return {
                ...state,
                rabatCode: { value: 0, code: '', result: null, type: 0, name: '' }
            }

        case SET_RABAT_CODE:

            return {
                ...state,
                rabatCode: action.rabatCode,
                changeShopCounter: action.rabatCode.result ? state.changeShopCounter + 1 : state.changeShopCounter
            }

        case SET_ORDER_RESULT:

            return {
                ...state,
                orderResult: action.orderResult
            }

        case CLEAR_FILTERS_CATEGORIES:

            return {
                ...state,
                filters: {
                    ...state.filters,
                    categories: []
                },
                filtersLocal: {
                    ...state.filtersLocal,
                    categories: []
                },
            }

        case SET_SHOP_FILTERS_LOCAL:

            return {
                ...state,
                filtersLocal: action.data,
            }

        case SET_SHOP_FILTERS_RESET:

            const initFilters = {
                ...initialsFilters,
                price: [ initialsFilters.price[0], state.maxPrice ]
            };

            const configMostPouplarReset = state.configShop.mostPopAttrs.map((attr) => {
                attr.selected = false;
                return attr;
            });

            return {
                ...state,
                filters: initFilters,
                filtersLocal: initFilters,
                counterResetSearch: action.clearSearch ? state.counterResetSearch + 1 : state.counterResetSearch,
                configShop: {
                    ...state.configShop,
                    mostPopAttrs: configMostPouplarReset
                }
            }

        // dodanie stanu claim do sklepu oraz pobranie z niego vouchera
        case SET_SHOP_CLAIM:

            const voucher =  action?.claim?.assessmentResults && action?.claim?.assessmentResults.length>0 ? action.claim.assessmentResults[action.claim.assessmentResults.length - 1].resolution.voucher : 0;
            const cart = {...state.cart};

            // recalculate cart
            let voucherActualClaim = voucher;
            if (cart.products.length > 0){
                cart.products.map((product: CartProductT) => {

                    if (voucherActualClaim>0){
                        if (product.amountOrigin >= voucherActualClaim){
                            voucherActualClaim = 0;
                        } else {
                            voucherActualClaim -= product.amount;
                        }
                    }

                    return product
                });
                cart.amountSum = cart.products.reduce((accu: number, pr: CartProductT) => accu + pr.amount, 0);
            } else {
                cart.amountSum = 0;
            }

            return {
                ...state,
                claim: action.claim,
                voucher,
                voucherActual: voucherActualClaim,
                cart,
                // changeShopCounter: state.changeShopCounter + 1
            }

        case REMOVE_CART_PRODUCT:

            const quantity = action.quantity && action.quantity>0 ? action.quantity : 1;
            let products = [...state.cart.products];
            let voucherActualRemove = state.voucher;
            
            if (quantity >= action.product.quantity){
                products = products.filter((pr: CartProductT) => pr.productSimpleId !== action.product.productSimpleId);
            } else {
                products.map((pr: CartProductT) => {
                    if (pr.productSimpleId === action.product.productSimpleId){
                        pr.amount -= (pr.amountOrigin * quantity);
                        pr.quantity -= quantity;
                    }
                    return pr
                });
            }

            let amountSumRemove = products.reduce((accu: number, pr: CartProductT) => accu + pr.amount, 0);
            if (voucherActualRemove >= amountSumRemove){
                voucherActualRemove -= amountSumRemove;
            } else {
                voucherActualRemove = 0;
            }

            return {
                ...state,
                cart: {
                    ...state.cart,
                    amountSum: amountSumRemove,
                    products: products
                },
                voucherActual: voucherActualRemove,
                changeShopCounter: state.changeShopCounter + 1
            }

        // Add product to cart
        case ADD_CART_PRODUCT:

            const addQuantity = (action?.quantity && action.quantity>0 ? action.quantity : 1);
            const productPrice = isKRStoreView ? action.productSimple.base_price : action.productSimple.price;
            let voucherActual = state.voucher;

            const productsState = [...state.cart.products];
            const findProduct = productsState.find((pr: CartProductT) => pr.productSimpleId === action.productSimple.id);
            if (findProduct){
                productsState.map((pr: CartProductT) => {
                    if (pr.productSimpleId === action.productSimple.id){
                        pr.quantity += addQuantity;
                        pr.amount = pr.amountOrigin * pr.quantity;
                    }
                    return pr
                });
            } else {
                productsState.push({
                    amount: productPrice * addQuantity,
                    amountOrigin: productPrice,
                    quantity: addQuantity,
                    // productSimple: action.productSimple,
                    // luggage: action.luggage,
                    material: action.luggage.extension_attributes.custom_options?.find((data: any) => data.attribute_code === 'material')?.value || '',
                    size: (action.luggage.custom_attributes?.find((data: any) => data.attribute_code === 'real_size')?.value || '').trim(),
                    weight: action.luggage.weight,
                    configurableId: action.luggage.id,
                    configurableSku: action.luggage.sku,
                    id: action.luggage.id,
                    productSimpleId: action.productSimple.id,
                    productSimpleSku: action.productSimple.sku,
                    productSimpleColor: action.productSimple.color.value,
                    productSimpleManufacturer: action.productSimple.manufacturer || '',
                    productSimpleName: action.productSimple.name
                });
            }

            let sumAddProduct = productsState.reduce((accu: number, pr: CartProductT) => accu + pr.amount, 0);
            if (voucherActual >= sumAddProduct){
                voucherActual -= sumAddProduct;
            } else {
                voucherActual = 0;
            }

            return {
                ...state,
                cart: {
                    ...state.cart,
                    amountSum: sumAddProduct,
                    products: productsState
                },
                voucherActual,
                changeShopCounter: state.changeShopCounter + 1
            }

        case CLEAR_PAYMENT: return {
            ...state,
            delivery: {
                ...state.delivery,
                paymentChoose: null
            }
        }

    }

    return state;
}

export default shopReducer;