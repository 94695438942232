import { useSelector } from 'react-redux';
import cx from 'classnames';

import { CartT, RabatCodeT } from 'Store/modules/luggageStore/shop';
import { PageConfigT, StoreConfigT } from 'Store/modules/page';
import useFormattedPrice from 'Hooks/useFormattedPrice';
import styles from './ShoppingCartTotal.module.scss';
import { calculateCart } from 'Helpers/store';
import { AppStateT } from 'Store/modules';

interface IShoppingCartTotal {
    cart: CartT;
    deliveryPrice: number;
    creditCardServicePrice: number;
    voucherValue: number;
    className?: string;
    currencySymbol: string,
    currencyCode: string,
    rabat: RabatCodeT,
    t: (data: string, obj?: any) => string
}

export default function ShoppingCartTotal(props: IShoppingCartTotal) {
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const storeConfig = useSelector<AppStateT, StoreConfigT>((state) => state.page.storeConfig);
    const dataPrice = calculateCart(props.cart, props.voucherValue, props.rabat, props.deliveryPrice, storeConfig?.paymentSkipThreshold?.amount);

    const formatPrice = useFormattedPrice(props.currencyCode, pageConfig.noFractionCurrencies)

    if (dataPrice.sumProducts <=0 ) return <></>;

    return (
        <div className={cx(styles.root, props.className)}>

            <div className={styles.totalContainer}>
                <div className={styles.totalOptions}>{props.t('shop.subtotal')}: {formatPrice(dataPrice.sumProducts)} {props.currencySymbol}</div>
                {/* <div className={styles.totalOptions}>{props.t('shop.delivery')}: {props.deliveryPrice.toFixed(2)}{props.currency}</div> */}
                <div className={styles.totalOptions}>{props.t('shop.delivery')}: {formatPrice(props.deliveryPrice)} {props.currencySymbol}</div>
                {props.voucherValue > 0 &&
                    <div className={styles.totalOptions}>
                        {props.t('shop.voucher')}: <span className={cx(styles.value, { [styles.checked]: props.voucherValue > 0 })}>{dataPrice.sumProducts > props.voucherValue ? '-' : '+'}
                        {formatPrice(Math.abs(dataPrice.sumProducts > props.voucherValue ? props.voucherValue : props.voucherValue - dataPrice.sumProducts))} {props.currencySymbol} </span>
                    </div>
                }
                {props.rabat.value > 0 &&
                    <div className={styles.totalOptions}>
                        {props.t('shop.discountCode')}: <span className={cx(styles.value, styles.checked)}>-{formatPrice(dataPrice.valueRabat)} {props.currencySymbol}</span>
                    </div>
                }
                {dataPrice.isTotalSpecial ? (
                    <div className={styles.totalSpecial}>
                        {props.t('shop.totalSpecial')}: 
                        <span className={styles.oldPrice}>{formatPrice(dataPrice.total)} {props.currencySymbol}</span>
                        <span className={styles.specialPrice}>{pageConfig.noFractionCurrencies.includes(props.currencyCode) ? 0 : '0.00'} {props.currencySymbol}</span>
                    </div>
                ) : (
                    <div className={styles.total}>
                        {props.t('shop.total')}: {formatPrice(dataPrice.total)} {props.currencySymbol}
                    </div>
                )}
            </div>
        </div>
    );
}
