import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import styles from './PersonalInformation.module.scss';
import { ReactComponent as CloseIcon } from 'Assets/cross-icon.svg';

import { AccountSettingsValueT} from 'Store/modules/accountSettings';
import PersonalInformationMain, {IPersonalInformation} from 'PageComponents/Settings/PersonalInformation/PersonalInformation';
import SideBarDesktop from 'Components/SideBarDesktop/SideBarDesktop';
import AnimatedModal from 'Components/AnimatedModal/AnimatedModal';
import { useDeviceType } from 'Helpers/responsiveContainers';
import { changeShowPersonalInformation } from 'Store/modules/page';
import { AppStateT } from 'Store/modules';
import { useTranslation } from 'react-i18next';

const PersonalInformation: React.FC<IPersonalInformation> = ({ userData, isSubmitting, onSubmit, error, submitted, reset, errEmail, setErrEmail }) => {

    const showPersonalInformation = useSelector<AppStateT, boolean>((state) => state.page.showPersonalInformation);
    const setting = useSelector<AppStateT, AccountSettingsValueT>((state) => state.accountSettings.setting);
    const dispatch = useDispatch();
    const { isMobile } = useDeviceType();
    const { t } = useTranslation();

    const onCloseAction = useCallback(() => {
        dispatch(changeShowPersonalInformation(false));
    }, [dispatch]);



    useLayoutEffect(() => {
        if (!isMobile && showPersonalInformation) {
            const listenerOutside = (event: any) => {
                const elementMenuLanguages = document.querySelector('.personalInfoLink');
                if (!(elementMenuLanguages && elementMenuLanguages.contains(event.target))) {
                    const elementModaBackdrop = document.querySelector('[class^="SideBarDesktop_backdrop"]');
                    const elementModaContent = document.querySelector('[class^="SideBarDesktop_root"]');
                    if (elementModaBackdrop && elementModaContent) {
                        if (!elementModaBackdrop.contains(event.target) && !elementModaContent.contains(event.target)) {
                            return onCloseAction();
                        }
                    }
                }
            };

            const listenerKeyEscape = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    onCloseAction();
                }
            };

            document.addEventListener("mousedown", listenerOutside);
            document.addEventListener("keydown", listenerKeyEscape);

            return () => {
                document.removeEventListener("mousedown", listenerOutside);
                document.removeEventListener("keydown", listenerKeyEscape);
            };
        }
    }, [isMobile, showPersonalInformation, onCloseAction]);

    if (isMobile) {
        return (
            <AnimatedModal
                children={(
                    <div className={styles.personalInformation}>
                        <div className={styles.headerTitleTop}>
                        {t('common.personalInformation')}
                        </div>
                        <div className={cx(styles.personalInformationMobileBody, setting !== '' ? styles.personalInformationBodyContent : null)}>
                        <PersonalInformationMain
                        userData={userData}
                        onSubmit={onSubmit}
                        submitted={submitted}
                        error={error}
                        reset={reset}
                        isSubmitting={isSubmitting}
                        errEmail={errEmail}
                        setErrEmail={setErrEmail}
                        />
                        </div>
                    </div>
                )}
                onClose={onCloseAction}
                open={showPersonalInformation}
                closeOnClickOutside
                containerClassName={styles.messageModalContainer}
                
            />
        );
    }

    return (
        <>
            <SideBarDesktop
                children={(
                    <div className={styles.personalInformation}>
                        <div className={cx(styles.personalInformationBody, setting !== '' ? styles.personalInformationBodyContent : null)}>
               <PersonalInformationMain
                        userData={userData}
                        onSubmit={onSubmit}
                        submitted={submitted}
                        error={error}
                        reset={reset}
                        isSubmitting={isSubmitting}
                        errEmail={errEmail}
                        setErrEmail={setErrEmail}
                />
                        </div>
                    </div>
                )}
                show={showPersonalInformation}
                backdrop
                animation={"right"}
                closeOnClickOutside
                onClose={onCloseAction}
                backdropClassName={styles.backgropDesktop}
                containerClassName={styles.contentDesktop}
                headerTitle={() => (
                    <div className={styles.sideBarTitle}>
                        <div className={styles.title}>
                        {t('common.personalInformation')}
                        </div>
                        <div className={styles.closeIconHeader} onClick={onCloseAction}>
                            <CloseIcon />
                        </div>
                    </div>
                )}
            />
        </>
    );
};

export default PersonalInformation;
