import { SalutationsE } from "Store/Enums/User";
import { AppStateT } from "Store/modules";
import { PageConfigT } from "Store/modules/page";
import type { StateT as UserStateT } from 'Store/modules/user';
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

type ResultDataT = {
    title: string,
    salutation: string
}

const useSalutation = (user: UserStateT['data']): ResultDataT => {

    const { t } = useTranslation();
    const [data, setData] = useState<ResultDataT>({ salutation: '', title: '' });
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);

    useEffect(() => {

        const result: ResultDataT = {
            salutation: '',
            title: ''
        }

        if (user.title && user.title !== 'NONE'){
            const findTitle = Object.entries(pageConfig.pax.title.titleEnum).find(data => data[1] === user.title);
            if (findTitle){
                result.title = t(`registration.${findTitle[0]}`)
            }
        }
        if (user.salutation === SalutationsE.MR){
            result.salutation = t('registration.titleMr');
        } else {
            result.salutation = t('registration.titleMrs');
        }

        setData(result);

    }, [user.salutation, user.title, t, pageConfig.pax.title.titleEnum])

    return data;

}

export default useSalutation;