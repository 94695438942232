import axios, { AxiosInstance } from 'axios';
import config from 'Config';
import { getAddress, AddressT } from 'Helpers/googleApi';

type PlaceCoordinatesT = ICoords;

export type PlaceT = {
  name: string;
  placeId: string;
};

let instance: GoogleLocationService;

export class GoogleLocationService {
  autocomplete: any;
  placesService: any;
  OSMAxiosInstance: AxiosInstance; // OSM - Open street maps

  constructor() {
    this.autocomplete = new window.google.maps.places.AutocompleteService();
    this.placesService = new window.google.maps.places.PlacesService(
      document.createElement('DIV') as HTMLDivElement,
    );
    this.OSMAxiosInstance = axios.create({
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      baseURL: 'https://nominatim.openstreetmap.org/search',
    });
  }

  guess = async (query: string): Promise<PlaceT[]> => {
    try {
      return await this.guessAddressFromQuery(query);
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  guessAddressFromQuery = (query: string): Promise<PlaceT[]> => {
    return new Promise((res, rej) => {
      this.autocomplete.getPlacePredictions(
        { input: query },
        async (response: { description: string; place_id: string }[], status: any) => {
          if (!Array.isArray(response)) {
            rej(status);
            return;
          }

          res(
            response.map((place) => ({
              name: place.description,
              placeId: place.place_id,
            })),
          );
        },
      );
    });
  };

  getPlaceCoordinatesById = (
    placeId: string,
  ): Promise<{ coords: PlaceCoordinatesT; address: AddressT | null }> => {
    return new Promise((res) => {
      this.placesService.getDetails(
        { placeId: placeId, fields: ['geometry.location', 'address_components'] },
        (place: any) => {
          const latLng = place.geometry.location.toJSON();
          const address = getAddress(place.address_components);
          res({
            coords: latLng,
            address,
          });
        },
      );
    });
  };

  reverseGeocode = ({ lat, lng }: ICoords) => {
    return axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=route&key=${config.GOOGLE_API.KEY}`,
    );
  };

  formatReverseGeocodeResult = (data: any) => {
    if (!Array.isArray(data.results)) {
      return;
    }

    if (!data.results.length) {
      return;
    }

    const [location] = data.results;
    const address = getAddress(location.address_components);

    return {
      formatted_address: location.formatted_address,
      location: location.geometry.location,
      address,
    };
  };
}

export default function googleLocationService(): GoogleLocationService {
  if (!instance) {
    instance = new GoogleLocationService();
  }

  return instance;
}
