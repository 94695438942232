import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import useFormattedPrice from 'Hooks/useFormattedPrice';
import { calculateDateDifference, getAgeName } from "Helpers/date";
import { ClaimHistoryT } from 'api/modules/case-resolution';
import useValueVoucher from "Hooks/useValueVoucher";
import { PageConfigT } from 'Store/modules/page';
import { ChatMessageGroup } from 'Components';
import { ClaimT } from 'api/modules/claim';
import { AppStateT } from 'Store/modules';

type PropsMainT = {
    claim: ClaimT,
    statusInfo: ClaimHistoryT,
    t: (text: string, data?: any) => string
}

const InfoChatIrreparable: React.FC<PropsMainT> = ({ claim, statusInfo, t }) => {

    const [messages, setMessages] = useState<{ message: any }[]>([]);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const formatPrice = useFormattedPrice(claim.currencyIsoCode, pageConfig.noFractionCurrencies);
    const rabatData = useValueVoucher(claim, pageConfig.airlinesWithRabatVoucher);

    useEffect(() => {
        const activeResult = claim?.assessmentResults?.find(r => r.isActive);
        const _m: { message: any }[] = [];
        if (claim?.airlineName) {
            if (claim.airlineName === 'Lufthansa' || claim.airlineName === 'Deutsche Lufthansa' || claim.airlineName === 'Swiss International Airlines'){
                if (claim.airlineName === 'Deutsche Lufthansa') {
                    _m.push({ message: t('chatMessages.irreparableDescriptionLH1') });
                }
                _m.push({ message: t('chatMessages.irreparableDescriptionLH2') });
            } else {
                _m.push({ message: t('chatMessages.irreparableDescription1') });
                _m.push({ message: t('chatMessages.irreparableDescription2') });
            }
            _m.push({ message: t('chatMessages.irreparableDescription3', { airlineName: claim.airlineName }) });
        }
        if (activeResult && activeResult.resolution?.refund && activeResult.resolution.refund > 0){
            _m.push({ 
                message: parse(t('chatMessages.irreparableDescription4', { amount: `<span class="price">${formatPrice(+activeResult.resolution.refund)}</span>`, currency: `<span class="price">${claim.currencyIsoCode}</span>` }))
            });
        }
        if (claim?.luggage?.cost && claim?.luggage?.currencyIsoCode){
            _m.push({
                message: parse(t('chatMessages.valueOfPaxValuation', { amount: `<span class="price">${formatPrice(claim?.luggage?.cost)} ${claim.luggage.currencyIsoCode}</span>` }))
            });
        }
        if (claim?.verdict?.purchaseDate && !!claim?.verdict?.assessmentCreatedAt){
            const diffData = calculateDateDifference(claim?.verdict?.purchaseDate, claim?.verdict?.assessmentCreatedAt);
            _m.push({
                message: t('chatMessages.irreparableDescription5', { age: getAgeName((diffData.years * 12) + diffData.months, t)}) 
            });
        }
        if (claim?.verdict?.value >= 0) {
            _m.push({ message: parse(t('chatMessages.irreparableDescription6', { 
                airlineName: claim.airlineName,
                amount: `<span class="price">${formatPrice(rabatData.values.residualValue)}</span>`,
                currency: `<span class="price">${claim.currencyIsoCode}</span>`
            }))});
        }
        if (claim.airlineName.indexOf('Eurowings') >= 0 && claim?.verdict?.resolution?.discountCode && claim?.verdict?.resolution?.voucher === 20) {
            _m.push({ message: t('chatMessages.voucherCodeDescription1', { airlineName: claim.airlineName, amount: '20 EUR' })})
        }
        if (claim?.airlineName.indexOf('Eurowings') < 0 && claim?.verdict?.resolution?.discountCode) {
            _m.push({ message: t('chatMessages.discountCodeDescription1', { percentageAmount: 30})})
            _m.push({ message: parse(t('chatMessages.discountCodeDescription2', { discountCode: `<strong>${claim.verdict.resolution.discountCode}</strong>`}))})
        }

        setMessages(_m);
    }, [claim.assessmentResults, claim.orders, claim.luggage, claim.luggageInput, claim.airlineName, claim.verdict, claim.currencyIsoCode, t, formatPrice, rabatData]);

    if (messages.length === 0) return null;

    return (
        <ChatMessageGroup
            data={messages}
            direction={'incoming'}
            statusInfo={statusInfo}
            as="MessageGroup"
            avatar='bot'
        />
    )
}

export default InfoChatIrreparable;