import type { ClaimStatusHistoryItemT, ClaimT, VerdictT } from 'api/modules/claim';
import { ShipmentUpdateT } from 'api/modules/claim';
import { ResolutionT } from '../../api/modules/case-resolution';

export const WEBSOCKET_ASSESSMENT_TIMEOUT = 'WEBSOCKET_ASSESSMENT_TIMEOUT';
export const WEBSOCKET_CLAIM_VERDICT_RECEIVED = 'WEBSOCKET_CLAIM_VERDICT_RECEIVED';
export const WEBSOCKET_CLAIM_REPORT_RECEIVED = 'WEBSOCKET_CLAIM_REPORT_RECEIVED';
export const WEBSOCKET_CLAIM_REPORT_RESOLVED = 'WEBSOCKET_CLAIM_REPORT_RESOLVED';
export const WEBSOCKET_CLAIM_RESOLUTION_RECEIVED = 'WEBSOCKET_CLAIM_RESOLUTION_RECEIVED';
export const WEBSOCKET_NEW_STATUS_RECEIVED = 'WEBSOCKET_NEW_STATUS_RECEIVED';
export const WEBSOCKET_SHIPMENT_UPDATE = 'WEBSOCKET_SHIPMENT_UPDATE';
export const WEBSOCKET_SET_CATALOGS = 'WEBSOCKET_SET_CATALOGS';
export const WEBSOCKET_SET_CLAIM = 'WEBSOCKET_SET_CLAIM';

export type WebsocketAssessmentTimeoutT = {
  type: typeof WEBSOCKET_ASSESSMENT_TIMEOUT;
  data: { claimId: string; verdict: VerdictT | null; report?: ReportT | null; claim?: ClaimT };
};

export type WebsocketClaimVerdictReceivedT = {
  type: typeof WEBSOCKET_CLAIM_VERDICT_RECEIVED;
  data: VerdictT;
};

export type WebsocketClaimReportReceivedT = {
  type: typeof WEBSOCKET_CLAIM_REPORT_RECEIVED;
  data: ReportT;
};

export type WebsocketClaimResolutionReceivedT = {
  type: typeof WEBSOCKET_CLAIM_RESOLUTION_RECEIVED;
  data: ResolutionT<any>;
};

export type WebsocketSetCatalogsT = {
  type: typeof WEBSOCKET_SET_CATALOGS;
  data: string[];
};

export type WebsocketSetClaimT = {
  type: typeof WEBSOCKET_SET_CLAIM;
  data: ClaimT;
};

export type WebsocketClaimReportResolvedT = {
  type: typeof WEBSOCKET_CLAIM_REPORT_RESOLVED;
  data: {
    claimId: string;
    reportId: string;
  };
};

export type WebsocketNewClaimStatusReceivedT = {
  type: typeof WEBSOCKET_NEW_STATUS_RECEIVED;
  data: ClaimStatusHistoryItemT;
};

export type WebsocketShipmentUpdateT = {
  type: typeof WEBSOCKET_SHIPMENT_UPDATE;
  data: ShipmentUpdateT;
};

export function websocketClaimVerdictReceived(data: VerdictT): WebsocketClaimVerdictReceivedT {
  return {
    type: WEBSOCKET_CLAIM_VERDICT_RECEIVED,
    data,
  };
}

export function websocketAssessmentTimeout(data: {
  claimId: string;
  verdict: VerdictT | null;
  report?: ReportT | null;
  claim?: ClaimT;
}): WebsocketAssessmentTimeoutT {
  return {
    type: WEBSOCKET_ASSESSMENT_TIMEOUT,
    data,
  };
}

export function websocketClaimReportReceived(data: ReportT): WebsocketClaimReportReceivedT {
  return {
    type: WEBSOCKET_CLAIM_REPORT_RECEIVED,
    data,
  };
}

export function websocketSetCatalogs(data: string[]): WebsocketSetCatalogsT {
  return {
    type: WEBSOCKET_SET_CATALOGS,
    data,
  };
}

export function websocketSetClaim(data: ClaimT): WebsocketSetClaimT {
  return {
    type: WEBSOCKET_SET_CLAIM,
    data,
  };
}

export function websocketClaimResolutionReceived(
  data: ResolutionT<any>,
): WebsocketClaimResolutionReceivedT {
  return {
    type: WEBSOCKET_CLAIM_RESOLUTION_RECEIVED,
    data,
  };
}

export function websocketClaimReportResolved(
  claimId: string,
  reportId: string,
): WebsocketClaimReportResolvedT {
  return {
    type: WEBSOCKET_CLAIM_REPORT_RESOLVED,
    data: {
      claimId,
      reportId,
    },
  };
}

export function websocketNewStatusReceived(
  data: ClaimStatusHistoryItemT,
): WebsocketNewClaimStatusReceivedT {
  return {
    type: WEBSOCKET_NEW_STATUS_RECEIVED,
    data,
  };
}

export function websocketShipmentUpdate(data: ShipmentUpdateT) {
  return {
    type: WEBSOCKET_SHIPMENT_UPDATE,
    data,
  };
}
