import cx from 'classnames';
import React from 'react';

import { iPhone } from 'Helpers/browser';
import styles from './Page.module.scss';

interface IProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  centered?: boolean;
  id?: string
}

export default function Page(props: IProps) {
  return (
    <div className={cx(styles.root, props.className, { [styles.centered]: props.centered, [styles.iphone]: iPhone() })}>
      {props.children}
    </div>
  );
}
