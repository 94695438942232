import React from 'react';
import cx from 'classnames';
import styles from './Loader.module.scss';

interface ILoader {
  className?: string;
}

export default function Loader(props: ILoader) {
  return <div className={cx(styles.loader, props.className)} />;
}
