import axios, { AxiosError } from 'axios';

import { ClaimCreateT, LandingStationT, RequiredDocumentT } from 'PageComponents/CreateClaim/CreateClaim/CreateForm/CreateForm';
import { IImage } from 'PageComponents/Chat/ReportUploadBox/ReportUploadBox';
import { formatDateSaleforce } from 'Helpers/date';
import { BACKEND_URL } from 'Config';

export enum ApiSendMethods {
    get = 'get',
    post = 'post',
    delete = 'delete',
    put = 'put',
    patch = 'patch',
}

export enum ErrorsApiE {
    INVALID_EMAIL_OR_PASSWORD = 'INVALID_EMAIL_OR_PASSWORD',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    USER_WITH_EMAIL_NOT_FOUND = 'USER_WITH_EMAIL_NOT_FOUND',
    USER_EMAIL_EXIST = 'USER_EMAIL_EXIST',
    INCORRECT_VERIFICATION_CODE = 'INCORRECT_VERIFICATION_CODE',
    INCORRECT_TOKEN_PAYLOAD = 'INCORRECT_TOKEN_PAYLOAD',
    INSUFFICIENT_PERMISSIONS = 'INSUFFICIENT_PERMISSIONS',
    INVALID_RECOVERY_CODE = 'INVALID_RECOVERY_CODE',
    INVALID_TOKEN_PAYLOAD = 'INVALID_TOKEN_PAYLOAD',
    NEED_PASSWORD_RESET = 'NEED_PASSWORD_RESET',
    VERIFICATION_CODE_EXPIRED = 'VERIFICATION_CODE_EXPIRED',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    ACCOUNT_TEMPORARILY_BLOCKED = 'ACCOUNT_TEMPORARILY_BLOCKED'
}

type ErrorDetailT = {
    message: string,
    path: string[],
    type: string,
    context: {
        label: string,
        key: string
    }
}

export type ResultApiTypeT = {
    result: boolean,
    data: any,
    status: number,
    error: {
        message?: string,
        code?: ErrorsApiE,
        dynamicCode?: number | null,
        errors?: ErrorDetailT[],
        data?: {
            accountBlockedUntil?: string,
            failedLoginAttempts?: number
        }
    }
}

export const url: string = BACKEND_URL;

const runApi = (path: string, data: object = {}, callBack?: (data: ResultApiTypeT) => void, method: ApiSendMethods = ApiSendMethods.post, timeout: number = 300000) => {

    const headers = {
        'Content-Type': 'application/json',
        Authorization: ''
    };

    const token = localStorage.getItem('token');

    if (token !== '' && token !== null) {
        headers.Authorization = 'Bearer ' + token;
    }

    axios({
        method: method,
        url: url + path,
        timeout,
        data,
        headers
    })
        .then((res: any) => {
            callBack?.({
                result: true,
                data: (res.data !== undefined ? res.data : null),
                status: res.status,
                error: {}
            });
        })
        .catch((error: AxiosError) => {
            callBack?.({
                result: false,
                data: null,
                status: +error.response?.status,
                error: {
                    message: error.response?.data?.message ? error.response.data?.message : '',
                    code: error.response?.data?.name ? error.response.data?.name : null,
                    dynamicCode: error.response?.data?.dynamicCode ? error.response.data?.dynamicCode : null,
                    data: error.response?.data?.data ? error.response.data?.data : null,
                    errors: error.response?.data?.details && Array.isArray(error.response.data.details) && error.response.data.details.length > 0 ? error.response.data.details : []
                }
            });
        })

}

interface SendContactDataT {
    phoneNumber: string
}

interface SendClaimT {
    referenceNumber: string,
    airlineName: string,
    airlineId: string,
    airlineDateLanding: string,
    location: {
        formattedAddress: string,
        address: string,
        city: string,
        country: string,
        countryCode: string,
        zipCode: string,
        coords: { lat: number, lng: number },
    },
    luggage: {
        type: string,
        color: string,
        material: string,
        brand: string,
        size: string,
        model: string,
        serialNumber: string,
        date: string | null,
        cost: number,
        length: number,
        capacity: number,
        width: number,
        height: number,
        functions: string,
        currencyIsoCode: string,
        damages: { side: string, type: string }[],
        tagNumber: string,
        fk_caseInfo_bag_id: string | null
    },
    // travelling14days: boolean,
    dateNextTravel: string | null,
    case: {
        files: {
            photos: { name: string, type: string }[],
            documents: { name: string, type: string }[],
        },
        wtValue: number | null,
        wtPurchaseDate: string | null,
        wtCurrencyIsoCode: string | null
    },
    requiredDocuments: RequiredDocumentT[],
    landingStation: LandingStationT | null,
    // missingDocuments: MissingDocumentT[]
    errorCounterSend: number
}

export interface ClaimCreateSendT extends ClaimCreateT {
    fk_caseInfo_bag_id: string | null,
    wtValue: number | null,
    wtPurchaseDate: string | null,
    wtCurrencyIsoCode: string | null
}

export const sendDataClaim = async (claimData: ClaimCreateSendT, errorCounterSend: number, callBack: (data: ResultApiTypeT) => void, requiredDocuments: RequiredDocumentT[]) => {

    const tempFiles = ['front', 'back', 'top', 'bottom', 'left', 'right'];
    let photos: any[] = [];
    tempFiles.forEach(data => {
        if (claimData.photos[data]) {
            const _file = claimData.photos[data] as IImage;
            if (_file) {
                const fileBlob = new File([_file.base64 as any], _file.name, { type: "image/jpg", lastModified: new Date().getTime() })
                photos.push(fileBlob);
            }
        }
    });

    claimData.photoDamages.forEach(_file => {
        const fileBlob = new File([_file.base64 as any], _file.name, { type: "image/jpg", lastModified: new Date().getTime() })
        photos.push(fileBlob);
    });

    claimData.missingDocuments.forEach((doc, idx) => {
        const newFile = new File([doc.document.base64 as any], doc.document.name, { type: 'image/jpeg' });
        photos.push(newFile);
    });

    let compressedFiles: Blob[] = await Promise.all(
        photos.map(async (mediaItem) => {
            return await mediaItem;
        })
    );

    const dataSend: SendClaimT = {
        referenceNumber: claimData.referenceNumberValid && !!claimData.referenceNumber ? claimData.referenceNumber : '',
        airlineName: claimData.referenceNumberStatus !== 'valid' ? claimData.airlineName : '',
        airlineId: claimData.referenceNumberStatus !== 'valid' ? claimData.airlineId : '',
        airlineDateLanding: claimData.referenceNumberStatus !== 'valid' ? (claimData.airlineDateLanding ? formatDateSaleforce(claimData.airlineDateLanding) : '') : '',
        location: {
            formattedAddress: `${claimData.contact.zipCode} ${claimData.contact.city}, ${claimData.contact.address}, ${claimData.contact.country.name}`,
            address: claimData.contact.address,
            city: claimData.contact.city,
            country: claimData.contact.country.name,
            countryCode: claimData.contact.country.code,
            zipCode: claimData.contact.zipCode,
            coords: { lat: claimData.contact.coords.lat ?? 0, lng: claimData.contact.coords.lon ?? 0 },
        },
        luggage: {
            type: claimData.luggageType,
            color: claimData.luggageColor,
            material: claimData.luggageMaterial || '',
            brand: claimData.details.brand,
            model: claimData.details.model,
            size: claimData.details.size,
            serialNumber: claimData.details.serialNumber,
            fk_caseInfo_bag_id: !!claimData.airlineId ? null : claimData.fk_caseInfo_bag_id,
            // date: claimData.details.date ? formatDateSaleforce(claimData.details.date) : '',
            date: claimData.details.date ? claimData.details.date.toISOString() : '',
            cost: claimData.details.costKnow && +claimData.details.cost > 0 ? +claimData.details.cost : null,
            length: claimData.details.length,
            capacity: claimData.details.capacity,
            width: claimData.details.width,
            height: claimData.details.height,
            functions: claimData.details.functions,
            currencyIsoCode: claimData.details.currency,
            damages: claimData.damage,
            tagNumber: claimData.tagNumber
        },
        // travelling14days: Boolean(claimData.contact.travelling14days),
        // dateNextTravel: claimData.dateNextTravel ? formatDateSaleforce(claimData.dateNextTravel) : '',
        dateNextTravel: claimData.dateNextTravel ? claimData.dateNextTravel.toISOString() : null,
        case: {
            files: {
                photos: [...tempFiles.filter(f => !!claimData.photos[f] && String((claimData.photos[f] as IImage)?.file.type).indexOf("image") >= 0).map(f => {
                    const data = claimData.photos[f] as IImage;
                    return {
                        name: data?.name,
                        type: f
                    }
                }), ...claimData.missingDocuments.filter(m => m.document.file.type.indexOf("image") >= 0).map(f => {
                    return {
                        name: f.document.name,
                        type: f.requireDocument.Name + '|missingDocument'
                    }
                }), ...claimData.photoDamages.map(photo => {
                    return {
                        name: photo.name,
                        type: 'damage'
                    }
                })
                ] as any,
                documents: [...tempFiles.filter(f => !!claimData.photos[f] && String((claimData.photos[f] as IImage)?.file.type).indexOf("image") < 0).map(f => {
                    const data = claimData.photos[f] as IImage;
                    return {
                        name: data?.name,
                        type: f
                    }
                }), ...claimData.missingDocuments.filter(m => m.document.file.type.indexOf("image") < 0).map((f, idx) => {
                    return {
                        name: f.document.name,
                        type: f.requireDocument.Name + '|missingDocument'
                    }
                })
                ] as any
            },
            wtValue: claimData.wtValue,
            wtPurchaseDate: claimData.wtPurchaseDate,
            wtCurrencyIsoCode: claimData.wtCurrencyIsoCode
        },
        landingStation: claimData.landingStationSelected,
        // missingDocuments: claimData.missingDocuments
        requiredDocuments,
        errorCounterSend
    };

    const contactData: SendContactDataT = {
        phoneNumber: claimData.contact.prefixPhone ? `+${claimData.contact.prefixPhone}${claimData.contact.phone}` : claimData.contact.phone
    }

    const formData = new FormData();
    formData.append('claimData', JSON.stringify(dataSend));
    if (claimData.contact.phone) {
        formData.append('contactData', JSON.stringify(contactData));
    }

    const files = [];
    photos.forEach((file, idx) => {
        formData.append(`files`, compressedFiles[idx], file.name);
        files.push(file.name);
        // formData.append(`case[files][${idx}]`, JSON.stringify(photosDescriptions[idx]));
    });

    const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: ''
    };

    const token = localStorage.getItem('token');

    if (token !== '' && token !== null) {
        headers.Authorization = 'Bearer ' + token;
    }

    axios({
        method: "post",
        url: url + "claim",
        data: formData,
        headers,
        timeout: 1000 * 60 * 10
    })
        .then(function (response) {
            //handle success
            callBack({
                result: true,
                data: (response.data !== undefined ? response.data : null),
                status: response.status,
                error: {}
            });
        })
        .catch((error: AxiosError) => {
            callBack && callBack({
                result: false,
                data: null,
                status: +error.response?.status,
                error: {
                    message: error.response?.data?.message ? error.response.data?.message : '',
                    code: error.response?.data?.name ? error.response.data?.name : null,
                    dynamicCode: error.response?.data?.dynamicCode ? error.response.data?.dynamicCode : null,
                }
            });
        })
}

export const sendDataFiles = async (path: string, filesSend: { file: IImage }[], callBack: (data: ResultApiTypeT) => void) => {


    const formData = new FormData();

    let photos: any[] = [];
    filesSend.forEach(_file => {
        const fileBlob = new File([_file.file.base64 as any], _file.file?.name, { type: _file.file?.file?.type ? _file.file.file.type : "image/jpg", lastModified: new Date().getTime() })
        photos.push(fileBlob);
    });

    let compressedFiles: Blob[] = await Promise.all(
        photos.map(async (mediaItem) => {
            return await mediaItem;
        })
    );

    photos.forEach((file, idx) => {
        formData.append(`files`, compressedFiles[idx], file.name);
    });

    const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: ''
    };

    const token = localStorage.getItem('token');

    if (token !== '' && token !== null) {
        headers.Authorization = 'Bearer ' + token;
    }

    axios({
        method: "post",
        url: url + path,
        data: formData,
        headers,
        timeout: 1000 * 60 * 5
    })
        .then(function (response) {
            //handle success
            callBack({
                result: true,
                data: (response.data !== undefined ? response.data : null),
                status: response.status,
                error: {}
            });
        })
        .catch((error: AxiosError) => {
            callBack && callBack({
                result: false,
                data: null,
                status: +error.response?.status,
                error: {
                    message: error.response?.data?.message ? error.response.data?.message : '',
                    code: error.response?.data?.name ? error.response.data?.name : null,
                    dynamicCode: error.response?.data?.dynamicCode ? error.response.data?.dynamicCode : null,
                }
            });
        })
}

export default runApi;