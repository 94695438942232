export enum ErrorsEnumE {
    CONFIGURABLE_PRODUCT_NOT_FOUND = 'CONFIGURABLE_PRODUCT_NOT_FOUND',
    COUPON_AMOUNT_INCORRECT = 'COUPON_AMOUNT_INCORRECT',
    ORDER_AMOUNT_TOTAL_INCORRECT = 'ORDER_AMOUNT_TOTAL_INCORRECT',
    ORDER_NOT_CREATED = 'ORDER_NOT_CREATED',
    ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
    PRODUCT_AMOUNT_INCORRECT = 'PRODUCT_AMOUNT_INCORRECT',
    PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
    ORDER_ALREADY_CREATED = 'ORDER_ALREADY_CREATED',
    CLAIM_IS_BLOCKED = 'CLAIM_IS_BLOCKED'
}