import { ClaimDetailsT } from 'api/modules/claim';
import { loading, loaded, error } from 'Helpers/store-async';
export const OPEN_CLAIM_DETAILS_REQUEST = 'CLAIM_DETAILS_REQUEST';
export const OPEN_CLAIM_DETAILS_SUCCESS = 'CLAIM_DETAILS_SUCCESS';
export const OPEN_CLAIM_DETAILS_FAILURE = 'CLAIM_DETAILS_FAILURE';
export const CLAIM_DETAILS_RESET = 'CLAIM_DETAILS_RESET';
export const TOGGLE_RATE_MODAL = 'TOGGLE_RATE_MODAL';
export const DOWNLOAD_CLAIM_INFO_REQUEST = 'DOWNLOAD_CLAIM_INFO_REQUEST';
export const DOWNLOAD_CLAIM_INFO_SUCCESS = 'DOWNLOAD_CLAIM_INFO_SUCCESS';
export const DOWNLOAD_CLAIM_INFO_FAILURE = 'DOWNLOAD_CLAIM_INFO_FAILURE';
export const CLEAR_PDF_ERROR = 'CLEAR_PDF_ERROR';

export type OpenClaimDetailsRequestT = {
  type: typeof OPEN_CLAIM_DETAILS_REQUEST;
  id: string;
  isArchived: boolean
};

export type OpenClaimDetailsSuccessT = {
  type: typeof OPEN_CLAIM_DETAILS_SUCCESS;
  data: ClaimDetailsT;
};

export type OpenClaimDetailsFailureT = {
  type: typeof OPEN_CLAIM_DETAILS_FAILURE;
  error: string;
};

export type ClaimDetailsResetT = {
  type: typeof CLAIM_DETAILS_RESET;
};

export type ModalPayloadT = {
  isModalOpen: boolean;
  rate: number;
};

export type ToggleRateModalT = {
  type: typeof TOGGLE_RATE_MODAL;
  payload: ModalPayloadT;
};

export type DownloadClaimInfoT = {
  type: typeof DOWNLOAD_CLAIM_INFO_REQUEST;
  id: string;
};

export type DownloadClaimInfoSuccessT = {
  type: typeof DOWNLOAD_CLAIM_INFO_SUCCESS;
};

export type DownloadClaimInfoFailureT = {
  type: typeof DOWNLOAD_CLAIM_INFO_FAILURE;
};

export type ClearPdfErrorT = {
  type: typeof CLEAR_PDF_ERROR;
};

export type ActionTypeT =
  | OpenClaimDetailsRequestT
  | OpenClaimDetailsSuccessT
  | OpenClaimDetailsFailureT
  | ClaimDetailsResetT
  | ToggleRateModalT
  | DownloadClaimInfoT
  | DownloadClaimInfoSuccessT
  | DownloadClaimInfoFailureT
  | ClearPdfErrorT;

export type StateT = {
  open: boolean;
  loading: boolean;
  loaded: boolean;
  data: ClaimDetailsT | null;
  error: string | null;
  isModalOpen: boolean;
  rate: number;
  pdfError: boolean;
};

const initialState: StateT = {
  open: false,
  loading: false,
  loaded: false,
  data: null,
  error: null,
  isModalOpen: false,
  rate: 0,
  pdfError: false,
};

export default function claimDetailsReducer(
  state: StateT = initialState,
  action: ActionTypeT,
): StateT {
  switch (action.type) {
    case OPEN_CLAIM_DETAILS_REQUEST: {
      return {
        ...loading<StateT>(state),
        open: true,
      };
    }
    case OPEN_CLAIM_DETAILS_SUCCESS: {
      return loaded<StateT, ClaimDetailsT>(state, action.data);
    }
    case OPEN_CLAIM_DETAILS_FAILURE: {
      return {
        ...error<StateT>(state, action.error),
        open: false,
      };
    }
    case CLAIM_DETAILS_RESET: {
      return {
        ...initialState,
      };
    }
    case TOGGLE_RATE_MODAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case DOWNLOAD_CLAIM_INFO_REQUEST: {
      return {
        ...loading<StateT>(state),
      };
    }
    case DOWNLOAD_CLAIM_INFO_SUCCESS: {
      return {
        ...loaded<StateT>(state),
      };
    }
    case DOWNLOAD_CLAIM_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        pdfError: true,
      };
    }
    case CLEAR_PDF_ERROR: {
      return {
        ...state,
        pdfError: false,
      };
    }
    default: {
      return state;
    }
  }
}

export function openClaimDetailsRequest(id: string, isArchived: boolean): OpenClaimDetailsRequestT {
  return {
    type: OPEN_CLAIM_DETAILS_REQUEST,
    id,
    isArchived
  };
}

export function openClaimDetailsSuccess(data: ClaimDetailsT): OpenClaimDetailsSuccessT {
  return {
    type: OPEN_CLAIM_DETAILS_SUCCESS,
    data,
  };
}

export function openClaimDetailsFailure(error: string): OpenClaimDetailsFailureT {
  return {
    type: OPEN_CLAIM_DETAILS_FAILURE,
    error,
  };
}

export function resetClaimDetailsState(): ClaimDetailsResetT {
  return {
    type: CLAIM_DETAILS_RESET,
  };
}

export function toggleRateModal(payload: ModalPayloadT): ToggleRateModalT {
  return {
    type: TOGGLE_RATE_MODAL,
    payload,
  };
}

export function downloadClaimInfo(id: string): DownloadClaimInfoT {
  return {
    type: DOWNLOAD_CLAIM_INFO_REQUEST,
    id,
  };
}

export function clearPdfError(): ClearPdfErrorT {
  return {
    type: CLEAR_PDF_ERROR,
  };
}
