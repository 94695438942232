import type { ReqTypeT } from '../';

class API {
  r: ReqTypeT;

  constructor(request: ReqTypeT) {
    this.r = request;
  }
}

export default API;

