import React from 'react';

import { ReactComponent as ExclamationIco } from 'Assets/exclamation.svg';
import styles from './Ico.module.scss';

type MainProps = {
    action: () => void,
    type?: 'exclamation'
}

const Ico: React.FC<MainProps> = ({ action, type = 'exclamation' }) => {
    if (type === 'exclamation'){
        return <ExclamationIco onClick={action} className={styles.ico} />
    }
    return <ExclamationIco onClick={action} className={styles.ico} />
}

export default Ico;