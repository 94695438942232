import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import React from 'react';

import animationSuccess from 'Assets/lotties/success.json';
import animationErrror from 'Assets/lotties/error.json';
import Button from 'Components/Button/Button';
import style from './AlertInfo.module.scss';
import Modal from 'Components/Modal/Modal';

const lottieOptions = {
    loop: false,
    // animationData: animationData,
};
  
type MainPropsT = {
    actionClose: () => void,
    type: 'success'|'error',
    description: string,
    isMobile?: boolean
}

const AlertInfo: React.FC<MainPropsT> = ({ actionClose, description, type, isMobile }) => {

    const { t } = useTranslation();

    return (
        <Modal open onClose={actionClose} hideTitle>
            <div className={style.root}>
                <div className={style.lottie}>
                    <Lottie
                        options={{
                            ...lottieOptions, 
                            animationData: type === 'success' ? animationSuccess : animationErrror
                        }} 
                        height={120} 
                        width={120}
                    />
                </div>
                <div className={style.description}>{description}</div>
                <div className={style.buttons}>
                    <Button color={'primary'} onClick={actionClose}>
                        {t('common.close')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
export default AlertInfo;