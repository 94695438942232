import React, { useMemo } from 'react';
import cx from 'classnames';
import styles from './RatioBox.module.scss';

interface IRatioBlock {
  width: number;
  height: number;
  children: React.ReactNode | React.ReactElement[] | React.ReactElement;
  className?: string;
}

export default function RatioBox(props: IRatioBlock) {
  const ratio = useMemo(() => {
    const boxRatio = (props.height / props.width) * 100;
    const roundedRatio = boxRatio.toFixed(5);

    return `${roundedRatio}%`;
  }, [props.width, props.height]);

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.placeholder} style={{ paddingTop: ratio }} />
      <div className={styles.content}>{props.children}</div>
    </div>
  );
}
