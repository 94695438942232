import { call, put, take, takeLatest } from 'redux-saga/effects';
import { EventChannel } from '@redux-saga/core';
import { eventChannel } from 'redux-saga';
import io from 'socket.io-client';
import {
  websocketClaimReportResolved,
  websocketNewStatusReceived,
  websocketShipmentUpdate,
} from 'Store/modules/websocket';

import { socketsSuccessAutomaticResolution, updateClaim, websocketChatAddMessage } from 'Store/modules/claimChat/data';
import { actionUpdateClaimFromList, removeMissingDocumentExamples } from 'Store/modules/claim';
import { ResolutionT } from '../../api/modules/case-resolution';
import type { ClaimT, VerdictT } from 'api/modules/claim';
import { NOTIFICATION_STATUSES } from 'Config/statuses';
import { SET_USER, SetUserT } from 'Store/modules/user';
import { WEBSOCKET_URL } from 'Config';

function initClaimSocket(jwtToken: string): EventChannel<any> {
  console.log('initClaimSocket = ', `${WEBSOCKET_URL}user`);
  return eventChannel((emitter) => {
    const socket = io(`${WEBSOCKET_URL}user`, {
      query: { token: jwtToken },
    });

    socket.on(
      'claimUpdate',
      (data: any) => {
        console.log('socket change claim: ', data);
        emitter(updateClaim(data));
        // emitter(actionUpdateClaimFromList(data));
        // emitter(reload());
      },
    );

    // Server events
    socket.on(
      'verdict',
      (data: {
        claimId: string;
        verdict: VerdictT | null;
        report?: ReportT | null;
        claim?: ClaimT;
        resolution?: ResolutionT<any> | null;
        catalogs?: []
      }) => {
        console.log('socket change verdict: ', data);
        if (data.claim){
          emitter(removeMissingDocumentExamples(data.claim.id));
          emitter(updateClaim({
            ...data.claim,
            verdict: data?.verdict || data.claim?.verdict
          }));
          emitter(actionUpdateClaimFromList(data.claim));
          // emitter(reload());
        }
      },
    );

    socket.on('reportResolved', ({ reportId, claimId }: { reportId: string; claimId: string }) => {
      emitter(websocketClaimReportResolved(claimId, reportId));
    });

    socket.on('claimNotification', (data: { claimId: string, automaticResolution: boolean }) => {
      console.log('socket notifications: ', data);
      if (data.automaticResolution){
        emitter(socketsSuccessAutomaticResolution(data.automaticResolution));
      }
    });

    socket.on('claimChatMessage', (data: any) => {
        emitter(websocketChatAddMessage(data));
    });

    socket.on(
      'notification',
      ({ notification }: { notification: { type: string; payload: any } }) => {
        if (notification.type === NOTIFICATION_STATUSES.STATUS_UPDATE) {
          if (notification.payload.isNeedRefresh) {
            window.location.reload();
          }
          emitter(websocketNewStatusReceived(notification.payload));
        }
        if (notification.type === NOTIFICATION_STATUSES.SHIPMENT_UPDATE) {
          emitter(websocketShipmentUpdate(notification.payload));
        }
      },
    );

    socket.on('serviceMessage', (meassage: any) => {

      console.log("==== Service backend Message ====");
      console.log(JSON.parse(meassage));
      console.log("==== END ====");

    });

    // Unsubscribe function
    return () => {
      socket.close();
    };
  });
}

function* openSocketChannel({ token }: SetUserT): any {
  const channel = yield call(initClaimSocket, token);

  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

export default function* websocket() {
  yield takeLatest(SET_USER, openSocketChannel);
}
