import Lottie from 'react-lottie';
import cx from 'classnames';
import React from 'react';

import animationDataError from 'Assets/lotties/error.json';

import { useDeviceType } from 'Helpers/responsiveContainers';
import style from './ModalError.module.scss';
import { Button, Modal } from 'Components';

type MainProps = {
    actionClose: () => void,
    title: string, 
    open: boolean,
    t: (data: string) => string,
}

const ModalError: React.FC<MainProps> = ({ actionClose, open, t, title }) => {

    const { isMobile } = useDeviceType();

    return (
        <Modal animatedContentClassName={cx(style.root, { [style.mobile]: isMobile })} open={open} onClose={actionClose} hideTitle>
            <div className={style.body}>
                {open &&
                    <>
                        <div className={style.lottie}>
                            <Lottie options={{ loop: false, animationData: animationDataError }} height={150} width={150} />
                        </div>
                        <div className={style.header}>
                            {title}
                        </div>
                        <div className={style.buttons}>
                            <Button onClick={actionClose} color={'secondary'}>
                                {t('common.close')}
                            </Button>
                        </div>
                    </>
                }
            </div>
        </Modal>
    );
}
export default React.memo(ModalError);
