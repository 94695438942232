import React from 'react';
import cx from 'classnames';
import NoImageSvgPath from 'Assets/no-image.svg';

import styles from './CaseListItemDesktop.module.scss';

type Props = {
  img?: string | null;
  referenceNumber: string;
  creationDate: string;
  id: string;
  location: string;
  active: boolean;
  onClick: () => void;
  unreadMessages: number;
  airlineName?: string
};

function CaseListItemDesktop({ img, referenceNumber, creationDate, id, location, active, onClick, unreadMessages, airlineName }: Props) {
  // console.log('fileData: ', fileData);
  return (
    <div
      className={cx(styles.messageField, {
        [styles.activeField]: active,
      })}
      onClick={onClick}
    >
      <div>
        {!!img && (
          <img src={img || NoImageSvgPath} alt={referenceNumber} className={styles.img} />
        )}
      </div>
      <div className={styles.itemContent}>
        <div className={styles.textContainer}>
          <div className={styles.referenceNumber}>{referenceNumber || (airlineName || '-')}</div>
          <div className={styles.createDate}>{creationDate}</div>
        </div>
        <div className={cx(styles.textContainer, styles.rightAlign)}>
          <div className={styles.referenceNumber}>{id}</div>
          <div className={styles.createDate}>{location}</div>
        </div>
      </div>
      {!!unreadMessages && (
        <div>
          <span className={styles.newMessages}>{unreadMessages}</span>
        </div>
      )}
    </div>
  );
}

export default CaseListItemDesktop;
