import { CLAIM_STATUSES } from "Config/statuses";

export const chatAutomaticResolutionWaitSeconds = 120;
export const statusesExtendToShowImmediately = [
    CLAIM_STATUSES.SHIPMENT_OUT_FOR_DELIVERY,
    CLAIM_STATUSES.SHIPMENT_IN_PROCESS,
    CLAIM_STATUSES.SHIPMENT_PARTLY_SHIPPED,
    CLAIM_STATUSES.SHIPMENT_COMPLETED,
    CLAIM_STATUSES.SOLUTION_PICKUP_PENDING,
    CLAIM_STATUSES.CLOSED,
    CLAIM_STATUSES.INVOICING,
    CLAIM_STATUSES.SOLUTION_DROP_OFF
];
export const shopRequireNipCountrys = ['BR'];
export const maxProductsInShoppingCart = 10;
export const variableCheckModalStoreSuccess = 'fromStoreSuccess'