import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'api';
import {
  SendReportRequestT,
  SEND_REPORT_REQUEST,
  sendReportSuccess,
  sendReportFailure,
} from 'Store/modules/claimChat/report';
import { updateClaim } from 'Store/modules/claimChat/data';

async function createReportFormData(data: { [key in ReportTagT]: any }): Promise<FormData> {
  const formData = new FormData();

  Object.entries(data).forEach(([field, value]) => {
    const isInput = typeof value === 'string';


    if (isInput) {
      formData.append(field, value);
    } else {
      if (Array.isArray(value)) {
        value.forEach((file) => {
          formData.append(field, file.file, file.name);
        });
      } else {
        formData.append(field, value.file, value.name);
      }
    }
  });

  return formData;
}

function* sendReportSaga({ data: { report, data } }: SendReportRequestT): any {
  try {
    const formData = yield createReportFormData(data);
    const { data: updatedReport } = yield call(
      api().claim.report,
      report.claimId,
      report.id,
      formData,
    );
    
    const { data: claim } = yield call(api().claim.getById, report.claimId, false);
    yield put(updateClaim(claim));
    yield put(sendReportSuccess(updatedReport));
  } catch (err) {
    yield put(sendReportFailure(err as any));
  }
}

export default function* report(): Generator {
  yield takeLatest(SEND_REPORT_REQUEST, sendReportSaga);
}
