import { DeliveryMethodT, ShopStateT } from 'Store/modules/luggageStore/shop';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useMemo } from "react";
import cx from 'classnames';

import styles from './DeliveryOptions.module.scss';
import { filterDelivery } from "Helpers/store";
import { AppStateT } from 'Store/modules';

interface IDeliveryOptionsItem {
    className?: string;
    item: DeliveryMethodT,
    active: boolean;
    onClick: (value: DeliveryMethodT) => void;
    currency?: string,
    disabled?: boolean,
    t: (data: string) => string
}

export function DeliveryOptionsItem(props: IDeliveryOptionsItem) {

    const getDeliveryName = useMemo(() => (name: string): string => {
        const translateName = props.t(`shop.${name}`);
        if (translateName !== name) return translateName;
        return name;
    }, [props]);

    return (
        <div className={cx(styles.deliveryOptionContainer, props.className, { [styles.disabled]: props.disabled })} onClick={() => !props.disabled ? props.onClick(props.item) : null}>
            <div className={styles.textContainer}>
                <div className={styles.title}>{getDeliveryName(props.item.name)} - {props.item.value || 0} {props.currency || '€'}</div>
            </div>
            <div className={cx(styles.circle, {[styles.circleActive]: props.active, [styles.disabled]: props.disabled})}>
                <div className={styles.smallcircle} />
            </div>
        </div>
    );
}

interface IDeliveryOptions {
    onValueChange: (value: DeliveryMethodT) => void;
    receiveSmsMassage: boolean;
    onReceiveSmsMassageChange: (newValue: boolean) => void;
    className?: string;
    value?: DeliveryMethodT
}

function DeliveryOptions(props: IDeliveryOptions) {

    const { configShop, delivery } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const { t } = useTranslation();
    
    return (
        <div className={cx(styles.root, props.className)}>
            {filterDelivery(delivery.paymentChoose, configShop.deliveryMethods, configShop.payment.types).map(value => (
                <DeliveryOptionsItem
                    key={value.name}
                    item={value}
                    onClick={(value) => props.onValueChange(value)}
                    active={props.value && props.value.name === value.name}
                    currency={configShop.currency.symbol}
                    t={t}
                />
            ))}
        </div>
    );
}

export default DeliveryOptions;
