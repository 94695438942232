import React, { useState } from 'react';

import ImageGallery from "Components/ImageGallery/ImageGallery";
import style from './Examples.module.scss';

type MainPropsT = {
    examplesUrl: string[],
    t: (data: string, params?: any) => string
}

const Examples: React.FC<MainPropsT> = ({ examplesUrl, t }) => {

    const [openGalleryIndex, setOpenGalleryIndex] = useState(-1);

    return (
        <>
            <div className={style.root}>
                <div className={style.header}>{t('createClaim.rnExamples')}</div>
                <div className={style.examples}>
                    {examplesUrl.map((url, idx) => (
                        <div
                            key={url}
                            className={style.example}
                            style={{ backgroundImage: `url(${url})` }}
                            onClick={() => setOpenGalleryIndex(idx)}
                        />
                    ))}
                </div>
            </div>
            {(openGalleryIndex >=0 && examplesUrl.length > 0) &&
                <ImageGallery
                    actionClose={() => setOpenGalleryIndex(-1)}
                    images={examplesUrl}
                    startIndex={openGalleryIndex}
                />
            }
        </>
    );
}
export default Examples;