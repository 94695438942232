import React from 'react';
import cx from 'classnames';

import styles from './SwitchPriceType.module.scss';

type MainProps = {
    showListPriceWithVoucher: boolean,
    actionChange: (status: boolean) => void,
    t: (data: string) => string
}

const SwitchPriceType: React.FC<MainProps> = ({ showListPriceWithVoucher, actionChange, t }) => {
    return (
        <div className={styles.main}>
            <div 
                className={cx(styles.option, { [styles.selected]: showListPriceWithVoucher ? false : true })}
                onClick={() => actionChange(false)}
            >
                {t('shop.switcherTopOfficial')}
            </div>
            <div
                className={cx(styles.option, { [styles.selected]: showListPriceWithVoucher ? true : false})}
                onClick={() => actionChange(true)}
            >
                {t('shop.switcherTopYours')}
            </div>
        </div>
    );
}
export default SwitchPriceType;
