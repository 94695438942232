import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from 'classnames';

import { clearRabatCode, fetchRabatCode, setRabatCode, ShopStateT } from "Store/modules/luggageStore/shop";
import styles from './ShoppingCartVoucher.module.scss';
import { runNotify } from "Helpers/notifications";
import { ClaimDetailsT } from 'api/modules/claim';
import { AppStateT } from "Store/modules";
import { Loader } from 'Components';
import Store from './Store';


// Data type of voucher
type VoucherT = {
    name: string,
    value: number
}

export interface LocalStoreT{
    voucherValue: number,
    deliveryPrice: number,
    vourcherValid: boolean,
    inputVoucherFocus: boolean,
    textVoucher: string,
    voucher: VoucherT|null,
    checking: boolean,
    resultVaucher: 'noResult'|'positive'|'negative' 
}

const defaultState: LocalStoreT = {
    voucherValue: 0,
    deliveryPrice: 5,
    vourcherValid: false,
    inputVoucherFocus: false,
    textVoucher: '',
    voucher: null,
    checking: false,
    resultVaucher: 'noResult'
}

type MainPropsT = {
    isMobile?: boolean,
    claimId: string
}

const ShoppingCartVoucher: React.FC<MainPropsT> = ({ isMobile = false, claimId = '' }) => {

    const claim = useSelector<AppStateT, ClaimDetailsT | null>(state => state.claimDetails.data);
    const { rabatCode } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const [hasCheckedVoucher, setHasCheckedVoucher] = useState(false);
    const [state, setState] = useReducer(Store, defaultState);
    const ref = useRef<HTMLInputElement>();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // set single data on local state
    const setSt = useCallback((dataType: string, data: any) => {
        setState({ type: 'SET_DATA', dataType, data });
    }, []);

    useEffect(() => {
        if (rabatCode.result || (!hasCheckedVoucher && claim?.verdict?.resolution?.discountCode && claim?.verdict?.resolution?.voucher === 0)){
            setSt('textVoucher', rabatCode.code || claim?.verdict.resolution.discountCode);
        }
    }, [rabatCode, claim?.verdict?.resolution?.discountCode, claim?.verdict?.resolution?.voucher, setSt, hasCheckedVoucher]);

    useEffect(() => {
        if (state.checking && rabatCode.result){
            runNotify({ message: t('shop.discountCodeActivated'), type: 'success' });
        }
        if (state.checking && rabatCode.result !== null){
            setSt('checking', false);
        }
    }, [state.checking, rabatCode.result, setSt, t]);
    
    const clearRabatCodeResult = useCallback(() => {
        dispatch(setRabatCode({ ...rabatCode, result: null }));
    }, [dispatch, rabatCode])

    const validationVoucher = useCallback((text: string): boolean => {
        return text.trim().length >=5;
    }, []);

    const handleChangeTextVoucher = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toUpperCase();
        setSt('textVoucher', value);
        setSt('vourcherValid', validationVoucher(value));
    }, [setSt, validationVoucher]);

    const checkVoucher = useCallback(() => {
        if (!state.checking){
            setSt('checking', true);
            dispatch(fetchRabatCode(claimId, state.textVoucher));
        }
    }, [dispatch, setSt, claimId, state.textVoucher, state.checking]);

    const removeVoucherCode= () => {
        dispatch(clearRabatCode());
        setSt('textVoucher', '');
        runNotify({ message: t('shop.discountCodeRemoved') , type: 'error'});
    }

    useEffect(() => {
        if (!hasCheckedVoucher && state.textVoucher === claim?.verdict?.resolution?.discountCode) {
            checkVoucher();
            setHasCheckedVoucher(true);
        }
    }, [hasCheckedVoucher, state.textVoucher, claim?.verdict?.resolution?.discountCode, checkVoucher]);

    return (
        <div className={
            cx(
                styles.voucharLayer, { 
                    [styles.checking]: state.checking, 
                    [styles.accepted]: !!rabatCode.result, 
                    [styles.error]: rabatCode.result === false, 
                    [styles.mobile]: isMobile
                }
            )}
        >
            <label>{t('shop.haveAdiscountCode')}</label>
            {state.checking && <Loader className={styles.loaderVoucher} />}
            <div className={styles.inputVoucherLayer}>
                <span
                    // className={cx((state.inputVoucherFocus || state.textVoucher !== '') && styles['focused'])}
                    className={cx({ [styles.focused]: state.inputVoucherFocus || state.textVoucher !== '' })}
                    onClick={() => state.inputVoucherFocus ? null : ref.current?.focus()}
                >
                    {t('shop.discountCode')}
                </span>
                <input
                    ref={ref as any}
                    onFocus={() => {
                        setSt('inputVoucherFocus', true);
                        setSt('resultVaucher', 'noResult');
                        if (rabatCode.result === false){
                            clearRabatCodeResult();
                        }
                    }}
                    onBlur={() => setSt('inputVoucherFocus', false)}
                    onChange={handleChangeTextVoucher}
                    value={state.textVoucher}
                    disabled={state.checking || !!rabatCode.result}
                />
                {state.resultVaucher === 'negative' &&
                    <div className={styles.noValidVoucherLabel}>{t('shop.discountInvalidDiscountCode')}</div>
                }
            </div>
            {rabatCode.result === false &&
                <div className={styles.wrongCodeLayer}>{t('shop.discountInvalidDiscountCode')}</div>
            }
            {!rabatCode.result ?
                <button
                    onClick={checkVoucher}
                    disabled={state.checking || !!rabatCode.result || state.textVoucher.trim().length <5}
                    className={styles.disabled}
                >
                    {t('shop.discountCodeRecalculate')}
                </button>:
                <button
                    onClick={removeVoucherCode}
                    disabled={false}
                >
                    {t('shop.discountCodeRemove')}
                </button>

            }
        </div>
    )
}

export default ShoppingCartVoucher;