import React, { useEffect, useState, useCallback } from 'react';
import { ChatMessageGroup } from 'Components';
import { useSelector } from 'react-redux';

import { ReactComponent as UploadIcoDoc } from 'Assets/upload-doc.svg';
import { ReactComponent as UploadIcoPdf } from 'Assets/upload-pdf.svg';
import { ReactComponent as IcoInfo } from 'Assets/info-btn.svg';

import { RequiredDocumentT } from 'PageComponents/CreateClaim/CreateClaim/CreateForm/CreateForm';
import type { StateT as ChatDataStateT } from 'Store/modules/claimChat/data';
import ContactBranch from 'Components/ContactBranch/ContactBranch';
import ImageDecrypt from "Components/ImageDecrypt/ImageDecrypt";
import { type StateT as UserStateT } from 'Store/modules/user';
import useReportTagTranslation from "Hooks/useOriginalTags";
import { runNotify } from "Helpers/notifications";
import { AIRPORT_DOCUMENT } from '../ReportBlock';
import Loading from "Components/Loading/Loading";
import type { AppStateT } from 'Store/modules';
import { getUrlOfImage } from "services/api";
import style from './Resolution.module.scss';
import { getFileData } from 'Helpers/files';
import { sizeAdapter } from 'Helpers/claim';

type MainPropsT = {
    t: (data: any) => string,
    report: ReportT,
    tagToNameData: { [key in string]: string },
    requireDocuments: RequiredDocumentT[],
    user: UserStateT['data']
}

const Resolution: React.FC<MainPropsT> = ({ t, report, tagToNameData, requireDocuments, user }) => {
    const [isReportedOriginal, setIsReportedOriginal] = useState(false);
    const { claim } = useSelector<AppStateT,Pick<ChatDataStateT, 'claim'>>(({ claimChat }) => ({claim: claimChat.data.claim}));
    const messages = [
        { 
            render: () => (
                <>{t('report.userResponseMessage')}</>
            ),
            className: style.chatMessage
        }
    ];
    const [loadingDocumentKey, setLoadingDocumentKey] = useState<string>('');

    const downloadInfo = useCallback((documentKey: string) => {
        setLoadingDocumentKey(documentKey);
        getUrlOfImage(documentKey, (result: boolean, url: string|undefined) => {
            setLoadingDocumentKey('');
            if (result && url){
                window.open(url);
            } else {
                runNotify({ message: t('shop.errorAddClaim'), type: 'error' });
            }
        });
    }, [t]);

    const getTitle = useReportTagTranslation(t);

    useEffect(() => {
        if (claim && !claim?.verdict && claim.reports[claim.reports?.length - 1]?.tags && claim.reports[claim.reports?.length - 1].tags?.some(t => t.value.indexOf('_ORIGINAL') >= 0)
            && claim.reports[claim.reports?.length - 1].tags?.length !== claim.reports[claim.reports?.length - 1].resolution?.length) {
          setIsReportedOriginal(true);
        } else {
          setIsReportedOriginal(false)
        }
    }, [claim]);

    const getTagValue = useCallback((res: any) => {
        if (res.tag === 'SIZE') {
            return sizeAdapter(res.input, user.location.countryCode, user.language)
        } else if (res.tag.indexOf('_ORIGINAL') >= 0) {
            return t('reportTags.deliveredOriginal')
        } else {
            return res.input
        }
    }, [user.location.countryCode, user.language, t])

    const DataTag = (res: any, fileDocumentName: string|null) => {

        return (
            <>
                <div className={style.tagTitle}>
                    {t(tagToNameData[res.tag])}
                    {fileDocumentName &&
                        <strong>: {fileDocumentName}</strong>
                    }
                </div>
                {res?.files && Array.isArray(res.files) ?
                    <div className={style.tagPhotos}>
                        {res.files.map((file: any) => {
                            const dataImage = getFileData(file.url);
                            if (!(dataImage.ext === 'jpg' || dataImage.ext === 'jpeg' || dataImage.ext === 'png' || dataImage.ext === 'bmp' || dataImage.ext === 'gif' || dataImage.ext === 'webp')){
                                if (dataImage.ext === 'pdf'){
                                    return (
                                        <React.Fragment key={`k-${file.key}`}>
                                            {file.key === loadingDocumentKey ?
                                                <Loading size={40} />:
                                                <UploadIcoPdf className={style.thumbSvg} onClick={() => downloadInfo(file.key)} />
                                            }
                                        </React.Fragment>
                                    )
                                }
                                return <UploadIcoDoc className={style.thumbSvg} key={`k-${file.key}`} />
                            } else return (
                                <div key={`im-${file.url}`}>
                                    <ImageDecrypt imageKey={file.key} key={`k-${file.key}`} />
                                </div>
                            )
                        })}
                    </div>:
                    <div className={style.tagValue}>{getTagValue(res)}</div>
                }
            </>
        );
    }

    return (
        <>
            <ChatMessageGroup
                    direction="outgoing"
                    className={style.chatMessage}
                    data={[...messages, ...report.resolution.map((res, idx) => {
                        const fileDocumentName = 
                                res.tag === AIRPORT_DOCUMENT
                            ?
                            report.tags[idx].value
                            : null;
                        return {
                            render: () => (
                                <>{DataTag(res, fileDocumentName)}</>
                            )
                        }
                    })]}
                    avatar="bot"
                />
                { isReportedOriginal && (
                    <div className={style.waitingOriginal}>
                        <div className={style.ico}>
                            <IcoInfo />
                        </div>
                        <div className={style.header}>
                            {t('chatMessages.reqDocsOriginal')}
                        </div>
                        <ul>
                            {claim.reports[claim.reports.length - 1]?.tags.filter(t => t.value.indexOf('_ORIGINAL') >= 0).map(tag => (
                                <li key={`doc-${tag.value}`}><strong>{getTitle(tag.value)}</strong></li>
                            ))}
                        </ul>
                        <ContactBranch title={t('chatMessages.reqDocsOriginalContactHeader')} hideDirectContact claim={claim} />
                    </div>
                )}
        </>
    );
}
export default React.memo(Resolution);