import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'api';
import {
  STORE_CATALOG_REQUEST,
  fetchStoreCatalogSuccess,
  fetchStoreCatalogFailure,
  FetchStoreCatalogRequestT,
} from 'Store/modules/luggageStore/catalog';
import { FetchConfigShopT, FetchOrderT, FetchRabatCodeT, FetchSaveCartT, FETCH_CONFIG_SHOP, FETCH_ORDER, FETCH_RABAT_CODE, FETCH_SAVE_CART, initialsFilters, setConfigShop, setMaxPrice, setOrderResult, setOrderSending, setRabatCode, setShopFiltersLocal, setStoreFromClaim } from 'Store/modules/luggageStore/shop';
import { clearSensitiveDataCart, filterDataStore } from "services/shop";
import { LuggageT } from 'api/modules/store';


function* fetchStoreCatalogSaga({ claimId, storeView }: FetchStoreCatalogRequestT): any {
  try {
    const response = yield call(api().store.catalog, claimId);
    const isKRStoreView = storeView.includes('kr_');
    // filtracja danych produktów, wykluczamy z ceną 0 i mniejsza oraz te , które w simple_products mają jakiś kolor typu 1 (hex)
    // const data: LuggageT[] = response.data.filter((d: LuggageT) => (isKRStoreView ? d?.extension_attributes?.simple_products[0]?.base_price : d?.extension_attributes?.simple_products[0]?.price) > 0 && d.extension_attributes.simple_products.some(si => si.color?.type === 1)).map((d: LuggageT) => {
    const data: LuggageT[] = response.data.filter((d: LuggageT) => filterDataStore(d, isKRStoreView)).map((d: LuggageT) => {
      d.price = (isKRStoreView ? d?.extension_attributes?.simple_products[0]?.base_price : d?.extension_attributes?.simple_products[0]?.price) || d.price;
      return d;
    });
    const indexedProdusts = [];
    data.forEach((data, idx) => {
      indexedProdusts[data.id] = data;
    });
    const maxPrice = data.reduce((accu: number, product: LuggageT) => accu < product.price ? product.price : accu,0);
    yield put(fetchStoreCatalogSuccess(data, indexedProdusts));

    yield put(setMaxPrice(maxPrice));
    yield put(setShopFiltersLocal({
      ...initialsFilters,
      price: [0, maxPrice]
    }));

    /*
     * Read data cart from database claim
     **/
    const { data: dataCart } = yield call(api().store.readCart, claimId);
    if (dataCart){

      let _sum = 0;

      const products = [];
      for(let i=0;i<dataCart.products.length;i++){
        const _product = data.find((product: LuggageT) => product.id === dataCart.products[i].id);
        if (_product){

          // OLD WAY check//  to remove in future (after 09.2024)
          let prSimple = _product.extension_attributes.simple_products.find(p => p.id === dataCart.products[i]?.productSimple?.id);
          if (prSimple){
            const productPrice = isKRStoreView ? prSimple.base_price : prSimple.price;
            _sum += (productPrice * dataCart.products[i].quantity);
            products.push({
              amount: productPrice * dataCart.products[i].quantity,
              amountOrigin: productPrice,
              quantity: dataCart.products[i].quantity,
              productSimple: prSimple,
              luggage: dataCart.products[i].luggage,
              configurableId: dataCart.products[i].configurableId,
              configurableSku: dataCart.products[i].configurableSku,
              size: dataCart.products[i].size,
              weight: dataCart.products[i].weight,
              material: dataCart.products[i].material,
              id: _product.id,
              productSimpleId: prSimple.id
            });
          } else {
            // NEW WAY CHECK
            prSimple = _product.extension_attributes.simple_products.find(p => p.id === dataCart.products[i].productSimpleId);
            if (prSimple){
              _sum += (prSimple.price * dataCart.products[i].quantity);
              products.push({
                amount: prSimple.price * dataCart.products[i].quantity,
                amountOrigin: prSimple.price,
                quantity: dataCart.products[i].quantity,
                // productSimple: prSimple,
                // luggage: dataCart.products[i].luggage,
                // configurableId: dataCart.products[i].configurableId,
                // configurableSku: dataCart.products[i].configurableSku,
                productSimpleSku: prSimple.sku,
                configurableSku: _product.sku,
                configurableId: _product.id,
                productSimpleName: prSimple.name,
                productSimpleColor: prSimple?.color?.swatch_value || '',
                productSimpleManufacturer: prSimple.manufacturer || '',
                size: dataCart.products[i].size,
                weight: dataCart.products[i].weight,
                material: dataCart.products[i].material,
                id: _product.id,
                productSimpleId: prSimple.id
              });
            }
          }
        }
      };
      if (products.length > 0){
        let totalOrder = dataCart.voucher > _sum ? 0 : _sum - dataCart.voucher;
        if (dataCart?.rabat?.value > 0){
          // % rabatu dla type === 0
          if ((dataCart?.rabat?.type || 0) === 0){
            totalOrder = totalOrder - Math.ceil(totalOrder * dataCart.rabat.value / 100);
          } else {
            totalOrder = totalOrder - dataCart.rabat.value;
            if (totalOrder < 0){
              totalOrder = 0;
            }
          }
        }
        yield put(setStoreFromClaim(clearSensitiveDataCart({
          ...dataCart,
          products,
          sumOrder: _sum,
          totalOrder
        })));

      }

    }


  } catch (err) {
    console.log('errr: ', err);
    yield put(fetchStoreCatalogFailure(err));
  }
}

function* fetchStoreConfigSaga({ claimId }: FetchConfigShopT): any {
  try {
    const response = yield call(api().store.fetchShopConfig, claimId);
    yield put(setConfigShop({...response.data, colors: []}));
  } catch (err) {
    console.log('error reading config: ', err);
  }
}

function* fetchRabatCodeSaga({ claimId, code }: FetchRabatCodeT): any {
  try {
    const { data } = yield call(api().store.fetchRabatCode, claimId, code);

    if (data){
      yield put(setRabatCode({ 
        code, 
        value: data.discount_amount, 
        name: data.name, 
        type: data.simple_action === 'by_percent' ? 0 : 1, 
        result: true
      }));
    } else {
      yield put(setRabatCode({ code: '', value: 0, result: false, type: 0, name: '' }));
    }
  } catch (err) {
    yield put(setRabatCode({ code: '', value: 0, result: false, type: 0, name: '' }));
  }
}

function* fetchOrderSaga({ order }: FetchOrderT): any {
  try {
    yield put(setOrderSending(true));
    const response = yield call(api().store.fetchOrderSend, order);
    yield put(setOrderResult({ commentResult: response?.message||'', process: false, result: true }));
    yield put(setOrderSending(false));
  } catch (err) {
    yield put(setOrderResult({ commentResult: String(err), process: false, result: false }));
    yield put(setOrderSending(false));
  }
}

function* fetchSaveOrderSaga({ claimId, data }: FetchSaveCartT): any {
  try {
    yield call(api().store.saveCart, claimId, clearSensitiveDataCart(data));
  } catch (err) {
    console.log('error saving cart data: ', err);
  }
}

export default function* store(): Generator {
  // yield takeLatest(FETCH_CATEGORIES, fetchStoreCategoriesSaga);
  yield takeLatest(FETCH_CONFIG_SHOP, fetchStoreConfigSaga);
  yield takeLatest(FETCH_RABAT_CODE, fetchRabatCodeSaga);
  yield takeLatest(FETCH_ORDER, fetchOrderSaga);
  yield takeLatest(FETCH_SAVE_CART, fetchSaveOrderSaga);
  yield takeLatest(STORE_CATALOG_REQUEST, fetchStoreCatalogSaga);
}
