import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { MostPopAttrsT, setCategotysId, setConfigShop, setShopFilters, setShopFiltersLocal, ShopStateT, StoreCategoryT } from 'Store/modules/luggageStore/shop';
import SwitchPriceType from './SwitchPriceType/SwitchPriceType';
import MostPopularTags from './MostPopularTags/MostPopularTags';
import { StoreCatalog } from 'PageComponents/LuggageStore';
import styles from './StoreDesktopMainScreen.module.scss';
import { getMostPopularValue, mapMaterialId } from 'Helpers/store';
import type { LuggageT } from 'api/modules/store';
import { AppStateT } from 'Store/modules';

interface IStoreDesktopMainScreen {
    sliderConfig: {
        width: number;
        height: number;
        visibleSlides: number;
        arrows: boolean;
        isIntrinsicHeight: boolean;
    };
    onItemSelect: (itemId: number) => void;
    catalog: LuggageT[] | null;
    mobile?: boolean
}

const isInSubCategories = (cats: StoreCategoryT[], ids: number[]) => {
    return cats.some((cat: StoreCategoryT) => ids.includes(cat.id));
}

const MakeMenuCategories: React.FC <{ cats: StoreCategoryT[], deep?: number, actionClickCategory: (event: React.MouseEvent, id: number) => void, categorysId: number[], mobile?: boolean }> = ({ cats, deep = 0, actionClickCategory, categorysId, mobile }) => {

    return (
        <>
            {cats.filter((c: StoreCategoryT) => c.is_active).map((c: StoreCategoryT) => {
                const canShowSub = c.children_data.length > 0 && (categorysId.includes(c.id) || (!categorysId.includes(c.id) && isInSubCategories(c.children_data, categorysId)));
                const topSub = cats.find((cat: StoreCategoryT) => categorysId.includes(cat.id) && cat.children_data.length > 0);

                return (
                    <React.Fragment key={`li_cat_${c.id}`}>
                        {(!topSub || (topSub && categorysId.includes(c.id))) &&
                            <li onClick={(event: React.MouseEvent) => actionClickCategory(event, c.id)} className={cx({[styles.selected]: categorysId.includes(c.id), [styles.top]: !!topSub, [styles.desktop]: !mobile})}>
                                {c.name}
                            </li>
                        }
                        {canShowSub &&
                            <MakeMenuCategories cats={c.children_data} deep={deep + 1} actionClickCategory={actionClickCategory} categorysId={categorysId} mobile={mobile} />
                        }
                    </React.Fragment>
                )
            })}
        </>
    )
}


function StoreDesktopMainScreen(props: IStoreDesktopMainScreen) {
    const { onItemSelect } = props;
    const { filters, configShop, voucherActual, categorysId, filtersLocal } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const dispatch = useDispatch();
    const [tags, setTags] = useState([...configShop.mostPopAttrs].sort((a, b) => a.selected ? -1 : (a.cnt < b.cnt ? 0 : 1)));
    const { t } = useTranslation();

    useEffect(() => {
        setTags([
            ...configShop.mostPopAttrs.filter(tag => {
                if (typeof tag.value === 'object' && tag.value.parent_id === configShop.catergoryDefaultId){
                    return false;
                }
                if (typeof tag.value === 'string' && tag.label.toLowerCase() === 'material') {
                    tag.materialId = mapMaterialId(tag.value)
                    return true
                }
                return true;
            })
        ].sort((a, b) => a.selected ? -1 : (a.cnt < b.cnt ? 0 : 1)));
    }, [configShop.mostPopAttrs, configShop.catergoryDefaultId]);

    // const showCards = useMemo(() => {
    //   if (!catalog) {
    //     return null;
    //   }

    //   return catalog.map((item, index) => {
    //     const producerName = item?.custom_attributes?.find((attr: LuggageCustomAttributeT) => attr.attribute_code === 'manufacturer')?.value||''
    //     return (
    //       <Slide index={index} key={index} className={styles.slide}>
    //         <div className={styles.card}>
    //           <LuggageShopCard
    //             img={item.imgUrl}
    //             title={producerName}
    //             subTitle={item.name}
    //             discountPrice={0}
    //             totalPrice={item.price}
    //             colors={item.colors}
    //             onClick={() => onItemSelect(item.id)}
    //           />
    //         </div>
    //       </Slide>
    //     );
    //   });
    // }, [catalog, onItemSelect]);

    const actionChangeFilter = useCallback((showListPriceWithVoucher: boolean) => {
        dispatch(setShopFilters({
            ...filters,
            search: filters.search,
            showListPriceWithVoucher
        }));
        dispatch(setShopFiltersLocal({
            ...filtersLocal,
            search: filtersLocal.search,
            showListPriceWithVoucher
        }));
    }, [dispatch, filters, filtersLocal]);

    // const actionClickCategory = useCallback((event: React.MouseEvent, id: number) => {
    //     event.stopPropagation();
    //     dispatch(setCategotyId(id));
    //     dispatch(clearFiltersCategories());

    //     let cats: number[] = [];
    //     if (id !== 0){
    //         const _cat = configShop.categories.length > 0 && configShop.categories[0].children_data.find((cat: StoreCategoryT) => cat.id === id);
    //         if (_cat && _cat.children_data.length > 0){
    //             if (!categorysId.includes(id)) cats = [id];
    //         } else {
    //             cats = categorysId.includes(id) ? categorysId.filter((_id: number) => _id !== id) : [...categorysId, id];
    //         }
    //     }

    //     dispatch(setCategotysId(cats));
    // }, [dispatch, categorysId, configShop.categories]);

    const actionClickTag = (tag: MostPopAttrsT) => {

        dispatch(setConfigShop({
            ...configShop,
            mostPopAttrs: configShop.mostPopAttrs.map((_tag: MostPopAttrsT) => {
                if (tag.id === _tag.id && tag.value === _tag.value){
                    const selected = !_tag.selected;
                    _tag.selected = selected;
                }
                return _tag;
            })
        }));

        configShop.mostPopAttrs.forEach((_tag: MostPopAttrsT) => {
            const value = getMostPopularValue(tag);
            if (tag.id === _tag.id && tag.value === _tag.value){
                const selected = _tag.selected;
                if (tag.label.toLowerCase() === 'bag color'){
                    const color = configShop.colors.find(c => c.name === tag.value);
                    if (color){

                        const destObj  = {
                            ...filters,
                            colors: !selected ? filters.colors.filter(c => c !== color.value) : [...filters.colors, color.value]
                        }
                        dispatch(setShopFilters(destObj));
                        dispatch(setShopFiltersLocal(destObj));
                    }
                } else if (tag.label.toLowerCase() === 'bag size'){
                    const sizeDestObj  = {
                        ...filters,
                        sizes: !selected ? filters.sizes.filter(s => s !== value) : [...filters.sizes, value]
                    }
                    dispatch(setShopFilters(sizeDestObj));
                    dispatch(setShopFiltersLocal(sizeDestObj));
                } else if (tag.label.toLowerCase() === 'manufacturer'){
                    const manufacturerDestObj  = {
                        ...filters,
                        manufacturers: !selected ? filters.manufacturers.filter(s => s !== value) : [...filters.manufacturers, value]
                    }
                    dispatch(setShopFilters(manufacturerDestObj));
                    dispatch(setShopFiltersLocal(manufacturerDestObj));
                } else if (tag.label.toLowerCase() === 'material'){
                    const materialDestObj  = {
                        ...filters,
                        materials: !selected ? filters.materials.filter(s => s !== String(tag.materialId)) : [...filters.materials, String(tag.materialId)]
                    }
                    dispatch(setShopFilters(materialDestObj));
                    dispatch(setShopFiltersLocal(materialDestObj));
                } else if (tag.label.toLowerCase() === 'categories'){
                    const cId = findCategory(configShop.categories, value);
                    if (cId){
                        if (!selected){
                            dispatch(setCategotysId(categorysId.filter(c => c !== cId)));
                        } else {
                            dispatch(setCategotysId([cId]));
                        }
                    }
                }
            }
        });
    }

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                {voucherActual > 0 &&
                    <div className={styles.layerTopFilterPrice}>
                        <SwitchPriceType
                            showListPriceWithVoucher={filters.showListPriceWithVoucher}
                            actionChange={actionChangeFilter}
                            t={t}
                        />
                    </div>
                }
                {/* <div className={styles.title}>Suggestions</div> */}
                {/* {configShop.categories[0]?.children_data &&
                    <div className={styles.luggageType}>
                        <div className={styles.navCategories}>
                            {configShop.categories.length > 0 && 
                                <>
                                    <div onClick={(event: React.MouseEvent) => actionClickCategory(event,0)} className={cx(styles.liAll, {[styles.selected]: categorysId.length === 0})}>
                                        All
                                    </div>
                                    <ul>
                                        <MakeMenuCategories 
                                            cats={configShop.categories[0].children_data}
                                            actionClickCategory={actionClickCategory}
                                            categorysId={categorysId}
                                            mobile={props.mobile}
                                        />
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                } */}
                {(configShop.mostPopAttrs && configShop.mostPopAttrs.length > 0) &&
                    <MostPopularTags
                        tags={tags}
                        actionClickTag={actionClickTag}
                        mobile={props.mobile}
                        colors={configShop.colors}
                        t={t}
                    />
                }
                <div className={styles.luggageCards}>
                    {/* ... sugestions list ... */}
                    {/* <CarouselProvider
                        totalSlides={6}
                        visibleSlides={sliderConfig.visibleSlides}
                        naturalSlideHeight={sliderConfig.height}
                        naturalSlideWidth={sliderConfig.width}
                        className={styles.carouselProvider}
                        isIntrinsicHeight={sliderConfig.isIntrinsicHeight}
                        lockOnWindowScroll
                    >
                        <Slider className={styles.slider}>{showCards}</Slider>
                        {sliderConfig.arrows && (
                        <>
                            <ButtonBack className={styles.buttonBack}>
                            <LeftArrow className={styles.arrow} />
                            </ButtonBack>
                            <ButtonNext className={styles.buttonNext}>
                            <RightArrow className={styles.arrow} />
                            </ButtonNext>
                        </>
                        )}
                    </CarouselProvider> */}
                </div>
                <div className={styles.storeCatalog}>
                    {props.catalog && <StoreCatalog onItemSelect={onItemSelect} catalog={props.catalog} />}
                </div>
                {/* <div className={styles.buttonContainer}>
                    <Button color="secondary" className={styles.button}>
                        Show more cases
                    </Button>
                </div> */}
            </div>
        </div>
    );
}

function findCategory(categories: StoreCategoryT[], name: string): number {
    for (let cat of categories) {
        if (cat.name === name) return cat.id
    
        if (cat.children_data.length > 0) {
            let desiredNode = findCategory(cat.children_data, name)
            if (desiredNode) return desiredNode;
        }
    }
    return 0;
}

export default StoreDesktopMainScreen;
