import { LoadableContent } from 'Components';

import styles from './ClaimDetailsDesktop.module.scss';

import CaseDetailsDesktop from '../CaseDetailsDesktop/CaseDetailsDesktop';
import historyStatus from 'Hooks/useHistory';
import { ClaimT } from "api/modules/claim";

type Props = {
  onClose: () => void;
  claim: ClaimT
};

function ClaimDetailsDesktop({ onClose, claim }: Props) {
  const claimHistory = historyStatus(claim);

  return (
    <div className={styles.root}>
      <LoadableContent loading={!claim}>
        <CaseDetailsDesktop
          data={claim}
          onClose={onClose}
          claimHistory={claimHistory}
        />
      </LoadableContent>
    </div>
  );
}

export default ClaimDetailsDesktop;
