import API from '../base/api';
import type { ReqTypeT /*, ResTypeT */ } from '../';

export class WebPushApi extends API {
  subscribe = (subscription: any) => {
    return this.r({
      method: 'POST',
      url: "/notification",
      data: {
        subscription: subscription,
      }
    });
  };
}

export default function webPush(request: ReqTypeT) {
  return new WebPushApi(request);
}
