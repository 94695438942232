import { initReactI18next } from 'react-i18next';
import i18nHttpApi from 'i18next-http-backend';
import type { Store } from 'redux';
import i18n from 'i18next';

import { languageChanged } from 'Store/modules/i18n';
import { defaultLanguage } from "Helpers/i18";
import config, { BACKEND_URL } from 'Config';

const options = {
  // ...devOptions,
  load: 'currentOnly' as 'currentOnly',
  fallbackLng:localStorage.getItem('language')|| defaultLanguage,
  lowerCaseLng: true,
  defaultNS: 'translation',
  nonExplicitSupportedLngs: true,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: `${BACKEND_URL}locales/{{lng}}/{{ns}}`,
    crossDomain: true,
  },
  detection: {
    order: ['localStorage'],
    caches: ['localStorage'],
    lookupLocalStorage: config.LANGUAGE_LOCAL_STORAGE_KEY,
  },
  react: {
    useSuspense: false
  }
};

class I18n {
  store: Store | null = null;
  initialized: boolean = false;

  constructor() {
    this.attachPlugins();
  }

  initialize(store: Store) {
    this.store = store;
    this.initialized = true;
    this.attachHandlers();
    i18n.init(options as any);
  }

  attachPlugins() {
    i18n.use(i18nHttpApi);
    i18n.use(initReactI18next);
  }

  attachHandlers() {
    if (!this.initialized || !this.store) {
      throw new Error('i18n attach handlers was called before instance was initialized');
    }

    const store: Store = this.store;

    i18n.on('languageChanged', (language) => {
      store.dispatch(languageChanged(language));
    });
  }

  async changeLanguage(language: string) {
    try {
      await i18n.loadLanguages(language);
      return i18n.changeLanguage(language);
    } catch {
      console.error('Failed to load language:', language);
    }
  }
}

let instance: I18n | null = null;

export default function i18nSingleton(): I18n {
  if (!instance) {
    instance = new I18n();
  }

  return instance;
}
