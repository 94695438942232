import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import cx from 'classnames';

import 'swiper/css';
// import 'swiper/swiper.scss'; // core Swiper
// import 'swiper/modules/navigation/navigation.scss'; // Navigation module

import ImAirfranse from 'Assets/aboutus/logos/airfrance_1.png';
import ImAirfranse_hover from 'Assets/aboutus/logos/airfrance_2.png';
import ImAmerican from 'Assets/aboutus/logos/american_1.png';
import ImAmerican_hover from 'Assets/aboutus/logos/american_2.png';
import ImAustralian from 'Assets/aboutus/logos/austrian_1.png';
import ImAustralian_hover from 'Assets/aboutus/logos/austrian_2.png';
import ImCzech from 'Assets/aboutus/logos/czech_1.png';
import ImCzech_hover from 'Assets/aboutus/logos/czech_2.png';
import ImEgipt from 'Assets/aboutus/logos/egypt_air_1.png';
import ImEgipt_hover from 'Assets/aboutus/logos/egypt_air_2.png';
import ImElal from 'Assets/aboutus/logos/elal_1.png';
import ImElal_hover from 'Assets/aboutus/logos/elal_2.png';
import ImEmirates from 'Assets/aboutus/logos/emirates_1.png';
import ImEmirates_hover from 'Assets/aboutus/logos/emirates_2.png';
import ImEnter from 'Assets/aboutus/logos/enter_1.png';
import ImEnter_hover from 'Assets/aboutus/logos/enter_2.png';
import ImEtihad from 'Assets/aboutus/logos/etihad_1.png';
import ImEtihad_hover from 'Assets/aboutus/logos/etihad_2.png';
import ImFinnair from 'Assets/aboutus/logos/finnair_1.png';
import ImFinnair_hover from 'Assets/aboutus/logos/finnair_2.png';
import ImKlm from 'Assets/aboutus/logos/klm_1.png';
import ImKlm_hover from 'Assets/aboutus/logos/klm_2.png';
import ImLot from 'Assets/aboutus/logos/lot_1.png';
import ImLot_hover from 'Assets/aboutus/logos/lot_2.png';
import ImLufthansa from 'Assets/aboutus/logos/lufthansa_1.png';
import ImLufthansa_hover from 'Assets/aboutus/logos/lufthansa_2.png';
import ImLuxair from 'Assets/aboutus/logos/luxair_1.png';
import ImLuxair_hover from 'Assets/aboutus/logos/luxair_2.png';
import ImQuatar from 'Assets/aboutus/logos/qatar_1.png';
import ImQuatar_hover from 'Assets/aboutus/logos/qatar_2.png';
import ImSmartwings from 'Assets/aboutus/logos/smartwings_1.png';
import ImSmartwings_hover from 'Assets/aboutus/logos/smartwings_2.png';
import ImSun_express from 'Assets/aboutus/logos/sun_express_1.png';
import ImSun_express_hover from 'Assets/aboutus/logos/sun_express_2.png';
import ImSwiss from 'Assets/aboutus/logos/swiss_1.png';
import ImSwiss_hover from 'Assets/aboutus/logos/swiss_2.png';
import ImTap from 'Assets/aboutus/logos/tap_1.png';
import ImTap_hover from 'Assets/aboutus/logos/tap_2.png';
import ImUnited_airlines from 'Assets/aboutus/logos/united_airlines_1.png';
import ImUnited_airlines_hover from 'Assets/aboutus/logos/united_airlines_2.png';

import ImgMap from 'Assets/aboutus/map.png';
import { ReactComponent as TimeLine2005 } from 'Assets/aboutus/timeline_2005.svg';
import { ReactComponent as TimeLine2009 } from 'Assets/aboutus/timeline_2009.svg';
import { ReactComponent as TimeLine2011 } from 'Assets/aboutus/timeline_2011.svg';
import { ReactComponent as TimeLine2013 } from 'Assets/aboutus/timeline_2013.svg';
import { ReactComponent as TimeLine2016 } from 'Assets/aboutus/timeline_2016.svg';
import { ReactComponent as TimeLine2017 } from 'Assets/aboutus/timeline_2017.svg';
import { ReactComponent as TimeLine2020 } from 'Assets/aboutus/timeline_2020.svg';
import { ReactComponent as TimeLine2021 } from 'Assets/aboutus/timeline_2021.svg';
import { ReactComponent as TimeLine2022 } from 'Assets/aboutus/timeline_2022.svg';
import { ReactComponent as TimeLine2024 } from 'Assets/aboutus/timeline_2024.svg';


import { useDeviceType } from 'Helpers/responsiveContainers';
import { Swiper as SwiperT } from 'swiper/types';
import styles from './AboutUs.module.scss';
import { Layout } from 'Components';

const slideImages = [[ImAirfranse, ImAirfranse_hover], [ImAmerican, ImAmerican_hover], [ImAustralian, ImAustralian_hover], [ImCzech, ImCzech_hover], [ImEgipt, ImEgipt_hover], [ImElal, ImElal_hover], [ImEmirates, ImEmirates_hover], [ImEnter, ImEnter_hover], [ImEtihad, ImEtihad_hover], [ImFinnair, ImFinnair_hover], [ImKlm, ImKlm_hover], [ImLot, ImLot_hover], [ImLufthansa, ImLufthansa_hover], [ImLuxair, ImLuxair_hover], [ImQuatar, ImQuatar_hover], [ImSmartwings, ImSmartwings_hover], [ImSun_express, ImSun_express_hover], [ImSwiss, ImSwiss_hover], [ImTap, ImTap_hover], [ImUnited_airlines, ImUnited_airlines_hover] ];
const slideImagesMobile = [[[ImAirfranse, ImAirfranse_hover], [ImAmerican, ImAmerican_hover], [ImAustralian, ImAustralian_hover]], [[ImCzech, ImCzech_hover], [ImEgipt, ImEgipt_hover], [ImElal, ImElal_hover]], [[ImEmirates, ImEmirates_hover], [ImEnter, ImEnter_hover], [ImEtihad, ImEtihad_hover]], [[ImFinnair, ImFinnair_hover], [ImKlm, ImKlm_hover], [ImLufthansa, ImLufthansa_hover]], [[ImLot, ImLot_hover], [ImLuxair, ImLuxair_hover], [ImSmartwings, ImSmartwings_hover]], [[ImQuatar, ImQuatar_hover], [ImSun_express, ImSun_express_hover], [ImTap, ImTap_hover]], [[ImSwiss, ImSwiss_hover], [ImUnited_airlines, ImUnited_airlines_hover]] ];

const AboutUs = () => {

    const [swiper, setSwiper] = useState<SwiperT|null>(null);
    const [slideNr, setSlideNr] = useState<number>(0);
    const { isTablet, isMobile, isMobileSmall } = useDeviceType();
    const { t } = useTranslation();

    const goSliderNav = useCallback((directionLeft: boolean = true) => {
        if (swiper){
            if (directionLeft){
                return swiper.slidePrev();
            }
            return swiper.slideNext();
        }
    }, [swiper]);

    const removeTrailingSlash = (str: string): string => {
        return str.replace(/\\(.)/mg, "$1");
      }

    const tt = useCallback((key: string, defaultValue?: string) => {

        const result = t(key);
        return removeTrailingSlash(result||defaultValue as string);

    }, [t]);

    return (
        <Layout>
            <div className={styles.main}>
                <div className={cx(styles.layer, styles.layerHeader)}>
                    <header>
                        {tt('about.header', 'Everyone has luggage, we take care of it!')}
                    </header>
                    <div className={styles.content}>
                        {tt('about.headerContent', 'We take care of your luggage and we love doing it. We specialize in baggage repairs, and our scope covers airline claims, warranty repairs and private cases.')}
                    </div>
                </div>
                <div className={cx(styles.layer, styles.layerWorks)}>
                    <div className={styles.header}>{tt('about.worksHeader', 'This is how it works')}</div>
                    <div className={styles.boxes}>
                        <div className={cx(styles.box, styles.boxDemaged)}>
                            <div className={styles.boxImage} />
                            <h3>{tt('about.worksDemaged', 'Demaged')}</h3>
                            <article>{tt('about.worksDemagedText', 'Damaged on a flight, during a trip, or through conventional use? We’ll do everything we can to fix it, remedy the situation, and make sure you love your experience with us.')}</article>
                        </div>
                        <div className={cx(styles.box, styles.boxEvaluation)}>
                            <div className={styles.boxImage} />
                            <h3>{tt('about.worksEvaluation', 'Evaluation')}</h3>
                            <article>{tt('about.worksEvaluationText', 'If you’ll let us, we’ll take care of your luggage for you. Our experts will assess the damages and customize a solution to meet your individual situation and needs.')}</article>
                        </div>
                        <div className={cx(styles.box, styles.boxRecovery)}>
                            <div className={styles.boxImage} />
                            <h3>{tt('about.worksRecovery', 'Recovery')}</h3>
                            <article>{tt('about.worksRecoveryText', 'Your next journey can\'t begin without a luggage. We’ll do everything we can to make sure you have a functional luggage for your next travel, so you can hit the road again!')}</article>
                        </div>
                    </div>
                </div>
                <div className={cx(styles.layer, styles.layerNumbers)}>
                    <div className={styles.numbers}>
                        <div className={cx(styles.number, styles.numberTeam)}>
                            <div className={styles.icon} />
                            <div className={styles.numberText}>{tt('about.numbersTeam', '300')}</div>
                            <div className={styles.content}>{tt('about.numbersTeamContent', 'Team members united by one mantra: We love luggage!')}</div>
                        </div>
                        <div className={cx(styles.number, styles.numberAirline)}>
                            <div className={styles.icon} />
                            <div className={styles.numberText}>{tt('about.numbersAirline', '108')}</div>
                            <div className={styles.content}>{tt('about.numbersAirlineContent', 'Airline, that put their trust in our hands!')}</div>
                        </div>
                        <div className={cx(styles.number, styles.numberSuitcases)}>
                            <div className={styles.icon} />
                            <div className={styles.numberText}>{tt('about.numbersSuitcases', '16,000')}</div>
                            <div className={styles.content}>{tt('about.numbersSuitcasesContent', 'Suitcases we evaluate, fix, replace and handle with care, every month!')}</div>
                        </div>
                        <div className={cx(styles.number, styles.numberChat)}>
                            <div className={styles.icon} />
                            <div className={styles.numberText}>{tt('about.numbersChat', '456')}</div>
                            <div className={styles.content}>{tt('about.numbersChatContent', 'Chat emojis we receive from our customers, every day!')}</div>
                        </div>
                    </div>
                </div>
                <div className={cx(styles.layer, styles.layerWhatdo)}>
                    <div className={styles.body}>
                        <div className={styles.header}>{tt('about.whatHeader', 'This is what we do')}</div>
                        <div className={styles.contentMain}>{tt('about.whatContent', 'We cooperate with over 100 airlines that include Start Alliance members, global leaders of the airways and local air carriers. We also work with more than 70 baggage brands that cover a full spectrum of luggage types and enable us to resolve any situations with damaged baggage you may have.')}</div>
                        <div className={styles.boxes}>
                            <div className={cx(styles.box, styles.boxAirline)}>
                                <div className={styles.icon} />
                                <div className={styles.header}>{tt('about.whatAirline', 'Airline claims')}</div>
                                <div className={styles.content}>{tt('about.whatAirlineContent', 'If your baggage gets damaged during the flight, we ensure that you have properly functioning luggage for your next travels.')}</div>
                            </div>
                            <div className={cx(styles.box, styles.boxWarranty)}>
                                <div className={styles.icon} />
                                <div className={styles.header}>{tt('about.whatWarranty', 'Warranty repairs')}</div>
                                <div className={styles.content}>{tt('about.whatWarrantyContent', 'About 15 leading baggage manufacturers entrust us to fix suitcases on behalf of their brands.')}</div>
                            </div>
                            <div className={cx(styles.box, styles.boxPrivate)}>
                                <div className={styles.icon} />
                                <div className={styles.header}>{tt('about.whatPrivate', 'Private cases')}</div>
                                <div className={styles.content}>{tt('about.whatPrivateContent', 'We fix broken luggage, fulfil private orders and produce custom baggage if you need such.')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx(styles.layer, styles.layerOurServices)}>
                    <div className={styles.body}>
                        <div className={styles.boxes}>
                            <div className={cx(styles.box, styles.boxOurService)}>
                                <label>{tt('about.services', 'Our services at glance')}</label>
                            </div>
                            <div className={cx(styles.box, styles.boxContent, styles.boxContact)}>
                                <label>{tt('about.servicesContact', 'Contact us!')}</label>
                                <article>{tt('about.servicesContactContent', 'When you contact us with damaged baggage, we open a new case for you. We thoroughly evaluate your situation to come up with the most appropriate solution.')}</article>
                            </div>
                            <div className={cx(styles.box, styles.boxContent, styles.boxDoor)}>
                                <label>{tt('about.servicesDoor', 'Right to your door-step')}</label>
                                <article>{tt('about.servicesDoorContent', 'When you contact us with damaged baggage, we open a new case for you. We thoroughly evaluate your situation to come up with the most appropriate solution.')}</article>
                            </div>
                            <div className={cx(styles.box, styles.boxContent, styles.boxRepair)}>
                                <label>{tt('about.servicesRepair', 'What do we repair?')}</label>
                                <article>{tt('about.servicesRepairContent', 'We are able to fix any type of baggage and most kinds of damages. In our 5 repair hubs we combine various experience in cobbling, tailoring repairs and producing leather goods with old style suitcase repair methods as well as modern tech tools.')}</article>
                            </div>
                            <div className={cx(styles.box, styles.boxContent, styles.boxLuggage)}>
                                <label>{tt('about.servicesReplacement', 'Replacement - Get a new luggage')}</label>
                                <article>{tt('about.servicesReplacementContent', 'When the baggage is unrepairable, we offer you a one-to-one or similar replacement which is possible thanks to the cooperation with leading and local brands covering a wide range of luggage types. We also have an internal shop where you can choose the best replacement by yourself.')}</article>
                            </div>
                            <div className={cx(styles.box, styles.boxContent, styles.boxQuestion)}>
                                <label>{tt('about.servicesCantfind', 'If you can’t find  what you want')}</label>
                                <article>{tt('about.servicesCantfindContent', 'If you can’t find a suitcase you want in our shop, or you have a baggage type/damage that is not listed on our pages, you’re always welcome to contact us. We will do our best to remedy your particular situation whatever it takes because we care about you and your baggage.')}</article>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx(styles.layer, styles.layerTrusted)}>
                    <header>{tt('about.TrustedHeader', 'Trusted by over 100 airlines and baggage manufacturer')}</header>
                    <div className={cx(styles.slider, (isMobileSmall ? styles.sliderMobile : null))}>
                        <div className={cx(styles.navArrow)} onClick={() => goSliderNav()} />
                        <div className={cx(styles.navArrow, styles.navArrowRight)} onClick={() => goSliderNav(false)} />
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={isMobileSmall ? 1 : (isMobile ? 2 : (isTablet ? 3 : 4))}
                            onSlideChange={(data) => setSlideNr(data.realIndex)}
                            onSwiper={(sw) => setSwiper(sw)}
                            loop
                            className={isMobileSmall ? styles.swiperMobile : styles.swiperDesktop}
                        >
                            {!isMobileSmall ?
                                <>
                                    {slideImages.map((image: any) => (
                                        <SwiperSlide key={`desktop_slide_${image[0]}`}>
                                            <div className={styles.imageLayer}>
                                                <img src={image[0]} alt="" className={styles.image1} />
                                                <img src={image[1]} alt="" className={styles.image2} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </> :
                                <>
                                    {slideImagesMobile.map((imageGroup: any) => (
                                        <SwiperSlide key={`desktop__mobile_slide_${imageGroup[0]}`}>
                                            {imageGroup.map((image: any) => (
                                                <div className={styles.imageLayer} key={`desktop__mobile_slide2_${image}`}>
                                                    <img src={image[0]} alt="" className={styles.image1} />
                                                    <img src={image[1]} alt="" className={styles.image2} />
                                                </div>
                                            ))}
                                        </SwiperSlide>
                                    ))}
                                </>
                            }
                        </Swiper>
                        <div className={styles.pagination}>
                            {!isMobileSmall ? 
                                <>
                                    {slideImages.map((el: any, index: number) => (
                                        <div className={cx(styles.pag, slideNr === index ? styles.selected : null)}  key={`dot_${index}`} />
                                    ))}
                                </>:
                                <>
                                    {slideImagesMobile.map((el: any, index: number) => (
                                        <div className={cx(styles.pag, slideNr === index ? styles.selected : null)}  key={`dot_mobile_${index}`} />
                                    ))}
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className={cx(styles.layer, styles.layerWho)}>
                    <header>{tt('about.whoHeader', 'This is who we are')}</header>
                    <div className={styles.content}>
                        <p>{tt('about.whoLine1', 'We are luggage lovers and experts in baggage repair and replacement services. United by one mission, “everyone has a luggage, we take care of it”, we support travellers with their damaged baggage since 2005. Over the years we have grown into an international company. We handled and recovered a vast number of suitcases, acquired a great deal of partners and connected with new wonderful people.')}</p>
                        <p>{tt('about.whoLine2', 'Our roots can be traced back to 1920 when the original company was founded. It mostly engaged in leather goods distribution and repair, and continued its work for more than 80 years. Impressive, isn’t it?')}</p>
                        <p>{tt('about.whoLine3', 'Although the tremendous business growth throughout the years, 2 things remained the same: Our passion for customer service and love for luggage.')}</p>
                    </div>
                    <div className={styles.footer}>{tt('about.whoFooter', 'This is where the story begins...')}</div>
                </div>
                <div className={cx(styles.layer, styles.layerTimeline)}>
                    <div className={styles.body}>
                        {!isMobile ?
                            <>
                                <div className={styles.left}>
                                    <div className={cx(styles.year, styles.year2009)}>2009</div>
                                    <div className={cx(styles.timeLineImage, styles.timeLineImage2009)}>
                                        <TimeLine2009 />
                                    </div>
                                    <div className={cx(styles.content, styles.content2011)}>{tt('about.timeLine2011', 'We open a new repair hub in Warsaw, Poland. The Polish branch repairs 2000 suitcases per month. Suitcases get repaired in a blink of an eye.')}</div>
                                    <div className={cx(styles.year, styles.year2013)}>2013</div>
                                    <div className={cx(styles.timeLineImage, styles.timeLineImage2013)}>
                                        <TimeLine2013 />
                                    </div>
                                    <div className={cx(styles.content, styles.content2013)}>{tt('about.timeLine2013', 'We open a contact centre in Warsaw, Poland. We start working on the Italian market. Europe is not the limit anymore. We’re doing it worldwide. There are 15 different languages you can hear in our contact centre. The number is still growing. Our family is getting bigger: there are 150 luggage lovers already. 675 calls per week. It’s getting hot here!')}</div>
                                    <div className={cx(styles.year, styles.year2017)}>2017</div>
                                    <div className={cx(styles.timeLineImage, styles.timeLineImage2017)}>
                                        <TimeLine2017 />
                                    </div>
                                    <div className={cx(styles.content, styles.content2017)}>{tt('about.timeLine2017', "We enter into a cooperation with the global CRM market leader Salesforce and build a new system that meets today's requirements.")}</div>
                                    <div className={cx(styles.year, styles.year2021)}>2021</div>
                                    <div className={cx(styles.timeLineImage, styles.timeLineImage2021)}>
                                        <TimeLine2021 />
                                    </div>
                                    <div className={cx(styles.content, styles.content2021)}>{tt('about.timeLine2021', 'We reach the number of 1 000 000 replaced suitcases. Can you believe it? 500 WhatsApp emojis received from clients daily. We are more than just happy.')}</div>
                                    <div className={cx(styles.year, styles.year2024)}>2024</div>
                                    <div className={cx(styles.timeLineImage, styles.timeLineImage2024)}>
                                        <TimeLine2024 />
                                    </div>
                                    <div className={cx(styles.content, styles.content2024)}>{tt('about.timeLine2024', 'In order to cope with the constantly growing order situation, we commission Rootstock to implement a new ERP solution for our order, warehouse and invoicing processes.')}</div>
                                </div>
                                <div className={styles.middle} />
                                <div className={styles.right}>
                                    <div className={cx(styles.year, styles.year2005)}>2005</div>
                                    <div className={cx(styles.timeLineImage, styles.timeLineImage2005)}>
                                        <TimeLine2005 />
                                    </div>
                                    <div className={cx(styles.content, styles.content2009)}>{tt('about.timeLine2009', 'We open our first repair hubs in Frankfurt and Prague. We sign our first contract with an airline and repair our first suitcase. Free door-to-door service to everyone!')}</div>
                                    <div className={cx(styles.year, styles.year2011)}>2011</div>
                                    <div className={cx(styles.timeLineImage, styles.timeLineImage2011)}>
                                        <TimeLine2011 />
                                    </div>
                                    <div className={cx(styles.content, styles.content2011)}>{tt('about.timeLine2011_2', 'We establish another repair hub in Lisbon, Portugal. First suitcases evaluated virtually and 5894 saved trees as a result. Thank you, Internet! We enter to the French and Spanish markets. That’s the spirit!')}</div>
                                    <div className={cx(styles.year, styles.year2016)}>2016</div>
                                    <div className={cx(styles.timeLineImage, styles.timeLineImage2016)}>
                                        <TimeLine2016 />
                                    </div>
                                    <div className={cx(styles.content, styles.content2020)}>{tt('about.timeLine2016', 'We send our first emoji to a customer via WhatsApp. We order 600 spare parts per month.')}</div>

                                    <div className={cx(styles.year, styles.year2020)}>2020</div>
                                    <div className={cx(styles.timeLineImage, styles.timeLineImage2020)}>
                                        <TimeLine2020 />
                                    </div>
                                    <div className={cx(styles.content, styles.content2020)}>{tt('about.timeLine2020', 'We establish our service on behalf of several major airlines on all continents. From America to Asia, we take care of repairs and the replacement of baggage.')}</div>
                                    
                                    <div className={cx(styles.year, styles.year2022)}>2022</div>
                                    <div className={cx(styles.timeLineImage, styles.timeLineImage2022)}>
                                        <TimeLine2022 />
                                    </div>
                                    <div className={cx(styles.content, styles.content2022)}>{tt('about.timeLine2022', 'Dolfi Brazil is founded to open up the South American market. Further cooperation agreements are concluded in South Africa, the United Arab Emirates, Thailand, China, Japan, Korea and many other countries.')}</div>
                                </div>
                            </>:
                            <>
                                <div className={styles.middle} />
                                <div className={styles.right}>
                                    <div className={cx(styles.mobileLayer, styles.mobileLayerFirst)}>
                                        <div className={styles.year}>2005</div>
                                        <div className={cx(styles.timeLineImage, styles.timeLineImage2005)}>
                                            <TimeLine2005 />
                                        </div>
                                        <div className={styles.content}>{tt('about.timeLine2009', 'We open our first repair hubs in Frankfurt and Prague. We sign our first contract with an airline and repair our first suitcase. Free door-to-door service to everyone!')}</div>
                                    </div>
                                    <div className={styles.mobileLayer}>
                                        <div className={styles.year}>2009</div>
                                        <div className={cx(styles.timeLineImage, styles.timeLineImage2009)}>
                                            <TimeLine2009 />
                                        </div>
                                        <div className={styles.content}>{tt('about.timeLine2011', 'We open a new repair hub in Warsaw, Poland. The Polish branch repairs 2000 suitcases per month. Suitcases get repaired in a blink of an eye.')}</div>
                                    </div>
                                    <div className={styles.mobileLayer}>
                                        <div className={styles.year}>2011</div>
                                        <div className={cx(styles.timeLineImage, styles.timeLineImage2011)}>
                                            <TimeLine2011 />
                                        </div>
                                        <div className={styles.content}>{tt('about.timeLine2011_2', 'We establish another repair hub in Lisbon, Portugal. First suitcases evaluated virtually and 5894 saved trees as a result. Thank you, Internet! We enter to the French and Spanish markets. That’s the spirit!')}</div>
                                    </div>
                                    <div className={styles.mobileLayer}>
                                        <div className={styles.year}>2013</div>
                                        <div className={cx(styles.timeLineImage, styles.timeLineImage2013)}>
                                            <TimeLine2013 />
                                        </div>
                                        <div className={styles.content}>{tt('about.timeLine2013', 'We open a contact centre in Warsaw, Poland. We start working on the Italian market. Europe is not the limit anymore. We’re doing it worldwide. There are 15 different languages you can hear in our contact centre. The number is still growing. Our family is getting bigger: there are 150 luggage lovers already. 675 calls per week. It’s getting hot here!')}</div>
                                    </div>
                                    <div className={styles.mobileLayer}>
                                        <div className={styles.year}>2016</div>
                                        <div className={cx(styles.timeLineImage, styles.timeLineImage2016)}>
                                            <TimeLine2016 />
                                        </div>
                                        <div className={styles.content}>{tt('about.timeLine2016', 'We send our first emoji to a customer via WhatsApp. We order 600 spare parts per month.')}</div>
                                    </div>
                                    <div className={styles.mobileLayer}>
                                        <div className={styles.year}>2017</div>
                                        <div className={cx(styles.timeLineImage, styles.timeLineImage2017)}>
                                            <TimeLine2017 />
                                        </div>
                                        <div className={styles.content}>{tt('about.timeLine2017', 'We reach the number of 1 000 000 replaced suitcases. Can you believe it? 500 WhatsApp emojis received from clients daily. We are more than just happy.')}</div>
                                    </div>
                                    <div className={styles.mobileLayer}>
                                        <div className={styles.year}>2020</div>
                                        <div className={cx(styles.timeLineImage, styles.timeLineImage2020)}>
                                            <TimeLine2020 />
                                        </div>
                                        <div className={styles.content}>{tt('about.timeLine2020', 'We establish our service on behalf of several major airlines on all continents. From America to Asia, we take care of repairs and the replacement of baggage.')}</div>
                                    </div>
                                    <div className={styles.mobileLayer}>
                                        <div className={styles.year}>2021</div>
                                        <div className={cx(styles.timeLineImage, styles.timeLineImage2021)}>
                                            <TimeLine2021 />
                                        </div>
                                        <div className={styles.content}>{tt('about.timeLine2021', "We enter into a cooperation with the global CRM market leader Salesforce and build a new system that meets today's requirements.")}</div>
                                    </div>
                                    <div className={styles.mobileLayer}>
                                        <div className={styles.year}>2022</div>
                                        <div className={cx(styles.timeLineImage, styles.timeLineImage2022)}>
                                            <TimeLine2022 />
                                        </div>
                                        <div className={styles.content}>{tt('about.timeLine2022', 'Dolfi Brazil is founded to open up the South American market. Further cooperation agreements are concluded in South Africa, the United Arab Emirates, Thailand, China, Japan, Korea and many other countries.')}</div>
                                    </div>
                                    <div className={styles.mobileLayer}>
                                        <div className={styles.year}>2024</div>
                                        <div className={cx(styles.timeLineImage, styles.timeLineImage2024)}>
                                            <TimeLine2024 />
                                        </div>
                                        <div className={styles.content}>{tt('about.timeLine2024', 'In order to cope with the constantly growing order situation, we commission Rootstock to implement a new ERP solution for our order, warehouse and invoicing processes.')}</div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className={cx(styles.layer, styles.layerOffices)}>
                    <div className={styles.body}>
                        <header>{tt('about.officesHeader', 'Our offices')}</header>
                        <div className={styles.topContent}>{tt('about.officesTopContent', 'It doesn’t mean that we provide our baggage services only there. Dolfi1920 functions in more than 20 European countries, and in some cases operates even globally. We are proud to support globetrotters, tourists and travellers from all around the world by providing best customer service! That’s our mission, our passion, and we do everything to follow it.')}</div>
                        <div className={styles.mapContact}>
                            {isMobile &&
                                <div className={styles.map}>
                                    <img src={ImgMap} alt="" />
                                </div>
                            }
                            <div className={styles.contact}>
                                <div className={styles.boxContact}>
                                    <div className={styles.header}>{t('about.mapGermany')}</div>
                                    <div className={styles.content}>
                                        {t('about.mapWorkHours')}<br />
                                        Dolfi1920<br />
                                        Langer Kornweg 34C<br />
                                        65451 Kelsterbach
                                    </div>
                                </div>
                                <div className={styles.boxContact}>
                                    <div className={styles.header}>{t('about.mapPortugal')}</div>
                                    <div className={styles.content}>
                                        {t('about.mapWorkHours')}<br />
                                        Dolfi1920<br />
                                        Cacém Park, <br />
                                        Estrada de Paço<br />
                                        d’Arcos 88, Armazém 33<br />
                                        2739-512 Cacém
                                    </div>
                                </div>
                                <div className={styles.boxContact}>
                                    <div className={styles.header}>{t('about.mapSwitzerland')}</div>
                                    <div className={styles.content}>
                                        {t('about.mapWorkHours')}<br />
                                        Dolfi1920<br />
                                        Hasliring 12<br />
                                        6032 Emmen
                                    </div>
                                </div>
                                <div className={styles.boxContact}>
                                    <div className={styles.header}>{t('about.mapPoland')}</div>
                                    <div className={styles.content}>
                                        {t('about.mapWorkHours')}<br />
                                        Dolfi1920<br />
                                        Mineralna 15, building 2,<br />
                                        02-274 Warszawa
                                    </div>
                                </div>
                                <div className={styles.boxContact}>
                                    <div className={styles.header}>{t('about.mapCzechRepublic')}</div>
                                    <div className={styles.content}>
                                        {t('about.mapWorkHours')}<br />
                                        Dolfi1920<br />
                                        Toužimská 778, hall B3<br />
                                        Praha 9 - Letňany
                                    </div>
                                </div>
                            </div>
                            {!isMobile &&
                                <div className={styles.map}>
                                    <img src={ImgMap} alt="" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs;