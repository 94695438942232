import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { setUserLanguage, type StateT as UserStateT } from 'Store/modules/user';
import runApi, { ApiSendMethods } from 'api/base/runApi';
import { SupportedLanguageT } from 'api/modules/i18n';
import { changeLanguage } from 'Store/modules/i18n';
import { AppStateT } from 'Store/modules';

const OutParams: React.FC = () => {

    const params: any = useParams();
    const navigate = useNavigate();
    const [counterChange, setCounterChange] = useState(0);
    const languages = useSelector<AppStateT, SupportedLanguageT[]>(({ i18n }) => i18n.supportedLanguages?.data || []);
    const { data: user } = useSelector<AppStateT, UserStateT>((state) => state.user);
    const dispatch = useDispatch();

    const langAdapter = useCallback((langCode: string): string => {
        if (langCode === 'sk') return 'cs-cz';
        if (langCode === 'pt') return 'pt-pt';
        // if (langCode === 'es') return 'es-es';
        if (langCode === 'it-it') return 'it';
        if (langCode === 'fr-fr') return 'fr';
        if (langCode === 'es-es') return 'es';
        return langCode;
    }, []);

    // change user language
    useEffect(() => {
        if (params?.type && params?.value && counterChange === 0){
            if (params.type === 'lang'){
                const langValue = langAdapter(params.value);
                localStorage.setItem('languageTrySet', langValue);
                const lang = languages.find(l => l.value === langValue);
                if (lang){
                    dispatch(changeLanguage(lang.value));
                    setCounterChange(old => old + 1);
                    if (user){
                        const personalInfo = {
                            language: lang.value
                        }
                        runApi('user', personalInfo, (r: any) => {
                            if (r.result){
                                dispatch(setUserLanguage(lang.value));
                            }
                        }, ApiSendMethods.patch);
                        localStorage.removeItem('languageTrySet');
                    }
                }
            }
        }
        navigate("/");
    }, [languages, params, navigate, dispatch, user, counterChange, langAdapter]);

    return (
        <></>
    )
}

export default OutParams;