import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import { ShoppingCartList, ShoppingCartTotal } from 'PageComponents/LuggageStore';
import { CartProductT, CartT, ShopStateT } from 'Store/modules/luggageStore/shop';
import ShoppingCartVoucher from './ShoppingCartVoucher/ShoppingCartVoucher';
import styles from './ShoppingCartMenu.module.scss';
import { AppStateT } from 'Store/modules';
import { Button } from 'Components';

interface IShoppingCartMenu {
    claimId: string;
    cart: CartT;
    removeFromCart: (data: CartProductT) => void;
}

function ShoppingCartMenu({ claimId, cart, removeFromCart }: IShoppingCartMenu) {

    const { voucher, configShop, rabatCode, delivery } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const actionShowProduct = useCallback((cartProduct: CartProductT) => {
        navigate(`/store/${claimId}/luggage-details/${cartProduct.id}/${cartProduct.productSimpleId}`);
    }, [claimId, navigate])

    return (
        <>
            <div className={styles.root}>
                <div className={styles.content}>
                    <ShoppingCartList cart={cart} removeFromCart={removeFromCart} claimId={claimId} actionShowProduct={actionShowProduct} />

                    <ShoppingCartVoucher claimId={claimId} />

                    <ShoppingCartTotal
                        cart={cart}
                        deliveryPrice={delivery.deliveryMethod?.value ? delivery.deliveryMethod.value : 0}
                        creditCardServicePrice={5}
                        voucherValue={voucher}
                        currencySymbol={configShop.currency.symbol}
                        currencyCode={configShop.currency.code}
                        rabat={rabatCode}
                        t={t}
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <Link to={`/store/${claimId}`} className={styles.buttonContainer}>
                        <Button color="secondary" className={styles.button}>
                            {t('shop.continueButton')}
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default ShoppingCartMenu;
