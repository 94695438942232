import React from 'react';
import cx from 'classnames';

import { ReactComponent as ArrowDown } from 'Assets/arrow-down.svg';
import { isIOS } from 'Helpers/responsiveContainers';
import styles from './DropdownMobile.module.scss';

export interface IDropdown {
  name: string;
  label: string;
  value: string;
  options: {text?: string; value?: string}[]
  onChange: (value: any) => void;
  className?: string;
  disabled?: boolean;
  id?: string
  hasEmptyValue?: boolean
}

const DropdownMobile: React.FC<IDropdown> = ({ name, label, options, value, onChange, className, disabled = false, hasEmptyValue = false, id }) => {
    const refreshKey = Date.now();
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(e.target);
    };

    return (
          <div className={cx(styles.root, className, { [styles.ios]: isIOS()})}>
            {!disabled && isIOS() && (
              <ArrowDown className={styles.arrow}/>
            )}
            <span className={styles.label}>{label}</span>
            <select
              key={refreshKey}
              name={name}
              value={value}
              onChange={handleChange}
              disabled={disabled}
              className={styles.select}
              id={id}
            >
              {hasEmptyValue && <option value=''></option>}
              {options.filter((option) => option.value).map((option) => (
                <option
                  key={option.text}
                  value={option.value}
                >
                  {option.text}
                </option>
              ))}
            </select>
          </div>
    );
}


export default DropdownMobile