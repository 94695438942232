import { useLocation } from 'react-router-dom';
import React, { useMemo } from 'react';
import cx from 'classnames';

import { ReactComponent as ChatBotIcon } from 'Assets/chat-bot.svg';
import ComicBubble from 'Components/ComicsBubble/ComicBubble';
import { useDeviceType } from 'Helpers/responsiveContainers';
import { useMediaQuery } from 'react-responsive';
import styles from './TitleBlock.module.scss';
import useTranslate from 'Hooks/useTranslate';
interface IProps {
    withIcon?: boolean;
    title: string;
    subtitle: string;
    className?: string;
    buttonLeft?: (className: string) => React.ReactNode;
    buttonRight?: (className: string) => React.ReactNode;
    chatBotClassName?: string;
}

const TitleBlock: React.FC<IProps> = ({ withIcon = true, title, subtitle, className, chatBotClassName, buttonLeft, buttonRight }) => {
    const location = useLocation();
    const t = useTranslate();
    const { isMobile} = useDeviceType();

    const isLoginPath = location.pathname.endsWith('/login');
    const isMediumScreen = useMediaQuery({
        query: '(min-width: 550px) and (max-width: 766px)'
    });
    const dynamicMarginTop = location.pathname.includes('/login') && isMobile 
    ? { marginTop: '100px' }
    : location.pathname.includes('/confirm') && isMobile
        ? { marginTop: '20px', paddingBottom: '12px' }
        : { marginTop: '10px' };

    if (isMediumScreen && location.pathname.includes('/login')) {
        dynamicMarginTop.marginTop = '400px';
    }

    const bubbleStyles = useMemo(() => {
        return isMobile
            ? { width: '120px', margin: '-90px 10px 0px 220px', }
            //{ width: '150px', margin: '-80px 10px 0px 230px' }; bez think tylko zwykły dymek
            : { width: '200px', margin: '-80px 0px 50px 280px' };
            //{ width: '200px', margin: '-60px 0px 50px 280px' }; bez think tylko zwykły dymek
    }, [isMobile]);
    return (
        <div className={cx(styles.container, className)} style={dynamicMarginTop}>
            {isLoginPath && (
                <ComicBubble
                    type="left think"
                    content={t('login.subtitle')}
                    width={bubbleStyles.width}
                    margin={bubbleStyles.margin}
                    style={isMobile && {
                        fontSize: '8px'
                    }}
                />
            )}
            {withIcon && (
                <div className={chatBotClassName ? chatBotClassName : styles.chatBot}>
                    {!!buttonLeft && buttonLeft(cx(styles.button, styles.buttonLeft))}

                    <ChatBotIcon />

                    {!!buttonRight && buttonRight(cx(styles.button, styles.buttonRight))}
                </div>
            )}
            <span className={cx(styles.title, 'first-row')}>{title}</span>
        </div>
    );
}

export default TitleBlock;