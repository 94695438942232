import React, { useCallback, useEffect, useMemo, useReducer} from 'react';
import { useSelector} from 'react-redux';
import cx from 'classnames';

import { Button, LoadableContent, Dropdown, Input } from 'Components';
import DropdownMobile from 'Components/DropdownMobile/DropdownMobile';
import type { StateT as UserStateT } from 'Store/modules/user';
import { useDeviceType } from 'Helpers/responsiveContainers';
import style from './PersonalInformation.module.scss';
import { formatInput } from 'Helpers/strings';
import { PageConfigT } from 'Store/modules/page';
import { validField } from 'Helpers/validForm';
import type { AppStateT } from 'Store/modules';
import useTranslate from "Hooks/useTranslate";
import Store from './Store';
import useChangeLanguage from 'Hooks/useChangeLanguage';

export interface IPersonalInformation {
    userData: UserStateT['data'];
    onSubmit: (data: any) => void;
    currency?: string;
    error: string | null;
    submitted: boolean;
    reset: () => void;
    className?: string;
    actionLogout?: () => void,
    isSubmitting?: boolean,
    errEmail?: boolean,
    setErrEmail?: (value: boolean) => void
}

const initialState: LocalStoreT = {
    firstName: '',
    lastName: '',
    title: '',
    salutation: '',
    email: '',
    phoneNumber: '',
    currency: '',
    valid: false,
    showTitle: true
}

export type LocalStoreT = {
    firstName: string,
    lastName: string,
    title: string,
    salutation: string,
    email: string,
    phoneNumber: string,
    currency: string,
    valid: boolean,
    showTitle: boolean
}

const defaultSalutation = 'Mr.';

const PersonalInformation: React.FC<IPersonalInformation> = ({ userData, isSubmitting, onSubmit, currency, reset, errEmail, setErrEmail }) => {
    const t = useTranslate();
    const { isMobile } = useDeviceType();

    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);

    const { sortedTranslatedLanguages, actualLanguage, changeAppLanguage } = useChangeLanguage();

    const languageOptions = useMemo(() => sortedTranslatedLanguages.map(language => ({
        ...language,
        text: t(language.displayName),
        key: `lang-${language.value}`,
    })), [sortedTranslatedLanguages, t]);
    
    const titleOptions = useCallback(() => {
        return Object.entries(pageConfig.pax.title.titleNames).sort((a,b) => a[1] < b[1] ? -1 : 1).map(r => {
            const key = `registration.${r[0]}`;
            const text = t(key) === key ? (r[1] || '-') : t(key);
            const value = !pageConfig.pax.title.titleNames[r[0]] ? pageConfig.pax.title.titleNames[r[0]] : pageConfig.pax.title.titleEnum[r[0]];
            return { text, value }
        });
    }, [pageConfig.pax, t])

    const salutationOptions = Object.entries(pageConfig.pax.salutation.salutationNames).filter(data => data[1]).sort((a,b) => a[1] < b[1] ? -1 : 1).map(data => {
        return { text: data[1] ? t('registration.title' + data[1].replace(".", "")) : '-', value: data[1] ? data[0] : '' }
    });

    const [state, setState] = useReducer(Store, {
        ...initialState,
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        title: userData.title || (Object.entries(pageConfig.pax.title.titleEnum)?.[0]?.[1] || ''),
        salutation: userData.salutation || defaultSalutation,
        email: userData.email?.replace(' ', '').toLowerCase() || '',
        phoneNumber: userData.phoneNumber || '',
        currency: currency || '',
    });

    useEffect(() => {
        setState({
            type: 'SET_SHOW_TITLE',
            data: pageConfig.pax.title.titleByLocation.includes(userData.location.countryCode)
        });
    }, [userData.location.countryCode, pageConfig.pax.title.titleByLocation]);

    const setSt = useCallback((dataType: string, data: any) => {
        setState({ type: 'SET_DATA', dataType, data });
    }, [setState]);

    const validData = useCallback((dataType: string): boolean => {

        switch(dataType){
            case 'firstName': return state.firstName.trim().length >= 2;
            case 'lastName': return state.lastName.trim().length >= 2;
            case 'email': return validField('email', state.email?.replace(' ', '').toLowerCase());
            case 'phoneNumber': return state.phoneNumber.trim().length >= 9;
        }

        return true;
    }, [state.firstName, state.lastName, state.email, state.phoneNumber]);

    useEffect(() => {
        if (validData('firstName') && validData('lastName') && validData('email') && validData('language') && validData('phoneNumber')){
            return setSt('valid', true);
        }
        return setSt('valid', false);
    }, [state.firstName, state.lastName, state.email, state.phoneNumber, setSt, validData]);

    useEffect(() => {
        return () => {
            reset();
        };
    }, [reset]);

    const actionPressSave = () => {

        const dataSend: any = {
            firstName: state.firstName,
            lastName: state.lastName,
            phoneNumber: state.phoneNumber,
            salutation: state.salutation,
            title: state.title,
            language: actualLanguage
        };

        onSubmit(dataSend);
    }

    const actionChangeInput = (event: React.ChangeEvent<HTMLInputElement>, dataType: string, capitalize = true) => {
        setSt(dataType, capitalize ? formatInput(event.target.value) : event.target.value);
    }

    return (
        <div className={style.root}>
            <div className={style.body}>

                <LoadableContent loading={!!isSubmitting}>
                    <div className={style.lastName}>
                        {!isMobile ? (
                        <>
                            <Dropdown
                                label={t('labels.title')}
                                name={'title_user'}
                                options={salutationOptions}
                                onChange={(value) => setSt('salutation', value.value)}
                                value={state.salutation}
                                className={style.salutation}
                                id={'search_user_salutation'}
                            />
                        </>
                        ) : (
                        <>
                            <DropdownMobile
                                label={t('labels.title')}
                                name={'title_user'}
                                options={salutationOptions}
                                onChange={(value) => setSt('salutation', value.value)}
                                value={state.salutation}
                                className={style.salutation}
                                id={'search_user_salutation'}
                            />
                        </>
                        )}
                        {state.showTitle && !isMobile &&
                            <Dropdown
                                label={t('labels.salutation')}
                                name={'salutation_user'}
                                options={titleOptions()}
                                onChange={(value) => setSt('title', value.value)}
                                value={state.title}
                                className={style.title}
                                id={'search_user_title'}
                            />
                        }
                        {state.showTitle && isMobile &&
                            <DropdownMobile
                                label={t('labels.salutation')}
                                name={'salutation_user'}
                                options={titleOptions()}
                                onChange={(value) => setSt('title', value.value)}
                                value={state.title}
                                className={style.title}
                                id={'search_user_title'}
                            />
                        }
                        {!state.showTitle &&
                            <Input
                                label={t('shop.formFirstName')}
                                value={state.firstName}
                                onChange={event => actionChangeInput(event, 'firstName')}
                                error={'required'}
                                touched={!validData('firstName')}
                                maxLength={40}
                                className={style.inputName}
                                id={'input_user_firstname'}
                            />
                        }
                    </div>
                    {state.showTitle &&
                        <Input
                            label={t('shop.formFirstName')}
                            value={state.firstName}
                            onChange={event => actionChangeInput(event, 'firstName')}
                            error={'required'}
                            touched={!validData('firstName')}
                            maxLength={40}
                            id={'input_user_firstname'}
                        />
                    }

                    <Input
                        label={t('shop.formLastName')}
                        value={state.lastName}
                        onChange={event => actionChangeInput(event, 'lastName')}
                        error={'required'}
                        touched={!validData('lastName')}
                        maxLength={40}
                        className={state.showTitle ? style.input : undefined}
                        id={'input_user_lastname'}
                    />

                    <Input
                        label={t('labels.phone')}
                        value={state.phoneNumber}
                        onChange={event => actionChangeInput(event, 'phoneNumber', false)}
                        error={'required'}
                        touched={!validData('phoneNumber')}
                        className={style.input}
                        id={'input_user_phone'}
                    />

                    <Input
                        label={t('labels.email')}
                        disabled
                        value={state.email?.replace(' ', '').toLowerCase()}
                        onChange={event => actionChangeInput(event, 'email', false)}
                        error={'required'}
                        touched={!validData('email')}
                        className={cx(style.input, style.email, { [style.error]: errEmail })}
                        onFocus={() => setErrEmail?.(false)}
                        id={'input_user_email'}
                    />

                    {!isMobile ? (
                    <>
                        <Dropdown
                            name="language"
                            label={t('labels.language')}
                            value={actualLanguage}
                            options={languageOptions}
                            onChange={({ value }) => {changeAppLanguage(value)}}
                            className={style.input}
                            id={'search_user_language'}
                        />
                    </>
                    ) : (
                    <>
                        <DropdownMobile
                            name="language"
                            label={t('labels.language')}
                            value={actualLanguage}
                            options={languageOptions}
                            onChange={({ value }) => changeAppLanguage(value)}
                            className={style.input}
                            id={'search_user_language'}
                        />
                    </>
                    )}

                </LoadableContent>
            </div>
            <div className={style.buttons}>
                <Button onClick={actionPressSave} disabled={!state.valid} id={'button_user_save'}>{t('common.save')}</Button>
            </div>

        </div>
    );
}

export default PersonalInformation;
