import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import React, { useState, useEffect, useMemo } from 'react';
import styles from './CircularTimer.module.scss';

type MainPropsT = {
	stopped?: boolean;
	limit?: number;
	onTimeOut?: () => void;
	initialTimerValue?: number;
	strokeWidth?: number;
	trailColor?: string,
	onTick?: (secondsLeft: number) => void
}

const CircularTimer: React.FC<MainPropsT> = ({ initialTimerValue = 0, limit = 60, onTick, onTimeOut = () => {}, stopped, strokeWidth, trailColor }) => {
	const [time, setTime] = useState(() => initialTimerValue);

	const progressStyle = useMemo(() =>
		buildStyles({
			textColor: '#2FC3DD',
			pathColor: '#2FC3DD',
			trailColor: trailColor || '#F0F3F3',
		}),
	[trailColor]);

	useEffect(() => {
		if (!stopped) {
			const tOut = setTimeout(() => {
				if (time + 1 > limit) {
					onTimeOut();
				} else {
					setTime((currentTime) => {
						onTick?.(limit - currentTime -1);
						return currentTime + 1
					});
				}
			}, 1000);

			return () => {
				clearTimeout(tOut);
			};
		}
	}, [stopped, limit, onTimeOut, time, onTick]);

	const percentValue = useMemo(() => {
		const decimalPercent = +(time / limit).toFixed(2);
		return decimalPercent * 100;
	}, [time, limit]);

	return (
		<div className={styles.root}>
			<div className={styles.timer}>
				<CircularProgressbar value={percentValue} text="" strokeWidth={strokeWidth || 4} styles={progressStyle} />
			</div>
		</div>
	);
}

export default CircularTimer;