import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { ReactComponent as ChatBotIcon } from 'Assets/chat_bot_small.svg';
import { DataCheckChatStatusT } from "Hooks/useStatusHasContent";
import useStatusHasContent from 'Hooks/useStatusHasContent';
import styles from './StatusBubble.module.scss';
import { ShipmentT } from 'api/modules/claim';

type VerdictT = {
    refund: number;
    repair: boolean;
    voucher: number;
};

type PropsT = {
    status: string;
    verdict: VerdictT | null | any;
    id: string;
    data: DataCheckChatStatusT;
    shipments: ShipmentT[]|undefined|null
};

export default function StatusBubble({ status, verdict, id, data, shipments }: PropsT) {
    const [open, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const toggle = useCallback((evt: any) => {
        evt.stopPropagation();
        setIsOpen((prevState) => !prevState);
    }, [setIsOpen]);
    
    const { hasContent, content: contentExtend, header: contentMain, hasHeader } = useStatusHasContent(status, shipments, data);

    const fullDescription = contentExtend || '';
    const truncatedDescription = fullDescription.length > 100 ? `${fullDescription.slice(0, 100)}...` : fullDescription;
    const description = open ? fullDescription : truncatedDescription;
    const buttonText = open ? t('common.less') : t('common.more');

    return (
        <>
            {hasHeader &&
                <div className={styles.root}>
                    <ChatBotIcon className={styles.icon} height="32" width="32" />
                    <div className={styles.bubble}>
                        <span className={styles.title}>{contentMain}</span>
                        {hasContent && (
                            <span className={styles.content}>
                                {parse(description)}{' '}
                                {fullDescription.length > 100 && (
                                    <button onClick={toggle} className={styles.toggleButton}>{buttonText}</button>
                                )}
                            </span>
                        )}
                    </div>
                </div>
            }
        </>
    );
}
