export const getSystemLanguage = (): { code: string, codeUpperCase: string, codeLowerCase: string, fullCode: string } => {

    const defaultResult = { code: 'en', codeUpperCase: 'US', codeLowerCase: 'us', fullCode: 'en-us' };

    if (window.navigator?.language){
        const dataLanguage = window.navigator.language.split('-');
        if (dataLanguage[1]){
            return { code: dataLanguage[0], codeUpperCase: dataLanguage[1], codeLowerCase: dataLanguage[1].toLowerCase(), fullCode: window.navigator.language };
        }
        return { code: window.navigator.language, codeUpperCase: window.navigator.language.toUpperCase(), codeLowerCase: window.navigator.language.toLowerCase(), fullCode: window.navigator.language };
    }

    return defaultResult;

}

export const isFirefox = (): boolean => navigator.userAgent.indexOf('Firefox') > -1;
export const isChrome = (): boolean => navigator.userAgent.indexOf('Chrome') > -1;

export const iOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const iPhone = (): boolean => {
    return navigator.userAgent.indexOf('iPhone') >=0;
}

export const isSafari = (): boolean => navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

export const actionShowCookies = () => {
    try{
        // @ts-ignore
        CCM.openWidget();
    } catch(error){
        console.log('error cookies: ', error);
    }
};