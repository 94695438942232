import React, { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import parse from 'html-react-parser';
import cx from 'classnames';

import { ReactComponent as ChatDisabled } from 'Assets/button-chat-expert-disabled.svg';
import { ReactComponent as ChatActive } from 'Assets/button-chat-expert-active.svg';
import { ReactComponent as ChatRingWhite } from 'Assets/chat-ring-white.svg';
import { ReactComponent as ChatRing } from 'Assets/chat-ring.svg';
import { ReactComponent as ChatInfo } from 'Assets/chat-bot.svg';

import { PageConfigT, StateT as PageStateT, setMessageClaim } from "Store/modules/page";
import runApi, { ApiSendMethods, ResultApiTypeT } from "api/base/runApi";
import { StateT, setChatMessages } from "Store/modules/claimChat/data";
import { useSocket } from "PageComponents/SocketContext/SocketContext";
import { type StateT as UserStateT } from 'Store/modules/user';
import { useDeviceType } from "Helpers/responsiveContainers";
import { ClaimChatMessagesT } from "api/modules/claim";
import { runNotify } from "Helpers/notifications";
import Loading from "Components/Loading/Loading";
import useWindowSize from "Hooks/useWindowSize";
import style from './ClaimMessage.module.scss';
import useTranslate from "Hooks/useTranslate";
import FaqAnswer from "./FaqAnswer/FaqAnswer";
import { chatFormatDate } from "Helpers/date";
import InputText from './InputText/InputText';
import { Button, Modal } from "Components";
import { AppStateT } from "Store/modules";
import { dataFaq } from "Helpers/chatFaq";
import Store from "./Store";
import useChatConversationVisible from "Hooks/useChatConversationVisible";

export type FaqT = {
    question: string,
    answerType: 'text'|'referenceNumber'|'caseNumber'|'order',
    grouped: 'assessment'|'dataValidation'|'rest',
    answer?: string,
    assignStatus?: string[],
    data?: React.Component,
    marked?: boolean
}

export type LocalStateT = {
    text: string,
    textSaved: string,
    faqSelectedIdx: number|null,
    isBlockedSend: boolean,
    loading: boolean,
    charsLeft: number,
    dataMessages: ClaimChatMessagesT| null,
    timerAddMessageEnd: Date|null,
    chatStatus: 'faq'|'empty'|'chat'|'chat-answer-pax'|'chat-answer-assessor'|'chat-close'|'chat-waiting'|'faq-no-question'|null,
    minTextLength: number,
    height: {
        layer: number,
        body: number
    },
    additionalHeight: number,
    blockedChange: boolean
}

const initialState: LocalStateT = {
    faqSelectedIdx: null,
    isBlockedSend: false,
    loading: false,
    text: '',
    textSaved: '',
    charsLeft: 0,
    dataMessages: null,
    timerAddMessageEnd: null,
    chatStatus: null,
    minTextLength: 40,
    height: {
        layer: 500,
        body: 420
    },
    additionalHeight: 0,
    blockedChange: false
}

const scrollDown = () => {
    const messagesChat = document.getElementById('messagesChat');
    if (messagesChat){
        messagesChat.scrollIntoView(false);
    }
}

type MainPropsT = {
    noModal?: boolean,
    height?: any
}

const ClaimMessage: React.FC<MainPropsT> = ({ noModal, height }) => {

    const { chatMessages, websocketChatAddMessage } = useSelector<AppStateT, StateT>((state) => state.claimChat.data);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const [state, setState] = useReducer(Store, {
        ...initialState,
        dataMessages: chatMessages,
        charsLeft: pageConfig.claimChat.MAX_MESSAGE_LENGTH
    });
    const { messageCase: claim } = useSelector<AppStateT, PageStateT>((state) => state.page);
    const dispatch  = useDispatch();
    const t = useTranslate();
    const { isMobile } = useDeviceType();
    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const [dataQuestions, setDataQuestions] = useState<FaqT[]>(dataFaq);
    const { send } = useSocket();
    const windowSize = useWindowSize();
    const enabledConversation = useChatConversationVisible(claim?.branch, claim?.status, chatMessages, user.language);

    const isTypingRef = useRef(false);
    const timeoutRef = useRef(null);
    const refFaqBody = useRef<HTMLDivElement>();

    useLayoutEffect(() => {
        if (isMobile && state.faqSelectedIdx >=0 && refFaqBody.current){
            refFaqBody.current.scrollIntoView();
        }
    }, [refFaqBody, state.faqSelectedIdx, isMobile]);

    useLayoutEffect(() => {
        if (state.dataMessages){
            scrollDown();
        }
    }, [state.dataMessages]);

    useLayoutEffect(() => {
        if (windowSize.height){
            const maxHeight = 750;
            let heightRest = 100;
            if (windowSize.height > 1090){
                return setState({ type: 'SET_HEIGHT', data: { layer: maxHeight, body: maxHeight - heightRest } });
            }
            setState({ type: 'SET_HEIGHT', data: { layer: windowSize.height - 300, body: (windowSize.height - 290) - heightRest } });
        }
    }, [windowSize, state.chatStatus]);

    useEffect(() => {
        setState({ type: 'SET_CHARS_LEFT', data: pageConfig.claimChat.MAX_MESSAGE_LENGTH - state.text.length });
    }, [state.text, pageConfig.claimChat.MAX_MESSAGE_LENGTH]);

    useEffect(() => {
        const minData = { firstMessage: 40, restMessages: 2 };
        setState({ type: 'SET_MIN_TEXT_LENGTH', data: !state.dataMessages?.messages || (state.dataMessages?.messages && state.dataMessages.messages.length < 1) ? minData.firstMessage : minData.restMessages });
    }, [state.dataMessages?.messages]);

    // order data faq with actual claim status
    useEffect(() => {
        const isAssessment = claim.status.indexOf('ASSESSMENT') >= 0;
        let  data = [...dataFaq];
        if (isAssessment){
            data.sort((a, b) => a.grouped === 'assessment' ? -1 : 0);
        } else {
            data.sort((a, b) => a.grouped === 'dataValidation' ? -1 : 0);
        }
        data = data.map(d => {
            if ((isAssessment && d.grouped === 'assessment') || (!isAssessment && d.grouped === 'dataValidation')) d.marked = true;
            else d.marked = null;
            return d;
        });
        setDataQuestions(data);
    }, [claim.status]);

    useEffect(() => {
        if (state.blockedChange) return;
        if ((!state.dataMessages || (state.dataMessages && state.dataMessages?.messages.length === 0)) && state.faqSelectedIdx === null){
            localStorage.removeItem(`chat-status-${claim?.id || ''}`);
            return setState({ type: 'SET_CHAT_STATUS', data: 'empty' });
        }
        if (state.faqSelectedIdx !== null && dataQuestions?.[state.faqSelectedIdx]){
            localStorage.removeItem(`chat-status-${claim?.id || ''}`);
            return setState({ type: 'SET_CHAT_STATUS', data: 'faq' });
        }
        if (state.dataMessages &&state.dataMessages.messages[state.dataMessages.messages.length - 1].type === 'PAX'){
            localStorage.removeItem(`chat-status-${claim?.id || ''}`);
            return setState({ type: 'SET_CHAT_STATUS', data: 'chat-answer-pax' });
        }
        if (state.dataMessages &&state.dataMessages.messages[state.dataMessages.messages.length - 1].type === 'ASSESSOR'){
            const lsData = localStorage.getItem(`chat-status-${claim.id}`);
            if (!lsData){
                return setState({ type: 'SET_CHAT_STATUS', data: 'chat-waiting' });
            }
            if (lsData === 'close'){
                return setState({ type: 'SET_CHAT_STATUS', data: 'chat-close' });
            }
            if (lsData === 'waiting'){
                return setState({ type: 'SET_CHAT_STATUS', data: 'chat-waiting' });
            }
            if (lsData === 'ready'){
                return setState({ type: 'SET_CHAT_STATUS', data: 'chat-answer-assessor' });
            }
        }
        setState({ type: 'SET_CHAT_STATUS', data: 'chat' });
    }, [state.dataMessages, state.faqSelectedIdx, claim.id, dataQuestions, state.blockedChange]);

    const readMessages = useCallback(() => {
        runApi(`claim/${claim.id}/chat`, {}, (r: any) => {
            if (r.result){
                setState({ type: 'SET_DATA_MESSAGES', data: r.data });
                if (r.data?.unreadedMessagesByPax && r.data.unreadedMessagesByPax > 0){
                    runApi(`claim/${claim.id}/chat/read`, {}, (r: any) => {
                        if (r.result){
                            dispatch(setChatMessages(r.data));
                            setTimeout(() => {
                                scrollDown();
                            }, 200);
                        }
                    }, ApiSendMethods.get);
                }
            }
        }, ApiSendMethods.get);
    }, [claim.id, dispatch]);

    useEffect(() => {
        if (websocketChatAddMessage && websocketChatAddMessage.claimId === claim.id){
            setState({ type: 'ADD_MESSAGE_FROM_WEBSOCKETS', data: websocketChatAddMessage.message });
            readMessages();
        }
    }, [websocketChatAddMessage, claim.id, readMessages]);

    useEffect(() => {
        readMessages();
    }, [readMessages]);

    const setSt = useCallback((dataType: string, data: any) => {
        setState({ type: 'SET_DATA', dataType, data });
    }, []);

    const sendMessage = useCallback(() => {
        setSt('loading', true);
        runApi(`claim/${claim.id}/chat/message`, { message: state.text }, (r: ResultApiTypeT) => {
            setSt('loading', false);
            if (r.result){
                setState({ type: 'ADD_MESSAGE', data: r.data, claimId: claim.id });
                runNotify({ message: t('chatContact.alertMessageSend'), type: 'success' });
                setTimeout(() => {
                    scrollDown();
                }, 200);
            } else {
                runNotify({ message: r.error.message || t('chatContact.errorSending'), type: 'error' });
            }
        }, ApiSendMethods.post);
    }, [claim.id, t, state.text, setSt]);

    const actionClose = useCallback(() => {
        if (isMobile) return setSt('faqSelectedIdx', null);
        dispatch(setMessageClaim(null));
    }, [dispatch, isMobile, setSt]);

    useEffect(() => {
        if (!enabledConversation){
            actionClose();
        }
    }, [enabledConversation, actionClose]);


    const actionSubmit = useCallback((event: React.FormEvent) => {
        event.preventDefault();
        if (enabledConversation){
            sendMessage();
        }
    }, [sendMessage, enabledConversation]);

    const actionChatContinue = useCallback(() => {
        localStorage.setItem(`chat-status-${claim?.id || ''}`, 'ready');
        setState({ type: 'SET_CHAT_STATUS', data: 'chat-answer-assessor' });
    }, [claim?.id]);

    const actionChatClose = useCallback(() => {
        localStorage.setItem(`chat-status-${claim?.id || ''}`, 'close');
        setState({ type: 'SET_CHAT_STATUS', data: 'chat-close' });
    }, [claim?.id])

    const actionChatOpen = useCallback(() => {
        if (state.dataMessages && state.dataMessages.messages[state.dataMessages.messages.length - 1].type === 'PAX'){
            return setState({ type: 'SET_CHAT_STATUS', data: 'chat-answer-pax' });
        }
        if (state.dataMessages && state.dataMessages.messages[state.dataMessages.messages.length - 1].type === 'ASSESSOR'){
            localStorage.setItem(`chat-status-${claim?.id || ''}`, 'ready');
            return setState({ type: 'SET_CHAT_STATUS', data: 'chat-answer-assessor' });
        }
    }, [state.dataMessages, claim?.id]);

    const emitWriteMessage = useCallback(() => {
        send('onPaxWritingMessage', { claimId: claim.id, isPaxWritingMessage: true });
    }, [claim.id, send]);

    const onChangeTextMessage = useCallback((text: string) => {
        setState({ type: 'SET_TEXT', data: text, maxLength: pageConfig.claimChat.MAX_MESSAGE_LENGTH });

        if (!isTypingRef.current) {
            isTypingRef.current = true;
            emitWriteMessage();
            setState({ type: 'SET_SAVE_TEXT' });
        }

        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            emitWriteMessage();
            isTypingRef.current = false;
            setState({ type: 'SET_SAVE_TEXT' });
        }, 1500);
    }, [emitWriteMessage, pageConfig.claimChat.MAX_MESSAGE_LENGTH]);

    const actionBlurText = useCallback(() => {
        if (state.text !== state.textSaved){
            emitWriteMessage();
            setState({ type: 'SET_SAVE_TEXT' });
        }
    }, [state.text, state.textSaved, emitWriteMessage]);

    const getStatus = useMemo((): string => {
        const translateKey = `caseStatuses.${claim.status}`;
        const translate = t(translateKey);
        if (translate !== translateKey){
            return translate;
        }
        return '';
    }, [t, claim.status]);

    const actionGoChat = useCallback(() => {
        setState({ type: 'GO_CHAT' });
    }, []);

    const actionIcoInput = useCallback(() => {
        setState({ type: 'SET_CHAT_STATUS', data: 'empty' });
    }, []);

    const actionAddHeight = useCallback((data: number) => {
        setState({ type: 'SET_ADDITIONAL_HEIGHT', data });
    }, []);

    const BodyClaimMessage = () => {
        return (
            <div
                className={
                    cx(
                        style.root,
                        {
                            [style.rootMobile]: isMobile,
                            [style.rootScroll]: state.chatStatus === 'empty' || state.chatStatus === 'faq' || state.chatStatus === 'faq-no-question'
                        }
                    )
                }
                style={{
                    height: isMobile && height ? height : state.height.layer 
                }}
            >
                <div
                    className={
                        cx(
                            style.body,
                            {
                                [style.bodyMobile]: isMobile && (state.chatStatus === 'faq' || state.chatStatus === 'empty'),
                                [style.bodyNoQuestion]: isMobile && state.chatStatus === 'faq-no-question'
                            }
                        )}
                    style={
                        state.chatStatus && state.chatStatus.indexOf('chat') >=0 ? { height: isMobile ? 'auto' : state.height.body - state.additionalHeight } : undefined
                    }
                >
                    {state.loading &&
                        <div className={style.loading}>
                            <Loading />
                        </div>
                    }
                    {(state.faqSelectedIdx !== null && dataQuestions?.[state.faqSelectedIdx]) ?
                        <div className={style.faq}>
                            <div className={style.faqBody} style={{ height: isMobile ? 'auto' : state.height.body - 50 }} ref={refFaqBody}>
                                <div className={style.question}>
                                    {t(dataQuestions[state.faqSelectedIdx].question)}
                                </div>
                                {dataQuestions[state.faqSelectedIdx].answerType &&
                                    <div className={style.answer}>
                                        <FaqAnswer typeAnswer={dataQuestions[state.faqSelectedIdx].answerType} answer={dataQuestions[state.faqSelectedIdx].answer} t={t} />
                                    </div>
                                }
                            </div>
                            <div className={style.buttons}>
                                <div className={style.question}>{t('chatContact.questionAnwerSolved')}</div>
                                <div className={style.buttonsBody}>
                                    <Button
                                        className={style.back}
                                        onClick={() => {
                                            if (state.dataMessages && state.dataMessages.messages && state.dataMessages.messages.length > 0 && !noModal){
                                                actionClose();
                                            }
                                            setSt('faqSelectedIdx', null)
                                        }}
                                    >
                                        {t('common.yes')}
                                    </Button>
                                    <Button onClick={actionGoChat}>{t('common.no')}</Button>
                                </div>
                            </div>
                        </div>:
                        <>
                            {(!state.dataMessages || (state.dataMessages && state.dataMessages?.messages.length === 0)) ?
                                <div className={style.noMessages}>
                                    <div className={style.ico}>
                                        <ChatInfo style={state.chatStatus === 'chat' && !isMobile ? { width: 180, height: 180 } : { width: 100, height: 100 }} />
                                    </div>
                                    {state.chatStatus === 'chat' &&
                                        <div className={cx(style.firstInfo, { [style.firstInfoMobile]: isMobile })}>
                                            {t('chatContact.firstMessage')}
                                        </div>
                                    }
                                </div>:
                                <>
                                    {(state.chatStatus !== 'empty' && state.chatStatus !== 'faq-no-question') &&
                                        <div className={style.bodyWrapper} id="messagesChat">
                                            {state.dataMessages.messages.map((data, idx) => {
                                                return (
                                                    <div className={cx(style.chat, { [style.right]: data.type !== 'ASSESSOR' })} key={`chat-${idx}`}>
                                                        <div className={style.date}>
                                                            {data.type === 'ASSESSOR' ? <ChatRing /> : <ChatRingWhite />}
                                                            {data.createdAt &&
                                                                <div className={style.dateString}>{chatFormatDate(new Date(data.createdAt), user.language)}</div>
                                                            }
                                                        </div>
                                                        <div className={style.description}>
                                                            {parse(data.message.replaceAll('\n', '<br />'))}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {state.chatStatus === 'chat-answer-pax' &&
                                                <div className={style.waitingMessage}>
                                                    {t('chatContact.waitMessageFooter')}
                                                </div>
                                            }
                                            {state.chatStatus === 'chat-waiting' &&
                                                <div className={cx(style.waitingMessage, style.assessorReply)}>
                                                    {t('chatContact.assessorAnswerSatisfied')}
                                                    <div className={style.buttons}>
                                                        <button onClick={actionChatContinue}>{t('common.no')}</button>
                                                        <button onClick={actionChatClose}>{t('chatContact.assessorAnswerYes')}</button>
                                                    </div>
                                                </div>
                                            }
                                            {state.chatStatus === 'chat-close' &&
                                                <div className={cx(style.waitingMessage, style.assessorReply)}>
                                                    {t('chatContact.assessorAnswerResume')}
                                                    <div className={style.buttons}>
                                                        <button onClick={actionChatOpen}>{t('chatContact.assessorAnswerResumeYes')}</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
                {(state.chatStatus === 'empty') &&
                    <div className={style.faqs}>
                        {getStatus &&
                            <div className={style.statusLine}>{t('caseDetails.caseStatus')}: <strong>{getStatus}</strong></div>
                        }
                        <header>{t('chatContact.description')}</header>
                        <ul>
                            {dataQuestions.map((data: FaqT, idx: number) => (
                                <li key={`tag_${data.question}_${idx}`} onClick={() => setSt('faqSelectedIdx', idx)} className={cx({ [style.marked]: !!data.marked })}>
                                    {t(data.question)}
                                </li>
                            ))}
                            <li className={style.noOnList} onClick={() => setState({ type: 'SET_SHOW_NO_QUESTION', data: true })}>
                                {t('chatContact.noQuestionList')}
                            </li>
                        </ul>
                    </div>
                }
                {state.chatStatus === 'faq-no-question' &&
                    <div className={style.faqNoQuestion}>
                        <header>
                            {parse(t('chatContact.noQuestionContent', { buttonBack: t('caseDetails.back'), buttonGoChat: t('chatContact.buttonGoChat') }))}
                        </header>
                        <div className={style.buttons}>
                            <Button onClick={() => setState({ type: 'SET_CHAT_STATUS', data: 'empty' })} type={'button'} className={style.subButton}>{t('caseDetails.back')}</Button>
                            <Button onClick={() => setState({ type: 'SET_CHAT_STATUS', data: 'chat' })} type={'button'}>{t('chatContact.buttonGoChat')}</Button>
                        </div>
                    </div>
                }
                {(state.chatStatus !== 'chat-waiting' && state.chatStatus !== 'chat-close' && state.chatStatus !== 'empty' && state.chatStatus !== 'faq' && state.chatStatus !== 'faq-no-question') &&
                    <>
                        <div className={style.inputs}>
                            {(!(state.dataMessages && state.dataMessages.messages.length > 0) && state.text.trim().length === 0) &&
                                <div className={style.firstMessage}>{t('chatMobile.firstMessageComment', { countChars: 40 })}</div>
                            }
                            <div className={cx(style.chatInput, { [style.isMobile]: isMobile })}>
                                <form onSubmit={actionSubmit} className={style.field}>
                                    <div className={style.ico} onClick={actionIcoInput}>{state.isBlockedSend ? <ChatDisabled /> : <ChatActive />}</div>
                                    <div className={style.input}>
                                        {/* <input
                                            value={state.text}
                                            onChange={onChangeTextMessage}
                                            disabled={state.isBlockedSend || state.loading} autoFocus={!isMobile}
                                            maxLength={pageConfig.claimChat.MAX_MESSAGE_LENGTH}
                                            onBlur={actionBlurText}
                                            // onFocus={emitWriteMessage}
                                        /> */}
                                        <InputText
                                            className={style.textarea}
                                            maxRows={4}
                                            onChange={onChangeTextMessage}
                                            value={state.text}
                                            actionAddHeight={actionAddHeight}
                                            actionOnBlur={actionBlurText}
                                        />
                                        {(!state.isBlockedSend && !state.loading) &&
                                            <div className={cx(style.charsLeft, { [style.canSubmit]: state.text.length >= state.minTextLength })}>
                                                {state.text.length < state.minTextLength ?
                                                    <><span className={style.textLength}>{state.text.length}</span><strong>/{state.minTextLength}</strong></>:
                                                    <>
                                                        {state.charsLeft}<strong>/{pageConfig.claimChat.MAX_MESSAGE_LENGTH}</strong>
                                                    </>
                                                }
                                            </div>
                                        }
                                        <button type={'submit'} disabled={state.isBlockedSend || state.loading || state.text.length < state.minTextLength}>{t('chatContact.sendButton')}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }

    if (noModal){
        return <>{BodyClaimMessage()}</>;
    }

    return (
        <Modal
            open
            onClose={actionClose}
            animatedContentClassName={cx(style.modalStyle, { [style.desktop]: !isMobile })}
            title={t('chatContact.header', { caseNumber: claim.caseNumber })}
            headerClassName={style.headerTop}
            contentClassName={cx(style.contentBody, { [style.mobileModal]: isMobile })}
            closeOnClickOutside
        >
            {BodyClaimMessage()}
        </Modal>
    );
}
export default ClaimMessage;