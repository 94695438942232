import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { ReactComponent as IcoSearch } from 'Assets/search-list.svg';
import { ReactComponent as IcoArrowDown } from 'Assets/arrow-down.svg';
import { ReactComponent as IcoArrowUp } from 'Assets/arrow-up.svg';
import { ReactComponent as IcoCross } from 'Assets/cross-icon.svg';

import style from './DropDownSearch.module.scss';
import { generateId } from 'Helpers/strings';

type DataT = { value: string, text: string };

type MainProps = {
    data: DataT[],
    onChange: (data: DataT) => void,
    onChangeText?: (data: string) => void,
    label: string,
    value: string,
    className?: string;
    classNameList?: string;
    noFilterList?: boolean,
    search?: boolean,
    t: (data: string) => string,
    onExpand?: (expand: boolean) => void,
    disabled?: boolean,
    success?: boolean,
    hasCrossIcon?: boolean,
    id?: string
}

const DropDownSearch: React.FC<MainProps> = ({ data, onChange, onChangeText, label, value, className, classNameList, noFilterList, search, t, onExpand, disabled, success, hasCrossIcon, id }) => {

    const [isFocused, setIsFocused] = useState(false);
    const [showList, setShowList] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [dataFilter, setDataFilter] = useState<DataT[]>(data);
    const inputRef = useRef<HTMLInputElement>();
    const ref: any = useRef(null);

    useEffect(() => {
        onExpand?.(showList);
    }, [showList, onExpand]);

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowList(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (noFilterList){
            return setDataFilter(data);
        }
        setDataFilter(data.filter((d: DataT) => d.text.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0));
    }, [searchValue, value, data, noFilterList]);

    useLayoutEffect(() => {
        if (showList){
            const listenerKeyEscape = (event: KeyboardEvent) => {
                if (event.key === 'Escape'){
                    setShowList(false);
                    setIsFocused(false);
                    setSearchValue('');
                } else if (!isFocused && search){
                    inputRef.current?.focus();
                }
            }
            document.addEventListener('keydown', listenerKeyEscape);
            return () => {
                document.removeEventListener("keydown", listenerKeyEscape);
            }
        }
    }, [setShowList, isFocused, showList, inputRef, search]);

    const actionChangeText = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(ev.target.value);
        onChangeText?.(ev.target.value);
    }

    const actionKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && dataFilter.length > 0 && dataFilter.length < data.length){
            const data: DataT = dataFilter[0];
            if (data){
                actionDataOption(data);
                setIsFocused(false);
            }
        }
    }

    const actionDataOption = (data: DataT) => {
        setSearchValue('');
        setShowList(false);
        onChange?.(data);
    }

    const clearSelection = () => {
        setSearchValue('');
        setShowList(false);
        onChange?.({ value: '', text: ''});
    }

    const acionLayerClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setSearchValue('');
        const targetElement = e.target as HTMLElement;
        if (!targetElement.closest(`.${style.clearButton}`)) {
            setShowList(true);
        }
    }, [setShowList, setSearchValue]);

    const actionArrow = useCallback(() => {
        if (showList){
            setShowList(false)
        }
    }, [showList, setShowList]);

    return (
        <div ref={ref} className={cx(style.root, className, { [style.noEmpty]: !!value, [style.expand]: showList, [style.notExpand]: !showList, [style.disabled]: disabled, [style.success]: success })} onClick={!showList && !disabled ? acionLayerClick : undefined} id={id}>
            <div className={style.titleLabel}>{label}</div>
            <div className={style.value}>{value}</div>
            {showList ?
                <IcoArrowUp className={cx(style.arrow, style.arrowDown)} onClick={actionArrow} />:
                <IcoArrowDown className={cx(style.arrow, style.arrowDown)} onClick={actionArrow} />
            }
            {hasCrossIcon && (
                <IcoCross className={cx(style.clearButton)} onClick={clearSelection}/>
            )}
            
            {showList &&
                <div className={cx(style.listLayer)}>
                    {search &&
                        <div className={cx(style.searchLayer, { [style.focus]: isFocused })}>
                            <input
                                className={style.search}
                                value={searchValue}
                                onChange={actionChangeText}
                                onFocus={() => {
                                    setIsFocused(true);
                                    if (!!!searchValue){
                                        setSearchValue('');
                                    }
                                }}
                                onBlur={() => setTimeout(() => {
                                    setIsFocused(false);
                                    // setSearchValue('');
                                }, 500)}
                                ref={inputRef as any}
                                placeholder={t('common.search')}
                                onKeyUp={actionKeyUp}
                            />
                            <IcoSearch className={style.ico} />
                        </div>
                    }
                    <div className={cx(style.list, classNameList)}>
                        {dataFilter.map((d: DataT, idx: number) => (
                            <div
                                className={cx(style.listOption, { [style.second]: idx % 2 })}
                                key={`list_${d.value}_${idx}_${generateId()}`}
                                onClick={() => actionDataOption(d)}
                            >
                                {d.text}
                            </div>
                        ))}
                        </div>
                </div>
            }
        </div>
    );
}
export default React.memo(DropDownSearch);
