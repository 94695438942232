import { CLAIM_STATUSES } from 'Config/statuses';
import { nun } from 'PageComponents/Chat/Chat/Chat';
import API from '../base/api';
import { ReqTypeT, ResTypeT } from '../index';
import { VerdictT } from './claim';

export type ResolutionTypeT = 'cashRefund' | 'repair' | 'orderNewLuggage';

export type ResolutionT<T> = {
  claimId: string;
  createdAt: string;
  id: string;
  resolutionData: ResolutionDataT | T;
  status: string;
  type: ResolutionTypeT;
  orderData?: any;
};

export type ResolutionDataT = {
  apt: string,
  courierNotes: string,
  date: string,
  email: string,
  extraService: {
    cost: number,
    currencyIsoCode: string,
    payment: {
      selectedType: string,
      status: string
    },
    salesforceId: string,
    service: string
  }
  leaveAt: string,
  location: {
    address: string
    city: string,
    coords: { lng: number, lat: number },
    country: string,
    countryCode: string,
    formattedAddress: string,
    zipCode: string,
  },
  name: string,
  phoneNumber: string,
  time: string
}

export type PickUpDataT = {
  name: string;
  location: LocationT;
  apt: string;
  leaveAt: string;
  courierNotes: string;
};

export type DeliveryDataT = {
  deliverySameAsBilling: boolean;
  delivery: {
    name: string;
    street: string;
    apt: string;
    zipCode: string;
    city: string;
    country: string;
  };
  billing: {
    name: string;
    company: string;
    tax: string;
    street: string;
    apt: string;
    zipCode: string;
    city: string;
    country: string;
  };
};

export type StatusT = {
  claimId: string;
  createdAt: string;
  id: string;
  status: CLAIM_STATUSES;
};

export interface IMyResolution {
  id: string;
  claimId: string;
  claimStatusId: string;
  createdAt: string;
  status: string; // "success"
  type: ResolutionTypeT;

  orderData: {
    type: string; // "pick-up";
  };

  resolutionData: {
    name: string; // "Le Ka"
    time: string; // "2021-12-24T08:00:00.000Z"
    apt: string; // ""
    courierNotes: string; // ""
    date: string; // "2021-12-24"
    leaveAt: string; // "door"

    location: {
      address: string; // "Droga Wojewódzka 637, 326"
      city: string; // "Warszawa"
      country: string; // "Poland"
      countryCode: string; // "PL"
      formattedAddress: string; // "DW637 326, 04-148 Warszawa, Poland"
      zipCode: string; // "04-148"

      coords: {
        lat: number; // 52.2470594
        lng: number; // 21.0592964
      };
    };
  };
}

export type ClaimHistoryT = {
  report: ReportT | null;
  resolution: IMyResolution | nun;
  status: StatusT;
  verdict: VerdictT;
};

// ### REPAIR ###

export type RepairDataT = LocationT;

export type RepairLocationRequestDataT = LocationT;

export type RepairLocationT = {
  distance: number;
  coordinates: [number, number];
  address: string;
  workingHours: any;
  telephoneNumber: string;
};

export type RepairPickUpT = PickUpDataT & {
  date: Date;
  time: string;
};

// ### REFUND ###

export enum REFUND_TYPES {
  PAYPAL = 'PayPal',
  WIRE_TRANSFER = 'Bank/Transfer',
}

export type PaypalAccountT = {
  receiver: string;
};

export type PossibleAccountDataT = PaypalAccountT;

export type WirelessTransferT = {};

export type CreditCardT = {
  cardNumber: string;
  expirationDate: string;
  cvv: string;
  cardHolder: string;
  amount: string;
};

export type PaypalResolutionData = {
  type: 'PayPal';
  status: 'success' | 'failure';
  amount: number;
};
export class CaseResolutionApi extends API {
  repairDropOff = (
    claimId: string,
    data: { location: RepairLocationT; userLocation: LocationT },
  ): ResTypeT<ResolutionT<any>> => {
    return this.r({
      method: 'POST',
      url: `/claim-resolution/${claimId}/repair/drop-off`,
      data: data,
    });
  };

  resetRefundResolution = (claimId: string) => {
    return this.r({
      method: 'DELETE',
      url: `/claim-resolution/${claimId}/refund`,
    });
  };
}

export default function caseResolution(request: ReqTypeT): CaseResolutionApi {
  return new CaseResolutionApi(request);
}
