import { useMemo } from 'react';

const useFormattedPrice = (currency: string, noFractionCurrencies: string[]): (price: number) => string => {
    const formatPrice = useMemo(() => {
        const isNoFractionCurrency = noFractionCurrencies.includes(currency);
    
        return (price: number): string => {
            if (isNoFractionCurrency) {
                return Math.round(price).toString();
            } else {
                const roundedPrice = Math.round(price * 100) / 100;
                const [integerPart, fractionalPart] = roundedPrice.toString().split('.');
            
                if (!fractionalPart) {
                    return integerPart;
                } else if (fractionalPart.length === 1) {
                    return `${integerPart}.${fractionalPart}0`;
                } else {
                    return roundedPrice.toFixed(2);
                }
            }
        };
    }, [currency, noFractionCurrencies]);
    
    return formatPrice;
}

export default useFormattedPrice;