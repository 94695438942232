import { useState } from 'react';
import Dropdown from 'react-dropdown';
import { FilterColor } from 'PageComponents/LuggageStore';
import { ReactComponent as ArrowIcon } from 'Assets/arrow.svg';

import styles from './LuggageDetailsForm.module.scss';
import { OptionSizeT } from '../LuggageDetailsMenu/LuggageDetailsMenu';
import { LuggageProductsSimpleColorT } from 'api/modules/store';

type initialStateT = {
    colors: string[];
};

type IProps = {
    size?: string[] | OptionSizeT[] | undefined;
    defaultSize?: OptionSizeT | null | undefined
    onSubmit: (arg: initialStateT) => void;
    onSizeChange?: (size: OptionSizeT) => void;
    colors?: LuggageProductsSimpleColorT[],
    defaultColor?: LuggageProductsSimpleColorT | null,
    actionChangeColor?: (color: LuggageProductsSimpleColorT) => void,
    mobile?: boolean
};

function LuggageDetailsForm({ size = [], defaultSize = null, onSizeChange, colors, defaultColor, actionChangeColor }: IProps) {
    const [selectedSize, setSelectedSize] = useState<OptionSizeT | null>(defaultSize);
    const [selectedColor, setSelectedColor] = useState<string[]>(defaultColor ? [defaultColor.value] : []);

    const handleColorChange = (color: LuggageProductsSimpleColorT) => {
        setSelectedColor([color.value]);
        actionChangeColor?.(color);
    };
    
    const handleSizeChange = (selectedOption: any) => {
        const newSize: OptionSizeT = {
          value: selectedOption.value,
          label: selectedOption.label,
        };
        setSelectedSize(newSize);
        onSizeChange?.(newSize);
    };

    return (
        <div className={styles.root}>
            {(colors && colors.length > 1) &&
                <FilterColor
                    colors={colors}
                    title="Color"
                    state={selectedColor}
                    noFilterBody
                    singleSelect
                    defaultColor={defaultColor || undefined}
                    actionChangeColor={handleColorChange}
                />
            }

            {size.length > 1 &&
                <Dropdown
                    options={size}
                    value={selectedSize ? selectedSize : defaultSize}
                    controlClassName={styles.dropdown}
                    menuClassName={styles.dropdownMenu}
                    onChange={handleSizeChange}
                    disabled={size.length <= 1}
                    arrowClassName={styles.arrowDown} 
                    arrowOpen={
                        <div className={styles.arrowDown}>
                            <div className={styles.line} />
                            <ArrowIcon className={styles.arrowOpen} />
                        </div>
                    }
                    arrowClosed={
                        <div className={styles.arrowDown}>
                            <div className={styles.line} />
                            <ArrowIcon className={styles.arrowClosed} />
                        </div>
                    }
                />
            }
        </div>
    );
}

export default LuggageDetailsForm;