import {
  put,
  takeLatest,
  retry,
  select,
  call,
  delay,
} from 'redux-saga/effects';
import api from 'api';
import i18nSingleton from 'i18n';
import {
  changeLanguage,
  ChangeLanguageT,
  CHANGE_LANGUAGE,
  loadSupportedLanguagesRequest,
  loadSupportedLanguagesSuccess,
  loadSupportedLanguagesFailure,
} from 'Store/modules/i18n';
import { SET_USER, SetUserT } from '../modules/user';
import { AppStateT } from "Store/modules";

function* fetchWithRetry<Fn extends (...args: any[]) => any>(fn: Fn, ...args: Parameters<Fn>): any {
  return yield retry(3, 0, fn, ...args);
}

function* changeLanguageSaga({ data }: ChangeLanguageT) {
  try {
    yield delay(200);
    yield i18nSingleton().changeLanguage(data);
  } catch (err) {
    console.log(err);
  }
}

function* fetchSupportedLanguages() {
  try {
    yield put(loadSupportedLanguagesRequest());
    const { data } = yield fetchWithRetry(api().i18n.getSupportedLanguages);

    const supportedLanguage = data.map((option: { name: string; value: string, title: string }) => ({
      text: option.name,
      value: option.value,
      title: option.title || ''
    }));

    yield put(loadSupportedLanguagesSuccess(supportedLanguage));
  } catch (error) {
    yield put(loadSupportedLanguagesFailure(error as any));
  }
}

function* checkCurrentLanguageSaga({ data }: SetUserT): any {
  const { i18n } = yield select((state: AppStateT) => state);

  if (i18n && i18n.language !== data.language) {
    yield put(changeLanguage(data.language));
  }
}

export default function* i18n(): Generator {
  yield takeLatest(CHANGE_LANGUAGE, changeLanguageSaga);
  yield takeLatest(SET_USER, checkCurrentLanguageSaga);

  // yield call(fetchEnvData);
  yield call(fetchSupportedLanguages);
}
