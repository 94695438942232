import styles from './HelpLink.module.scss';
import { ReactComponent as Help } from 'Assets/help-menu.svg';
import { t } from 'i18next';

const HelpLink: React.FC<{ helpUrl: string }> = ({ helpUrl }) => (
    <div className={styles.linkMenu}>
        <a className={styles.linkAnchor} href={helpUrl} target="_blank" rel="noopener noreferrer">
            <div className={styles.iconLink}>
                <Help />
            </div>
            <div className={styles.textLink}>
                {t('common.helpCenter')}
            </div>
        </a>
    </div>
);
export default HelpLink;