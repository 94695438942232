import React, { useRef } from 'react';
import cx from 'classnames';
import useClickOutside from 'Helpers/useClickOutside';
import { ReactComponent as CrossIcon } from 'Assets/cross-icon.svg';
import styles from './SearchInput.module.scss';

interface ISearchInput {
  placeholder?: string;
  value: string;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  clear: () => void;
  className?: string;
  onClickOutside?: () => void;
  close?: () => void;
  onEnter?: (eventKey: string) => void,
  onBlur?: () => void
}

export default function SearchInput(props: ISearchInput) {
  const inputRef = useRef<HTMLInputElement>(null);
  useClickOutside(inputRef, () => {
    if (props.onClickOutside) {
      props.onClickOutside();
    }
  });

  return (
    <label
      className={cx(styles.root, props.className, {
        [styles.emptyField]: !props.value.length,
      })}
    >
      <input
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onBlur={props?.onBlur}
        autoFocus
        ref={inputRef}
        onKeyDown={(event) => {
          return event.key.toLowerCase() === 'enter' ? props.onEnter?.(event.key) : null;
        }}
      />

      {(!!props.value || !!props.close) && (
        <button className={styles.clear} onClick={props.value ? props.clear : props.close}>
          <CrossIcon />
        </button>
      )}
    </label>
  );
}
