import { useTranslation } from 'react-i18next';
import React from "react";

import { UrlActionTypeT } from '../Footer';
import { ReactComponent as LogOut } from 'Assets/log-out-menu.svg';
import { ReactComponent as Help } from 'Assets/help-menu.svg';
import { ReactComponent as Info } from 'Assets/info-menu.svg';
import { ReactComponent as LogIn } from 'Assets/log-in-menu.svg';
import { ReactComponent as Language } from 'Assets/language-menu.svg';
import { ReactComponent as Cookies } from 'Assets/cookies-menu.svg';
import { ReactComponent as Regulations } from 'Assets/regulation-menu.svg';
import styles from './WrapperLink.module.scss';

type PropsMainT = {
    path: string;
    name: string;
    actionUrlClick: (e: React.MouseEvent, type: UrlActionTypeT) => void;
}

const WrapperLink: React.FC<PropsMainT> = ({ path, name, actionUrlClick }) => {
    const { t } = useTranslation();

    const renderIcon = () => {
        if (path.indexOf('/login') === 0) return <div className={styles.iconPadding}><LogIn/></div>;
        if (path.indexOf('/about') === 0) return <Info/>;
        if (path.indexOf('/help') === 0) return <Help/>;
        if (path.indexOf('/lang') === 0) return <Language/>;
        if (path.indexOf('/privacy') === 0) return <Cookies/>;
        if (path.indexOf('/terms') === 0) return  <div className={styles.iconPadding}><Regulations/></div>;
        if (path.indexOf('/personalInfo') === 0) return <Info/>;
        if (path.indexOf('/logOut') === 0) return <LogOut/>;
        return null;
    };

    const getActionType = (): UrlActionTypeT => {
        if (path.indexOf('/login') === 0) return 'login';
        if (path.indexOf('/about') === 0) return 'about';
        if (path.indexOf('/help') === 0) return 'help';
        if (path.indexOf('/lang') === 0) return 'language';
        if (path.indexOf('/privacy') === 0) return 'privacy';
        if (path.indexOf('/terms') === 0) return 'terms';
        if (path.indexOf('/personalInfo') === 0) return 'personalInfo';
        if (path.indexOf('/logOut') === 0) return 'logOut';
        return 'login';
    };

    const handleClick = (event: React.MouseEvent) => {
        const actionType = getActionType();
        
        if (actionUrlClick) {
            if (path.indexOf('/login') === 0 || path.indexOf('/about') === 0) {
                setTimeout(() => {
                    actionUrlClick(event, actionType);
                }, 500); 
            } else {
                actionUrlClick(event, actionType);
            }
        }
    };

    return (
        <div className={styles.menuElement} onClick={handleClick}>
            <div className={styles.iconMenu}>
                {renderIcon()}
            </div>
            <div className={styles.menuLabel}>
                {t(name)}
            </div>
        </div>
    );
};

export default WrapperLink;
