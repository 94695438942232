import React from 'react';
import cx from 'classnames';

import { useDeviceType } from 'Helpers/responsiveContainers';
import { ReactComponent as ArrowIcon } from 'Assets/arrow.svg';

import styles from './FilterHeader.module.scss';

interface IProps {
  onClickHandler: () => void;
  title: string;
  isOpen: boolean;
}

export default function Header(props: IProps) {
  const { title, onClickHandler, isOpen } = props;
  const { isNotMobile } = useDeviceType();
  return (
    <button
      className={cx(styles.header, {
        [styles.isOpen]: isOpen,
        [styles.desktopHeader]: isNotMobile,
      })}
      onClick={onClickHandler}
      type="button"
    >
      <p className={styles.title}>{title}</p>
      <span className={styles.arrow}>
        <ArrowIcon />
      </span>
    </button>
  );
}
